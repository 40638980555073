import React, { useContext } from 'react';
import { FieldValues } from 'react-hook-form';

import { GridRowId } from '@mui/x-data-grid-pro';

import {
  addOriginatorScoreClass,
  deleteOriginatorScoreClass,
  getDefaultScoreClasses,
  getOriginatorScoreClassMargins,
  getOriginatorScoreClasses,
} from '../../../../../../api/controllers/ScoreClassController';
import {
  OriginatorScoreClassMargin,
  OriginatorScoreClassMarginCreate,
} from '../../../../../../api/types/originatorTypes';
import { originatorScoreClassesColumns } from '../../../../../../common/tableColumns/originators/details/originatorScoreClassesColumns';
import { originatorScoreClassesMarginColumns } from '../../../../../../common/tableColumns/originators/details/originatorScoreClassesMarginColumns';
import {
  CLOSE_CONFIRMATION_DIALOG,
  OPEN_CONFIRMATION_DIALOG,
  OPEN_FORM_DIALOG,
} from '../../../../../../utils/constants/actions';
import { ADD_SCORE_CLASS_BUTTON } from '../../../../../../utils/constants/buttons';
import { ZERO_NUMBER_VALUE } from '../../../../../../utils/constants/common';
import { DELETE_SCORE_CLASS_CONFIRMATION } from '../../../../../../utils/constants/confirmations';
import {
  ADD_ORIGINATOR_SCORE_CLASS_FORM_ID,
  DELETE_ORIGINATOR_SCORE_CLASS_DIALOG_ID,
  UPDATE_ORIGINATOR_SCORE_CLASS_FORM_ID,
} from '../../../../../../utils/constants/identifiers';
import { ORIGINATOR_SCORE_CLASS_MARGIN_PERMISSION } from '../../../../../../utils/constants/permissions';
import {
  ADD_SCORE_CLASS_TITLE,
  DELETE_SCORE_CLASS_TITLE,
  UPDATE_SCORE_CLASS_TITLE,
} from '../../../../../../utils/constants/titles';
import { FormDialogSubmitType } from '../../../../../../utils/enums/FormDialogSubmitType';
import { closeFormHandler } from '../../../../../../utils/helpers/closeFormHandler';
import { convertExponentialToDecimal } from '../../../../../../utils/helpers/commonHelper';
import { usePermissions } from '../../../../../../utils/hooks/permissions/usePermissions';
import { OriginatorScoreClassRequest } from '../../../../../../utils/interfaces/originator/details/OriginatorScoreClassRequest';
import { Context } from '../../../../../../utils/store';
import { ContextType } from '../../../../../../utils/store/types/ContextType';
import { OriginatorTabsHeaderView } from '../../OriginatorTabsHeaderView';
import { OriginatorScoreClassesTableView } from './OriginatorScoreClassesTableView';
import { AddOriginatorScoreClassForm } from './forms/AddOriginatorScoreClassForm';
import { UpdateOriginatorScoreClassForm } from './forms/UpdateOriginatorScoreClassForm';

export const OriginatorScoreClassesTab = (props: { originatorId: number }) => {
  const { originatorId } = props;

  const { dispatch } = useContext<ContextType>(Context);

  const permissions = usePermissions();

  const originatorScoreClasses = permissions.hasReadPermission(
    ORIGINATOR_SCORE_CLASS_MARGIN_PERMISSION,
  )
    ? getOriginatorScoreClassMargins(originatorId)
    : getOriginatorScoreClasses(originatorId);

  const scoreClassColumns = permissions.hasReadPermission(ORIGINATOR_SCORE_CLASS_MARGIN_PERMISSION)
    ? originatorScoreClassesMarginColumns
    : originatorScoreClassesColumns;

  const allScoreClasses = getOriginatorScoreClasses(originatorId);
  const defaultScoreClasses = getDefaultScoreClasses(originatorId);

  const addOriginatorScoreClassMutation = addOriginatorScoreClass(originatorId);
  const deleteOriginatorScoreClassMutation = deleteOriginatorScoreClass();

  const originatorScoreClassMarginPermissionType = ORIGINATOR_SCORE_CLASS_MARGIN_PERMISSION;

  const handleAddSubmit = (data: FieldValues): void => {
    const choosenScoreClass = defaultScoreClasses.find((x) => x.id === data.scoreClassId);

    // By default we set scoreTo to be from chosen score class.
    // If user wants he can submit new scoreTo number.
    const scoreTo = parseFloat(
      data.scoreTo || convertExponentialToDecimal(choosenScoreClass?.scoreTo || ZERO_NUMBER_VALUE),
    );
    const payloadData = {
      ...data,
      scoreClassId: choosenScoreClass?.id,
      originatorId,
      scoreTo: scoreTo,
    };

    addOriginatorScoreClassMutation
      .mutateAsync(payloadData as OriginatorScoreClassMarginCreate)
      .then((response) => {
        closeFormHandler(dispatch, response.status);
      });
  };

  const handleAdd = (): void => {
    dispatch({
      type: OPEN_FORM_DIALOG,
      payload: {
        title: ADD_SCORE_CLASS_TITLE,
        content: (
          <AddOriginatorScoreClassForm
            onSubmit={handleAddSubmit}
            scoreClasses={defaultScoreClasses}
          />
        ),
        formId: ADD_ORIGINATOR_SCORE_CLASS_FORM_ID,
        submitType: FormDialogSubmitType.Add,
      },
    });
  };

  const handleEdit = (id: number): void => {
    const scoreClass = originatorScoreClasses.find((x) => x.id === id);

    if (scoreClass) {
      dispatch({
        type: OPEN_FORM_DIALOG,
        payload: {
          title: UPDATE_SCORE_CLASS_TITLE,
          content: (
            <UpdateOriginatorScoreClassForm
              defaultScoreClasses={defaultScoreClasses}
              scoreClasses={allScoreClasses}
              scoreClass={scoreClass as OriginatorScoreClassMargin}
              originatorId={originatorId}
              originatorScoreClassId={id}
            />
          ),
          formId: UPDATE_ORIGINATOR_SCORE_CLASS_FORM_ID,
          submitType: FormDialogSubmitType.Edit,
        },
      });
    }
  };

  const handleDelete = (id: GridRowId): void => {
    dispatch({
      type: OPEN_CONFIRMATION_DIALOG,
      payload: {
        dialogId: DELETE_ORIGINATOR_SCORE_CLASS_DIALOG_ID,
        title: DELETE_SCORE_CLASS_TITLE,
        content: DELETE_SCORE_CLASS_CONFIRMATION,
        onAgree: () => handleDeleteConfirm(Number(id)),
      },
    });
  };

  const handleDeleteConfirm = (originatorScoreClassId: number): void => {
    const request = {
      originatorId: originatorId,
      originatorScoreClassId: originatorScoreClassId,
    } as OriginatorScoreClassRequest;

    deleteOriginatorScoreClassMutation.mutateAsync(request);

    dispatch({ type: CLOSE_CONFIRMATION_DIALOG });
  };

  return (
    <>
      <OriginatorTabsHeaderView
        buttonLabel={ADD_SCORE_CLASS_BUTTON}
        onButtonClick={handleAdd}
        permissionType={originatorScoreClassMarginPermissionType}
      />
      <OriginatorScoreClassesTableView
        scoreClasses={originatorScoreClasses}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
        permissionType={originatorScoreClassMarginPermissionType}
        scoreClassColumns={scoreClassColumns}
      />
    </>
  );
};
