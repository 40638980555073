import React, { useState } from 'react';
import { useNavigate } from 'react-router';

import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import logoWhite from '../../assets/logo/logo-white.png';
import { flexGrowStyle } from '../../styles/commonStyles';
import {
  headerLogoutButtonStyles,
  headerMainLogoStyles,
  headerMenuButtonStyles,
} from '../../styles/headerStyles';
import { logOut } from '../../utils/authentication/authentication';
import { LOG_OUT_BUTTON } from '../../utils/constants/buttons';
import { ACCOUNT_MENU_ID, HEADER_WHITE_LOGO_ALT } from '../../utils/constants/common';
import { ICON_SIZE_LARGE } from '../../utils/constants/components';
import { ROUTE_PATH_HOME, ROUTE_PATH_LOGIN } from '../../utils/constants/routes';
import { COLOR_INHERIT } from '../../utils/constants/styles';
import { ButtonVariants } from '../../utils/enums/ButtonVariants';
import { IconPositionOptions } from '../../utils/enums/IconPositionOptions';
import { HeaderProps } from '../../utils/interfaces/layout/HeaderProps';
import { AccountMenu } from '../common/AccountMenu';

export const MainHeaderView = (props: HeaderProps) => {
  const { toggleDrawer } = props;
  const history = useNavigate();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const isAccountMenuOpen = Boolean(anchorEl);

  const logout = async (): Promise<void> => {
    await logOut();
    history(ROUTE_PATH_LOGIN);
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <AppBar sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Toolbar>
        <IconButton
          edge={IconPositionOptions.Start}
          color={COLOR_INHERIT}
          sx={headerMenuButtonStyles}
          onClick={toggleDrawer}
        >
          <MenuIcon />
        </IconButton>
        <Typography noWrap sx={flexGrowStyle}>
          <img
            src={logoWhite}
            alt={HEADER_WHITE_LOGO_ALT}
            style={headerMainLogoStyles}
            onClick={() => history(ROUTE_PATH_HOME)}
          />
        </Typography>
        <AccountMenu anchorEl={anchorEl} isOpen={isAccountMenuOpen} handleClose={handleMenuClose} />
        <Box>
          <IconButton edge={IconPositionOptions.End} color={COLOR_INHERIT} size={ICON_SIZE_LARGE}>
            <NotificationsIcon />
          </IconButton>
          <IconButton
            onClick={handleMenuOpen}
            edge={IconPositionOptions.End}
            color={COLOR_INHERIT}
            size={ICON_SIZE_LARGE}
            aria-controls={isAccountMenuOpen ? ACCOUNT_MENU_ID : undefined}
            aria-haspopup="true"
            aria-expanded={isAccountMenuOpen ? 'true' : undefined}
          >
            <AccountCircle />
          </IconButton>
          <Button sx={headerLogoutButtonStyles} variant={ButtonVariants.Contained} onClick={logout}>
            {LOG_OUT_BUTTON}
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
