import React from 'react';
import { useForm } from 'react-hook-form';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import { directSubmitStyles } from '../../../../styles/fileUploadStyles';
import { baseFormStyles } from '../../../../styles/formStyles';
import { TransactionTypeMockData } from '../../../../test/mocks/transactions/transactionDetails/TransactionTypeMockData';
import { FORM_TAG } from '../../../../utils/constants/components';
import {
  DATA_TYPE_ID,
  EXPORT_TRANSACTION_FORM_ID,
  HAS_ERROR_ID,
} from '../../../../utils/constants/identifiers';
import { ONLY_ERRORS_LABEL, TYPE_LABEL } from '../../../../utils/constants/labels';
import { FormProps } from '../../../../utils/interfaces/form/FormProps';
import { SelectorChoicesType } from '../../../../utils/interfaces/form/SelectorChoicesType';
import { SelectField } from '../../../common/form/SelectField';

export const ExportTransactionForm = (props: FormProps) => {
  const { onSubmit } = props;

  const { handleSubmit, register, control } = useForm();

  return (
    <Box
      component={FORM_TAG}
      id={EXPORT_TRANSACTION_FORM_ID}
      onSubmit={handleSubmit(onSubmit)}
      sx={baseFormStyles}
    >
      <SelectField
        id={DATA_TYPE_ID}
        label={TYPE_LABEL}
        control={control}
        choices={TransactionTypeMockData as SelectorChoicesType[]}
        required
      />
      <FormControlLabel
        control={<Checkbox />}
        label={ONLY_ERRORS_LABEL}
        sx={directSubmitStyles}
        {...register(HAS_ERROR_ID)}
      />
    </Box>
  );
};
