import React, { useContext, useState } from 'react';

import { getAllCountries } from '../../../api/controllers/NomenclatureController';
import {
  createOriginatorGroup,
  getAllOriginatorGroups,
  updateOriginatorGroup,
} from '../../../api/controllers/OriginatorGroupController';
import { OriginatorGroupBase } from '../../../api/types/originatorTypes';
import { OPEN_FORM_DIALOG } from '../../../utils/constants/actions';
import { ADD_GROUP_BUTTON } from '../../../utils/constants/buttons';
import { CREATE_GROUP_FORM_ID, UPDATE_GROUP_FORM_ID } from '../../../utils/constants/identifiers';
import { ORIGINATOR_GROUPS_PERMISSION } from '../../../utils/constants/permissions';
import { ADD_GROUP_DIALOG_TITLE, UPDATE_GROUP_DIALOG_TITLE } from '../../../utils/constants/titles';
import { FormDialogSubmitType } from '../../../utils/enums/FormDialogSubmitType';
import { closeFormHandler } from '../../../utils/helpers/closeFormHandler';
import { SelectorChoicesType } from '../../../utils/interfaces/form/SelectorChoicesType';
import { Context } from '../../../utils/store';
import { ContextType } from '../../../utils/store/types/ContextType';
import { GridHeaderView } from '../../common/grid/GridHeaderView';
import { CreateGroupForm } from '../../views/groups/forms/CreateGroupForm';
import { UpdateGroupForm } from '../../views/groups/forms/UpdateGroupForm';
import { GroupsDataGrid } from '../../views/groups/tables/GroupsDataGrid';

export const GroupsScreen = () => {
  const { state, dispatch } = useContext<ContextType>(Context);

  const [groupId, setGroupId] = useState<number>();

  const originatorGroupsData = getAllOriginatorGroups();
  const countries = getAllCountries();
  const createUpdateMutation =
    state.formDialog?.submitType === FormDialogSubmitType.Edit && groupId
      ? updateOriginatorGroup(groupId)
      : createOriginatorGroup();

  const originatorGroupsPermissionType = ORIGINATOR_GROUPS_PERMISSION;

  const handleSubmit = (data: OriginatorGroupBase): void => {
    createUpdateMutation.mutateAsync(data).then((response) => {
      closeFormHandler(dispatch, response.status);
    });
  };

  const handleEditClick = (id: number): void => {
    const group = originatorGroupsData.find((x) => x.id === id);

    if (!group) {
      return;
    }

    setGroupId(id);

    dispatch({
      type: OPEN_FORM_DIALOG,
      payload: {
        title: UPDATE_GROUP_DIALOG_TITLE,
        content: (
          <UpdateGroupForm
            onSubmit={handleSubmit}
            group={group}
            countries={countries as SelectorChoicesType[]}
          />
        ),
        formId: UPDATE_GROUP_FORM_ID,
        submitType: FormDialogSubmitType.Edit,
      },
    });
  };

  const handleAddClick = (): void => {
    dispatch({
      type: OPEN_FORM_DIALOG,
      payload: {
        title: ADD_GROUP_DIALOG_TITLE,
        content: (
          <CreateGroupForm onSubmit={handleSubmit} countries={countries as SelectorChoicesType[]} />
        ),
        formId: CREATE_GROUP_FORM_ID,
        submitType: FormDialogSubmitType.Add,
      },
    });
  };

  return (
    <>
      <GridHeaderView
        buttonLabel={ADD_GROUP_BUTTON}
        onButtonClick={handleAddClick}
        permissionType={originatorGroupsPermissionType}
      />
      <GroupsDataGrid
        handleEdit={handleEditClick}
        data={originatorGroupsData}
        permissionType={originatorGroupsPermissionType}
      />
    </>
  );
};
