import React, { useEffect } from 'react';
import { useContext } from 'react';
import { FieldValues } from 'react-hook-form';

import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import DownloadIcon from '@mui/icons-material/Download';
import { Button, Grid, Toolbar, Typography } from '@mui/material';

import {
  IMPORT_DRAFT_PERMISSION,
  IMPORT_PERMISSION,
  TRANSACTIONS_PERMISSION,
} from '../../../../../../src/utils/constants/permissions';
import {
  approveTransaction,
  draftImportFile,
  exportTransaction,
  importFile,
} from '../../../../../api/controllers/TransactionController';
import { ErrorCard } from '../../../../../components/common/ErrorCard';
import { flexGrowStyle } from '../../../../../styles/commonStyles';
import {
  cardsContainerStyles,
  transactionButtonStyles,
} from '../../../../../styles/transactionsStyles';
import {
  OPEN_CONFIRMATION_DIALOG,
  OPEN_FORM_DIALOG,
  SHOW_TRANSACTION_STATUS,
} from '../../../../../utils/constants/actions';
import {
  APPROVE_BUTTON,
  BUTTON_TYPE,
  EXPORT_BUTTON,
  UPDATE_BUTTON,
} from '../../../../../utils/constants/buttons';
import { APPROVE_TRANSACTION_CONFIRMATION } from '../../../../../utils/constants/confirmations';
import {
  APPROVE_TRANSACTION_DIALOG_ID,
  EXPORT_TRANSACTION_FORM_ID,
  IMPORT_TRANSACTION_FORM_ID,
} from '../../../../../utils/constants/identifiers';
import { ERRORS_LABEL, WARNINGS_LABEL } from '../../../../../utils/constants/labels';
import {
  APPROVE_TRANSACTION_TITLE,
  EXPORT_DIALOG_TITLE,
  IMPORT_DIALOG_TITLE,
} from '../../../../../utils/constants/titles';
import { ButtonVariants } from '../../../../../utils/enums/ButtonVariants';
import { FormDialogSubmitType } from '../../../../../utils/enums/FormDialogSubmitType';
import { TransactionStatus } from '../../../../../utils/enums/TransactionStatus';
import { closeFormHandler } from '../../../../../utils/helpers/closeFormHandler';
import { showLoader } from '../../../../../utils/helpers/commonHelper';
import { isButtonVisible } from '../../../../../utils/helpers/transactionButtonsVisibility';
import { usePermissions } from '../../../../../utils/hooks/permissions/usePermissions';
import { ErrorCardType } from '../../../../../utils/interfaces/ErrorCardProps';
import { TransactionInfoProps } from '../../../../../utils/interfaces/transaction/TransactionInfoProps';
import { Context } from '../../../../../utils/store';
import { ExportTransactionForm } from '../../forms/ExportTransactionForm';
import { ImportTransactionForm } from '../../forms/ImportTransactionForm';
import { TransactionInfoGrid } from '../../tables/TransactionInfoGrid';

export const TransactionInfoTab = (props: TransactionInfoProps) => {
  const { state, dispatch } = useContext(Context);
  const {
    data: { transaction },
  } = props;

  useEffect(() => {
    dispatch({ type: SHOW_TRANSACTION_STATUS, payload: transaction?.transactionStatus });
  }, [transaction?.transactionStatus]);

  const approveMutation = approveTransaction(transaction?.transId as string);
  const importMutation = importFile(transaction?.transId);
  const draftImportMutation = draftImportFile(transaction?.transId);

  const permissions = usePermissions();

  const importPermissionType = IMPORT_PERMISSION;
  const importDraftPermissionType = IMPORT_DRAFT_PERMISSION;
  const transactionsPermissionType = TRANSACTIONS_PERMISSION;

  const handleApproveConfirm = (): void => {
    showLoader(dispatch, APPROVE_TRANSACTION_DIALOG_ID);

    approveMutation.mutateAsync({ status: TransactionStatus.Published }).then((response) => {
      closeFormHandler(dispatch, response.status);
    });
  };

  const handleApproveClick = (): void => {
    dispatch({
      type: OPEN_CONFIRMATION_DIALOG,
      payload: {
        dialogId: APPROVE_TRANSACTION_DIALOG_ID,
        title: APPROVE_TRANSACTION_TITLE,
        content: APPROVE_TRANSACTION_CONFIRMATION,
        onAgree: handleApproveConfirm,
      },
    });
  };

  const handleExportClick = (): void => {
    if (transaction && transaction.transId) {
      dispatch({
        type: OPEN_FORM_DIALOG,
        payload: {
          title: EXPORT_DIALOG_TITLE,
          content: <ExportTransactionForm onSubmit={onExportSubmit} />,
          formId: EXPORT_TRANSACTION_FORM_ID,
          submitType: FormDialogSubmitType.Export,
        },
      });
    }
  };

  const onExportSubmit = (data: FieldValues): void => {
    if (transaction && transaction.transId) {
      const signal = state.controller && state.controller.signal;
      exportTransaction(transaction.transId, data.dataType, data.hasError, dispatch, signal);
    }
  };

  const onUpdateSubmit = (data: FieldValues): void => {
    showLoader(dispatch, IMPORT_TRANSACTION_FORM_ID);

    data.isDraft
      ? importMutation
          .mutateAsync({ ...data, originatorId: transaction?.originatorId })
          .then((response) => {
            closeFormHandler(dispatch, response.status);
          })
      : draftImportMutation
          .mutateAsync({ ...data, originatorId: transaction?.originatorId })
          .then((response) => {
            closeFormHandler(dispatch, response.status);
          });
  };

  const handleUpdateButtonClick = () => {
    dispatch({
      type: OPEN_FORM_DIALOG,
      payload: {
        title: IMPORT_DIALOG_TITLE,
        content: (
          <ImportTransactionForm onSubmit={onUpdateSubmit} permissionType={importPermissionType} />
        ),
        formId: IMPORT_TRANSACTION_FORM_ID,
        submitType: FormDialogSubmitType.Add,
      },
    });
  };

  return (
    <>
      <Toolbar disableGutters>
        <Typography sx={flexGrowStyle}>
          {isButtonVisible(
            transactionsPermissionType,
            state.user,
            transaction?.transactionStatus,
          ) && (
            <Button
              variant={ButtonVariants.Contained}
              type={BUTTON_TYPE}
              startIcon={<CheckIcon />}
              onClick={handleApproveClick}
            >
              {APPROVE_BUTTON}
            </Button>
          )}
        </Typography>
        {isButtonVisible(
          [importPermissionType, importDraftPermissionType],
          state.user,
          transaction?.transactionStatus,
        ) && (
          <Button
            variant={ButtonVariants.Contained}
            type={BUTTON_TYPE}
            startIcon={<AddIcon />}
            onClick={handleUpdateButtonClick}
          >
            {UPDATE_BUTTON}
          </Button>
        )}
        {permissions.hasReadPermission(importPermissionType) && (
          <Button
            variant={ButtonVariants.Contained}
            type={BUTTON_TYPE}
            startIcon={<DownloadIcon />}
            sx={transactionButtonStyles}
            onClick={handleExportClick}
          >
            {EXPORT_BUTTON}
          </Button>
        )}
      </Toolbar>
      <TransactionInfoGrid data={transaction} />
      <Grid container justifyContent="space-between" sx={cardsContainerStyles}>
        <ErrorCard
          title={ERRORS_LABEL}
          content={transaction?.loanErrorCount || 0}
          type={ErrorCardType.Error}
        />
        <ErrorCard
          title={WARNINGS_LABEL}
          content={transaction?.loanWarningCount || 0}
          type={ErrorCardType.Warning}
        />
      </Grid>
    </>
  );
};
