import React, { useContext, useState } from 'react';
import { FieldValues } from 'react-hook-form';

import { getAllCountries } from '../../../../../api/controllers/NomenclatureController';
import { createOriginatorCountry } from '../../../../../api/controllers/OriginatorSpvController';
import { getAllSpvs } from '../../../../../api/controllers/SPVController';
import { OriginatorCountryBase } from '../../../../../api/types/originatorTypes';
import { CLOSE_FORM_DIALOG } from '../../../../../utils/constants/actions';
import { ADD_COUNTRY_SPV_TITLE, ADD_COUNTRY_TITLE } from '../../../../../utils/constants/titles';
import { CountrySpvViewType } from '../../../../../utils/enums/CountrySpvViewType';
import { HttpStatusCodes } from '../../../../../utils/enums/HttpStatusCodes';
import { closeFormHandler } from '../../../../../utils/helpers/closeFormHandler';
import { useStepper } from '../../../../../utils/hooks/useStepper';
import { AddCountryStepperProps } from '../../../../../utils/interfaces/originator/details/AddCountryStepperProps';
import { Context } from '../../../../../utils/store';
import { StepperWrapper } from '../../../../common/StepperWrapper';
import { AddSpvWrapper } from './AddSpvWrapper';
import { CreateOriginatorCountryForm } from './CreateOriginatorCountryForm';

export const AddCountryStepper = (props: AddCountryStepperProps) => {
  const { originatorId, originatorCountryId } = props;
  const { dispatch } = useContext(Context);
  const [countryId, setCountryId] = useState<number>(0);

  const createCountryMutation = createOriginatorCountry(originatorId);

  const allCountries = getAllCountries();
  const allSpvs = getAllSpvs();

  const handleClose = () => {
    dispatch({ type: CLOSE_FORM_DIALOG });
  };

  const createCountry = (data: FieldValues) => {
    createCountryMutation.mutateAsync(data as OriginatorCountryBase).then((response) => {
      closeFormHandler(dispatch, response.status);
    });
  };

  const continueToSpv = (data: FieldValues) => {
    createCountryMutation.mutateAsync(data as OriginatorCountryBase).then((x) => {
      if (x.status !== HttpStatusCodes.BadRequest) {
        setCountryId(data.countryId);
        goToNextStep();
      }
    });
  };

  const countrySteps = [
    {
      label: ADD_COUNTRY_TITLE,
      component: (
        <CreateOriginatorCountryForm
          onSubmit={createCountry}
          handleClose={handleClose}
          onContinue={continueToSpv}
          countries={allCountries}
        />
      ),
    },
    {
      label: ADD_COUNTRY_SPV_TITLE,
      component: (
        <AddSpvWrapper
          handleClose={handleClose}
          spvs={allSpvs}
          countryId={countryId}
          originatorId={originatorId}
          originatorCountryId={originatorCountryId}
          view={originatorCountryId && CountrySpvViewType.Table}
        />
      ),
    },
  ];

  const { activeStep, goToNextStep } = useStepper(countrySteps, handleClose);

  return (
    <StepperWrapper
      steps={countrySteps}
      // Adding 1 more step if we have origCountryId to directly trigger stepper to second step.
      activeStep={originatorCountryId ? activeStep + 1 : activeStep}
    />
  );
};
