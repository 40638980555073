import React, { useContext, useEffect } from 'react';

import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import {
  breadcrumbChipStyles,
  breadcrumbsDividerStyles,
  breadcrumbsTitleStyles,
} from '../../../styles/breadcrumbsStyles';
import { HIDE_TRANSACTION_STATUS } from '../../../utils/constants/actions';
import { SIZE_SMALL } from '../../../utils/constants/styles';
import { StatusOptions } from '../../../utils/enums/StatusOptions';
import { TypographyVariants } from '../../../utils/enums/TypographyVariants';
import { getStatusLabel } from '../../../utils/helpers/transactionStatusHelper';
import { getStatusColor } from '../../../utils/helpers/transactionStatusHelper';
import { useBreadcrumbs } from '../../../utils/hooks/useBreadcrumbs';
import { useRouteChange } from '../../../utils/hooks/useRouteChange';
import { Context } from '../../../utils/store';
import { Breadcrumb } from './Breadcrumb';

export const BreadcrumbsView = () => {
  const breadcrumbs = useBreadcrumbs();
  const { dispatch, state } = useContext(Context);

  const [hasRouteChanged, currentRoute] = useRouteChange();

  useEffect(() => {
    if (hasRouteChanged) {
      dispatch({ type: HIDE_TRANSACTION_STATUS });
    }
  }, [currentRoute]);

  const title = breadcrumbs.length > 0 && breadcrumbs[breadcrumbs.length - 1].name;

  return (
    <>
      <Typography variant={TypographyVariants.H5} sx={breadcrumbsTitleStyles}>
        {title}
        {state.transactionStatusText && (
          <Chip
            sx={breadcrumbChipStyles}
            size={SIZE_SMALL}
            label={getStatusLabel(state.transactionStatusText).toUpperCase()}
            color={getStatusColor(state.transactionStatusText || StatusOptions.Default)}
          />
        )}
      </Typography>
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <Divider sx={breadcrumbsDividerStyles} />
    </>
  );
};
