import React from 'react';

import { GridColumns, GridRowId } from '@mui/x-data-grid-pro';

import { OriginatorScoreClass } from '../../../../../../api/types/originatorTypes';
import { GRID_ACTIONS } from '../../../../../../utils/constants/common';
import { GRID_ACTIONS_COLUMN_FLEX } from '../../../../../../utils/constants/styles';
import { GridColType } from '../../../../../../utils/enums/GridColType';
import { getGridColumns } from '../../../../../../utils/helpers/getGridColumns';
import { DataGridView } from '../../../../../common/grid/DataGridView';
import { GridActionsView } from '../../../../../common/grid/GridActionsView';

export const OriginatorScoreClassesTableView = (props: {
  scoreClasses: OriginatorScoreClass[];
  handleDelete?(id: GridRowId): void;
  handleEdit?(id: GridRowId): void;
  permissionType: string;
  scoreClassColumns: GridColumns;
}) => {
  const { handleEdit, handleDelete, scoreClasses, permissionType, scoreClassColumns } = props;

  const actions: GridColumns = [
    {
      field: GRID_ACTIONS,
      type: GridColType.Actions,
      flex: GRID_ACTIONS_COLUMN_FLEX,
      getActions: ({ id }) => [
        <GridActionsView
          key={id}
          handleEditClick={() => (handleEdit ? handleEdit(id) : null)}
          handleDeleteClick={() => (handleDelete ? handleDelete(id) : null)}
          permissionType={permissionType}
        />,
      ],
    },
  ];

  const columns: GridColumns = [...scoreClassColumns, ...actions];

  return (
    <DataGridView data={scoreClasses} columns={getGridColumns(columns)} disableColumnMenu={true} />
  );
};
