import React, { useContext } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { updateOriginatorSettings } from '../../../../../../api/controllers/OriginatorController';
import { OriginatorSettings } from '../../../../../../api/types/originatorTypes';
import { originatorSettingsFields } from '../../../../../../common/details/originatorSettingsFields';
import { tabToolbarStyles, tabToolbarTitleStyles } from '../../../../../../styles/commonStyles';
import {
  originatorDetailsEditButtonStyles,
  originatorTabsContentHeight,
} from '../../../../../../styles/originatorDetailsStyles';
import { OPEN_FORM_DIALOG } from '../../../../../../utils/constants/actions';
import { UPDATE_SETTINGS_FORM_ID } from '../../../../../../utils/constants/identifiers';
import { GENERAL_SETTINGS_LABEL } from '../../../../../../utils/constants/labels';
import { TOOLBAR_DENSE_VARIANT } from '../../../../../../utils/constants/options';
import { EDIT_SETTINGS_TITLE } from '../../../../../../utils/constants/titles';
import { FormDialogSubmitType } from '../../../../../../utils/enums/FormDialogSubmitType';
import { closeFormHandler } from '../../../../../../utils/helpers/closeFormHandler';
import { originatorSettingsResultHelper } from '../../../../../../utils/helpers/commonHelper';
import { usePermissions } from '../../../../../../utils/hooks/permissions/usePermissions';
import { OriginatorSettingsViewProps } from '../../../../../../utils/interfaces/originator/details/OriginatorSettingsViewProps';
import { Context } from '../../../../../../utils/store';
import { StyledDetail } from '../../StyledDetail';
import { UpdateSettingsForm } from './UpdateSettingsForm';

export const OriginatorSettingsView = (props: OriginatorSettingsViewProps) => {
  const { originatorSettings, permissionType, originatorId } = props;

  const { dispatch } = useContext(Context);

  const editMutation = updateOriginatorSettings(originatorId);

  const permissions = usePermissions();

  const onSubmit = (data: OriginatorSettings): void => {
    const payload = {
      ...data,
      isActivePrimaryMarket: false,
      isActiveSecondaryMarket: false,
    };

    editMutation.mutateAsync(payload).then((response) => {
      closeFormHandler(dispatch, response.status);
    });
  };

  const handleEditClick = (): void => {
    dispatch({
      type: OPEN_FORM_DIALOG,
      payload: {
        title: EDIT_SETTINGS_TITLE,
        content: (
          <UpdateSettingsForm
            originatorSettings={originatorSettings}
            onSubmit={onSubmit}
            permissionType={permissionType}
            originatorId={originatorId}
          />
        ),
        formId: UPDATE_SETTINGS_FORM_ID,
        submitType: FormDialogSubmitType.Edit,
      },
    });
  };

  return (
    <Box sx={originatorTabsContentHeight}>
      <Toolbar variant={TOOLBAR_DENSE_VARIANT} sx={tabToolbarStyles} disableGutters>
        <Typography sx={tabToolbarTitleStyles} gutterBottom>
          {GENERAL_SETTINGS_LABEL}
        </Typography>
        {permissions.hasUpdatePermission(permissionType) && (
          <IconButton onClick={handleEditClick}>
            <EditIcon sx={originatorDetailsEditButtonStyles} />
          </IconButton>
        )}
      </Toolbar>
      {originatorSettingsFields.map((x, index) => (
        <StyledDetail
          key={index}
          title={x.title}
          value={originatorSettingsResultHelper(originatorSettings && originatorSettings[x.id])}
        />
      ))}
    </Box>
  );
};
