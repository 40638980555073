import { GridColumns } from '@mui/x-data-grid-pro';

import { USERNAME_ID, USER_CLASS_ID } from '../../../utils/constants/identifiers';
import {
  ADMIN_IUVO_LABEL,
  EMAIL_LABEL,
  ORIGINATOR_LABEL,
  USER_CLASS_LABEL,
} from '../../../utils/constants/labels';
import { GRID_ACTIONS_COLUMN_FLEX } from '../../../utils/constants/styles';

export const accountsColumns: GridColumns = [
  { field: USERNAME_ID, headerName: EMAIL_LABEL, flex: GRID_ACTIONS_COLUMN_FLEX },
  {
    field: USER_CLASS_ID,
    headerName: USER_CLASS_LABEL,
    flex: GRID_ACTIONS_COLUMN_FLEX,
    valueGetter: ({ value }) => (value ? ADMIN_IUVO_LABEL : ORIGINATOR_LABEL),
  },
];
