import React, { useContext, useEffect, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { getAllNomenclaturesByType } from '../../../../api/controllers/NomenclatureController';
import { getAllOriginators } from '../../../../api/controllers/OriginatorController';
import { draftImportFile, importFile } from '../../../../api/controllers/TransactionController';
import { NomenclatureType } from '../../../../api/types/nomenclatureTypes';
import { AdvancedFilters } from '../../../../components/common/filter/AdvancedFilters';
import { OPEN_FORM_DIALOG } from '../../../../utils/constants/actions';
import {
  FILTERS_ADMIN_INITIAL_STATE,
  FILTERS_INITIAL_STATE,
} from '../../../../utils/constants/advancedFilters';
import { IMPORT_BUTTON } from '../../../../utils/constants/buttons';
import { IMPORT_TRANSACTION_FORM_ID } from '../../../../utils/constants/identifiers';
import { ID_LABEL } from '../../../../utils/constants/labels';
import {
  IMPORT_DRAFT_PERMISSION,
  IMPORT_PERMISSION,
} from '../../../../utils/constants/permissions';
import { ROUTE_PATH_TRANSACTIONS } from '../../../../utils/constants/routes';
import { IMPORT_DIALOG_TITLE } from '../../../../utils/constants/titles';
import { FormDialogSubmitType } from '../../../../utils/enums/FormDialogSubmitType';
import { TransactionStatus } from '../../../../utils/enums/TransactionStatus';
import { closeFormHandler } from '../../../../utils/helpers/closeFormHandler';
import { showLoader } from '../../../../utils/helpers/commonHelper';
import { usePermissions } from '../../../../utils/hooks/permissions/usePermissions';
import { FiltersInitialState } from '../../../../utils/interfaces/filter/FiltersInitialState';
import { Context } from '../../../../utils/store';
import { customHistory } from '../../../App';
import { GridHeaderView } from '../../../common/grid/GridHeaderView';
import { ImportTransactionForm } from '../../../views/transactions/forms/ImportTransactionForm';
import { TransactionDataGrid } from '../../../views/transactions/tables/TransactionDataGrid';

export const TransactionsScreen = () => {
  const { state, dispatch } = useContext(Context);
  const { user } = state;
  const isSystemUser = user?.user?.isSystemUser;

  const history = useNavigate();
  const permissions = usePermissions();
  const search = useLocation().search;

  const [searchParams] = useSearchParams();

  const currentOriginator = Number(searchParams.get('id')) || Number(state.originatorID);

  const setQueryParams = (params: URLSearchParams) => {
    params.set(ID_LABEL.toLowerCase(), JSON.stringify(currentOriginator));
    customHistory.push({ search: params.toString() });
  };

  useEffect(() => {
    const params = new URLSearchParams(search);
    if (!isSystemUser) {
      setQueryParams(params);
    }
  }, []);

  const importMutation = importFile();
  const draftImportMutation = draftImportFile();
  const onSubmit = (data: FieldValues): void => {
    const requestData = { ...data, originatorId: currentOriginator || data.originatorId };

    showLoader(dispatch, IMPORT_TRANSACTION_FORM_ID);

    data.isDraft
      ? importMutation.mutateAsync(requestData).then((response) => {
          closeFormHandler(dispatch, response.status);
        })
      : draftImportMutation.mutateAsync(requestData).then((response) => {
          closeFormHandler(dispatch, response.status);
        });
  };

  const importPermissionType = IMPORT_PERMISSION;
  const importDraftPermissionType = IMPORT_DRAFT_PERMISSION;

  const handleImportClick = (): void => {
    dispatch({
      type: OPEN_FORM_DIALOG,
      payload: {
        title: IMPORT_DIALOG_TITLE,
        content: (
          <ImportTransactionForm
            onSubmit={onSubmit}
            showOriginatorDropdown={currentOriginator ? false : true}
            permissionType={importPermissionType}
          />
        ),
        formId: IMPORT_TRANSACTION_FORM_ID,
        submitType: FormDialogSubmitType.Add,
      },
    });
  };

  const handleRowClick = (id: string): void => {
    if (permissions.hasReadPermission(importPermissionType)) {
      history(`${ROUTE_PATH_TRANSACTIONS}/${id}`);
    }
  };

  const toggleHeaderSections = (): void => {
    setIsAdvancedFiltersOpen(!isAdvancedFiltersOpen);
  };

  const [isAdvancedFiltersOpen, setIsAdvancedFiltersOpen] = useState(false);
  const [filters, setFilters] = useState<FiltersInitialState[]>(
    JSON.parse(JSON.stringify(isSystemUser ? FILTERS_ADMIN_INITIAL_STATE : FILTERS_INITIAL_STATE)),
  );
  const [beFilters, setBeFilters] = useState<any>({
    tableSettings: {
      pageSize: 10,
      pageNumber: 0,
    },
  });

  const originators = isSystemUser ? getAllOriginators() : [];
  const loanStatuses = getAllNomenclaturesByType(NomenclatureType.LOAN_STATUSES);
  const transcationStatuses = Object.keys(TransactionStatus).map((content: string) => {
    return { name: content, id: TransactionStatus[content] };
  });

  const nomenclatures = {
    originatorId: originators,
    loanStatusId: loanStatuses,
    transactionStatus: transcationStatuses,
  };

  return (
    <>
      <GridHeaderView
        buttonLabel={IMPORT_BUTTON}
        onButtonClick={handleImportClick}
        toggleHeaderSections={toggleHeaderSections}
        permissionType={[importPermissionType, importDraftPermissionType]}
        isAdvancedFiltersOpen={isAdvancedFiltersOpen}
      />
      <AdvancedFilters
        nomenclatures={nomenclatures}
        setIsAdvancedFiltersOpen={setIsAdvancedFiltersOpen}
        setBeFilters={setBeFilters}
        filters={filters}
        setFilters={setFilters}
        isAdvancedFiltersOpen={isAdvancedFiltersOpen}
      />
      <TransactionDataGrid
        originatorId={currentOriginator}
        filters={beFilters}
        handleRowClick={handleRowClick}
      />
    </>
  );
};
