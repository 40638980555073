import { FIELDS_KEY, LOAN_TYPES_KEY } from '../../utils/constants/keys';
import { FIELDS_LABEL, LOAN_TYPES_LABEL } from '../../utils/constants/labels';
import { TabsProps } from '../../utils/interfaces/tabs/TabsProps';

export const borrowerDataTabs: TabsProps[] = [
  {
    key: LOAN_TYPES_KEY,
    label: LOAN_TYPES_LABEL,
  },
  {
    key: FIELDS_KEY,
    label: FIELDS_LABEL,
  },
];
