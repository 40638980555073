export const CREATE_GROUP_FORM_ID = 'createGroupForm';
export const UPDATE_GROUP_FORM_ID = 'updateGroupForm';

export const CREATE_SPV_FORM_ID = 'createCreateSpvForm';
export const UPDATE_SPV_FORM_ID = 'updateCreateSpvForm';

export const CREATE_ORIGINATOR_FORM_ID = 'createOriginatorForm';
export const UPDATE_ORIGINATOR_FORM_ID = 'updateOriginatorForm';

export const CREATE_SCORE_CLASS_FORM_ID = 'createScoreClassForm';
export const UPDATE_SCORE_CLASS_FORM_ID = 'updateScoreClassForm';

export const IMPORT_TRANSACTION_FORM_ID = 'importTransactionForm';
export const EXPORT_TRANSACTION_FORM_ID = 'exportTransactionForm';

export const EXPORT_LOANS_ID = 'exportLoans';
export const REPORT_ID = 'Report';

export const CREATE_PRODUCT_FORM_ID = 'createProductForm';
export const UPDATE_PRODUCT_FORM_ID = 'updateProductForm';

export const CREATE_ACCOUNT_FORM_ID = 'createAccountForm';
export const EDIT_PERMISSIONS_FORM_ID = 'editPermissionsForm';

export const CREATE_ORIGINATOR_COUNTRY_FORM_ID = 'createOriginatorCountryForm';
export const ADD_COUNTRY_SPVS_FORM_ID = 'addCountrySpvsForm';

export const CREATE_BORROWER_DATA_FIELD_FORM_ID = 'createFieldForm';
export const UPDATE_BORROWER_DATA_FIELD_FORM_ID = 'updateFieldForm';
export const UPDATE_BORROWER_DATA_LOAN_FIELD_FORM_ID = 'updateLoanFieldForm';

export const CREATE_CURENCY_INVEST_AMOUNT_FORM_ID = 'createCurrencyInvestAmountForm';
export const UPDATE_CURENCY_INVEST_AMOUNT_FORM_ID = 'updateCurrencyInvestAmountForm';

export const APPROVE_TRANSACTION_DIALOG_ID = 'approveTransactionDialog';
export const DELETE_COUNTRY_SPV_DIALOG_ID = 'deleteCountrySpvDialog';
export const DELETE_ORIGINATOR_SCORE_CLASS_DIALOG_ID = 'deleteOriginatorScoreClassDialog';
export const DELETE_FIELD_DIALOG_ID = 'deleteFieldDialog';
export const DELETE_SCORE_CLASS_DIALOG_ID = 'deleteScoreClassDialog;';
export const DELETE_ACCOUNT_DIALOG_ID = 'deleteAccountDialog';
export const DELETE_ORIGINATOR_COUNTRY_SPV_ID = 'deleteOriginatorCountrySpvDialog';

export const EMAIL_FIELD_ID = 'email';
export const PASSWORD_FIELD_ID = 'password';
export const PASSWORD_FIELD_CONFIRM_ID = 'confirmPassword';
export const CHANGE_PASSWORD_FORM_ID = 'changePasswordForm';

export const ADD_ORIGINATOR_SCORE_CLASS_FORM_ID = 'addOriginatorScoreClassForm';
export const UPDATE_ORIGINATOR_SCORE_CLASS_FORM_ID = 'updateOriginatorScoreClassForm';

export const ID = 'id';
export const NAME_ID = 'name';
export const PUBLIC_NAME_ID = 'publicName';
export const LEGAL_NAME_ID = 'legalName';
export const TRADE_NAME_ID = 'tradeName';
export const REGISTRATION_NUMBER_ID = 'registrationNumber';
export const CEO_ID = 'ceo';
export const COUNTRY = 'country';
export const COUNTRY_OF_REGISTRATION_ID = 'countryOfRegistrationId';
export const ADDRESS_ID = 'address';
export const ORIGINATOR_GROUP = 'originatorGroup';
export const ORIGINATOR_GROUP_ID = 'originatorGroupId';
export const FEE = 'fee';
export const FEE_ID = 'feeId';
export const SCORE_TO_ID = 'scoreTo';
export const BUSINESS_STATUS_ID = 'businessStatusId';
export const SCORE_NUMBER_ID = 'scoreNumber';
export const FILE_ID = 'file';
export const LOAN_ID = 'loanId';
export const TRANSACTION_ID = 'transId';
export const KID_ID = 'kid';
export const TYPE_ID = 'typeId';
export const USE_OF_LOAN_ID = 'useOfLoanId';
export const LOAN_STATUS_ID = 'loanStatusId';
export const COLLATERAL_TYPE_ID = 'collateralTypeId';
export const COLLATERAL_AMOUNT_ID = 'collateralAmount';

export const PAY_SCHEDULE_ID = 'payScheduleId';
export const TRANS_ID = 'transId';
export const ORIGINATOR_ID = 'originatorId';
export const INST_NUMBER_ID = 'instNumber';
export const MATURITY_DATE_ID = 'maturityDate';
export const PRINCIPAL_AMOUNT_ID = 'principalAmount';
export const INTEREST_AMOUNT_ID = 'interestAmount';
export const IS_PAID_ID = 'isPaid';
export const BUSINESS_DAY_ID = 'bussinessDay';

export const ROW_ID = 'id';

export const PAY_ID = 'payId';
export const DATE_ID = 'date';
export const IS_GUARANT_ID = 'isGuarant';
export const IS_EARLY_ID = 'isEarly';

export const IS_DRAFT_ID = 'isDraft';
export const COUNT_ID = 'count';
export const PROCESSED_ID = 'processed';
export const SUBMITTED_ID = 'submitted';

export const TRANSACTION_FEE_ID = 'feeId';
export const AMOUNT_ID = 'amount';
export const PAY_AMOUNT_ID = 'payAmount';
export const PAY_DATE_ID = 'payDate';
export const FEE_PAY_DATE_ID = 'payDate';
export const TYPE = 'type';

export const AGE_ID = 'age';
export const CAR_COUNT_ID = 'carCount';
export const FREE_CASH_ID = 'freeCash';
export const GENDER_ID = 'genderId';
export const JOB_TYPE_ID = 'jobTypeId';
export const EDUCATION_ID = 'educationId';
export const INCOME_TOTAL_ID = 'incomeTotal';
export const GET_SALARY_ID = 'getSalaryId';
export const INCOME_SALARY_ID = 'incomeSalary';
export const GET_SALARY_DAY_ID = 'getSalaryDay';
export const INCOME_PENSION_ID = 'incomePension';
export const RESIDENCY_CITY_ID = 'residencyCity';
export const BORROWER_TYPE_ID = 'borrowerTypeId';
export const CAR_OWNERSHIP_ID = 'carOwnershipId';
export const RESIDENCY_REGION_ID = 'residencyRegion';
export const SALARY_PERIOD_ID = 'salaryPeriodId';
export const LIABILITIES_PHONE_ID = 'liabilitiesPhone';
export const LIABILITIES_TOTAL_ID = 'liabilitiesTotal';
export const MARTIAL_STATUS_ID = 'maritalStatusId';
export const RESIDENCY_COUNTRY_ID = 'residencyCountry';
export const OCCUPATION_AREA_ID = 'occupationAreaId';
export const EMPLOYMENT_STATUS_ID = 'employmentStatusId';
export const FAMILY_MEMBERS_COUNT_ID = 'familyMembersCount';
export const INCOME_PARTNER_SALARY_ID = 'incomePartnerSalary';
export const PROPERTY_OWNERSHIP_ID = 'propertyOwnershipId';
export const WORK_EXPERIENCE_YEARS_ID = 'workExpirienceYears';
export const HOME_OWNERSHIP_TYPE_ID = 'homeOwnershipTypeId';
export const LIABILITIES_NET_TV_ID = 'liabilitiesNetAndTv';
export const LIABILITIES_ELECTRICITY_ID = 'liabilitiesElectricity';
export const WORK_EXPERIENCE_MONTHS_ID = 'workExpirienceTotalMonths';
export const WORK_EXPERIENCE_CURR_EMPLOYER_YEARS_ID = 'workExpirienceCurrentEmployerYears';
export const WORK_EXPERIENCE_CURR_EMPLOYER_MONTHS_ID = 'workExpirienceCurrentEmployerMonths';
export const REAL_ESTATE_SIZE_ID = 'realEstateSize';
export const REAL_ESTATE_COUNTRY_CODE_ID = 'realEstateCountryCode';
export const REAL_ESTATE_REGION_ID = 'realEstateRegion';
export const BRAND_MODEL_ID = 'brandAndModel';
export const APPRAISER_ID = 'appraiser';
export const MANUFACTURING_YEAR_ID = 'manufacturingYear';
export const REGISTRATION_DATE_ID = 'registrationDate';
export const BUSINESS_SECTOR_ID = 'businessSectorId';
export const ACTIVITY_DESCRIPTION_ID = 'activityDescr';
export const COLLATERAL_VALUATION_DATE_ID = 'collateralValuationDate';
export const REGISTRATION_COUNTRY_CODE_ID = 'registrationCountryCode';
export const SELLER_BUSINESS_DESCRIPTION_ID = 'sellerBusinessDescr';
export const BUYER_BUSINESS_DESCRIPTION_ID = 'buyerBusinessDescr';
export const ADVANCE_PERCENTAGE_ID = 'advancePerc';
export const FACTORING_TRANS_TYPE_ID = 'factoringTransTypeId';
export const HAS_RECOURSE_ID = 'hasRecourse';
export const HAS_INVOICE_INSURANCE_ID = 'hasInvoiceInsurance';
export const ERROR_ID = 'error';
export const STATUS_ID = 'status';

export const AMORTIZATION_METHOD_ID = 'amortizationMethodId';
export const CURRENCY_ID = 'currencyId';
export const DEFAULTED_ON_DAY_ID = 'defaultedOnDay';
export const END_DATE_ID = 'endDate';
export const GUARANT_INTEREST_PERC_ID = 'guarantInterestPerc';
export const GUARANT_PERIOD_DAYS_ID = 'guarantPeriodDays';
export const GUARANT_PRINCIPAL_PERC_ID = 'guarantPrincipalPerc';
export const INST_COUNT_ID = 'instCount';
export const INST_PERIOD_ID = 'instPeriodId';
export const INST_PERIOD_NUM_ID = 'instPeriodNum';
export const INVEST_PART_AMOUNT_ID = 'investPartAmount';
export const INVEST_PART_PERC_ID = 'investPartPerc';
export const IS_LISTED_ID = 'isListed';
export const ISSUE_DATE_ID = 'issueDate';
export const LATE_FEE_TERMS_ID = 'lateFeeTerms';
export const LATE_FEES_PAYABLE_ID = 'lateFeesPayable';
export const LOAN_IMPORT_STATUS_ID = 'loanImportStatus';
export const MARGIN_INTEREST_PERC_ID = 'marginInterestPerc';
export const MINIMUM_INVEST_AMOUNT_ID = 'minimumInvestAmount';
export const ORIG_CURRENCY_ID = 'origCurrency';
export const ORIG_INTEREST_AMOUNT_ID = 'origInterestAmount';
export const ORIG_INTEREST_PERC_ID = 'origInterestPerc';
export const ORIGINATOR_SCORE_CLASS_ID = 'originatorScoreClassId';
export const ORIGINATOR_SCORE_CLASS_PUBLIC_NAME_ID = 'originatorScoreClassPublicName';
export const PAY_TERM_POLICY_ID = 'payTermPolicy';
export const PD_PERC_ID = 'pdPerc';
export const WARNING_ID = 'warning';
export const YEAR_DAYS_BASE_ID = 'yearDaysBase';
export const PRODUCT_ID = 'productId';
export const PRODUCT_NAME_ID = 'productName';
export const GUARANT_ACTIVATION_DATE_ID = 'guarantActivationDate';
export const INTEREST_RATE_MIN_ID = 'interestRateMin';
export const INTEREST_RATE_MAX_ID = 'interestRateMax';
export const INVEST_PART_PERCENT_ID = 'investPartPercent';
export const LOAN_CURRENCY_ID = 'loanCurrencyId';
export const LOANS_CURRENCY_ID = 'currency';
export const NUMBER_OF_INSTALMENTS_MIN_ID = 'numberOfInstalmentsMin';
export const NUMBER_OF_INSTALMENTS_MAX_ID = 'numberOfInstalmentsMax';
export const LOAN_GUARANT_PRINCIPAL_PERC_ID = 'loanGuarantPrincipalPerc';
export const LOAN_GUARANT_INTEREST_PERC_ID = 'loanGuarantInterestPerc';
export const LOAN_GUARANT_PERIOD_DAYS_ID = 'loanGuarantPeriodDays';
export const APPLICATION_MODE_ID = 'applicationMode';
export const INSTALLMENT_TYPE_ID = 'installmentTypeId';
export const INSTALLMENT_TYPE_NUMBER_ID = 'installmentTypeNumber';
export const LOAN_TYPE_ID = 'loanTypeId';
export const COUNTRY_ID = 'countryId';
export const HAS_ERROR_ID = 'hasError';
export const ERROR_DESC_ID = 'errorDesc';
export const USERNAME_ID = 'username';
export const USER_CLASS_ID = 'isSystemUser';

export const APP_MODE_VALIDATE_ID = 'VALIDATE';
export const APP_MODE_ADD_IF_MISSING_ID = 'ADD_IF_MISSING';
export const APP_MODE_OVERRIDE_ID = 'OVERRIDE';
export const VALID_TO_ID = 'validToId';
export const FACILITY_AGREEMENT_NUMBER_ID = 'facilityAgreementNumberId';
export const FACILITY_AGREEMENT_NUMBER = 'facilityAgreementNumber';
export const SPV_ID = 'spvId';
export const VALID_TO_DATE_ID = 'validToDate';
export const DATA_TYPE_ID = 'dataType';

export const LOANS_TRANSACTION_ID = 'LOANS';
export const PAYMENT_SCHEDULES_TRANSACTION_ID = 'PAY_SCHEDULES';
export const PAYMENTS_TRANSACTION_ID = 'PAYMENTS';
export const FEES_TRANSACTION_ID = 'FEES';
export const BORROWER_DATA_TRANSACTION_ID = 'BORROWER_DATA';
export const ALL_TRANSACTION_ID = 'ALL';
export const MULTI_ORIGINATOR_USER_ID = 'multiOriginatorUser';
export const IS_SYSTEM_USER_ID = 'isSystemUser';
export const INTEREST_MARGIN_ID = 'interestMargin';
export const SCORE_CLASS_ID = 'scoreClassId';
export const LABEL_ID = 'label';
export const DESCRIPTION_ID = 'description';
export const FIELD_ID = 'field';

export const CURRENCY_INVEST_ID = 'currencyId';
export const MIN_PRIMARY_AMOUNT_ID = 'minAmountPrimary';
export const MIN_SECONDARY_AMOUNT_ID = 'minAmountSecondary';

export const VALID_FROM_ID = 'validFrom';
export const FEE_AMOUNT_ID = 'amount';
export const FEE_TYPE_ID = 'feeType';
export const FEE_DATE_ID = 'date';
export const PAYMENT_ID = 'payId';
export const SPV_FACILITY_AGREEMENT_NUMBER = 'spvFacilityAgreementNumber';
export const SPV_VALID_TO_DATE_ID = 'spvValidToDate';
export const ORIGINATOR_NAME_ID = 'originatorName';
export const IS_ACTIVE_IMPORT_LOANS_ID = 'isActiveImportLoans';
export const IS_ACTIVE_IMPORT_PAYMENTS_ID = 'isActiveImportPayments';
export const IS_ACTIVE_PRIMARY_MARKET_ID = 'isActivePrimaryMarket';
export const IS_ACTIVE_SECONDARY_MARKET_ID = 'isActiveSecondaryMarket';
export const UPDATE_SETTINGS_FORM_ID = 'updateSettingsFormId';
