export const formMainStyles = {
  maxWidth: 500,
  margin: 'auto',
};

export const authTitleStyles = {
  fontSize: {
    lg: 58,
    xs: 40,
  },
  fontWeight: 'bold',
};

export const authSubTitleStyles = {
  color: '#0000008A',
  fontSize: {
    lg: 18,
    xs: 16,
  },
};

export const authFieldStyles = {
  width: '100%',
};

export const authRequiredFieldStyles = {
  fontStyle: 'italic',
};

export const authButtonViewStyles = {
  mt: 3,
  mb: 2,
  width: authFieldStyles.width,
};

export const authLinkButtonStyles = {
  ml: 1,
  fontWeight: 'bold',
  fontSize: {
    lg: 18,
    xs: 16,
  },
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
};

export const alertStyles = {
  mt: 1,
};

export const warningAlertIconStyles = {
  color: '#663C00',
};
