import React from 'react';

import { Grid } from '@mui/material';

import { roleItemStyles } from '../../../../styles/accountsStyles';
import { getRoleLabel } from '../../../../utils/helpers/rolesHelpers';
import { PermissionItemProps } from '../../../../utils/interfaces/PermissionItemProps';
import { CheckboxField } from '../../../common/form/CheckboxField';

export const PermissionItem = (props: PermissionItemProps) => {
  const { availableRole, availableRoleDescription, register, isChecked, disabled, setValue } =
    props;

  return (
    <Grid container item sx={roleItemStyles}>
      <CheckboxField
        id={availableRole as string}
        label={getRoleLabel(availableRole as string)}
        tooltip={availableRoleDescription}
        register={register}
        isChecked={isChecked}
        disabled={disabled}
        setValue={setValue}
      />
    </Grid>
  );
};
