import * as React from 'react';
import { useContext } from 'react';

import { Toolbar } from '@mui/material';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';

import { displayFlex, versionContainer } from '../../../styles/commonStyles';
import {
  sidebarDrawerDesktopStyles,
  sidebarDrawerMobileStyles,
  sidebarMainStyles,
} from '../../../styles/sidebarStyles';
import { DrawerVariants } from '../../../utils/enums/DrawerVariants';
import { SidebarProps } from '../../../utils/interfaces/layout/SidebarProps';
import { Context } from '../../../utils/store';
import { UserInfo } from './UserInfo';
import { OriginatorSelect } from './tabs/OriginatorSelect';
import { SidebarTabs } from './tabs/SidebarTabs';

export const SidebarView = (props: SidebarProps) => {
  const { visible } = props;

  const { state } = useContext(Context);
  const { user } = state;
  const isSystemUser = user?.user?.isSystemUser;

  return (
    <Box sx={displayFlex}>
      <CssBaseline />
      <Box sx={sidebarMainStyles}>
        <Drawer
          variant={DrawerVariants.Temporary}
          open={visible}
          ModalProps={{
            keepMounted: true,
          }}
          sx={sidebarDrawerMobileStyles}
          disableScrollLock={true}
        >
          <Toolbar />
          <UserInfo />
          {!isSystemUser ? <OriginatorSelect /> : []}
          <SidebarTabs />
        </Drawer>
        <Drawer variant={DrawerVariants.Persistent} sx={sidebarDrawerDesktopStyles} open={visible}>
          <Box>
            <Toolbar />
            <UserInfo />
            {!isSystemUser ? <OriginatorSelect /> : []}
            <SidebarTabs />
          </Box>
          <Box sx={versionContainer}>v 1.4.0</Box>
        </Drawer>
      </Box>
    </Box>
  );
};
