import React, { useContext, useState } from 'react';

import { getAllCountries } from '../../../api/controllers/NomenclatureController';
import { createSpv, getAllSpvs, updateSpv } from '../../../api/controllers/SPVController';
import { SpvBase } from '../../../api/types/originatorTypes';
import { OPEN_FORM_DIALOG } from '../../../utils/constants/actions';
import { ADD_SPV_BUTTON } from '../../../utils/constants/buttons';
import { CREATE_SPV_FORM_ID, UPDATE_SPV_FORM_ID } from '../../../utils/constants/identifiers';
import { SPV_PERMISSION } from '../../../utils/constants/permissions';
import { ADD_SPV_DIALOG_TITLE, UPDATE_SPV_DIALOG_TITLE } from '../../../utils/constants/titles';
import { FormDialogSubmitType } from '../../../utils/enums/FormDialogSubmitType';
import { closeFormHandler } from '../../../utils/helpers/closeFormHandler';
import { SelectorChoicesType } from '../../../utils/interfaces/form/SelectorChoicesType';
import { Context } from '../../../utils/store';
import { ContextType } from '../../../utils/store/types/ContextType';
import { GridHeaderView } from '../../common/grid/GridHeaderView';
import { CreateSpvForm } from '../../views/spvs/forms/CreateSpvForm';
import { UpdateSpvForm } from '../../views/spvs/forms/UpdateSpvForm';
import { SpvsDataGrid } from '../../views/spvs/tables/SpvsDataGrid';

export const SpvsScreen = () => {
  const { state, dispatch } = useContext<ContextType>(Context);

  const [spvId, setSpvId] = useState<number>();

  const spvsData = getAllSpvs();
  const countries = getAllCountries();

  const createUpdateMutation =
    state.formDialog?.submitType === FormDialogSubmitType.Edit && spvId
      ? updateSpv(spvId)
      : createSpv();

  const spvPermissionType = SPV_PERMISSION;

  const handleSubmit = (data: SpvBase): void => {
    createUpdateMutation.mutateAsync(data).then((response) => {
      closeFormHandler(dispatch, response.status);
    });
  };

  const handleEditClick = (id: number): void => {
    const spv = spvsData.find((x) => x.id === id);

    if (!spv) {
      return;
    }

    setSpvId(id);

    dispatch({
      type: OPEN_FORM_DIALOG,
      payload: {
        title: UPDATE_SPV_DIALOG_TITLE,
        content: (
          <UpdateSpvForm
            onSubmit={handleSubmit}
            countries={countries as SelectorChoicesType[]}
            spv={spv}
          />
        ),
        formId: UPDATE_SPV_FORM_ID,
        submitType: FormDialogSubmitType.Edit,
      },
    });
  };

  const handleAddClick = (): void => {
    dispatch({
      type: OPEN_FORM_DIALOG,
      payload: {
        title: ADD_SPV_DIALOG_TITLE,
        content: (
          <CreateSpvForm onSubmit={handleSubmit} countries={countries as SelectorChoicesType[]} />
        ),
        formId: CREATE_SPV_FORM_ID,
        submitType: FormDialogSubmitType.Add,
      },
    });
  };

  return (
    <>
      <GridHeaderView
        buttonLabel={ADD_SPV_BUTTON}
        onButtonClick={handleAddClick}
        permissionType={spvPermissionType}
      />
      <SpvsDataGrid
        handleEdit={handleEditClick}
        data={spvsData}
        permissionType={spvPermissionType}
      />
    </>
  );
};
