import { nameof } from 'ts-simple-nameof';

import { useGetRequest } from '../../utils/hooks/query/useGetRequest';
import { useMutationRequest } from '../../utils/hooks/query/useMutationRequest';
import { DeleteOriginatorCountryRequest } from '../../utils/interfaces/originator/details/DeleteOriginatorCountryRequest';
import { originatorServiceClient } from '../apiClient';
import {
  Api,
  OriginatorCountry,
  OriginatorCountryBase,
  OriginatorCountrySpvResponseData,
  OriginatorCountrySpvSetting,
  OriginatorCountrySpvSettingBase,
  OriginatorSpv,
} from '../types/originatorTypes';

export const getOriginatorSpvs = (originatorId: number): OriginatorSpv => {
  const params = { originatorId };

  const { data: originatorSpvs } = useGetRequest({
    key: nameof<Api<unknown>>((x) => x.api.getSingleOriginatorCountriesSpvs),
    func: async () =>
      (await originatorServiceClient.api.getSingleOriginatorCountriesSpvs(originatorId)).data,
    ...params,
  });

  return originatorSpvs?.data as OriginatorSpv;
};

export const getAllOriginatorCountries = (originatorId: number): OriginatorCountry[] => {
  const params = { originatorId };

  const { data: originatorCountries } = useGetRequest({
    key: nameof<Api<unknown>>((x) => x.api.getOriginatorCountries),
    func: async () => (await originatorServiceClient.api.getOriginatorCountries(originatorId)).data,
    params,
  });

  if (!originatorCountries || !originatorCountries.data) {
    return [];
  }

  return originatorCountries.data;
};

export const getOriginatorCountrySpvs = (
  originatorId: number,
  originatorCountryId: number,
): OriginatorCountrySpvSetting[] => {
  const params = { originatorId };

  const { data: originatorCountrySpvs } = useGetRequest({
    key: nameof<Api<unknown>>((x) => x.api.getOriginatorCountrySpvSettings),
    func: async () =>
      (
        await originatorServiceClient.api.getOriginatorCountrySpvSettings(
          originatorId,
          originatorCountryId,
        )
      ).data,
    params,
  });

  if (!originatorCountrySpvs || !originatorCountrySpvs.data) {
    return [];
  }

  return originatorCountrySpvs.data;
};

export const createOriginatorCountry = (originatorId: number) =>
  useMutationRequest(
    (request: OriginatorCountryBase) =>
      originatorServiceClient.api.createOriginatorCountry(originatorId, request),
    [
      nameof<Api<unknown>>((x) => x.api.getOriginatorCountries),
      nameof<Api<unknown>>((x) => x.api.getAllOriginatorsCountriesSpvs),
    ],
  );

export const createCountrySpv = (originatorId: number, countryId: number) =>
  useMutationRequest(
    (request: OriginatorCountrySpvSettingBase) =>
      originatorServiceClient.api.createOriginatorCountrySpvSetting(
        originatorId,
        countryId,
        request,
      ),
    [
      nameof<Api<unknown>>((x) => x.api.getOriginatorCountries),
      nameof<Api<unknown>>((x) => x.api.getAllOriginatorsCountriesSpvs),
      nameof<Api<unknown>>((x) => x.api.getOriginatorCountrySpvSettings),
    ],
  );

export const deleteOriginatorCountrySpv = (originatorId: number, originatorCountryId: number) =>
  useMutationRequest(
    (spvId: number) =>
      originatorServiceClient.api.deleteOriginatorCountrySpvSetting(
        originatorId,
        originatorCountryId,
        spvId,
      ),
    [
      nameof<Api<unknown>>((x) => x.api.getAllOriginatorsCountriesSpvs),
      nameof<Api<unknown>>((x) => x.api.getOriginatorCountrySpvSettings),
      nameof<Api<unknown>>((x) => x.api.getSingleOriginatorCountriesSpvs),
    ],
  );

export const deleteOriginatorCountry = () =>
  useMutationRequest(
    (request: DeleteOriginatorCountryRequest) =>
      originatorServiceClient.api.deleteOriginatorCountry(
        request.originatorId,
        request.originatorCountryId,
      ),
    [
      nameof<Api<unknown>>((x) => x.api.getAllOriginatorsCountriesSpvs),
      nameof<Api<unknown>>((x) => x.api.getOriginatorCountrySpvSettings),
      nameof<Api<unknown>>((x) => x.api.getSingleOriginatorCountriesSpvs),
    ],
  );

export const getAllOriginatorsSpvs = (): OriginatorCountrySpvResponseData[] => {
  const { data: originatorsSpvs } = useGetRequest({
    key: nameof<Api<unknown>>((x) => x.api.getAllOriginatorsCountriesSpvs),
    func: async () => (await originatorServiceClient.api.getAllOriginatorsCountriesSpvs()).data,
  });

  if (!originatorsSpvs || !originatorsSpvs.data) {
    return [];
  }

  return originatorsSpvs.data;
};
