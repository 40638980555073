import React from 'react';
import { useForm } from 'react-hook-form';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { formGridSpacing } from '../../../../../../../styles/formStyles';
import { FORM_TAG } from '../../../../../../../utils/constants/components';
import {
  ADD_ORIGINATOR_SCORE_CLASS_FORM_ID,
  INTEREST_MARGIN_ID,
  SCORE_CLASS_ID,
  SCORE_TO_ID,
} from '../../../../../../../utils/constants/identifiers';
import {
  INTEREST_MARGIN_LABEL,
  SCORE_CLASS_LABEL,
  SCORE_TO_LABEL,
} from '../../../../../../../utils/constants/labels';
import { ORIGINATOR_SCORE_NUMBER_ACCURACY } from '../../../../../../../utils/constants/options';
import { InputFieldType } from '../../../../../../../utils/enums/InputFieldType';
import { SelectorChoicesType } from '../../../../../../../utils/interfaces/form/SelectorChoicesType';
import { AddOriginatorScoreClassProps } from '../../../../../../../utils/interfaces/originator/details/AddOriginatorScoreClassProps';
import { NumberInputField } from '../../../../../../common/form/NumberInputField';
import { SelectField } from '../../../../../../common/form/SelectField';
import { LargeGridField } from '../../../../../../common/form/grid/LargeGridField';

export const AddOriginatorScoreClassForm = (props: AddOriginatorScoreClassProps) => {
  const { onSubmit, scoreClasses } = props;

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
  } = useForm();

  return (
    <Box
      component={FORM_TAG}
      id={ADD_ORIGINATOR_SCORE_CLASS_FORM_ID}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid container rowSpacing={1} columnSpacing={formGridSpacing}>
        <LargeGridField>
          <SelectField
            id={SCORE_CLASS_ID}
            label={SCORE_CLASS_LABEL}
            control={control}
            choices={scoreClasses as SelectorChoicesType[]}
            required
          />
        </LargeGridField>
        <LargeGridField>
          <NumberInputField
            id={SCORE_TO_ID}
            label={SCORE_TO_LABEL}
            inputType={InputFieldType.Decimal}
            register={register}
            errors={errors}
            required
          />
        </LargeGridField>
        <LargeGridField>
          <NumberInputField
            id={INTEREST_MARGIN_ID}
            label={INTEREST_MARGIN_LABEL}
            inputType={InputFieldType.Decimal}
            register={register}
            errors={errors}
            decimalAccuracy={ORIGINATOR_SCORE_NUMBER_ACCURACY}
            required
          />
        </LargeGridField>
      </Grid>
    </Box>
  );
};
