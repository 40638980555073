import React from 'react';
import { useNavigate } from 'react-router';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

import {
  breadcrumbsContentStyles,
  breadcrumbsDisabledStyles,
} from '../../../styles/breadcrumbsStyles';
import { linkUnderlineStyles } from '../../../styles/commonStyles';
import {
  DISABLED_CRUMB_ADMIN,
  DISABLED_CRUMB_DATA_EXCHANGE,
} from '../../../utils/constants/common';
import { BUTTON_TAG } from '../../../utils/constants/components';
import { COLOR_INHERIT } from '../../../utils/constants/styles';
import { BreadcrumbProps } from '../../../utils/interfaces/breadcrumb/BreadcrumbProps';

export const Breadcrumb = (props: BreadcrumbProps) => {
  const { breadcrumbs } = props;

  const history = useNavigate();

  const disabledBreadcrumbs = [DISABLED_CRUMB_ADMIN, DISABLED_CRUMB_DATA_EXCHANGE];

  return (
    <Breadcrumbs sx={breadcrumbsContentStyles}>
      {breadcrumbs.map(({ name, path }) => {
        return disabledBreadcrumbs.find((x) => name === x) ? (
          <Link
            component={BUTTON_TAG}
            key={path}
            sx={breadcrumbsDisabledStyles}
            color={COLOR_INHERIT}
          >
            {name}
          </Link>
        ) : (
          <Link
            component={BUTTON_TAG}
            key={path}
            onClick={() => history(path)}
            sx={linkUnderlineStyles}
            color={COLOR_INHERIT}
          >
            {name}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};
