import React, { useContext } from 'react';
import { FieldValues } from 'react-hook-form';

import { addOriginatorBDLoanField } from '../../../../../../api/controllers/BorrowerDataController';
import { getAllNomenclaturesByType } from '../../../../../../api/controllers/NomenclatureController';
import { NomenclatureType } from '../../../../../../api/types/nomenclatureTypes';
import { OPEN_FORM_DIALOG } from '../../../../../../utils/constants/actions';
import { ZERO_NUMBER_VALUE } from '../../../../../../utils/constants/common';
import { ORIGINATOR_LOAN_BORROWER_DATA_FIELDS_PERMISSION } from '../../../../../../utils/constants/permissions';
import { EDIT_LOAN_TITLE } from '../../../../../../utils/constants/titles';
import { FormDialogSubmitType } from '../../../../../../utils/enums/FormDialogSubmitType';
import { usePermissions } from '../../../../../../utils/hooks/permissions/usePermissions';
import { Context } from '../../../../../../utils/store';
import { ContextType } from '../../../../../../utils/store/types/ContextType';
import { BorrowerDataLoanTypesTableView } from '../../../../borrowerData/tables/BorrowerDataLoanTypesTableView';
import { UpdateBorrowerDataLoanForm } from '../../../../common/borrowerData/forms/UpdateBorrowerDataLoanForm';

export const OriginatorBorrowerDataTab = (props: { originatorId: number }) => {
  const { originatorId } = props;
  const { dispatch } = useContext<ContextType>(Context);

  const loanTypes = getAllNomenclaturesByType(NomenclatureType.LOAN_TYPES);
  const addLoanField = addOriginatorBDLoanField();

  const permissions = usePermissions();

  let loanId = ZERO_NUMBER_VALUE;

  const userOriginatorsPermissionType = ORIGINATOR_LOAN_BORROWER_DATA_FIELDS_PERMISSION;

  const handleEditClick = (id: number): void => {
    loanId = id;

    dispatch({
      type: OPEN_FORM_DIALOG,
      payload: {
        title: EDIT_LOAN_TITLE,
        content: (
          <UpdateBorrowerDataLoanForm
            onSubmit={handleSubmit}
            loanTypeId={id}
            originatorId={originatorId}
            permissionType={userOriginatorsPermissionType}
          />
        ),
        submitType: FormDialogSubmitType.Edit,
      },
    });
  };

  const handleSubmit = (data: FieldValues): void => {
    const formData = {
      originatorId: originatorId,
      loanId: loanId,
      id: data.field,
    };

    addLoanField.mutateAsync(formData);
  };

  return (
    <BorrowerDataLoanTypesTableView
      handleEdit={handleEditClick}
      loanTypes={loanTypes}
      permissionType={userOriginatorsPermissionType}
      canRead={permissions.hasReadPermission(userOriginatorsPermissionType)}
    />
  );
};
