export const DASHBOARD_LABEL = 'Dashboard';
export const DATA_EXCHANGE_LABEL = 'Data exchange';
export const ORIGINATORS_SPVS_LABEL = 'Originators SPVs';
export const ADMIN_LABEL = 'Admin';
export const ADMIN_IUVO_LABEL = 'IUVO Admin';
export const NO_ROWS_LABEL = 'No rows';
export const NO_DATA_LABEL = 'No data';
export const ORIGINATORS_LABEL = 'Originators';
export const SPVS_LABEL = 'SPVs';
export const SPV_LABEL = 'SPV';
export const GROUPS_LABEL = 'Groups';
export const BORROWER_DATA_LABEL = 'Borrower data';
export const SCORE_CLASSES_LABEL = 'Score classes';
export const CURRENCY_INVEST_AMOUNT_LABEL = 'Currency invest amount';
export const LOAN_ORIGINATORS_LABEL = 'Loan originators';
export const CURRENT_USER_LABEL = 'Current user';
export const ROLE_LABEL = 'Role';
export const CURRENT_LOAN_ORIGINATOR_LABEL = 'Current loan originator';
export const EASY_CREDIT_LABEL = 'Easy credit';
export const CREATE_ACCOUNT_LABEL = 'Create account';
export const ENTER_YOUR_DETAILS_LABEL = 'Enter your details and start your journey with us!';
export const FORGOT_YOUR_PASSWORD_LABEL = 'Forgot your password?';
export const RESET_PASSWORD_LABEL = 'Reset password';
export const EMAIL_LABEL = 'Email';
export const PASSWORD_LABEL = 'Password';
export const PASSWORD_CONFIRM_LABEL = 'Confirm password';
export const PASSWORD_CURRENT_LABEL = 'Current password';
export const PASSWORD_NEW_LABEL = 'New password';
export const PASSWORD_RESET_LABEL = 'Password reset';
export const ENTER_EMAIL_PASSWORD_RESET_LABEL = 'Enter your email to reset your password.';
export const ALREADY_HAVE_ACCOUNT_LABEL = 'Already have an account?';
export const DONT_HAVE_ACCOUNT_LABEL = "Don't have an account?";
export const TRY_AGAIN_LABEL = 'Try again';
export const SIGN_IN_LABEL = 'Sign in';
export const BACK_TO_SIGN_IN_LABEL = 'Back to Sign in';
export const EDIT_LABEL = 'Edit';
export const DELETE_LABEL = 'Delete';
export const NAME_LABEL = 'Name';
export const PUBLIC_NAME_LABEL = 'Public Name';
export const LEGAL_NAME_LABEL = 'Legal Name';
export const TRADE_NAME_LABEL = 'Trade Name';
export const REGISTRATION_NUMBER_LABEL = 'Registration number';
export const CEO_LABEL = 'CEO';
export const COUNTRY_LABEL = 'Country';
export const COUNTRY_OF_REGISTRATION_LABEL = 'Country of registration';
export const ADDRESS_LABEL = 'Address';
export const GROUP_LABEL = 'Group';
export const FEE_LABEL = 'Fee';
export const SCORE_TO_LABEL = 'Score to';
export const BUSINESS_STATUS_LABEL = 'Business status';
export const SCORE_NUMBER_LABEL = 'Score number';
export const TRANSACTIONS_LABEL = 'Transactions';
export const LOANS_LABEL = 'Loans';
export const FILE_LABEL = 'File';
export const DIRECT_SUBMIT_LABEL = 'Direct submit';
export const ONLY_ERRORS_LABEL = 'Only errors';
export const ERRORS_LABEL = 'Errors';
export const WARNINGS_LABEL = 'Warnings';
export const COUNT_LABEL = 'Count';
export const PROCESSED_LABEL = 'Processed';
export const SUBMITTED_LABEL = 'Submitted';
export const STATUS_LABEL = 'Status';
export const WARNING_LABEL = 'warning';
export const SUCCESS_LABEL = 'success';
export const ERROR_LABEL = 'error';
export const ACTIONS_LABEL = 'actions';
export const ORIGINATOR_LABEL = 'Originator';
export const INFO_LABEL = 'Info';
export const PAYMENT_SCHEDULES_LABEL = 'Payment schedules';
export const PAYMENTS_LABEL = 'Payments';
export const FEES_LABEL = 'Fees';
export const FEE_AMOUNT_LABEL = 'Fee amount';
export const FEE_TYPE_LABEL = 'Fee type';
export const FEE_DATE_LABEL = 'Fee date';
export const LOAN_ID_LABEL = 'Loan id';
export const FEE_ID_LABEL = 'Fee id';
export const TRANSACTION_ID_LABEL = 'Transaction id';
export const KID_LABEL = 'Kid';
export const LOAN_TYPE_LABEL = 'Loan type';
export const USE_OF_LOAN_LABEL = 'Use of loan';
export const LOAN_STATUS_LABEL = 'Loan status';
export const COLLATERAL_TYPE_ID_LABEL = 'Collateral type id';
export const COLLATERAL_AMOUNT_LABEL = 'Collateral amount';
export const DRAFT_STATUS_LABEL = 'Draft';
export const PUBLISHED_STATUS_LABEL = 'Published';
export const PARTIALLY_PUBLISHED_STATUS_LABEL = 'Partially published';
export const PROCESSING_STATUS_LABEL = 'Processing';
export const NO_STATUS_LABEL = 'No status';
export const PAY_SCHEDULE_ID_LABEL = 'Pay schedule id';
export const INST_NUMBER_LABEL = 'Inst number';
export const MATURITY_DATE_LABEL = 'Maturity date';
export const PRINCIPAL_AMOUNT_LABEL = 'Principal amount';
export const INTEREST_AMOUNT_LABEL = 'Interest amount';
export const IS_PAID_LABEL = 'Is paid';
export const BUSINESS_DAY_LABEL = 'Business day';
export const PAYMENT_ID_LABEL = 'Payment id';
export const PAY_DATE_LABEL = 'Pay date';
export const PAY_AMOUNT_LABEL = 'Pay amount';
export const IS_GUARANTEED_LABEL = 'Is guaranteed';
export const IS_EARLY_LABEL = 'Is early';
export const AGE_LABEL = 'Age';
export const CAR_COUNT_LABEL = 'Car count';
export const FREE_CASH_LABEL = 'Free cash';
export const GENDER_LABEL = 'Gender';
export const JOB_TYPE_LABEL = 'Job type';
export const EDUCATION_LABEL = 'Education';
export const INCOME_TOTAL_LABEL = 'Income total';
export const GET_SALARY_LABEL = 'Get salary';
export const INCOME_SALARY_LABEL = 'Income salary';
export const GET_SALARY_DAY_LABEL = 'Get salary day';
export const INCOME_PENSION_LABEL = 'Income pension';
export const RESIDENCY_CITY_LABEL = 'Residency city';
export const BORROWER_TYPE_LABEL = 'Borrower type';
export const CAR_OWNERSHIP_LABEL = 'Car ownership';
export const RESIDENCY_REGION_LABEL = 'Residency region';
export const SALARY_PERIOD_LABEL = 'Salary period';
export const LIABILITIES_PHONE_LABEL = 'Liabilities phone';
export const LIABILITIES_TOTAL_LABEL = 'Liabilities total';
export const MARTIAL_STATUS_LABEL = 'Martial status';
export const RESIDENCY_COUNTRY_LABEL = 'Residency country';
export const OCCUPATION_AREA_LABEL = 'Occupation area';
export const EMPLOYMENT_STATUS_LABEL = 'Employment status';
export const FAMILY_MEMBERS_COUNT_LABEL = 'Family members';
export const INCOME_PARTNER_SALARY_LABEL = 'Income partner salary';
export const PROPERTY_OWNERSHIP_LABEL = 'Property ownership';
export const WORK_EXPERIENCE_YEARS_LABEL = 'Work expirience years';
export const HOME_OWNERSHIP_TYPE_LABEL = 'Home ownership type';
export const LIABILITIES_NET_TV_LABEL = 'Liabilities net and TV';
export const LIABILITIES_ELECTRICITY_LABEL = 'Liabilities electricity';
export const WORK_EXPERIENCE_MONTHS_LABEL = 'Work expirience total months';
export const WORK_EXPERIENCE_CURR_EMPLOYER_YEARS_LABEL = 'Work expirience current employer years';
export const WORK_EXPERIENCE_CURR_EMPLOYER_MONTHS_LABEL = 'Work expirience current employer months';
export const REAL_ESTATE_SIZE_LABEL = 'Real estate size';
export const REAL_ESTATE_COUNTRY_CODE_LABEL = 'Real estate country code';
export const REAL_ESTATE_REGION_LABEL = 'Real estate region';
export const BRAND_MODEL_LABEL = 'Brand and model';
export const APPRAISER_LABEL = 'Appraiser';
export const MANUFACTURING_YEAR_LABEL = 'Manufacturing year';
export const REGISTRATION_DATE_LABEL = 'Registration date';
export const BUSINESS_SECTOR_LABEL = 'Business sector';
export const ACTIVITY_DESCRIPTION_LABEL = 'Activity description';
export const COLLATERAL_VALUATION_DATE_LABEL = 'Collateral valuation date';
export const REGISTRATION_COUNTRY_CODE_LABEL = 'Registration country code';
export const SELLER_BUSINESS_DESCRIPTION_LABEL = 'Seller business description';
export const BUYER_BUSINESS_DESCRIPTION_LABEL = 'Buyer business description';
export const ADVANCE_PERCENTAGE_LABEL = 'Advance percentage';
export const FACTORING_TRANS_TYPE_LABEL = 'Factoring trans type';
export const HAS_RECOURSE_LABEL = 'Has recourse';
export const HAS_INVOICE_INSURANCE_LABEL = 'Has invoice insurance';
export const ORIGINATOR_DETAILS_LABEL = 'Originator details';
export const AMORTIZATION_METHOD_LABEL = 'Amortization method';
export const AMOUNT_LABEL = 'Amount';
export const CURRENCY_LABEL = 'Currency';
export const DEFAULTED_ON_DAY_LABEL = 'Defaulted on day';
export const END_DATE_LABEL = 'End date';
export const GUARANT_INTEREST_PERC_LABEL = 'Guarant interest percent';
export const GUARANT_PERIOD_DAYS_LABEL = 'Guarant period days';
export const GUARANT_PRINCIPAL_PERC_LABEL = 'Guarant principal percent';
export const INST_COUNT_LABEL = 'Inst count';
export const INST_PERIOD_ID_LABEL = 'Inst period id';
export const INST_PERIOD_NUM_LABEL = 'Inst period number';
export const INVEST_PART_AMOUNT_LABEL = 'Invest part amount';
export const INVEST_PART_PERC_LABEL = 'Invest part percent';
export const IS_LISTED_LABEL = 'Is listed';
export const ISSUE_DATE_LABEL = 'Issue date';
export const LATE_FEE_TERMS_LABEL = 'Late fee terms';
export const LATE_FEES_PAYABLE_LABEL = 'Late fees payable';
export const LOAN_IMPORT_STATUS_LABEL = 'Loan import status';
export const INSERTED_DATE_LABEL = 'Inserted date:';
export const COMPLETED_DATE_LABEL = 'Completed date:';
export const MARGIN_INTEREST_PERC_LABEL = 'Margin interest percent';
export const ORIG_CURRENCY_LABEL = 'Originator currency';
export const ORIG_INTEREST_AMOUNT_LABEL = 'Originator interest amount';
export const ORIG_INTEREST_PERC_LABEL = 'Originator interest percent';
export const ORIGINATOR_SCORE_CLASS_ID_LABEL = 'Originator SC Id';
export const ORIGINATOR_SCORE_CLASS_PUBLIC_NAME_LABEL = 'Originator SC Public Name';
export const PAY_TERM_POLICY_LABEL = 'Pay term policy';
export const PD_PERC_LABEL = 'Pd percent';
export const YEAR_DAYS_BASE_LABEL = 'Years days base';
export const MINIMUM_INVEST_AMOUNT_LABEL = 'Minimum invest amount';
export const TRANS_ID_LABEL = 'Trans id';
export const TYPE_ID_LABEL = 'Type id';
export const PRODUCT_ID_LABEL = 'Product id';
export const PRODUCT_NAME_LABEL = 'Product name';
export const GUARANT_ACTIVATION_DATE_LABEL = 'Guarant activation date';
export const PRODUCTS_LABEL = 'Products';
export const ACCOUNTS_LABEL = 'Accounts';
export const SETTINGS_LABEL = 'Settings';
export const INTEREST_RATE_LABEL = 'Interest Rate';
export const INTEREST_RATE_MIN_LABEL = 'Min interest Rate';
export const INTEREST_RATE_MAX_LABEL = 'Max interest Rate';
export const INVEST_PART_PERCENT_LABEL = 'Shared part of the loan amount for investing';
export const LOAN_CURRENCY_LABEL = 'Original Loan Currency';
export const NUMBER_OF_INSTALMENTS_MIN_LABEL = 'Min № of instalments';
export const NUMBER_OF_INSTALMENTS_MAX_LABEL = 'Max № of instalments';
export const LOAN_GUARANT_PRINCIPAL_PERC_LABEL = 'Guaranteed Principal';
export const LOAN_GUARANT_INTEREST_PERC_LABEL = 'Guaranteed Interest';
export const LOAN_GUARANT_PERIOD_DAYS_LABEL = 'Guarantee activation days';
export const APPLICATION_MODE_LABEL = 'How to apply properties';
export const INSTALLMENT_TYPE_LABEL = 'Installment type';
export const INSTALLMENT_TYPE_NUMBER_LABEL = 'Installment number';
export const ID_LABEL = 'Id';
export const IS_DRAFT_LABEL = 'Is draft';
export const USER_CLASS_LABEL = 'Class';
export const APP_MODE_VALIDATE_LABEL = 'Validate';
export const APP_MODE_ADD_IF_MISSING_LABEL = 'Add if missing';
export const APP_MODE_OVERRIDE_LABEL = 'Override';
export const EDIT_PERMISSIONS_LABEL = 'Edit permissions';
export const ORIGINATOR_ROLES_LABEL = 'Originator roles';
export const FACILITY_AGREEMENT_NUMBER_LABEL = 'Facility agreement number';
export const VALID_TO_LABEL = 'Valid to';
export const ALL_LABEL = 'All';
export const TYPE_LABEL = 'Type';
export const MULTI_ORIGINATOR_LABEL = 'Multi originator';
export const MULTI_ORIGINATOR_ACCOUNT_LABEL = 'This account is multi originator.';
export const NO_ORIGINATOR_ORGANIZATION = 'No Originator organization';
export const INTEREST_MARGIN_LABEL = 'Interest margin';
export const SCORE_CLASS_LABEL = 'Score class';
export const LOAN_TYPES_LABEL = 'Loan types';
export const FIELDS_LABEL = 'Fields';
export const FIELD_LABEL = 'Field';
export const LABEL_LABEL = 'Label';
export const DESCRIPTION_LABEL = 'Description';
export const MIN_PRIMARY_AMOUNT_LABEL = 'Min Primary Amount';
export const MIN_SECONDNARY_AMOUNT_LABEL = 'Min Secondary Amount';
export const VALID_FROM_LABEL = 'Valid from';
export const GENERAL_SETTINGS_LABEL = 'General settings';
export const ACTIVE_IMPORT_LOANS = 'Active import loans';
export const ACTIVE_IMPORT_PAYMENTS = 'Active import payments';
export const ACTIVE_PRIMARY_MARKETS = 'Active primary markets';
export const ACTIVE_SECONDARY_MARKETS = 'Active secondary markets';
export const YES_LABEL = 'yes';
export const NO_LABEL = 'no';
export const ROWS_LABEL = 'Rows:';
export const DOWNLOAD_LABEL = 'Download link';
