import { LOANS_KEY, TRANSACTIONS_KEY } from '../../utils/constants/keys';
import { LOANS_LABEL, TRANSACTIONS_LABEL } from '../../utils/constants/labels';
import { ROUTE_PATH_LOANS, ROUTE_PATH_TRANSACTIONS } from '../../utils/constants/routes';
import { SidebarTabsProps } from '../../utils/interfaces/layout/SidebarTabsProps';

export const dataExchangeTabs: SidebarTabsProps[] = [
  {
    key: TRANSACTIONS_KEY,
    title: TRANSACTIONS_LABEL,
    path: ROUTE_PATH_TRANSACTIONS,
  },
  {
    key: LOANS_KEY,
    title: LOANS_LABEL,
    path: ROUTE_PATH_LOANS,
  },
];
