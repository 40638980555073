import React from 'react';

import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import { Box, Link, Tooltip, Typography } from '@mui/material';

import {
  iconStyles,
  linkStyles,
  reportNameStyles,
  reportWrapperStyles,
  textWrapperStyles,
} from '../../../styles/exportReport';
import { FINISHED } from '../../../utils/constants/common';
import { DOWNLOAD_LABEL } from '../../../utils/constants/labels';
import { REPORT_IN_PROGRESS_MESSAGE } from '../../../utils/constants/messages';
import { GeneralInfoProps } from '../../../utils/interfaces/reports/GeneralInfoProps';

export const GeneralInfo = (props: GeneralInfoProps) => {
  const { report, openedRows, handleDownload, openAdditionalInfo, closeAdditionalInfo } = props;
  return (
    <Box sx={reportWrapperStyles}>
      <Box sx={textWrapperStyles}>
        <Typography sx={reportNameStyles}>{report.name}</Typography>
        {report.status === FINISHED ? (
          <Link
            onClick={() => handleDownload(report.id as number, report.name as string)}
            sx={linkStyles}
          >
            {DOWNLOAD_LABEL}
          </Link>
        ) : (
          <Tooltip title={REPORT_IN_PROGRESS_MESSAGE} arrow>
            <Typography>{DOWNLOAD_LABEL}</Typography>
          </Tooltip>
        )}
      </Box>
      {openedRows[report.id as number] && openedRows[report.id as number]?.isOpen ? (
        <KeyboardArrowUpRoundedIcon
          sx={iconStyles}
          id={report.id as unknown as string}
          onClick={() => closeAdditionalInfo(report.id as number)}
        />
      ) : (
        <KeyboardArrowDownRoundedIcon
          sx={iconStyles}
          id={report.id as unknown as string}
          onClick={() => openAdditionalInfo(report.id as number)}
        />
      )}
    </Box>
  );
};
