import React from 'react';

import { Button } from '@mui/material';

import { authButtonViewStyles } from '../../../styles/authFormStyles';
import { BUTTON_SUBMIT_TYPE } from '../../../utils/constants/buttons';
import { ButtonVariants } from '../../../utils/enums/ButtonVariants';

export const AuthButtonView = (props: { label: string }) => {
  const { label } = props;

  return (
    <Button variant={ButtonVariants.Contained} type={BUTTON_SUBMIT_TYPE} sx={authButtonViewStyles}>
      {label}
    </Button>
  );
};
