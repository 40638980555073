import React from 'react';
import { useContext } from 'react';

import { Box, Step, StepLabel, Stepper } from '@mui/material';

import { baseFormStyles } from '../../styles/formStyles';
import { stepperStyles } from '../../styles/stepperStyles';
import { EDIT_ACCOUNT_TITLE } from '../../utils/constants/titles';
import { StepperWrapperProps } from '../../utils/interfaces/StepperWrapperProps';
import { Context } from '../../utils/store';

export const StepperWrapper = (props: StepperWrapperProps) => {
  const { steps, activeStep } = props;
  const { state } = useContext(Context);

  return (
    <Box sx={baseFormStyles}>
      {state.formDialog?.title !== EDIT_ACCOUNT_TITLE && (
        <Stepper activeStep={activeStep} sx={stepperStyles}>
          {steps.map((step) => {
            return (
              <Step key={step.label}>
                <StepLabel>{step.label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      )}
      {steps[activeStep].component}
    </Box>
  );
};
