export const advancedFiltersWrapperStyles = {
  paddingRight: 2,
  py: 1,
  mb: 2,
};

export const reportsWrapperStyles = {
  p: 3,
};

export const advancedFiltersGridStyles = {
  margin: '0px auto',
};

export const buttonWrapperStyles = {
  display: 'flex',
  justifyContent: 'flex-end',
  my: 2,
};

// date range picker
export const datePickerWrapperStyles = {
  mt: 2,
  width: '100%',
  display: 'flex',
  gap: '8px',
};

export const dateStyles = {
  width: '50%',
};

// multiple select component
export const multipleSelectWrapperStyles = {
  width: '100%',
};

// text field
export const textFieldWrapperStyles = {
  width: '100%',
};
