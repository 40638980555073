import { GridSortDirection } from '@mui/x-data-grid-pro';
import { nameof } from 'ts-simple-nameof';

import { useGetRequest } from '../../utils/hooks/query/useGetRequest';
import { BeFiltersInterface } from '../../utils/interfaces/filter/BeFiltersInterface';
import { originatorServiceClient } from '../apiClient';
import {
  FeesDataResponse,
  LoansDataResponse,
  Originator,
  Api as OriginatorApi,
  PaySchedulesDataResponse,
  PaymentsDataResponse,
} from '../types/originatorTypes';

export const getAllLoans = (
  filters?: BeFiltersInterface,
  sorting?: [string, GridSortDirection][],
): LoansDataResponse => {
  const { data: loans } = useGetRequest(
    {
      key: nameof<OriginatorApi<unknown>>((x) => x.api.getAllLoans),
      func: async () =>
        (
          await originatorServiceClient.api.getAllLoans({
            filter: JSON.stringify(filters?.formFields),
            pageSize: filters?.tableSettings.pageSize,
            pageNumber: filters?.tableSettings.pageNumber,
            sort: JSON.stringify(sorting),
          })
        ).data,
    },
    { filters, sorting },
  );

  if (!loans || !loans.data) {
    return {};
  }

  return loans;
};

export const getAllPaySchedules = (
  filters?: BeFiltersInterface,
  sorting?: [string, GridSortDirection][],
): PaySchedulesDataResponse => {
  const { data: paySchedules } = useGetRequest(
    {
      key: nameof<OriginatorApi<unknown>>((x) => x.api.getAllPaySchedules),
      func: async () =>
        (
          await originatorServiceClient.api.getAllPaySchedules({
            filter: JSON.stringify(filters?.formFields),
            pageSize: filters?.tableSettings.pageSize,
            pageNumber: filters?.tableSettings.pageNumber,
            sort: JSON.stringify(sorting),
          })
        ).data,
    },
    { filters, sorting },
  );

  if (!paySchedules || !paySchedules.data) {
    return {};
  }

  return paySchedules;
};

export const getAllPayments = (
  filters?: BeFiltersInterface,
  sorting?: [string, GridSortDirection][],
): PaymentsDataResponse => {
  const { data: payments } = useGetRequest(
    {
      key: nameof<OriginatorApi<unknown>>((x) => x.api.getAllPayments),
      func: async () =>
        (
          await originatorServiceClient.api.getAllPayments({
            filter: JSON.stringify(filters?.formFields),
            pageSize: filters?.tableSettings.pageSize,
            pageNumber: filters?.tableSettings.pageNumber,
            sort: JSON.stringify(sorting),
          })
        ).data,
    },
    { filters, sorting },
  );

  if (!payments || !payments.data) {
    return {};
  }

  return payments;
};

export const getAllFees = (
  filters?: BeFiltersInterface,
  sorting?: [string, GridSortDirection][],
): FeesDataResponse => {
  const { data: fees } = useGetRequest(
    {
      key: nameof<OriginatorApi<unknown>>((x) => x.api.getAllFees),
      func: async () =>
        (
          await originatorServiceClient.api.getAllFees({
            filter: JSON.stringify(filters?.formFields),
            pageSize: filters?.tableSettings.pageSize,
            pageNumber: filters?.tableSettings.pageNumber,
            sort: JSON.stringify(sorting),
          })
        ).data,
    },
    { filters, sorting },
  );

  if (!fees || !fees.data) {
    return {};
  }

  return fees;
};

export const getAllOriginatorLoans = (
  originatorId: number,
  filters?: BeFiltersInterface,
  sorting?: [string, GridSortDirection][],
): Originator => {
  const { data: originatorLoans } = useGetRequest(
    {
      key: nameof<OriginatorApi<unknown>>((x) => x.api.getAllOriginatorLoans),
      func: async () =>
        (
          await originatorServiceClient.api.getAllOriginatorLoans(originatorId, {
            filter: JSON.stringify(filters?.formFields),
            pageSize: filters?.tableSettings.pageSize,
            pageNumber: filters?.tableSettings.pageNumber,
            sort: JSON.stringify(sorting),
          })
        ).data,
    },
    { filters, sorting },
  );

  if (!originatorLoans || !originatorLoans.data) {
    return {} as Originator;
  }

  return originatorLoans as Originator;
};

export const getAllOriginatorPaySchedules = (
  originatorId: number,
  filters?: BeFiltersInterface,
  sorting?: [string, GridSortDirection][],
): Originator => {
  const { data: originatorPaySchedules } = useGetRequest(
    {
      key: nameof<OriginatorApi<unknown>>((x) => x.api.getAllOriginatorPaySchedules),
      func: async () =>
        (
          await originatorServiceClient.api.getAllOriginatorPaySchedules(originatorId, {
            filter: JSON.stringify(filters?.formFields),
            pageSize: filters?.tableSettings.pageSize,
            pageNumber: filters?.tableSettings.pageNumber,
            sort: JSON.stringify(sorting),
          })
        ).data,
    },
    { filters, sorting },
  );

  if (!originatorPaySchedules || !originatorPaySchedules.data) {
    return [] as Originator;
  }

  return originatorPaySchedules as Originator;
};

export const getAllOriginatorPayments = (
  originatorId: number,
  filters?: BeFiltersInterface,
  sorting?: [string, GridSortDirection][],
): Originator => {
  const { data: originatorPayments } = useGetRequest(
    {
      key: nameof<OriginatorApi<unknown>>((x) => x.api.getAllOriginatorPayments),
      func: async () =>
        (
          await originatorServiceClient.api.getAllOriginatorPayments(originatorId, {
            filter: JSON.stringify(filters?.formFields),
            pageSize: filters?.tableSettings.pageSize,
            pageNumber: filters?.tableSettings.pageNumber,
            sort: JSON.stringify(sorting),
          })
        ).data,
    },
    { filters, sorting },
  );

  if (!originatorPayments || !originatorPayments.data) {
    return [] as Originator;
  }

  return originatorPayments as Originator;
};

export const getAllOriginatorFees = (
  originatorId: number,
  filters?: BeFiltersInterface,
  sorting?: [string, GridSortDirection][],
): Originator => {
  const { data: originatorFees } = useGetRequest(
    {
      key: nameof<OriginatorApi<unknown>>((x) => x.api.getAllOriginatorFees),
      func: async () =>
        (
          await originatorServiceClient.api.getAllOriginatorFees(originatorId, {
            filter: JSON.stringify(filters?.formFields),
            pageSize: filters?.tableSettings.pageSize,
            pageNumber: filters?.tableSettings.pageNumber,
            sort: JSON.stringify(sorting),
          })
        ).data,
    },
    { filters, sorting },
  );

  if (!originatorFees || !originatorFees.data) {
    return [] as Originator;
  }

  return originatorFees as Originator;
};
