import { Dispatch } from 'react';

import { CLOSE_FORM_DIALOG, OPEN_FORM_DIALOG } from '../constants/actions';
import { EXPORT_LOANS_ID, REPORT_ID } from '../constants/identifiers';
import { Action } from '../store/action';

export const closeDialog = (dispatch: Dispatch<Action>) => {
  dispatch({ type: CLOSE_FORM_DIALOG });
};

export const openDialog = (dispatch: Dispatch<Action>, component: JSX.Element) => {
  dispatch({
    type: OPEN_FORM_DIALOG,
    payload: {
      title: REPORT_ID,
      content: component,
      formId: EXPORT_LOANS_ID,
    },
  });
};
