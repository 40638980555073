import { UserMapped } from '../../utils/interfaces/Permissions';
import { UserLogin } from '../types/originatorTypes';

export const mapUserResponse = (loginInfo: UserLogin): UserMapped => {
  const permissions = loginInfo.permissions;

  const permissionsMapped = {};
  permissions?.forEach((permission) => {
    if (permission.resource?.resource) {
      permissionsMapped[permission.resource?.resource] = permission.scopeHolder;
    }
  });

  return { ...loginInfo, permissions: permissionsMapped };
};
