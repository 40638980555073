/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** This is the currency array response object */
export interface AllCurrenciesResponse {
  data?: Currency[];
  /** This is the error object */
  error?: Error;
}

/** This is the currency response object */
export interface SingleCurrencyResponse {
  /** This is the currency object */
  data?: Currency;
  /** This is the error object */
  error?: Error;
}

/** This is the currency object */
export type Currency = BaseModel & {
  isoCode?: string;
  symbol?: string;
  symbolBeforeAmount?: boolean;
};

/** This is the country array response object */
export interface AllCountriesResponse {
  data?: Country[];
  /** This is the error object */
  error?: Error;
}

/** This is the country response object */
export interface SingleCountryResponse {
  /** This is the country object */
  data?: Country;
  /** This is the error object */
  error?: Error;
}

/** This is the country object */
export type Country = BaseModel & {
  isoCode?: string;
  phoneCode?: string;
};

/** This is the base model array response object */
export interface AllBaseModelsResponse {
  data?: BaseModel[];
  /** This is the error object */
  error?: Error;
}

/** This is the base model response object */
export interface SingleBaseModelResponse {
  /** This is the base model for nomenclature */
  data?: BaseModel;
  /** This is the error object */
  error?: Error;
}

/** This is the base model for nomenclature */
export interface BaseModel {
  /**
   * @format int64
   * @min 1
   */
  id?: number;
  name?: string;
}

/** The nomenclature base type */
export enum NomenclatureType {
  AMORTIZATION_METHODS = 'AMORTIZATION_METHODS',
  ANNUAL_INCOME_RANGES = 'ANNUAL_INCOME_RANGES',
  BORROWER_TYPES = 'BORROWER_TYPES',
  BUSINESS_SECTORS = 'BUSINESS_SECTORS',
  CAR_OWNERSHIPS = 'CAR_OWNERSHIPS',
  COLLATERAL_TYPES = 'COLLATERAL_TYPES',
  EDUCATIONS = 'EDUCATIONS',
  EMPLOYMENT_STATUSES = 'EMPLOYMENT_STATUSES',
  FACTORING_TRANS_TYPES = 'FACTORING_TRANS_TYPES',
  FIELDS_OF_WORK = 'FIELDS_OF_WORK',
  FUNDS_ORIGINS = 'FUNDS_ORIGINS',
  GENDERS = 'GENDERS',
  GET_SALARY_METHODS = 'GET_SALARY_METHODS',
  HOME_OWNERSHIP_TYPES = 'HOME_OWNERSHIP_TYPES',
  IMPORTER_ERROR_TYPES = 'IMPORTER_ERROR_TYPES',
  INCOME_ORIGINS = 'INCOME_ORIGINS',
  INSTALLMENT_TYPES = 'INSTALLMENT_TYPES',
  JOB_TYPES = 'JOB_TYPES',
  LOAN_PURPOSES = 'LOAN_PURPOSES',
  LOAN_STATUSES = 'LOAN_STATUSES',
  LOAN_TYPES = 'LOAN_TYPES',
  MARITAL_STATUSES = 'MARITAL_STATUSES',
  OCCUPATION_AREAS = 'OCCUPATION_AREAS',
  PLANNED_INVESTMENT_SCOPES = 'PLANNED_INVESTMENT_SCOPES',
  POLITICALLY_EXPOSED = 'POLITICALLY_EXPOSED',
  PROPERTY_OWNERSHIPS = 'PROPERTY_OWNERSHIPS',
  SALARY_PERIODS = 'SALARY_PERIODS',
  SENIORITY_LEVELS = 'SENIORITY_LEVELS',
}

/** This is the response object in case of errors, compliant with RFC7807 */
export interface ErrorResponse {
  /** This is the error object */
  error?: Error;
}

/** This is the error object */
export interface Error {
  type?: string;
  title?: string;
  status?: number;
  detail?: string;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = 'http://localhost:8080';
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join('&');
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => 'undefined' !== typeof query[key]);
    return keys
      .map((key) =>
        Array.isArray(query[key])
          ? this.addArrayQueryParam(query, key)
          : this.addQueryParam(query, key),
      )
      .join('&');
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : '';
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === 'object' || typeof input === 'string')
        ? JSON.stringify(input)
        : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === 'object' && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(
      `${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`,
      {
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
        },
        signal: cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal,
        body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body),
      },
    ).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title Nomenclature Service
 * @version 1.0.0
 * @baseUrl http://localhost:8080
 *
 * This is a Template YAML For Iuvo Microservices
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @name GetAllNomenclaturesByType
     * @summary This is a method returning all nomenclature from the specified type
     * @request GET:/api/nomenclatures
     * @secure
     */
    getAllNomenclaturesByType: (
      query: {
        /** The nomenclature type */
        type: NomenclatureType;
      },
      params: RequestParams = {},
    ) =>
      this.request<AllBaseModelsResponse, ErrorResponse>({
        path: `/api/nomenclatures`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name GetNomenclatureByIdAndType
     * @summary This is a method returning a single nomenclature by id
     * @request GET:/api/nomenclatures/{id}
     * @secure
     */
    getNomenclatureByIdAndType: (
      id: number,
      query: {
        /** The nomenclature type */
        type: NomenclatureType;
      },
      params: RequestParams = {},
    ) =>
      this.request<SingleBaseModelResponse, ErrorResponse>({
        path: `/api/nomenclatures/${id}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name GetAllCountries
     * @summary This is a method returning all countries
     * @request GET:/api/nomenclatures/countries
     * @secure
     */
    getAllCountries: (params: RequestParams = {}) =>
      this.request<AllCountriesResponse, ErrorResponse>({
        path: `/api/nomenclatures/countries`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name GetCountryById
     * @summary This is a method returning a single country by id
     * @request GET:/api/nomenclatures/countries/{id}
     * @secure
     */
    getCountryById: (id: number, params: RequestParams = {}) =>
      this.request<SingleCountryResponse, ErrorResponse>({
        path: `/api/nomenclatures/countries/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name GetAllCurrencies
     * @summary This is a method returning all currencies
     * @request GET:/api/nomenclatures/currencies
     * @secure
     */
    getAllCurrencies: (params: RequestParams = {}) =>
      this.request<AllCurrenciesResponse, ErrorResponse>({
        path: `/api/nomenclatures/currencies`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name GetCurrencyById
     * @summary This is a method returning a single currency by id
     * @request GET:/api/nomenclatures/currencies/{id}
     * @secure
     */
    getCurrencyById: (id: number, params: RequestParams = {}) =>
      this.request<SingleCurrencyResponse, ErrorResponse>({
        path: `/api/nomenclatures/currencies/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
}
