import { QueryCache, QueryClient } from 'react-query';

import { Error } from '../../../api/types/originatorTypes';
import { errorResultHandler } from '../../helpers/errorResultHandler';

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (response: unknown) => {
      errorResultHandler(response as Error);
    },
  }),
});
