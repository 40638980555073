import React, { useContext, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import {
  sidebarOriginatorSelectContainerStyles,
  sidebarSingleOriginator,
} from '../../../../styles/sidebarStyles';
import { NO_ORIGINATOR_ORGANIZATION } from '../../../../utils/constants/labels';
import { Context } from '../../../../utils/store';
import { TabCollapse } from './TabCollapse';

export const OriginatorTabs = () => {
  const { state } = useContext(Context);
  const { user } = state;

  const [isOpen, setIsOpen] = useState(false);
  const multiOriginator = user?.originators && user?.originators.length > 1;
  const [searchParams] = useSearchParams();
  const id = Number(searchParams.get('id')) || state.originatorID;

  const selectedOriginator = user?.originators?.find((originator) => {
    return id === originator.id;
  });

  const originatorTabs = user?.originators?.map((x) => ({
    key: x?.id,
    title: x?.name,
    path: x?.name,
  }));

  const handleToggle = (): void => {
    setIsOpen(!isOpen);
  };

  return multiOriginator ? (
    <>
      <ListItemButton onClick={handleToggle} sx={sidebarOriginatorSelectContainerStyles}>
        <ListItemText primary={selectedOriginator?.name} />
        {isOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <TabCollapse
        isOpen={isOpen}
        tabs={originatorTabs as []}
        handleToggle={handleToggle}
        setOriginatorID
      />
      {!isOpen && <Divider />}
    </>
  ) : (
    <ListItemText
      primary={selectedOriginator?.name ? selectedOriginator?.name : NO_ORIGINATOR_ORGANIZATION}
      sx={sidebarSingleOriginator}
    />
  );
};
