import React, { useContext, useState } from 'react';
import { FieldValues } from 'react-hook-form';

import {
  assignRolesToUser,
  assignUserToOriginator,
  createAccount as createUser,
} from '../../../api/controllers/AccountsController';
import {
  UserData,
  UserRolesRequest,
  UserRolesResponseData,
} from '../../../api/types/originatorTypes';
import { StepperWrapper } from '../../../components/common/StepperWrapper';
import { CLOSE_FORM_DIALOG, CREATED_ACCOUNT_DATA } from '../../../utils/constants/actions';
import { CREATE_ACCOUNT_LABEL, EDIT_PERMISSIONS_LABEL } from '../../../utils/constants/labels';
import { HttpStatusCodes } from '../../../utils/enums/HttpStatusCodes';
import { useStepper } from '../../../utils/hooks/useStepper';
import { AddAccountStepperProps } from '../../../utils/interfaces/originator/details/AddAccountStepperProps';
import { AssignRoleToUserRequest } from '../../../utils/interfaces/originator/details/AssignRoleToUserRequest';
import { AssignUserToOriginatorRequest } from '../../../utils/interfaces/originator/details/AssignUserToOriginatorRequest';
import { Context } from '../../../utils/store';
import { CreateAccountForm } from './forms/CreateAccountForm';
import { EditPermissionsForm } from './forms/EditPermissionsForm';

export const AddAccountStepper = (props: AddAccountStepperProps) => {
  const { originatorId, userId } = props;

  const [currentUserId, setCurrentUserId] = useState<number>();
  const [userRolesList, setUserRolesList] = useState<UserRolesResponseData[]>();

  const { dispatch } = useContext(Context);

  const createAccountMutation = createUser();
  const assignUserToOrigintorMutation = assignUserToOriginator();
  const assignRoleToUserMutation = assignRolesToUser();

  const handleClose = () => {
    dispatch({ type: CLOSE_FORM_DIALOG });
  };

  const createAccount = (data: FieldValues) => {
    if (originatorId) {
      assignUserToOrigintorMutation
        .mutateAsync({ ...data, originatorId } as AssignUserToOriginatorRequest)
        .then((response) => {
          if (response.status === HttpStatusCodes.Success) {
            setCurrentUserId(response.data.data?.id);
            setUserRolesList(response.data.data as UserRolesResponseData[]);
            goToNextStep();
          }
        });
    } else if (data.email) {
      createAccountMutation.mutateAsync(data.email).then((response) => {
        dispatch({ type: CREATED_ACCOUNT_DATA, payload: response?.data?.data as UserData });
        if (response.status === HttpStatusCodes.Success) {
          setCurrentUserId(response.data.data?.id);
          goToNextStep();
        }
      });
    }
  };

  const addPermissions = (data: FieldValues) => {
    const structuredData = {
      assignedRoleList: Object.keys(data).filter((key) => data[key]),
    } as UserRolesRequest;

    assignRoleToUserMutation
      .mutateAsync({
        userId: currentUserId || userId,
        baseRequest: structuredData,
      } as AssignRoleToUserRequest)
      .then((response) => {
        if (response.status === HttpStatusCodes.Success) {
          handleClose();
        }
      });
  };

  const accountSteps = [
    {
      label: CREATE_ACCOUNT_LABEL,
      component: <CreateAccountForm onSubmit={createAccount} handleClose={handleClose} />,
    },
    {
      label: EDIT_PERMISSIONS_LABEL,
      component: (
        <EditPermissionsForm
          onSubmit={addPermissions}
          handleClose={handleClose}
          originatorId={originatorId}
          hasAssignedUserRoles={!!userRolesList}
          userId={userId || currentUserId}
        />
      ),
    },
  ];

  const { activeStep, goToNextStep } = useStepper(accountSteps, handleClose);

  return (
    <StepperWrapper
      steps={accountSteps}
      // Adding 1 more step if we have userId to directly trigger stepper to second step.
      activeStep={userId ? activeStep + 1 : activeStep}
    />
  );
};
