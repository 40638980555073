import { nameof } from 'ts-simple-nameof';

import { useGetRequest } from '../../utils/hooks/query/useGetRequest';
import { useMutationRequest } from '../../utils/hooks/query/useMutationRequest';
import { originatorServiceClient } from '../apiClient';
import {
  Api,
  Originator,
  OriginatorBase,
  OriginatorSettings,
  OriginatorSpv,
} from '../types/originatorTypes';

export const getAllOriginators = (): Originator[] => {
  const { data: originators } = useGetRequest({
    key: nameof<Api<unknown>>((x) => x.api.getAllOriginators),
    func: async () => (await originatorServiceClient.api.getAllOriginators()).data,
  });

  if (!originators || !originators.data) {
    return [];
  }

  return originators.data;
};

export const getOriginatorById = (id: number): Originator => {
  const params = { id };

  const { data: originator } = useGetRequest({
    key: nameof<Api<unknown>>((x) => x.api.getOriginatorById(id)),
    func: async () => (await originatorServiceClient.api.getOriginatorById(id)).data,
    params,
  });

  return originator?.data as Originator;
};

export const createOriginator = () =>
  useMutationRequest(
    (request: OriginatorBase) => originatorServiceClient.api.createOriginator(request),
    nameof<Api<unknown>>((x) => x.api.getAllOriginators),
  );

export const updateOriginator = (id: number) =>
  useMutationRequest(
    (request: OriginatorBase) => originatorServiceClient.api.updateOriginator(id, request),
    nameof<Api<unknown>>((x) => x.api.getOriginatorById(id)),
  );

export const getOriginatorSpvs = (originatorId: number): OriginatorSpv => {
  const params = { originatorId };

  const { data: originatorSpvs } = useGetRequest({
    key: nameof<Api<unknown>>((x) => x.api.getSingleOriginatorCountriesSpvs),
    func: async () =>
      (await originatorServiceClient.api.getSingleOriginatorCountriesSpvs(originatorId)).data,
    ...params,
  });

  return originatorSpvs?.data as OriginatorSpv;
};

export const getOriginatorSettings = (originatorId: number): OriginatorSettings => {
  const params = { originatorId };

  const { data: originatorSettings } = useGetRequest({
    key: nameof<Api<unknown>>((x) => x.api.getOriginatorSettingsById),
    func: async () =>
      (await originatorServiceClient.api.getOriginatorSettingsById(originatorId)).data,
    ...params,
  });

  return originatorSettings?.data as OriginatorSettings;
};

export const updateOriginatorSettings = (id: number) =>
  useMutationRequest(
    (request: OriginatorSettings) =>
      originatorServiceClient.api.updateOriginatorSettings(id, request),
    nameof<Api<unknown>>((x) => x.api.getOriginatorSettingsById),
  );
