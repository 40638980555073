import React, { useContext } from 'react';

import { GridRowId } from '@mui/x-data-grid-pro';

import { originatorProductsColumns } from '../../../../../../common/tableColumns/originators/details/originatorProductColumns';
import { OPEN_INFO_DIALOG } from '../../../../../../utils/constants/actions';
import { getGridColumns } from '../../../../../../utils/helpers/getGridColumns';
import { OriginatorProductDataProps } from '../../../../../../utils/interfaces/originator/details/OriginatorProductsDataProps';
import { Context } from '../../../../../../utils/store';
import { DataGridView } from '../../../../../common/grid/DataGridView';
import { OriginatorProductDialog } from './OriginatorProductDialog';

export const OriginatorProductsTable = (props: OriginatorProductDataProps) => {
  const {
    originatorId,
    products,
    countries,
    loanTypes,
    loanCurrencies,
    amortizationMethods,
    installmentTypes,
  } = props;

  const { dispatch } = useContext(Context);

  const handleRowClick = (id: GridRowId) => {
    const product = products.find((x) => x.id === id);

    if (product && product.id) {
      dispatch({
        type: OPEN_INFO_DIALOG,
        payload: {
          content: (
            <OriginatorProductDialog
              originatorId={originatorId}
              productId={product.id}
              countries={countries}
              loanTypes={loanTypes}
              loanCurrencies={loanCurrencies}
              amortizationMethods={amortizationMethods}
              installmentTypes={installmentTypes}
            />
          ),
        },
      });
    }
  };

  return (
    <DataGridView
      data={products}
      columns={getGridColumns(originatorProductsColumns)}
      handleRowClick={handleRowClick}
    />
  );
};
