import {
  IS_ACTIVE_IMPORT_LOANS_ID,
  IS_ACTIVE_IMPORT_PAYMENTS_ID,
} from '../../utils/constants/identifiers';
import { ACTIVE_IMPORT_LOANS, ACTIVE_IMPORT_PAYMENTS } from '../../utils/constants/labels';
import { OriginatorDetailsFieldProps } from '../../utils/interfaces/originator/OriginatorDetailsFieldsProps';

export const originatorSettingsFields: OriginatorDetailsFieldProps[] = [
  { title: ACTIVE_IMPORT_LOANS, id: IS_ACTIVE_IMPORT_LOANS_ID },
  { title: ACTIVE_IMPORT_PAYMENTS, id: IS_ACTIVE_IMPORT_PAYMENTS_ID },
];
