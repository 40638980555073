import React from 'react';

import { FeeInfo } from '../../../../../api/types/originatorTypes';
import { feeColumns } from '../../../../../common/tableColumns/transactions/transactionDetails/feeColumns';
import { TRANSACTION_FEE_ID } from '../../../../../utils/constants/identifiers';
import { getGridColumns } from '../../../../../utils/helpers/getGridColumns';
import { DataGridView } from '../../../../common/grid/DataGridView';

export const TransactionFeesTab = (props: { data: FeeInfo[] }) => {
  const { data } = props;

  return (
    <DataGridView
      data={data}
      columns={getGridColumns(feeColumns)}
      rowId={TRANSACTION_FEE_ID}
      disableSelectionOnClick
      applyErrorOnRows
    />
  );
};
