import * as React from 'react';
import { useState } from 'react';
import { useLocation } from 'react-router';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { DataGridPro, GridSortModel } from '@mui/x-data-grid-pro';

import { customHistory } from '../../../components/App';
import { dataGridColumnStyles, dataGridContainerStyles } from '../../../styles/dataGridStyles';
import {
  TABLE_CURRENT_PAGE,
  TABLE_ROWS_PAGE_OPTIONS,
  TABLE_ROWS_PER_PAGE,
} from '../../../utils/constants/common';
import { ROW_ID } from '../../../utils/constants/identifiers';
import { paginationChange } from '../../../utils/helpers/advancedFilters';
import { getGridRowStatus } from '../../../utils/helpers/getGridRowStatus';
import { DataGridProps } from '../../../utils/interfaces/grid/DataGridProps';

export const DataGridView = <T extends object>(props: DataGridProps<T>) => {
  const {
    data,
    rowId,
    columns,
    rowsPerPageOptions,
    rowsPerPage,
    disableColumnMenu,
    disableColumnResize,
    applyErrorOnRows,
    disableSelectionOnClick,
    handleRowClick,
    treeData,
    getTreeDataPath,
    groupingColDef,
    tableSettings,
    dataCount,
    paginationMode,
    setNewSort,
    sortModelChange,
    sortingMode,
  } = props;

  const [pageSize, setPageSize] = useState<number>(rowsPerPage || TABLE_ROWS_PER_PAGE);
  const [page, setPage] = useState<number>(TABLE_CURRENT_PAGE);

  const search = useLocation().search;

  return (
    <Paper>
      <Box sx={dataGridContainerStyles}>
        <DataGridPro
          treeData={treeData}
          getTreeDataPath={getTreeDataPath}
          groupingColDef={groupingColDef}
          rows={data ? data : []}
          onRowClick={(row) => handleRowClick && handleRowClick(row.id)}
          getRowId={(row) => row[rowId ?? ROW_ID]}
          columns={columns}
          pagination
          paginationMode={paginationMode || 'client'}
          rowsPerPageOptions={rowsPerPageOptions || TABLE_ROWS_PAGE_OPTIONS}
          rowCount={paginationMode === 'server' ? dataCount || 0 : data?.length || 0}
          page={paginationMode === 'server' ? (tableSettings ? tableSettings.pageNumber : 0) : page}
          onPageChange={
            paginationMode === 'server'
              ? (newPage) => {
                  const params = new URLSearchParams(search);
                  paginationChange(newPage, 'pageNumber', params);
                }
              : (newPage) => setPage(newPage)
          }
          pageSize={
            paginationMode === 'server' ? (tableSettings ? tableSettings.pageSize : 10) : pageSize
          }
          onPageSizeChange={
            paginationMode === 'server'
              ? (newPageSize) => {
                  const params = new URLSearchParams(search);
                  paginationChange(newPageSize, 'pageSize', params);

                  params.set('pageNumber', '0');
                  customHistory.push({ search: params.toString() });
                }
              : (newPageSize) => setPageSize(newPageSize)
          }
          sx={dataGridColumnStyles}
          loading={data === null}
          disableVirtualization
          disableColumnMenu={disableColumnMenu}
          disableSelectionOnClick={disableSelectionOnClick}
          getRowClassName={(params) => getGridRowStatus(params, applyErrorOnRows)}
          disableColumnResize={disableColumnResize}
          defaultGroupingExpansionDepth={-1}
          sortingMode={sortingMode || 'client'}
          onSortModelChange={(newData: GridSortModel) => {
            if (sortModelChange && setNewSort) {
              sortModelChange(newData, setNewSort);
            }
          }}
        />
      </Box>
    </Paper>
  );
};
