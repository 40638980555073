import React from 'react';

import { PayScheduleInfo } from '../../../../../api/types/originatorTypes';
import { paySchedulesColumns } from '../../../../../common/tableColumns/transactions/transactionDetails/paySchedulesColumns';
import { PAY_SCHEDULE_ID } from '../../../../../utils/constants/identifiers';
import { getGridColumns } from '../../../../../utils/helpers/getGridColumns';
import { DataGridView } from '../../../../common/grid/DataGridView';

export const TransactionPaySchedulesTab = (props: { data: PayScheduleInfo[] }) => {
  const { data } = props;

  return (
    <DataGridView
      data={data}
      rowId={PAY_SCHEDULE_ID}
      columns={getGridColumns(paySchedulesColumns)}
      disableSelectionOnClick
      applyErrorOnRows
    />
  );
};
