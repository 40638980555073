import React from 'react';

import { EMPTY_GRID_CELL_NUMBER_VALUE } from '../../../../utils/constants/options';
import { convertExponentialToDecimal } from '../../../../utils/helpers/commonHelper';

export const DecimalNumberCell = (props: { value?: number }) => {
  const { value } = props;

  return <>{value ? convertExponentialToDecimal(value) : EMPTY_GRID_CELL_NUMBER_VALUE}</>;
};
