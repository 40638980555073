import { nameof } from 'ts-simple-nameof';

import { useGetRequest } from '../../utils/hooks/query/useGetRequest';
import { useMutationRequest } from '../../utils/hooks/query/useMutationRequest';
import { originatorServiceClient } from '../apiClient';
import { Api, InvestmentSetting, InvestmentSettingBase } from '../types/originatorTypes';

export const getAllCurrecyInvestAmounts = (): InvestmentSetting[] => {
  const { data: currencyInvests } = useGetRequest({
    key: nameof<Api<unknown>>((x) => x.api.getAllInvestmentSettings),
    func: async () => (await originatorServiceClient.api.getAllInvestmentSettings()).data,
  });

  if (!currencyInvests || !currencyInvests.data) {
    return [];
  }

  return currencyInvests.data;
};

export const createCurrencyInvestAmount = () =>
  useMutationRequest(
    (request: InvestmentSettingBase) =>
      originatorServiceClient.api.createInvestmentSetting(request),
    nameof<Api<unknown>>((x) => x.api.getAllInvestmentSettings),
  );

export const updateCurrencyInvestAmount = (id: number) =>
  useMutationRequest(
    (request: InvestmentSettingBase) =>
      originatorServiceClient.api.updateInvestmentSetting(id, request),
    nameof<Api<unknown>>((x) => x.api.getAllInvestmentSettings),
  );

export const deleteCurrencyInvestAmount = () =>
  useMutationRequest(
    (id: number) => originatorServiceClient.api.deleteInvestmentSetting(id),
    nameof<Api<unknown>>((x) => x.api.getAllInvestmentSettings),
  );
