import React from 'react';

import Box from '@mui/material/Box';
import { DataGridPro } from '@mui/x-data-grid-pro';

import { infoColumns } from '../../../../common/tableColumns/transactions/transactionDetails/infoColumns';
import {
  transactionInfoBoxStyles,
  transactionInfoGridStyles,
} from '../../../../styles/transactionsStyles';
import { getGridColumns } from '../../../../utils/helpers/getGridColumns';
import { generateTransactionInfoData } from '../../../../utils/helpers/transactionHelpers';
import { TransactionInfoGridProps } from '../../../../utils/interfaces/transaction/TransactionInfoGridProps';

export const TransactionInfoGrid = (props: TransactionInfoGridProps) => {
  const { data } = props;

  if (!data) {
    return null;
  }

  const transactionData = generateTransactionInfoData(data);

  return (
    <Box sx={transactionInfoBoxStyles}>
      <DataGridPro
        rows={transactionData}
        columns={getGridColumns(infoColumns)}
        sx={transactionInfoGridStyles}
        disableVirtualization
        hideFooter
      />
    </Box>
  );
};
