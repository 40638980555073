import React, { useContext } from 'react';

import { GridColumns, GridRowId } from '@mui/x-data-grid-pro';

import { deleteOriginatorAccount } from '../../../../../../api/controllers/AccountsController';
import { UserData } from '../../../../../../api/types/originatorTypes';
import { originatorAccountsColumns } from '../../../../../../common/tableColumns/originators/details/originatorAccountsColumns';
import {
  CLOSE_CONFIRMATION_DIALOG,
  OPEN_CONFIRMATION_DIALOG,
  OPEN_FORM_DIALOG,
} from '../../../../../../utils/constants/actions';
import { GRID_ACTIONS } from '../../../../../../utils/constants/common';
import { DELETE_ACCOUNT_CONFIRMATION } from '../../../../../../utils/constants/confirmations';
import { DELETE_ACCOUNT_DIALOG_ID } from '../../../../../../utils/constants/identifiers';
import { GRID_ACTIONS_COLUMN_FLEX } from '../../../../../../utils/constants/styles';
import { DELETE_ACCOUNT_TITLE, EDIT_ACCOUNT_TITLE } from '../../../../../../utils/constants/titles';
import { GridColType } from '../../../../../../utils/enums/GridColType';
import { getGridColumns } from '../../../../../../utils/helpers/getGridColumns';
import { Context } from '../../../../../../utils/store';
import { DataGridView } from '../../../../../common/grid/DataGridView';
import { GridActionsView } from '../../../../../common/grid/GridActionsView';
import { AddAccountStepper } from '../../../../accounts/AddAccountStepper';

export const OriginatorAccountsTableView = (props: {
  originatorAccountsData: UserData[];
  originatorId: number;
  permissionType: string;
}) => {
  const { originatorAccountsData, originatorId, permissionType } = props;
  const { dispatch } = useContext(Context);
  const deleteAccountMutation = deleteOriginatorAccount();

  const handleEdit = (id: GridRowId): void => {
    dispatch({
      type: OPEN_FORM_DIALOG,
      payload: {
        title: EDIT_ACCOUNT_TITLE,
        content: <AddAccountStepper userId={Number(id)} originatorId={originatorId} />,
      },
    });
  };

  const handleDelete = (id: GridRowId): void => {
    dispatch({
      type: OPEN_CONFIRMATION_DIALOG,
      payload: {
        dialogId: DELETE_ACCOUNT_DIALOG_ID,
        title: DELETE_ACCOUNT_TITLE,
        content: DELETE_ACCOUNT_CONFIRMATION,
        onAgree: () => handleDeleteConfirm(Number(id)),
      },
    });
  };

  const handleDeleteConfirm = (id: number): void => {
    deleteAccountMutation.mutateAsync({ originatorId: originatorId, userId: id });

    dispatch({ type: CLOSE_CONFIRMATION_DIALOG });
  };

  const actions: GridColumns = [
    {
      field: GRID_ACTIONS,
      type: GridColType.Actions,
      flex: GRID_ACTIONS_COLUMN_FLEX,
      getActions: ({ id }) => [
        <GridActionsView
          key={id}
          handleEditClick={() => (handleEdit ? handleEdit(id) : null)}
          handleDeleteClick={() => (handleDelete ? handleDelete(id) : null)}
          permissionType={permissionType}
        />,
      ],
    },
  ];

  const columns: GridColumns = [...originatorAccountsColumns, ...actions];

  return <DataGridView data={originatorAccountsData} columns={getGridColumns(columns)} />;
};
