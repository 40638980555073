export const TABLE_REFETCH_INTERVAL_MS = 3000;

export const MIN_NUMBER = 0;
export const MAX_NUMBER = Number.MAX_SAFE_INTEGER;
export const DECIMAL_MAX_NUMBER = 1;
export const DECIMAL_ACCURACY = 10;
export const ORIGINATOR_FEE_DECIMAL_ACCURACY = 5;

export const EMPTY_CELL_VALUE = 0;

export const DATE_FORMAT = 'DD.MM.YYYY';
export const REPORT_DATE_FORMAT = 'DD-MM-YYYY';
export const DATEPICKER_DATE_FORMAT = 'yyyy/MM/dd';

export const AUTH_REDIRECT_MS = 3000;
export const TOAST_MESSAGE_DURATION_MS = 4000;
export const TOOLBAR_DENSE_VARIANT = 'dense';

export const EMPTY_GRID_CELL_NUMBER_VALUE = 0;
export const EMPTY_GRID_CELL_STRING_VALUE = '-';

export const INSTALLMENT_TYPE_NUMBER_MIN_VALUE = 1;
export const INSTALLMENT_TYPE_NUMBER_MAX_VALUE = 31;
export const EMPTY_QUERY_ID = 0;

export const MIN_NUMBER_OF_INSTALMENT_MIN_NUMBER = 1;
export const MAX_NUMBER_OF_INSTALMENT_MIN_NUMBER = 1;
export const LOAN_GUARANT_PERIOD_DAYS_MIN_NUMBER = 1;
export const YEAR_DAYS_BASE_MIN_NUMBER = 1;
export const ORIGINATOR_SCORE_NUMBER_ACCURACY = 10;
export const SHARED_PART_LOAN_MAX_NUMBER = 1;
