import React from 'react';

import Paper from '@mui/material/Paper';

import { BORROWER_DATA_FIELDS_PERMISSION } from '../../../../src/utils/constants/permissions';
import { tabsPaperStyles } from '../../../styles/tabsStyles';
import { LOAN_BORROWER_DATA_FIELDS_PERMISSION } from '../../../utils/constants/permissions';
import { BorrowerDataTabs } from '../../../utils/enums/BorrowerDataTabs';
import { BorrowerDataTabsProps } from '../../../utils/interfaces/borrowerData/BorrowerDataTabsProps';
import { TabsContentView } from '../../common/tabs/TabsContentView';
import { BorrowerDataFieldsTab } from './tabs/BorrowerDataFieldsTab';
import { BorrowerDataLoanTypesTab } from './tabs/BorrowerDataLoanTypesTab';

export const BorrowerDataTabsContentView = (props: BorrowerDataTabsProps) => {
  const { selectedTab } = props;

  return (
    <Paper square sx={tabsPaperStyles}>
      <TabsContentView
        selectedTab={selectedTab}
        tabIndex={BorrowerDataTabs.LoanTypes}
        disablePadding
        permissionType={LOAN_BORROWER_DATA_FIELDS_PERMISSION}
      >
        <BorrowerDataLoanTypesTab />
      </TabsContentView>
      <TabsContentView
        selectedTab={selectedTab}
        tabIndex={BorrowerDataTabs.Fields}
        disablePadding
        permissionType={BORROWER_DATA_FIELDS_PERMISSION}
      >
        <BorrowerDataFieldsTab />
      </TabsContentView>
    </Paper>
  );
};
