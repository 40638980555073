import React, { useEffect } from 'react';
import Dropzone, { Accept } from 'react-dropzone';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import {
  chooseFileButtonStyle,
  dropFileStyle,
  fileUploadBoxStyles,
} from '../../../styles/fileUploadStyles';
import { CHOOSE_FILE_BUTTON } from '../../../utils/constants/buttons';
import { DIV_TAG, LABEL_TAG } from '../../../utils/constants/components';
import { FILE_LABEL } from '../../../utils/constants/labels';
import { REQUIRED_FIELD_MESSAGE } from '../../../utils/constants/messages';
import { FORM_FIELD_MARGIN } from '../../../utils/constants/styles';
import { AlignOptions } from '../../../utils/enums/AlignOptions';
import { ButtonVariants } from '../../../utils/enums/ButtonVariants';
import { FileType } from '../../../utils/enums/FileType';
import { handleRequiredFields } from '../../../utils/helpers/formFieldsHelper';
import { FileUploadViewProps } from '../../../utils/interfaces/form/FileUploadViewProps';

const DROP_FILE_HERE = 'or Drop file here';

export const FileUploadView = (props: FileUploadViewProps) => {
  const { id, register, errors, setValue } = props;

  useEffect(() => {
    register(id, {
      required: REQUIRED_FIELD_MESSAGE,
    });
  }, [register]);

  // Later we can move this outside, so the docs we are accepting will become flexible.
  const acceptFiles: Accept = {
    [`${FileType.Xlsx}`]: [],
  };

  return (
    <TextField
      id={id}
      label={FILE_LABEL}
      InputLabelProps={{ required: true, shrink: true }}
      margin={FORM_FIELD_MARGIN}
      error={Boolean(errors[id])}
      helperText={handleRequiredFields(errors, id)}
      fullWidth
      disabled
      InputProps={{
        startAdornment: (
          <Dropzone
            onDrop={(acceptedFiles) => setValue(id, acceptedFiles, { shouldValidate: true })}
            multiple={false}
            accept={acceptFiles}
          >
            {({ getRootProps, getInputProps }) => (
              <Box component={DIV_TAG} sx={fileUploadBoxStyles} {...getRootProps()}>
                <Typography align={AlignOptions.Left}>
                  <input {...getInputProps()} />
                  <Button
                    component={LABEL_TAG}
                    variant={ButtonVariants.Contained}
                    sx={chooseFileButtonStyle}
                  >
                    {CHOOSE_FILE_BUTTON}
                  </Button>
                </Typography>
                <Typography sx={dropFileStyle} align={AlignOptions.Right}>
                  {DROP_FILE_HERE}
                </Typography>
              </Box>
            )}
          </Dropzone>
        ),
      }}
    />
  );
};
