import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';

import Grid from '@mui/material/Grid';

import { getAllCountries } from '../../../../api/controllers/NomenclatureController';
import { getOriginatorById } from '../../../../api/controllers/OriginatorController';
import { getAllOriginatorGroups } from '../../../../api/controllers/OriginatorGroupController';
import { GridOptions } from '../../../../utils/enums/GridOptions';
import { Context } from '../../../../utils/store';
import { ContextType } from '../../../../utils/store/types/ContextType';
import { OriginatorDetailsView } from '../../../views/originators/details/OriginatorDetailsView';
import { OriginatorTabsView } from '../../../views/originators/details/OriginatorTabsView';

export const OriginatorDetailsScreen = () => {
  const params = useParams();
  const { state } = useContext<ContextType>(Context);

  const groups = getAllOriginatorGroups(!state.user?.user?.isSystemUser);

  const currentOriginator = state.originatorID;
  const originatorId = Number(params.id) || Number(currentOriginator);

  const originator = getOriginatorById(originatorId);

  const countries = getAllCountries();

  if (!originator || !originator.id) {
    //TODO: Implement NotFound screen later.
    return <></>;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={GridOptions.ExtraLarge} lg={GridOptions.ExtraSmall}>
        <OriginatorDetailsView originator={originator} countries={countries} groups={groups} />
      </Grid>
      <Grid item xs={GridOptions.ExtraLarge} lg={GridOptions.Large}>
        <OriginatorTabsView originatorId={originator.id} />
      </Grid>
    </Grid>
  );
};
