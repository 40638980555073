import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import {
  ALL_ROUTES_WILDCARD,
  BASE_PATH_AUTHENTICATION,
  ROUTE_PATH_ACCOUNTS,
  ROUTE_PATH_BORROWER_DATA,
  ROUTE_PATH_CURRENCY_INVEST_AMOUNT,
  ROUTE_PATH_DASHBOARD,
  ROUTE_PATH_GROUPS,
  ROUTE_PATH_HOME,
  ROUTE_PATH_LOANS,
  ROUTE_PATH_LOGIN,
  ROUTE_PATH_ORIGINATORS,
  ROUTE_PATH_ORIGINATORS_SPVS,
  ROUTE_PATH_ORIGINATOR_DETAILS,
  ROUTE_PATH_REGISTER,
  ROUTE_PATH_RESET_PASSWORD,
  ROUTE_PATH_SCORE_CLASSES,
  ROUTE_PATH_SPVS,
  ROUTE_PATH_TRANSACTIONS,
  ROUTE_PATH_TRANSACTION_DETAILS,
} from '../utils/constants/routes';
import { AuthenticationProtectedRoute } from './common/authentication/AuthProtectedRoute';
import { ProtectedRoute } from './common/authentication/ProtectedRoute';
import { AuthLayout } from './layouts/AuthLayout';
import { MainLayout } from './layouts/MainLayout';
import { AccountsScreen } from './screens/admin/AccountsScreen';
import { BorrowerDataScreen } from './screens/admin/BorrowerDataScreen';
import { CurrencyInvestAmountScreen } from './screens/admin/CurrencyInvestAmountScreen';
import { DashboardScreen } from './screens/admin/DashboardScreen';
import { GroupsScreen } from './screens/admin/GroupsScreen';
import { OriginatorsSpvsScreen } from './screens/admin/OriginatorsSpvsScreen';
import { ScoreClassesScreen } from './screens/admin/ScoreClassesScreen';
import { SpvsScreen } from './screens/admin/SpvsScreen';
import { OriginatorDetailsScreen } from './screens/admin/originators/OriginatorDetailsScreen';
import { OriginatorsScreen } from './screens/admin/originators/OriginatorsScreen';
import { LoansScreen } from './screens/dataExchange/loans/LoansScreen';
import { TransactionDetailsScreen } from './screens/dataExchange/transaction/TransactionDetailsScreen';
import { TransactionsScreen } from './screens/dataExchange/transaction/TransactionsScreen';
import { CreateAccountForm } from './views/authentication/CreateAccountForm';
import { LoginForm } from './views/authentication/LoginForm';
import { ResetPasswordForm } from './views/authentication/ResetPasswordForm';

export const AppRoutes = () => {
  return (
    <Routes>
      <>
        <Route path={BASE_PATH_AUTHENTICATION} element={<Navigate to={ROUTE_PATH_LOGIN} />} />
        <Route path={BASE_PATH_AUTHENTICATION} element={<AuthLayout />}>
          <Route element={<AuthenticationProtectedRoute />}>
            <Route path={ROUTE_PATH_LOGIN} element={<LoginForm />} />
            <Route path={ROUTE_PATH_REGISTER} element={<CreateAccountForm />} />
            <Route path={ROUTE_PATH_RESET_PASSWORD} element={<ResetPasswordForm />} />
          </Route>
        </Route>
        <Route path={ALL_ROUTES_WILDCARD} element={<Navigate to={ROUTE_PATH_DASHBOARD} />} />
        <Route path={ROUTE_PATH_HOME} element={<MainLayout />}>
          <Route element={<ProtectedRoute />}>
            <Route path={ROUTE_PATH_HOME} element={<Navigate to={ROUTE_PATH_DASHBOARD} />} />
            <Route path={ROUTE_PATH_ORIGINATORS_SPVS} element={<OriginatorsSpvsScreen />} />
            <Route path={ROUTE_PATH_DASHBOARD} element={<DashboardScreen />} />
            <Route path={ROUTE_PATH_ORIGINATORS} element={<OriginatorsScreen />} />
            <Route path={ROUTE_PATH_ORIGINATOR_DETAILS} element={<OriginatorDetailsScreen />} />
            (
            <Route path={ROUTE_PATH_SPVS} element={<SpvsScreen />} />)
            <Route path={ROUTE_PATH_GROUPS} element={<GroupsScreen />} />
            <Route path={ROUTE_PATH_SCORE_CLASSES} element={<ScoreClassesScreen />} />
            <Route path={ROUTE_PATH_ACCOUNTS} element={<AccountsScreen />} />
            <Route path={ROUTE_PATH_TRANSACTIONS} element={<TransactionsScreen />} />
            <Route path={ROUTE_PATH_TRANSACTION_DETAILS} element={<TransactionDetailsScreen />} />
            <Route path={ROUTE_PATH_LOANS} element={<LoansScreen />} />
            <Route path={ROUTE_PATH_BORROWER_DATA} element={<BorrowerDataScreen />} />
            <Route
              path={ROUTE_PATH_CURRENCY_INVEST_AMOUNT}
              element={<CurrencyInvestAmountScreen />}
            />
          </Route>
        </Route>
      </>
    </Routes>
  );
};
