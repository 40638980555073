import {
  FEES_LABEL,
  LOANS_LABEL,
  PAYMENTS_LABEL,
  PAYMENT_SCHEDULES_LABEL,
} from '../constants/labels';
import { Transaction } from './../../api/types/originatorTypes';

export const generateTransactionInfoData = (data: Transaction) => {
  return [
    {
      id: 1,
      name: LOANS_LABEL,
      count: data.loanTotalCount,
      processed: data.loanProcessedCount,
      submitted: data.loanSubmittedCount,
    },
    {
      id: 2,
      name: PAYMENT_SCHEDULES_LABEL,
      count: data.payScheduleTotalCount,
      processed: data.payScheduleProcessedCount,
      submitted: data.payScheduleSubmittedCount,
    },
    {
      id: 3,
      name: PAYMENTS_LABEL,
      count: data.paymentTotalCount,
      processed: data.paymentProcessedCount,
      submitted: data.paymentSubmittedCount,
    },
    {
      id: 4,
      name: FEES_LABEL,
      count: data.feeTotalCount,
      processed: data.feeProcessedCount,
      submitted: data.feeSubmittedCount,
    },
  ];
};
