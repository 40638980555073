import { TransactionStatus } from '../../api/types/originatorTypes';
import { TransactionStatus as CustomStatus } from '../enums/TransactionStatus';
import { usePermissions } from '../hooks/permissions/usePermissions';
import { UserMapped } from '../interfaces/Permissions';

export const isButtonVisible = (
  permissionType: string | string[],
  user?: UserMapped,
  status?: TransactionStatus,
): boolean => {
  const permissions = usePermissions();

  const hasPermission =
    typeof permissionType === 'string'
      ? permissions.hasUpdatePermission(permissionType)
      : permissionType &&
        (permissions.hasUpdatePermission(permissionType[0]) ||
          permissions.hasUpdatePermission(permissionType[1]));

  let isVisible: boolean = false;

  switch (status) {
    case CustomStatus.Draft:
      isVisible = true;
      break;
    case CustomStatus.PartiallyPublished:
      isVisible = true;
      break;
    default:
      isVisible = false;
      break;
  }

  isVisible = isVisible && hasPermission;

  return isVisible;
};
