import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { extractBreadcrumbs } from '../helpers/extractBreadcrumbs';
import { Breadcrumb } from '../interfaces/breadcrumb/Breadcrumb';

export const useBreadcrumbs = (): Breadcrumb[] => {
  const location = useLocation();
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([]);

  useEffect(() => {
    const extractedBreadcrumbs = extractBreadcrumbs(location.pathname);

    setBreadcrumbs(extractedBreadcrumbs);
  }, [location.pathname]);

  return breadcrumbs;
};
