import React from 'react';

import { getAllCountries } from '../../../../api/controllers/NomenclatureController';
import { EMPTY_GRID_CELL_STRING_VALUE } from '../../../../utils/constants/options';

export const CountryOfRegistrationCell = (props: { countryOfRegistrationId: number }) => {
  const { countryOfRegistrationId } = props;

  const country = getAllCountries().find((x) => x.id === countryOfRegistrationId);

  return <>{country ? country.name : EMPTY_GRID_CELL_STRING_VALUE}</>;
};
