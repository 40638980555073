import React, { useContext } from 'react';
import { FieldValues, useForm } from 'react-hook-form';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import jwtDecode from 'jwt-decode';

import { alertMessagesStyles } from '../../../styles/changePasswordStyles';
import { changePassword, getToken } from '../../../utils/authentication/authentication';
import { CLOSE_FORM_DIALOG } from '../../../utils/constants/actions';
import { FORM_TAG } from '../../../utils/constants/components';
import { CHANGE_PASSWORD_FORM_ID } from '../../../utils/constants/identifiers';
import {
  PASSWORD_FIELD_CONFIRM_ID,
  PASSWORD_FIELD_CURRENT_ID,
  PASSWORD_FIELD_NEW_ID,
} from '../../../utils/constants/keys';
import {
  ERROR_LABEL,
  PASSWORD_CURRENT_LABEL,
  PASSWORD_NEW_LABEL,
  WARNING_LABEL,
} from '../../../utils/constants/labels';
import {
  NO_CONNECTION_TO_THE_SERVER_MESSAGE,
  UNEXPECTED_ERROR_MESSAGE,
} from '../../../utils/constants/messages';
import { useAuthResponseHandler } from '../../../utils/hooks/useAuthResponseHandler';
import { AuthErrorType } from '../../../utils/interfaces/authentication/AuthErrorType';
import { Context } from '../../../utils/store';
import { AlertView } from '../../common/AlertView';
import { ConfirmPasswordField } from '../../common/form/ConfirmPasswordField';
import { PasswordField } from '../../common/form/PasswordField';

export const ChangePassword = () => {
  const { dispatch } = useContext(Context);
  const authResponseHandler = useAuthResponseHandler();

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm();

  const onError = (error: AuthErrorType) => {
    authResponseHandler.handleError(error);
  };

  const onSuccess = () => {
    dispatch({ type: CLOSE_FORM_DIALOG });
  };

  const onSubmit = async (data: FieldValues) => {
    // TODO: Eventually move this to higher level component and pass user in Context
    const token = getToken();
    const decodedToken: Record<string, string> = jwtDecode(token as string);

    changePassword(
      decodedToken.email,
      data[PASSWORD_FIELD_CURRENT_ID],
      data[PASSWORD_FIELD_NEW_ID],
      data[PASSWORD_FIELD_CONFIRM_ID],
      onSuccess,
      onError,
    );
  };

  return (
    <Box component={FORM_TAG} id={CHANGE_PASSWORD_FORM_ID} onSubmit={handleSubmit(onSubmit)}>
      {authResponseHandler.authenticationError && (
        <AlertView
          severity={WARNING_LABEL}
          message={authResponseHandler.authenticationError.detail}
          sx={alertMessagesStyles}
        />
      )}
      {authResponseHandler.isServerError && (
        <AlertView severity={ERROR_LABEL} message={NO_CONNECTION_TO_THE_SERVER_MESSAGE} />
      )}
      {authResponseHandler.isClientError && (
        <AlertView severity={ERROR_LABEL} message={UNEXPECTED_ERROR_MESSAGE} />
      )}
      <Grid container rowSpacing={1}>
        <PasswordField
          id={PASSWORD_FIELD_CURRENT_ID}
          label={PASSWORD_CURRENT_LABEL}
          register={register}
          errors={errors}
        />
        <PasswordField
          id={PASSWORD_FIELD_NEW_ID}
          label={PASSWORD_NEW_LABEL}
          register={register}
          errors={errors}
        />
        <ConfirmPasswordField
          register={register}
          errors={errors}
          watch={watch}
          watchPasswordFieldId={PASSWORD_FIELD_NEW_ID}
        />
      </Grid>
    </Box>
  );
};
