import { nameof } from 'ts-simple-nameof';

import { appConfig } from '../../config/app.config';
import { isAuthenticated } from '../../utils/authentication/authentication';
import { ACCESS_TOKEN, BEARER_TOKEN } from '../../utils/constants/common';
import { errorResultHandler } from '../../utils/helpers/errorResultHandler';
import { useGetRequest } from '../../utils/hooks/query/useGetRequest';
import { UserMapped } from '../../utils/interfaces/Permissions';
import { originatorServiceClient } from '../apiClient';
import { mapUserResponse } from '../mappers/UserMapper';
import { Api, UserLogin } from '../types/originatorTypes';

export const getCurrentUser = (): UserMapped | undefined => {
  if (!isAuthenticated()) {
    return {};
  }

  const { data: user } = useGetRequest({
    func: async () => (await originatorServiceClient.api.login()).data,
    key: nameof<Api<unknown>>((x) => x.api.login),
  });

  if (!user || !user.data) {
    return undefined;
  }

  return mapUserResponse(user?.data as UserLogin);
};

const internalRegisterUrl = `${appConfig.originatorServiceApiUrl}/api/register`;

// We use directly fetch here without wrapper or axios.
// Because there is only 1 request that is different from react query.
// If we have to send more requests without react query we gonna include axios with configs.
export const internalSignUp = async () => {
  const response = await fetch(internalRegisterUrl, {
    method: 'POST',
    headers: {
      Authorization: `${BEARER_TOKEN} ${localStorage.getItem(ACCESS_TOKEN)}`,
    },
  });

  const { error } = await response.json();

  if (error) {
    errorResultHandler(error);
  }
};
