import React from 'react';
import { useForm } from 'react-hook-form';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import {
  stepperButtonsWrapperStyles,
  stepperCancelButtonStyles,
  stepperContinueToSpvButtonStyles,
} from '../../../../../styles/stepperStyles';
import {
  ADD_COUNTRY_BUTTON,
  BUTTON_SUBMIT_TYPE,
  CANCEL_BUTTON,
  CONTINUE_TO_SPVS_BUTTON,
} from '../../../../../utils/constants/buttons';
import { FORM_TAG } from '../../../../../utils/constants/components';
import {
  COUNTRY_ID,
  CREATE_ORIGINATOR_COUNTRY_FORM_ID,
} from '../../../../../utils/constants/identifiers';
import { COUNTRY_LABEL } from '../../../../../utils/constants/labels';
import { ButtonVariants } from '../../../../../utils/enums/ButtonVariants';
import { SelectorChoicesType } from '../../../../../utils/interfaces/form/SelectorChoicesType';
import { CreateOriginatorCountryProps } from '../../../../../utils/interfaces/originator/details/CreateOriginatorCountryProps';
import { SelectField } from '../../../../common/form/SelectField';

export const CreateOriginatorCountryForm = (props: CreateOriginatorCountryProps) => {
  const { onSubmit, onContinue, handleClose, countries } = props;

  const { handleSubmit, control } = useForm();

  return (
    <Box
      component={FORM_TAG}
      id={CREATE_ORIGINATOR_COUNTRY_FORM_ID}
      onSubmit={handleSubmit(onSubmit)}
    >
      <SelectField
        id={COUNTRY_ID}
        label={COUNTRY_LABEL}
        control={control}
        choices={countries as SelectorChoicesType[]}
        required
      />
      <Box sx={stepperButtonsWrapperStyles}>
        <Button
          variant={ButtonVariants.Outlined}
          sx={stepperCancelButtonStyles}
          onClick={handleClose}
        >
          {CANCEL_BUTTON}
        </Button>
        <Button
          type={BUTTON_SUBMIT_TYPE}
          id={CREATE_ORIGINATOR_COUNTRY_FORM_ID}
          variant={ButtonVariants.Contained}
        >
          {ADD_COUNTRY_BUTTON}
        </Button>
        <Button
          onClick={handleSubmit(onContinue)}
          type={BUTTON_SUBMIT_TYPE}
          id={CREATE_ORIGINATOR_COUNTRY_FORM_ID}
          variant={ButtonVariants.Contained}
          sx={stepperContinueToSpvButtonStyles}
        >
          {CONTINUE_TO_SPVS_BUTTON}
        </Button>
      </Box>
    </Box>
  );
};
