import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { gridButtonWrapperStyles, gridHeaderStyles } from '../../../styles/commonStyles';
import {
  HIDE_ADVANCED_FILTERS_BUTTON,
  HIDE_REPORTS_BUTTON,
  SHOW_ADVANCED_FILTERS_BUTTON,
  SHOW_REPORTS_BUTTON,
} from '../../../utils/constants/buttons';
import { ButtonVariants } from '../../../utils/enums/ButtonVariants';
import { usePermissions } from '../../../utils/hooks/permissions/usePermissions';
import { GridHeaderViewProps } from '../../../utils/interfaces/grid/GridHeaderViewProps';

export const GridHeaderView = (props: GridHeaderViewProps) => {
  const {
    buttonLabel,
    onButtonClick,
    toggleHeaderSections,
    permissionType,
    isAdvancedFiltersOpen,
    isReportsOpen,
    showReports,
  } = props;

  const permissions = usePermissions();

  const hasPermission =
    typeof permissionType === 'string'
      ? permissions.hasCreatePermission(permissionType)
      : permissionType &&
        (permissions.hasCreatePermission(permissionType[0]) ||
          permissions.hasCreatePermission(permissionType[1]));

  return (
    <Box sx={gridHeaderStyles}>
      <Box sx={gridButtonWrapperStyles}>
        {toggleHeaderSections && (
          <Button onClick={(e) => toggleHeaderSections(e)} startIcon={<FilterAltOutlinedIcon />}>
            {isAdvancedFiltersOpen ? HIDE_ADVANCED_FILTERS_BUTTON : SHOW_ADVANCED_FILTERS_BUTTON}
          </Button>
        )}

        {showReports && toggleHeaderSections && (
          <Button onClick={(e) => toggleHeaderSections(e)} startIcon={<AssessmentOutlinedIcon />}>
            {isReportsOpen ? HIDE_REPORTS_BUTTON : SHOW_REPORTS_BUTTON}
          </Button>
        )}
      </Box>
      {hasPermission && onButtonClick && (
        <Button onClick={onButtonClick} variant={ButtonVariants.Contained} startIcon={<AddIcon />}>
          {buttonLabel}
        </Button>
      )}
    </Box>
  );
};
