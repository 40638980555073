import {
  AMORTIZATION_METHOD_ID,
  APPLICATION_MODE_ID,
  COUNTRY_ID,
  INSTALLMENT_TYPE_ID,
  INSTALLMENT_TYPE_NUMBER_ID,
  INTEREST_RATE_MAX_ID,
  INTEREST_RATE_MIN_ID,
  INVEST_PART_PERCENT_ID,
  LOAN_CURRENCY_ID,
  LOAN_GUARANT_INTEREST_PERC_ID,
  LOAN_GUARANT_PERIOD_DAYS_ID,
  LOAN_GUARANT_PRINCIPAL_PERC_ID,
  LOAN_TYPE_ID,
  NAME_ID,
  NUMBER_OF_INSTALMENTS_MAX_ID,
  NUMBER_OF_INSTALMENTS_MIN_ID,
  YEAR_DAYS_BASE_ID,
} from '../../utils/constants/identifiers';
import {
  AMORTIZATION_METHOD_LABEL,
  APPLICATION_MODE_LABEL,
  COUNTRY_LABEL,
  INSTALLMENT_TYPE_LABEL,
  INSTALLMENT_TYPE_NUMBER_LABEL,
  INTEREST_RATE_MAX_LABEL,
  INTEREST_RATE_MIN_LABEL,
  INVEST_PART_PERCENT_LABEL,
  LOAN_CURRENCY_LABEL,
  LOAN_GUARANT_INTEREST_PERC_LABEL,
  LOAN_GUARANT_PERIOD_DAYS_LABEL,
  LOAN_GUARANT_PRINCIPAL_PERC_LABEL,
  LOAN_TYPE_LABEL,
  NAME_LABEL,
  NUMBER_OF_INSTALMENTS_MAX_LABEL,
  NUMBER_OF_INSTALMENTS_MIN_LABEL,
  YEAR_DAYS_BASE_LABEL,
} from '../../utils/constants/labels';
import { OriginatorDetailsFieldProps } from '../../utils/interfaces/originator/OriginatorDetailsFieldsProps';

export const originatorProductDetailsFields: OriginatorDetailsFieldProps[] = [
  {
    id: NAME_ID,
    title: NAME_LABEL,
  },
  {
    id: COUNTRY_ID,
    title: COUNTRY_LABEL,
  },
  {
    id: LOAN_TYPE_ID,
    title: LOAN_TYPE_LABEL,
  },
  {
    id: INTEREST_RATE_MIN_ID,
    title: INTEREST_RATE_MIN_LABEL,
  },
  {
    id: INTEREST_RATE_MAX_ID,
    title: INTEREST_RATE_MAX_LABEL,
  },
  {
    id: INVEST_PART_PERCENT_ID,
    title: INVEST_PART_PERCENT_LABEL,
  },
  {
    id: LOAN_CURRENCY_ID,
    title: LOAN_CURRENCY_LABEL,
  },
  {
    id: AMORTIZATION_METHOD_ID,
    title: AMORTIZATION_METHOD_LABEL,
  },
  {
    id: INSTALLMENT_TYPE_ID,
    title: INSTALLMENT_TYPE_LABEL,
  },
  {
    id: INSTALLMENT_TYPE_NUMBER_ID,
    title: INSTALLMENT_TYPE_NUMBER_LABEL,
  },
  {
    id: NUMBER_OF_INSTALMENTS_MIN_ID,
    title: NUMBER_OF_INSTALMENTS_MIN_LABEL,
  },
  {
    id: NUMBER_OF_INSTALMENTS_MAX_ID,
    title: NUMBER_OF_INSTALMENTS_MAX_LABEL,
  },
  {
    id: LOAN_GUARANT_PRINCIPAL_PERC_ID,
    title: LOAN_GUARANT_PRINCIPAL_PERC_LABEL,
  },
  {
    id: LOAN_GUARANT_INTEREST_PERC_ID,
    title: LOAN_GUARANT_INTEREST_PERC_LABEL,
  },
  {
    id: LOAN_GUARANT_PERIOD_DAYS_ID,
    title: LOAN_GUARANT_PERIOD_DAYS_LABEL,
  },
  {
    id: YEAR_DAYS_BASE_ID,
    title: YEAR_DAYS_BASE_LABEL,
  },
  {
    id: APPLICATION_MODE_ID,
    title: APPLICATION_MODE_LABEL,
  },
];
