import { useState } from 'react';

import { customHistory } from '../../components/App';

export const useRouteChange = () => {
  const currentRoute = customHistory.location.pathname;

  const [previousRoute, setPreviousRoute] = useState(currentRoute);

  const hasRouteChanged = () => {
    if (previousRoute !== currentRoute) {
      return true;
    }
    setPreviousRoute(currentRoute);
    return false;
  };

  return [hasRouteChanged, currentRoute];
};
