import React from 'react';
import { useForm } from 'react-hook-form';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { formGridSpacing } from '../../../../styles/formStyles';
import { FORM_TAG } from '../../../../utils/constants/components';
import {
  CURRENCY_ID,
  MIN_PRIMARY_AMOUNT_ID,
  MIN_SECONDARY_AMOUNT_ID,
  UPDATE_CURENCY_INVEST_AMOUNT_FORM_ID,
} from '../../../../utils/constants/identifiers';
import {
  CURRENCY_LABEL,
  MIN_PRIMARY_AMOUNT_LABEL,
  MIN_SECONDNARY_AMOUNT_LABEL,
} from '../../../../utils/constants/labels';
import { MAX_NUMBER } from '../../../../utils/constants/options';
import { InputFieldType } from '../../../../utils/enums/InputFieldType';
import { UpdateCurrencyInvestAmountFormProps } from '../../../../utils/interfaces/originator/currencyInvestAmount/UpdateCurrencyInvestAmountFormProps';
import { NumberInputField } from '../../../common/form/NumberInputField';
import { SelectField } from '../../../common/form/SelectField';
import { LargeGridField } from '../../../common/form/grid/LargeGridField';
import { MediumGridField } from '../../../common/form/grid/MediumGridField';

export const UpdateCurrencyInvestAmountForm = (props: UpdateCurrencyInvestAmountFormProps) => {
  const { onSubmit, currencyInvest, currencies } = props;

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
  } = useForm();

  return (
    <Box
      component={FORM_TAG}
      id={UPDATE_CURENCY_INVEST_AMOUNT_FORM_ID}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid container rowSpacing={1} columnSpacing={formGridSpacing}>
        <LargeGridField>
          <SelectField
            id={CURRENCY_ID}
            label={CURRENCY_LABEL}
            control={control}
            choices={currencies}
            required
            defaultValue={currencyInvest.currencyId}
          />
        </LargeGridField>
        <MediumGridField>
          <NumberInputField
            id={MIN_PRIMARY_AMOUNT_ID}
            label={MIN_PRIMARY_AMOUNT_LABEL}
            inputType={InputFieldType.Number}
            register={register}
            errors={errors}
            required
            defaultValue={currencyInvest.minAmountPrimary}
            maxNumber={MAX_NUMBER}
          />
        </MediumGridField>
        <MediumGridField>
          <NumberInputField
            id={MIN_SECONDARY_AMOUNT_ID}
            label={MIN_SECONDNARY_AMOUNT_LABEL}
            inputType={InputFieldType.Number}
            register={register}
            errors={errors}
            defaultValue={currencyInvest.minAmountSecondary}
            maxNumber={MAX_NUMBER}
            required
          />
        </MediumGridField>
      </Grid>
    </Box>
  );
};
