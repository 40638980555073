import React from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';

import { Box } from '@mui/material';

import { formMainStyles } from '../../../styles/authFormStyles';
import { signUp } from '../../../utils/authentication/authentication';
import { CREATE_ACCOUNT_BUTTON } from '../../../utils/constants/buttons';
import { FORM_TAG } from '../../../utils/constants/components';
import { PASSWORD_FIELD_ID } from '../../../utils/constants/keys';
import {
  ALREADY_HAVE_ACCOUNT_LABEL,
  CREATE_ACCOUNT_LABEL,
  ENTER_YOUR_DETAILS_LABEL,
  ERROR_LABEL,
  SIGN_IN_LABEL,
  WARNING_LABEL,
} from '../../../utils/constants/labels';
import {
  NO_CONNECTION_TO_THE_SERVER_MESSAGE,
  REGISTER_SUCCESS_MESSAGE,
  UNEXPECTED_ERROR_MESSAGE,
} from '../../../utils/constants/messages';
import { AUTH_REDIRECT_MS } from '../../../utils/constants/options';
import { ROUTE_PATH_LOGIN } from '../../../utils/constants/routes';
import { HttpStatusCodes } from '../../../utils/enums/HttpStatusCodes';
import { useAuthResponseHandler } from '../../../utils/hooks/useAuthResponseHandler';
import { AuthErrorType } from '../../../utils/interfaces/authentication/AuthErrorType';
import { AlertView } from '../../common/AlertView';
import { ConfirmPasswordField } from '../../common/form/ConfirmPasswordField';
import { EmailField } from '../../common/form/EmailField';
import { PasswordField } from '../../common/form/PasswordField';
import { AuthFormFooterView } from './AuthFormFooterView';
import { AuthFormHeaderView } from './AuthFormHeaderView';

export const CreateAccountForm = () => {
  const authResponseHandler = useAuthResponseHandler();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const history = useNavigate();

  const onSuccess = (): void => {
    authResponseHandler.successResultHandle(HttpStatusCodes.Created);

    setTimeout((): void => {
      history(ROUTE_PATH_LOGIN);
    }, AUTH_REDIRECT_MS);
  };

  const onError = (error: AuthErrorType): void => {
    authResponseHandler.handleError(error);
  };

  const onSubmit = async (data: FieldValues): Promise<void> => {
    signUp(data.email, data.password, data.confirmPassword, onSuccess, onError);
  };

  return (
    <Box component={FORM_TAG} onSubmit={handleSubmit(onSubmit)} style={formMainStyles}>
      <AuthFormHeaderView title={CREATE_ACCOUNT_LABEL} details={ENTER_YOUR_DETAILS_LABEL} />
      {authResponseHandler.isSuccess && <AlertView message={REGISTER_SUCCESS_MESSAGE} />}
      {authResponseHandler.isServerError && (
        <AlertView severity={ERROR_LABEL} message={NO_CONNECTION_TO_THE_SERVER_MESSAGE} />
      )}
      {authResponseHandler.isClientError && (
        <AlertView severity={ERROR_LABEL} message={UNEXPECTED_ERROR_MESSAGE} />
      )}
      {authResponseHandler.authenticationError && (
        <AlertView
          severity={WARNING_LABEL}
          message={authResponseHandler.authenticationError.detail}
        />
      )}
      <EmailField register={register} errors={errors} />
      <PasswordField register={register} errors={errors} />
      <ConfirmPasswordField
        register={register}
        errors={errors}
        watch={watch}
        watchPasswordFieldId={PASSWORD_FIELD_ID}
      />
      <AuthFormFooterView
        buttonLabel={CREATE_ACCOUNT_BUTTON}
        title={ALREADY_HAVE_ACCOUNT_LABEL}
        details={SIGN_IN_LABEL}
        linkPath={ROUTE_PATH_LOGIN}
      />
    </Box>
  );
};
