import { PopoverOrigin } from '@mui/material/Popover';

export const popoverPositionStyles: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
};

export const popoverPointerStyles = {
  pointerEvents: 'none',
};

export const popoverPaperStyles = {
  p: 1.3,
};

export const popoverErrorStyles = {
  ...popoverPaperStyles,
  color: '#D32F2F',
};

export const popoverWarningStyles = {
  ...popoverPaperStyles,
  color: '#ED6C02',
};
