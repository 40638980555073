import {
  CLOSE_CONFIRMATION_DIALOG,
  CLOSE_FORM_DIALOG,
  CLOSE_INFO_DIALOG,
  CREATED_ACCOUNT_DATA,
  HIDE_LOADER,
  HIDE_TRANSACTION_STATUS,
  OPEN_CONFIRMATION_DIALOG,
  OPEN_FORM_DIALOG,
  OPEN_INFO_DIALOG,
  SET_ORIGINATOR_ID,
  SET_USER,
  SHOW_LOADER,
  SHOW_TRANSACTION_STATUS,
} from '../constants/actions';
import { Action } from './action';
import { State } from './types/State';

export const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case OPEN_FORM_DIALOG:
      return {
        ...state,
        formDialog: { ...action.payload },
      };
    case CLOSE_FORM_DIALOG:
      return {
        ...state,
        formDialog: undefined,
        controller: new AbortController(),
      };
    case OPEN_CONFIRMATION_DIALOG:
      return {
        ...state,
        confirmationDialog: { ...action.payload },
      };
    case CLOSE_CONFIRMATION_DIALOG:
      return {
        ...state,
        confirmationDialog: undefined,
      };
    case OPEN_INFO_DIALOG:
      return {
        ...state,
        infoDialog: { ...action.payload },
      };
    case CLOSE_INFO_DIALOG:
      return {
        ...state,
        infoDialog: undefined,
      };
    case SHOW_TRANSACTION_STATUS:
      return {
        ...state,
        transactionStatusText: action.payload,
      };
    case HIDE_TRANSACTION_STATUS:
      return {
        ...state,
        transactionStatusText: undefined,
      };
    case SHOW_LOADER:
      return {
        ...state,
        loader: { ...action.payload },
      };
    case HIDE_LOADER:
      return {
        ...state,
        loader: undefined,
      };
    case SET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case SET_ORIGINATOR_ID:
      return {
        ...state,
        originatorID: action.payload,
      };
    case CREATED_ACCOUNT_DATA:
      return {
        ...state,
        createdAccountData: action.payload,
      };
    default:
      return state;
  }
};
