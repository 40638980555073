import React, { useContext } from 'react';
import { FieldValues } from 'react-hook-form';

import { addBorrowerDataLoanField } from '../../../../api/controllers/BorrowerDataController';
import { getAllNomenclaturesByType } from '../../../../api/controllers/NomenclatureController';
import { NomenclatureType } from '../../../../api/types/nomenclatureTypes';
import { OPEN_FORM_DIALOG } from '../../../../utils/constants/actions';
import { ZERO_NUMBER_VALUE } from '../../../../utils/constants/common';
import { LOAN_BORROWER_DATA_FIELDS_PERMISSION } from '../../../../utils/constants/permissions';
import { EDIT_LOAN_TITLE } from '../../../../utils/constants/titles';
import { FormDialogSubmitType } from '../../../../utils/enums/FormDialogSubmitType';
import { usePermissions } from '../../../../utils/hooks/permissions/usePermissions';
import { AddBorrowerDataLoanFieldRequest } from '../../../../utils/interfaces/borrowerData/loans/AddBorrowerDataLoanFieldRequest';
import { Context } from '../../../../utils/store';
import { ContextType } from '../../../../utils/store/types/ContextType';
import { UpdateBorrowerDataLoanForm } from '../../common/borrowerData/forms/UpdateBorrowerDataLoanForm';
import { BorrowerDataLoanTypesTableView } from '../tables/BorrowerDataLoanTypesTableView';

export const BorrowerDataLoanTypesTab = () => {
  const { dispatch } = useContext<ContextType>(Context);

  const loanTypes = getAllNomenclaturesByType(NomenclatureType.LOAN_TYPES);

  const addLoanTypeFieldMutation = addBorrowerDataLoanField();

  const permissions = usePermissions();

  let loanId = ZERO_NUMBER_VALUE;

  const loanBorrowerDataFieldsPermissionType = LOAN_BORROWER_DATA_FIELDS_PERMISSION;

  const handleEditClick = (id: number): void => {
    loanId = id;

    dispatch({
      type: OPEN_FORM_DIALOG,
      payload: {
        title: EDIT_LOAN_TITLE,
        content: (
          <UpdateBorrowerDataLoanForm
            onSubmit={handleSubmit}
            loanTypeId={id}
            permissionType={loanBorrowerDataFieldsPermissionType}
          />
        ),
        submitType: FormDialogSubmitType.Edit,
      },
    });
  };

  const handleSubmit = (data: FieldValues): void => {
    const formData = {
      id: data.field,
      loanId: loanId,
    } as AddBorrowerDataLoanFieldRequest;

    addLoanTypeFieldMutation.mutateAsync(formData);
  };

  return (
    <BorrowerDataLoanTypesTableView
      handleEdit={handleEditClick}
      loanTypes={loanTypes}
      permissionType={loanBorrowerDataFieldsPermissionType}
      canRead={permissions.hasReadPermission(loanBorrowerDataFieldsPermissionType)}
    />
  );
};
