export const stepperStyles = { maxWidth: '90%', mx: 'auto', mb: 3 };

export const stepperButtonsWrapperStyles = {
  display: 'flex',
  justifyContent: 'flex-end',
  pt: 2,
};

export const stepperCancelButtonStyles = { mr: 2 };

export const stepperContinueToSpvButtonStyles = { ml: 2 };
