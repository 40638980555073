import React from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { formMainStyles } from '../../../styles/authFormStyles';
import { logIn } from '../../../utils/authentication/authentication';
import { SIGN_IN_BUTTON } from '../../../utils/constants/buttons';
import {
  CREATE_ACCOUNT_LABEL,
  DONT_HAVE_ACCOUNT_LABEL,
  ERROR_LABEL,
  FORGOT_YOUR_PASSWORD_LABEL,
  RESET_PASSWORD_LABEL,
  SIGN_IN_LABEL,
  WARNING_LABEL,
} from '../../../utils/constants/labels';
import {
  NO_CONNECTION_TO_THE_SERVER_MESSAGE,
  UNEXPECTED_ERROR_MESSAGE,
} from '../../../utils/constants/messages';
import {
  ROUTE_PATH_HOME,
  ROUTE_PATH_REGISTER,
  ROUTE_PATH_RESET_PASSWORD,
} from '../../../utils/constants/routes';
import { useAuthResponseHandler } from '../../../utils/hooks/useAuthResponseHandler';
import { AuthErrorType } from '../../../utils/interfaces/authentication/AuthErrorType';
import { AlertView } from '../../common/AlertView';
import { EmailField } from '../../common/form/EmailField';
import { PasswordField } from '../../common/form/PasswordField';
import { AuthFormFooterView } from './AuthFormFooterView';
import { AuthFormHeaderView } from './AuthFormHeaderView';

export const LoginForm = () => {
  const authResponseHandler = useAuthResponseHandler();
  const history = useNavigate();

  const onSuccessLogin = (): void => {
    history(ROUTE_PATH_HOME);
  };

  const onErrorLogin = (error: AuthErrorType): void => {
    authResponseHandler.handleError(error);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data: FieldValues): Promise<void> => {
    logIn(data.email, data.password, onSuccessLogin, onErrorLogin);
  };

  return (
    <form style={formMainStyles} onSubmit={handleSubmit(onSubmit)}>
      <AuthFormHeaderView
        title={SIGN_IN_LABEL}
        details={DONT_HAVE_ACCOUNT_LABEL}
        detailsLink={CREATE_ACCOUNT_LABEL}
        linkPath={ROUTE_PATH_REGISTER}
      />
      {authResponseHandler.authenticationError && (
        <AlertView
          severity={WARNING_LABEL}
          message={authResponseHandler.authenticationError.detail}
        />
      )}
      {authResponseHandler.isServerError && (
        <AlertView severity={ERROR_LABEL} message={NO_CONNECTION_TO_THE_SERVER_MESSAGE} />
      )}
      {authResponseHandler.isClientError && (
        <AlertView severity={ERROR_LABEL} message={UNEXPECTED_ERROR_MESSAGE} />
      )}
      <EmailField register={register} errors={errors} />
      <PasswordField register={register} errors={errors} />
      <AuthFormFooterView
        buttonLabel={SIGN_IN_BUTTON}
        title={FORGOT_YOUR_PASSWORD_LABEL}
        details={RESET_PASSWORD_LABEL}
        linkPath={ROUTE_PATH_RESET_PASSWORD}
      />
    </form>
  );
};
