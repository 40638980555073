import React, { useContext, useState } from 'react';

import {
  createScoreClass,
  deleteScoreClass,
  getAllScoreClasses,
  updateScoreClass,
} from '../../../api/controllers/ScoreClassController';
import { ScoreClassBase } from '../../../api/types/originatorTypes';
import {
  CLOSE_CONFIRMATION_DIALOG,
  OPEN_CONFIRMATION_DIALOG,
  OPEN_FORM_DIALOG,
} from '../../../utils/constants/actions';
import { ADD_SCORE_CLASS_BUTTON } from '../../../utils/constants/buttons';
import { DELETE_SCORE_CLASS_CONFIRMATION } from '../../../utils/constants/confirmations';
import {
  CREATE_SCORE_CLASS_FORM_ID,
  DELETE_SCORE_CLASS_DIALOG_ID,
  UPDATE_SCORE_CLASS_FORM_ID,
} from '../../../utils/constants/identifiers';
import { SCORE_CLASS_PERMISSION } from '../../../utils/constants/permissions';
import {
  ADD_SCORE_CLASS_TITLE,
  DELETE_SCORE_CLASS_TITLE,
  UPDATE_SCORE_CLASS_TITLE,
} from '../../../utils/constants/titles';
import { FormDialogSubmitType } from '../../../utils/enums/FormDialogSubmitType';
import { closeFormHandler } from '../../../utils/helpers/closeFormHandler';
import { Context } from '../../../utils/store';
import { ContextType } from '../../../utils/store/types/ContextType';
import { GridHeaderView } from '../../common/grid/GridHeaderView';
import { CreateScoreClassForm } from '../../views/scoreClasses/forms/CreateScoreClassForm';
import { UpdateScoreClassForm } from '../../views/scoreClasses/forms/UpdateScoreClassForm';
import { ScoreClassesDataGrid } from '../../views/scoreClasses/tables/ScoreClassesDataGrid';

export const ScoreClassesScreen = () => {
  const { state, dispatch } = useContext<ContextType>(Context);

  const [scoreClassId, setScoreClassId] = useState<number>();

  const scoreClasses = getAllScoreClasses();

  const createUpdateMutation =
    state.formDialog?.submitType === FormDialogSubmitType.Edit && scoreClassId
      ? updateScoreClass(scoreClassId)
      : createScoreClass();

  const deleteScoreClassMutation = deleteScoreClass();

  const scoreClassPermissionType = SCORE_CLASS_PERMISSION;

  const handleAddClick = (): void => {
    dispatch({
      type: OPEN_FORM_DIALOG,
      payload: {
        title: ADD_SCORE_CLASS_TITLE,
        content: <CreateScoreClassForm onSubmit={handleSubmit} />,
        formId: CREATE_SCORE_CLASS_FORM_ID,
        submitType: FormDialogSubmitType.Add,
      },
    });
  };

  const handleSubmit = (data: ScoreClassBase): void => {
    createUpdateMutation.mutateAsync(data).then((response) => {
      closeFormHandler(dispatch, response.status);
    });
  };

  const handleEditClick = (id: number): void => {
    const scoreClass = scoreClasses.find((x) => x.id === id);

    if (!scoreClass) {
      return;
    }

    setScoreClassId(scoreClass.id);

    dispatch({
      type: OPEN_FORM_DIALOG,
      payload: {
        title: UPDATE_SCORE_CLASS_TITLE,
        content: <UpdateScoreClassForm onSubmit={handleSubmit} scoreClass={scoreClass} />,
        formId: UPDATE_SCORE_CLASS_FORM_ID,
        submitType: FormDialogSubmitType.Edit,
      },
    });
  };

  const handleDeleteConfirm = (id: number): void => {
    deleteScoreClassMutation.mutateAsync(id);

    dispatch({ type: CLOSE_CONFIRMATION_DIALOG });
  };

  const handleDeleteClick = (id: number): void => {
    dispatch({
      type: OPEN_CONFIRMATION_DIALOG,
      payload: {
        dialogId: DELETE_SCORE_CLASS_DIALOG_ID,
        title: DELETE_SCORE_CLASS_TITLE,
        content: DELETE_SCORE_CLASS_CONFIRMATION,
        onAgree: () => handleDeleteConfirm(id),
      },
    });
  };

  return (
    <>
      <GridHeaderView
        buttonLabel={ADD_SCORE_CLASS_BUTTON}
        onButtonClick={handleAddClick}
        permissionType={scoreClassPermissionType}
      />
      <ScoreClassesDataGrid
        handleEdit={handleEditClick}
        handleDelete={handleDeleteClick}
        data={scoreClasses}
        permissionType={scoreClassPermissionType}
      />
    </>
  );
};
