export const ROLES_IUVO = ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_ADMIN_VIEWER'];
export const ROLES_ORIGINATORS = [
  'ROLE_ORIGINATOR_SUPER_ADMIN',
  'ROLE_REPORTS_VIEW',
  'ROLE_ORIGINATOR_ADMIN',
  'ROLE_REPORTS_FULL',
  'ROLE_IMPORT_VIEW',
  'ROLE_NOTIFICATIONS_VIEW',
  'ROLE_IMPORT_DRAFT',
  'ROLE_NOTIFICATIONS_FULL',
  'ROLE_IMPORT_FULL',
  'ROLE_ACCESS_MANAGEMENT_VIEW',
  'ROLE_CONTROL_PANEL_VIEW',
  'ROLE_ACCESS_MANAGEMENT_FULL',
  'ROLE_CONTROL_PANEL_FULL',
];
