import { GridColumns } from '@mui/x-data-grid-pro';

import {
  AMORTIZATION_METHOD_ID,
  AMOUNT_ID,
  BUSINESS_DAY_ID,
  COLLATERAL_AMOUNT_ID,
  COLLATERAL_TYPE_ID,
  COUNTRY,
  DEFAULTED_ON_DAY_ID,
  END_DATE_ID,
  GUARANT_ACTIVATION_DATE_ID,
  GUARANT_INTEREST_PERC_ID,
  GUARANT_PERIOD_DAYS_ID,
  GUARANT_PRINCIPAL_PERC_ID,
  INST_COUNT_ID,
  INST_PERIOD_ID,
  INST_PERIOD_NUM_ID,
  INVEST_PART_AMOUNT_ID,
  INVEST_PART_PERC_ID,
  ISSUE_DATE_ID,
  IS_LISTED_ID,
  KID_ID,
  LATE_FEES_PAYABLE_ID,
  LATE_FEE_TERMS_ID,
  LOANS_CURRENCY_ID,
  LOAN_ID,
  LOAN_IMPORT_STATUS_ID,
  LOAN_STATUS_ID,
  MARGIN_INTEREST_PERC_ID,
  MINIMUM_INVEST_AMOUNT_ID,
  ORIGINATOR_ID,
  ORIGINATOR_SCORE_CLASS_ID,
  ORIGINATOR_SCORE_CLASS_PUBLIC_NAME_ID,
  ORIG_CURRENCY_ID,
  ORIG_INTEREST_AMOUNT_ID,
  ORIG_INTEREST_PERC_ID,
  PAY_TERM_POLICY_ID,
  PD_PERC_ID,
  PRODUCT_ID,
  PRODUCT_NAME_ID,
  TRANS_ID,
  TYPE_ID,
  USE_OF_LOAN_ID,
  WARNING_ID,
  YEAR_DAYS_BASE_ID,
} from '../../utils/constants/identifiers';
import {
  AMORTIZATION_METHOD_LABEL,
  AMOUNT_LABEL,
  BUSINESS_DAY_LABEL,
  COLLATERAL_AMOUNT_LABEL,
  COLLATERAL_TYPE_ID_LABEL,
  COUNTRY_LABEL,
  CURRENCY_LABEL,
  DEFAULTED_ON_DAY_LABEL,
  END_DATE_LABEL,
  GUARANT_ACTIVATION_DATE_LABEL,
  GUARANT_INTEREST_PERC_LABEL,
  GUARANT_PERIOD_DAYS_LABEL,
  GUARANT_PRINCIPAL_PERC_LABEL,
  INST_COUNT_LABEL,
  INST_PERIOD_ID_LABEL,
  INST_PERIOD_NUM_LABEL,
  INVEST_PART_AMOUNT_LABEL,
  INVEST_PART_PERC_LABEL,
  ISSUE_DATE_LABEL,
  IS_LISTED_LABEL,
  KID_LABEL,
  LATE_FEES_PAYABLE_LABEL,
  LATE_FEE_TERMS_LABEL,
  LOAN_ID_LABEL,
  LOAN_IMPORT_STATUS_LABEL,
  LOAN_STATUS_LABEL,
  LOAN_TYPES_LABEL,
  MARGIN_INTEREST_PERC_LABEL,
  MINIMUM_INVEST_AMOUNT_LABEL,
  ORIGINATOR_LABEL,
  ORIGINATOR_SCORE_CLASS_ID_LABEL,
  ORIGINATOR_SCORE_CLASS_PUBLIC_NAME_LABEL,
  ORIG_CURRENCY_LABEL,
  ORIG_INTEREST_AMOUNT_LABEL,
  ORIG_INTEREST_PERC_LABEL,
  PAY_TERM_POLICY_LABEL,
  PD_PERC_LABEL,
  PRODUCT_ID_LABEL,
  PRODUCT_NAME_LABEL,
  TRANS_ID_LABEL,
  USE_OF_LOAN_LABEL,
  WARNING_LABEL,
  YEAR_DAYS_BASE_LABEL,
} from '../../utils/constants/labels';

export const loansLoanColumns: GridColumns = [
  {
    field: LOAN_ID,
    headerName: LOAN_ID_LABEL,
  },
  {
    field: KID_ID,
    headerName: KID_LABEL,
  },
  {
    field: COUNTRY,
    headerName: COUNTRY_LABEL,
  },
  {
    field: TYPE_ID,
    headerName: LOAN_TYPES_LABEL,
  },
  {
    field: USE_OF_LOAN_ID,
    headerName: USE_OF_LOAN_LABEL,
  },
  {
    field: LOAN_STATUS_ID,
    headerName: LOAN_STATUS_LABEL,
  },
  {
    field: COLLATERAL_TYPE_ID,
    headerName: COLLATERAL_TYPE_ID_LABEL,
  },
  {
    field: COLLATERAL_AMOUNT_ID,
    headerName: COLLATERAL_AMOUNT_LABEL,
  },
  {
    field: AMORTIZATION_METHOD_ID,
    headerName: AMORTIZATION_METHOD_LABEL,
  },
  {
    field: AMOUNT_ID,
    headerName: AMOUNT_LABEL,
  },
  {
    field: BUSINESS_DAY_ID,
    headerName: BUSINESS_DAY_LABEL,
  },
  {
    field: LOANS_CURRENCY_ID,
    headerName: CURRENCY_LABEL,
  },
  {
    field: DEFAULTED_ON_DAY_ID,
    headerName: DEFAULTED_ON_DAY_LABEL,
  },
  {
    field: END_DATE_ID,
    headerName: END_DATE_LABEL,
  },
  {
    field: GUARANT_INTEREST_PERC_ID,
    headerName: GUARANT_INTEREST_PERC_LABEL,
  },
  {
    field: GUARANT_PERIOD_DAYS_ID,
    headerName: GUARANT_PERIOD_DAYS_LABEL,
  },
  {
    field: GUARANT_PRINCIPAL_PERC_ID,
    headerName: GUARANT_PRINCIPAL_PERC_LABEL,
  },
  {
    field: INST_COUNT_ID,
    headerName: INST_COUNT_LABEL,
  },
  {
    field: INST_PERIOD_ID,
    headerName: INST_PERIOD_ID_LABEL,
  },
  {
    field: INST_PERIOD_NUM_ID,
    headerName: INST_PERIOD_NUM_LABEL,
  },
  {
    field: INVEST_PART_AMOUNT_ID,
    headerName: INVEST_PART_AMOUNT_LABEL,
  },
  {
    field: INVEST_PART_PERC_ID,
    headerName: INVEST_PART_PERC_LABEL,
  },
  {
    field: IS_LISTED_ID,
    headerName: IS_LISTED_LABEL,
  },
  {
    field: ISSUE_DATE_ID,
    headerName: ISSUE_DATE_LABEL,
  },
  {
    field: LATE_FEE_TERMS_ID,
    headerName: LATE_FEE_TERMS_LABEL,
  },
  {
    field: LATE_FEES_PAYABLE_ID,
    headerName: LATE_FEES_PAYABLE_LABEL,
  },
  {
    field: LOAN_IMPORT_STATUS_ID,
    headerName: LOAN_IMPORT_STATUS_LABEL,
  },
  {
    field: MARGIN_INTEREST_PERC_ID,
    headerName: MARGIN_INTEREST_PERC_LABEL,
  },
  {
    field: MINIMUM_INVEST_AMOUNT_ID,
    headerName: MINIMUM_INVEST_AMOUNT_LABEL,
  },
  {
    field: ORIG_CURRENCY_ID,
    headerName: ORIG_CURRENCY_LABEL,
  },
  {
    field: ORIG_INTEREST_AMOUNT_ID,
    headerName: ORIG_INTEREST_AMOUNT_LABEL,
  },
  {
    field: ORIG_INTEREST_PERC_ID,
    headerName: ORIG_INTEREST_PERC_LABEL,
  },
  {
    field: ORIGINATOR_ID,
    headerName: ORIGINATOR_LABEL,
  },
  {
    field: ORIGINATOR_SCORE_CLASS_ID,
    headerName: ORIGINATOR_SCORE_CLASS_ID_LABEL,
  },
  {
    field: ORIGINATOR_SCORE_CLASS_PUBLIC_NAME_ID,
    headerName: ORIGINATOR_SCORE_CLASS_PUBLIC_NAME_LABEL,
  },
  {
    field: PAY_TERM_POLICY_ID,
    headerName: PAY_TERM_POLICY_LABEL,
  },
  {
    field: PD_PERC_ID,
    headerName: PD_PERC_LABEL,
  },
  {
    field: TRANS_ID,
    headerName: TRANS_ID_LABEL,
  },
  {
    field: WARNING_ID,
    headerName: WARNING_LABEL,
  },
  {
    field: YEAR_DAYS_BASE_ID,
    headerName: YEAR_DAYS_BASE_LABEL,
  },
  {
    field: PRODUCT_ID,
    headerName: PRODUCT_ID_LABEL,
  },
  {
    field: PRODUCT_NAME_ID,
    headerName: PRODUCT_NAME_LABEL,
  },
  {
    field: GUARANT_ACTIVATION_DATE_ID,
    headerName: GUARANT_ACTIVATION_DATE_LABEL,
  },
];
