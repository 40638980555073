import React, { useState } from 'react';

import Grid from '@mui/material/Grid';

import { originatorDetailsTabs } from '../../../../common/originators/originatorDetailsTabs';
import { GridOptions } from '../../../../utils/enums/GridOptions';
import { OriginatorTabs } from '../../../../utils/enums/OriginatorTabs';
import { TabsVariants } from '../../../../utils/enums/TabsVariants';
import { TabsView } from '../../../common/tabs/TabsView';
import { OriginatorTabsContentView } from './OriginatorTabsContentView';

export const OriginatorTabsView = (props: { originatorId: number }) => {
  const { originatorId } = props;

  const [selectedTab, setSelectedTab] = useState(OriginatorTabs.Products);

  return (
    <Grid container>
      <Grid item xs={GridOptions.ExtraLarge} lg={GridOptions.Large}>
        <TabsView
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          tabs={originatorDetailsTabs}
          tabsVariant={TabsVariants.Scrollable}
        />
      </Grid>
      <Grid item xs={GridOptions.ExtraLarge}>
        <OriginatorTabsContentView selectedTab={selectedTab} originatorId={originatorId} />
      </Grid>
    </Grid>
  );
};
