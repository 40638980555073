import React, { useContext } from 'react';
import { matchPath, useNavigate } from 'react-router';

import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import { DIV_TAG } from '../../../../utils/constants/components';
import {
  DASHBOARD_LABEL,
  ORIGINATORS_SPVS_LABEL,
  ORIGINATOR_DETAILS_LABEL,
} from '../../../../utils/constants/labels';
import { ORIGINATORS_PERMISSION } from '../../../../utils/constants/permissions';
import {
  ROUTE_PATH_DASHBOARD,
  ROUTE_PATH_ORIGINATORS,
  ROUTE_PATH_ORIGINATORS_SPVS,
} from '../../../../utils/constants/routes';
import { usePermissions } from '../../../../utils/hooks/permissions/usePermissions';
import { Context } from '../../../../utils/store';
import { AdminTabs } from './AdminTabs';
import { DataExchangeTabs } from './DataExchangeTabs';

export const SidebarTabs = () => {
  const history = useNavigate();

  const { state } = useContext(Context);
  const { user } = state;
  const isSystemUser = user?.user?.isSystemUser;
  const permissions = usePermissions();

  const isDashboardSelected = !!matchPath(location.pathname, ROUTE_PATH_DASHBOARD);
  const isOriginatorsSpvsSelected = !!matchPath(location.pathname, ROUTE_PATH_ORIGINATORS_SPVS);

  return (
    <List>
      <List component={DIV_TAG} disablePadding>
        <ListItemButton selected={isDashboardSelected}>
          <ListItemText primary={DASHBOARD_LABEL} />
        </ListItemButton>
        <Divider />
        <DataExchangeTabs />
        {isSystemUser ? (
          <>
            <ListItemButton
              selected={isOriginatorsSpvsSelected}
              onClick={() => history(ROUTE_PATH_ORIGINATORS_SPVS)}
            >
              <ListItemText primary={ORIGINATORS_SPVS_LABEL} />
            </ListItemButton>
            <Divider />
            <AdminTabs />
          </>
        ) : (
          user?.originators?.length !== 0 &&
          permissions.hasReadPermission(ORIGINATORS_PERMISSION) && (
            <ListItemButton
              onClick={() => history(`${ROUTE_PATH_ORIGINATORS}/${state.originatorID}`)}
            >
              <ListItemText primary={ORIGINATOR_DETAILS_LABEL} />
            </ListItemButton>
          )
        )}
      </List>
    </List>
  );
};
