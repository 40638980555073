import { GridSortDirection, GridSortModel } from '@mui/x-data-grid-pro';

export const sortModelChange = (
  newData: GridSortModel,
  setNewSort: (newSort: [] | [string, GridSortDirection][]) => void,
) => {
  const [sortData] = newData;
  if (setNewSort && (sortData?.field !== undefined || sortData?.sort !== undefined)) {
    setNewSort([[sortData?.field, sortData?.sort]]);
  } else if (setNewSort && !(sortData?.field || sortData?.sort)) {
    setNewSort([]);
  } else {
    return;
  }
};
