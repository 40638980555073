export const headerAuthLogoStyles = {
  width: '119px',
  height: '40px',
};

export const headerLogoTypographyStyles = {
  flexGrow: 1,
};

export const headerAuthToolbarStyles = {
  backgroundColor: '#FFFFFF',
};

export const headerMainLogoStyles = {
  paddingTop: '12px',
  width: '119px',
  height: '50px',
};

export const headerMenuButtonStyles = {
  mr: 2,
};

export const headerLogoutButtonStyles = {
  ml: 2,
};
