import React from 'react';

import { Paper } from '@mui/material';

import { tabsPaperStyles } from '../../../../styles/tabsStyles';
import { LoansTabs } from '../../../../utils/enums/LoansTabs';
import { LoansTabsProps } from '../../../../utils/interfaces/loans/LoansTabsProps';
import { TabsContentView } from '../../../common/tabs/TabsContentView';
import { LoansFeesTab } from './fees/LoansFeesTab';
import { LoansLoanTab } from './loans/LoansLoanTab';
import { LoansPaymentSchedulesTab } from './paymentSchedules/LoansPaymentSchedulesTab';
import { LoansPaymentsTab } from './payments/LoansPaymentsTab';

export const LoansTabContentView = (props: LoansTabsProps) => {
  const { selectedTab, permissionType, filters, sort } = props;

  return (
    <Paper square sx={tabsPaperStyles}>
      <TabsContentView
        selectedTab={selectedTab}
        tabIndex={LoansTabs.Loans}
        permissionType={permissionType}
      >
        <LoansLoanTab filters={filters} sort={sort} />
      </TabsContentView>

      <TabsContentView
        selectedTab={selectedTab}
        tabIndex={LoansTabs.PaymentSchedules}
        permissionType={permissionType}
      >
        <LoansPaymentSchedulesTab filters={filters} sort={sort} />
      </TabsContentView>

      <TabsContentView
        selectedTab={selectedTab}
        tabIndex={LoansTabs.Payments}
        permissionType={permissionType}
      >
        <LoansPaymentsTab filters={filters} sort={sort} />
      </TabsContentView>

      <TabsContentView
        selectedTab={selectedTab}
        tabIndex={LoansTabs.Fees}
        permissionType={permissionType}
      >
        <LoansFeesTab filters={filters} sort={sort} />
      </TabsContentView>
    </Paper>
  );
};
