import React, { Fragment, useContext } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router';

import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import { sidebarCollapseTabStyles } from '../../../../styles/sidebarStyles';
import { SET_ORIGINATOR_ID } from '../../../../utils/constants/actions';
import { DIV_TAG } from '../../../../utils/constants/components';
import { TRANSACTIONS_KEY } from '../../../../utils/constants/keys';
import {
  TRANSACTIONS_ALL_PERMISSION,
  TRANSACTIONS_PERMISSION,
} from '../../../../utils/constants/permissions';
import { ROUTE_PATH_DASHBOARD } from '../../../../utils/constants/routes';
import { usePermissions } from '../../../../utils/hooks/permissions/usePermissions';
import { SidebarTabsProps } from '../../../../utils/interfaces/layout/SidebarTabsProps';
import { TabCollapseProps } from '../../../../utils/interfaces/layout/TabCollapseProps';
import { Context } from '../../../../utils/store';

export const TabCollapse = (props: TabCollapseProps) => {
  const { isOpen, tabs, handleToggle, setOriginatorID } = props;
  const location = useLocation();

  const { dispatch } = useContext(Context);
  const history = useNavigate();

  const permissions = usePermissions();

  const handleItemClick = (tab: SidebarTabsProps) => {
    if (handleToggle) {
      handleToggle();
    }
    if (setOriginatorID) {
      dispatch({
        type: SET_ORIGINATOR_ID,
        payload: parseInt(tab.key),
      });

      // After choose originator, go to dashboard so we can have fresh data on requests.
      history(ROUTE_PATH_DASHBOARD);
    } else {
      history(tab.path);
    }
  };

  return (
    <Collapse in={isOpen} timeout="auto" unmountOnExit>
      <Divider />
      <List component={DIV_TAG} disablePadding>
        {tabs.map((tab) => {
          if (tab.key === TRANSACTIONS_KEY && permissions) {
            if (
              !permissions.hasReadPermission(TRANSACTIONS_ALL_PERMISSION) &&
              !permissions.hasReadPermission(TRANSACTIONS_PERMISSION)
            ) {
              return;
            }
          }
          const selected = !!matchPath(location.pathname, tab.path);

          return (
            <Fragment key={tab.key}>
              <ListItemButton
                sx={sidebarCollapseTabStyles}
                onClick={() => handleItemClick(tab)}
                selected={selected}
              >
                <ListItemText primary={tab.title} />
              </ListItemButton>
              <Divider />
            </Fragment>
          );
        })}
      </List>
    </Collapse>
  );
};
