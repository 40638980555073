import { viravaService } from '../api/apiClient';
import { customHistory } from '../components/App';
import { isAuthenticated } from '../utils/authentication/authentication';
import { ACCESS_TOKEN, BEARER_TOKEN } from '../utils/constants/common';
import { ROUTE_PATH_LOGIN } from '../utils/constants/routes';

export const apiConfigBase = {
  securityWorker: async () => {
    if (isAuthenticated() && viravaService.isTokenExpired()) {
      await viravaService.refreshToken();
    }

    const token = localStorage.getItem(ACCESS_TOKEN);

    if (!token) {
      customHistory.push(ROUTE_PATH_LOGIN);
      return {};
    }

    return {
      headers: {
        Authorization: `${BEARER_TOKEN} ${token}`,
      },
    };
  },
};
