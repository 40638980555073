import React from 'react';
import { useForm } from 'react-hook-form';

import { Box, Button } from '@mui/material';

import {
  stepperButtonsWrapperStyles,
  stepperCancelButtonStyles,
} from '../../../../styles/stepperStyles';
import {
  BUTTON_SUBMIT_TYPE,
  CANCEL_BUTTON,
  CONTINUE_BUTTON,
} from '../../../../utils/constants/buttons';
import { CREATE_ACCOUNT_FORM_ID } from '../../../../utils/constants/identifiers';
import { ButtonVariants } from '../../../../utils/enums/ButtonVariants';
import { CreateAccountFormsProps } from '../../../../utils/interfaces/originator/details/CreateAccountFormsProps';
import { EmailField } from '../../../common/form/EmailField';

export const CreateAccountForm = (props: CreateAccountFormsProps) => {
  const { onSubmit, handleClose } = props;

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  return (
    <form id={CREATE_ACCOUNT_FORM_ID} onSubmit={handleSubmit(onSubmit)}>
      <EmailField register={register} errors={errors} />
      <Box sx={stepperButtonsWrapperStyles}>
        <Button
          variant={ButtonVariants.Outlined}
          sx={stepperCancelButtonStyles}
          onClick={handleClose}
        >
          {CANCEL_BUTTON}
        </Button>
        <Button
          type={BUTTON_SUBMIT_TYPE}
          id={CREATE_ACCOUNT_FORM_ID}
          variant={ButtonVariants.Contained}
        >
          {CONTINUE_BUTTON}
        </Button>
      </Box>
    </form>
  );
};
