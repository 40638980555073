import moment from 'moment';

import {
  DATE_FORMAT,
  EMPTY_GRID_CELL_NUMBER_VALUE,
  EMPTY_GRID_CELL_STRING_VALUE,
} from '../constants/options';
import { GridColType } from '../enums/GridColType';

export const gridCellValueFormatter = (type?: string, value?: string): string | number => {
  if (!type || type === GridColType.String) {
    return value || EMPTY_GRID_CELL_STRING_VALUE;
  }

  switch (type) {
    case GridColType.Number:
      return value || EMPTY_GRID_CELL_NUMBER_VALUE;
    case GridColType.Date:
      return value ? moment(value).format(DATE_FORMAT) : EMPTY_GRID_CELL_STRING_VALUE;
    case GridColType.String:
      return value || EMPTY_GRID_CELL_STRING_VALUE;
    case GridColType.Boolean:
      return value || EMPTY_GRID_CELL_STRING_VALUE;
    default:
      return value || EMPTY_GRID_CELL_STRING_VALUE;
  }
};
