import React from 'react';

import { getOriginatorSettings } from '../../../../../../api/controllers/OriginatorController';
import { OriginatorSettingsTabProps } from '../../../../../../utils/interfaces/originator/details/OriginatorSettingsTabProps';
import { OriginatorSettingsView } from './OriginatorSettingsView';

export const OriginatorSettingsTab = (props: OriginatorSettingsTabProps) => {
  const { originatorId, permissionType } = props;

  const originatorSettings = getOriginatorSettings(originatorId);

  return (
    <OriginatorSettingsView
      originatorSettings={originatorSettings}
      permissionType={permissionType}
      originatorId={originatorId}
    />
  );
};
