import React, { useContext } from 'react';
import { FieldValues, useForm } from 'react-hook-form';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { updateOriginatorScoreClass } from '../../../../../../../api/controllers/ScoreClassController';
import { OriginatorScoreClassMargin } from '../../../../../../../api/types/originatorTypes';
import { formGridSpacing } from '../../../../../../../styles/formStyles';
import { FORM_TAG } from '../../../../../../../utils/constants/components';
import {
  INTEREST_MARGIN_ID,
  SCORE_CLASS_ID,
  SCORE_TO_ID,
  UPDATE_ORIGINATOR_SCORE_CLASS_FORM_ID,
} from '../../../../../../../utils/constants/identifiers';
import {
  INTEREST_MARGIN_LABEL,
  SCORE_CLASS_LABEL,
  SCORE_TO_LABEL,
} from '../../../../../../../utils/constants/labels';
import { ORIGINATOR_SCORE_NUMBER_ACCURACY } from '../../../../../../../utils/constants/options';
import { InputFieldType } from '../../../../../../../utils/enums/InputFieldType';
import { closeFormHandler } from '../../../../../../../utils/helpers/closeFormHandler';
import { SelectorChoicesType } from '../../../../../../../utils/interfaces/form/SelectorChoicesType';
import { OriginatorScoreClassRequest } from '../../../../../../../utils/interfaces/originator/details/OriginatorScoreClassRequest';
import { UpdateOriginatorScoreClassProps } from '../../../../../../../utils/interfaces/originator/details/UpdateOriginatorScoreClassProps';
import { Context } from '../../../../../../../utils/store';
import { ContextType } from '../../../../../../../utils/store/types/ContextType';
import { NumberInputField } from '../../../../../../common/form/NumberInputField';
import { SelectField } from '../../../../../../common/form/SelectField';
import { LargeGridField } from '../../../../../../common/form/grid/LargeGridField';

export const UpdateOriginatorScoreClassForm = (props: UpdateOriginatorScoreClassProps) => {
  const { defaultScoreClasses, scoreClasses, scoreClass, originatorId, originatorScoreClassId } =
    props;

  const { dispatch } = useContext<ContextType>(Context);

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
  } = useForm();

  const updateOriginatorScoreClassMutation = updateOriginatorScoreClass();

  const handleEditSubmit = (data: FieldValues): void => {
    const scoreClassId = scoreClasses.find(
      (x) => x.scoreClassId === data.scoreClassId,
    )?.scoreClassId;

    const request = {
      originatorData: {
        originatorId: originatorId,
        originatorScoreClassId: originatorScoreClassId,
      } as OriginatorScoreClassRequest,
      formData: {
        ...data,
        scoreClassId: scoreClassId,
        id: originatorScoreClassId,
        originatorId: originatorId,
      } as OriginatorScoreClassMargin,
    };

    updateOriginatorScoreClassMutation.mutateAsync(request).then((response) => {
      closeFormHandler(dispatch, response.status);
    });
  };

  return (
    <Box
      component={FORM_TAG}
      id={UPDATE_ORIGINATOR_SCORE_CLASS_FORM_ID}
      onSubmit={handleSubmit(handleEditSubmit)}
    >
      <Grid container rowSpacing={1} columnSpacing={formGridSpacing}>
        <LargeGridField>
          <SelectField
            id={SCORE_CLASS_ID}
            label={SCORE_CLASS_LABEL}
            control={control}
            choices={defaultScoreClasses as SelectorChoicesType[]}
            defaultValue={scoreClass.scoreClassId}
            required
          />
        </LargeGridField>
        <LargeGridField>
          <NumberInputField
            id={SCORE_TO_ID}
            label={SCORE_TO_LABEL}
            inputType={InputFieldType.Decimal}
            register={register}
            errors={errors}
            defaultValue={scoreClass.scoreTo}
          />
        </LargeGridField>
        <LargeGridField>
          <NumberInputField
            id={INTEREST_MARGIN_ID}
            label={INTEREST_MARGIN_LABEL}
            inputType={InputFieldType.Decimal}
            register={register}
            errors={errors}
            decimalAccuracy={ORIGINATOR_SCORE_NUMBER_ACCURACY}
            defaultValue={scoreClass.interestMargin}
            required
          />
        </LargeGridField>
      </Grid>
    </Box>
  );
};
