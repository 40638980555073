import { FilterTypes } from '../enums/FilterTypes';
import { FiltersInitialState } from '../interfaces/filter/FiltersInitialState';

export const FILTERS_INITIAL_STATE: FiltersInitialState[] = [
  {
    name: 'insertedAt',
    label: 'inserted at',
    value: [null, null],
    type: FilterTypes.DateRange,
  },
  {
    name: 'completedDate',
    label: 'completed date',
    value: [null, null],
    type: FilterTypes.DateRange,
  },
  {
    name: 'loanId',
    label: 'Loan id',
    value: null,
    type: FilterTypes.Text,
  },
  {
    name: 'kid',
    label: 'Kid',
    value: null,
    type: FilterTypes.Text,
  },
  {
    name: 'transId',
    label: 'Transaction id',
    value: null,
    type: FilterTypes.Text,
  },
  {
    name: 'payScheduleId',
    label: 'Payment schedule id',
    value: null,
    type: FilterTypes.Text,
  },
  {
    name: 'payId',
    label: 'Payment id',
    value: null,
    type: FilterTypes.Text,
  },
  {
    name: 'loanStatusId',
    label: 'Loan status id',
    value: [],
    type: FilterTypes.MultipleSelect,
    data: [],
  },
  {
    name: 'transactionStatus',
    label: 'Transaction status',
    value: [],
    type: FilterTypes.MultipleSelect,
    data: [],
  },
  {
    name: 'pageNumber',
    value: 0,
    type: FilterTypes.Custom,
  },
  {
    name: 'pageSize',
    value: 10,
    type: FilterTypes.Custom,
  },
];

export const FILTERS_ADMIN_INITIAL_STATE: FiltersInitialState[] = [
  {
    name: 'insertedAt',
    label: 'inserted at',
    value: [null, null],
    type: FilterTypes.DateRange,
  },
  {
    name: 'completedDate',
    label: 'completed date',
    value: [null, null],
    type: FilterTypes.DateRange,
  },
  {
    name: 'originatorId',
    label: 'Originator id',
    value: [],
    type: FilterTypes.MultipleSelect,
    data: [],
  },
  {
    name: 'loanId',
    label: 'Loan id',
    value: null,
    type: FilterTypes.Text,
  },
  {
    name: 'kid',
    label: 'Kid',
    value: null,
    type: FilterTypes.Text,
  },
  {
    name: 'transId',
    label: 'Transaction id',
    value: null,
    type: FilterTypes.Text,
  },
  {
    name: 'payScheduleId',
    label: 'Payment schedule id',
    value: null,
    type: FilterTypes.Text,
  },
  {
    name: 'payId',
    label: 'Payment id',
    value: null,
    type: FilterTypes.Text,
  },
  {
    name: 'loanStatusId',
    label: 'Loan status id',
    value: [],
    type: FilterTypes.MultipleSelect,
    data: [],
  },
  {
    name: 'transactionStatus',
    label: 'Transaction status',
    value: [],
    type: FilterTypes.MultipleSelect,
    data: [],
  },
  {
    name: 'pageNumber',
    value: 0,
    type: FilterTypes.Custom,
  },
  {
    name: 'pageSize',
    value: 10,
    type: FilterTypes.Custom,
  },
];
