import React from 'react';

import Box from '@mui/material/Box';
import { GridColumns } from '@mui/x-data-grid-pro';

import { formViewGridStyles } from '../../../../styles/dataGridStyles';
import { getGridColumns } from '../../../../utils/helpers/getGridColumns';
import { StyledFormGrid } from './StyledFormGrid';

interface FormGridViewProps<T> {
  columns: GridColumns;
  data: Array<T>;
}

export const FormGridView = <T extends object>(props: FormGridViewProps<T>) => {
  const { columns, data } = props;

  return (
    <Box sx={formViewGridStyles}>
      <StyledFormGrid columns={getGridColumns(columns)} hideFooter rows={data} />
    </Box>
  );
};
