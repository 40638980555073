import React from 'react';
import { useForm } from 'react-hook-form';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { formGridSpacing } from '../../../../../../../styles/formStyles';
import { ApplicationModeMockData } from '../../../../../../../test/mocks/ApplicationModeMockData';
import { FORM_TAG } from '../../../../../../../utils/constants/components';
import {
  AMORTIZATION_METHOD_ID,
  APPLICATION_MODE_ID,
  COUNTRY_ID,
  INSTALLMENT_TYPE_ID,
  INSTALLMENT_TYPE_NUMBER_ID,
  INTEREST_RATE_MAX_ID,
  INTEREST_RATE_MIN_ID,
  INVEST_PART_PERCENT_ID,
  LOAN_CURRENCY_ID,
  LOAN_GUARANT_INTEREST_PERC_ID,
  LOAN_GUARANT_PERIOD_DAYS_ID,
  LOAN_GUARANT_PRINCIPAL_PERC_ID,
  LOAN_TYPE_ID,
  NAME_ID,
  NUMBER_OF_INSTALMENTS_MAX_ID,
  NUMBER_OF_INSTALMENTS_MIN_ID,
  UPDATE_PRODUCT_FORM_ID,
  YEAR_DAYS_BASE_ID,
} from '../../../../../../../utils/constants/identifiers';
import {
  AMORTIZATION_METHOD_LABEL,
  APPLICATION_MODE_LABEL,
  COUNTRY_OF_REGISTRATION_LABEL,
  INSTALLMENT_TYPE_LABEL,
  INSTALLMENT_TYPE_NUMBER_LABEL,
  INTEREST_RATE_MAX_LABEL,
  INTEREST_RATE_MIN_LABEL,
  INVEST_PART_PERCENT_LABEL,
  LOAN_CURRENCY_LABEL,
  LOAN_GUARANT_INTEREST_PERC_LABEL,
  LOAN_GUARANT_PERIOD_DAYS_LABEL,
  LOAN_GUARANT_PRINCIPAL_PERC_LABEL,
  LOAN_TYPE_LABEL,
  NAME_LABEL,
  NUMBER_OF_INSTALMENTS_MAX_LABEL,
  NUMBER_OF_INSTALMENTS_MIN_LABEL,
  YEAR_DAYS_BASE_LABEL,
} from '../../../../../../../utils/constants/labels';
import {
  INSTALLMENT_TYPE_NUMBER_MAX_VALUE,
  INSTALLMENT_TYPE_NUMBER_MIN_VALUE,
  LOAN_GUARANT_PERIOD_DAYS_MIN_NUMBER,
  MAX_NUMBER_OF_INSTALMENT_MIN_NUMBER,
  MIN_NUMBER_OF_INSTALMENT_MIN_NUMBER,
  SHARED_PART_LOAN_MAX_NUMBER,
  YEAR_DAYS_BASE_MIN_NUMBER,
} from '../../../../../../../utils/constants/options';
import { ALLOWED_PRODUCT_YEAR_DAYS_BASE } from '../../../../../../../utils/constants/validations';
import { InputFieldType } from '../../../../../../../utils/enums/InputFieldType';
import { SelectorChoicesType } from '../../../../../../../utils/interfaces/form/SelectorChoicesType';
import { UpdateProductProps } from '../../../../../../../utils/interfaces/originator/details/UpdateProductProps';
import { InputField } from '../../../../../../common/form/InputField';
import { NumberInputField } from '../../../../../../common/form/NumberInputField';
import { SelectField } from '../../../../../../common/form/SelectField';
import { LargeGridField } from '../../../../../../common/form/grid/LargeGridField';
import { MediumGridField } from '../../../../../../common/form/grid/MediumGridField';
import { SmallGridField } from '../../../../../../common/form/grid/SmallGridField';

export const UpdateProductForm = (props: UpdateProductProps) => {
  const {
    onSubmit,
    product,
    countries,
    loanCurrencies,
    loanTypes,
    amortizationMethods,
    installmentTypes,
  } = props;

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    watch,
  } = useForm();

  return (
    <Box component={FORM_TAG} id={UPDATE_PRODUCT_FORM_ID} onSubmit={handleSubmit(onSubmit)}>
      <Grid container rowSpacing={1} columnSpacing={formGridSpacing}>
        <MediumGridField>
          <InputField
            id={NAME_ID}
            label={NAME_LABEL}
            register={register}
            errors={errors}
            defaultValue={product.name}
            required
          />
        </MediumGridField>
        <MediumGridField>
          <SelectField
            id={COUNTRY_ID}
            label={COUNTRY_OF_REGISTRATION_LABEL}
            control={control}
            choices={countries as SelectorChoicesType[]}
            defaultValue={product.countryId}
            required
          />
        </MediumGridField>
        <MediumGridField>
          <SelectField
            id={LOAN_TYPE_ID}
            label={LOAN_TYPE_LABEL}
            control={control}
            choices={loanTypes as SelectorChoicesType[]}
            defaultValue={product.loanTypeId}
            required
          />
        </MediumGridField>
        <SmallGridField>
          <NumberInputField
            id={INTEREST_RATE_MIN_ID}
            label={INTEREST_RATE_MIN_LABEL}
            register={register}
            errors={errors}
            inputType={InputFieldType.Decimal}
            defaultValue={product.interestRateMin}
            biggerValue={watch(INTEREST_RATE_MAX_ID)}
            required
          />
        </SmallGridField>
        <SmallGridField>
          <NumberInputField
            id={INTEREST_RATE_MAX_ID}
            label={INTEREST_RATE_MAX_LABEL}
            register={register}
            errors={errors}
            inputType={InputFieldType.Decimal}
            defaultValue={product.interestRateMax}
            lowerValue={watch(INTEREST_RATE_MIN_ID)}
            required
          />
        </SmallGridField>
        <MediumGridField>
          <NumberInputField
            id={INVEST_PART_PERCENT_ID}
            label={INVEST_PART_PERCENT_LABEL}
            register={register}
            errors={errors}
            inputType={InputFieldType.Number}
            maxNumber={SHARED_PART_LOAN_MAX_NUMBER}
            defaultValue={product.investPartPercent}
            required
          />
        </MediumGridField>
        <MediumGridField>
          <SelectField
            id={LOAN_CURRENCY_ID}
            label={LOAN_CURRENCY_LABEL}
            control={control}
            choices={loanCurrencies as SelectorChoicesType[]}
            defaultValue={product.loanCurrencyId}
            required
          />
        </MediumGridField>
        <MediumGridField>
          <SelectField
            id={AMORTIZATION_METHOD_ID}
            label={AMORTIZATION_METHOD_LABEL}
            control={control}
            choices={amortizationMethods as SelectorChoicesType[]}
            defaultValue={product.amortizationMethodId}
            required
          />
        </MediumGridField>
        <MediumGridField>{<></>}</MediumGridField>

        <MediumGridField>
          <SelectField
            id={INSTALLMENT_TYPE_ID}
            label={INSTALLMENT_TYPE_LABEL}
            control={control}
            choices={installmentTypes as SelectorChoicesType[]}
            defaultValue={product.installmentTypeId}
            required
          />
        </MediumGridField>
        <MediumGridField>
          <NumberInputField
            id={INSTALLMENT_TYPE_NUMBER_ID}
            label={INSTALLMENT_TYPE_NUMBER_LABEL}
            register={register}
            errors={errors}
            inputType={InputFieldType.Number}
            minNumber={INSTALLMENT_TYPE_NUMBER_MIN_VALUE}
            maxNumber={INSTALLMENT_TYPE_NUMBER_MAX_VALUE}
            defaultValue={product.installmentTypeNumber}
            required
          />
        </MediumGridField>
        <MediumGridField>
          <NumberInputField
            id={NUMBER_OF_INSTALMENTS_MIN_ID}
            label={NUMBER_OF_INSTALMENTS_MIN_LABEL}
            register={register}
            errors={errors}
            minNumber={MIN_NUMBER_OF_INSTALMENT_MIN_NUMBER}
            inputType={InputFieldType.Number}
            defaultValue={product.numberOfInstalmentsMin}
            required
          />
        </MediumGridField>
        <MediumGridField>
          <NumberInputField
            id={NUMBER_OF_INSTALMENTS_MAX_ID}
            label={NUMBER_OF_INSTALMENTS_MAX_LABEL}
            register={register}
            errors={errors}
            minNumber={MAX_NUMBER_OF_INSTALMENT_MIN_NUMBER}
            inputType={InputFieldType.Number}
            defaultValue={product.numberOfInstalmentsMax}
            required
          />
        </MediumGridField>
        <MediumGridField>
          <NumberInputField
            id={LOAN_GUARANT_PRINCIPAL_PERC_ID}
            label={LOAN_GUARANT_PRINCIPAL_PERC_LABEL}
            register={register}
            errors={errors}
            inputType={InputFieldType.Decimal}
            defaultValue={product.loanGuarantPrincipalPerc}
            required
          />
        </MediumGridField>
        <MediumGridField>
          <NumberInputField
            id={LOAN_GUARANT_INTEREST_PERC_ID}
            label={LOAN_GUARANT_INTEREST_PERC_LABEL}
            register={register}
            errors={errors}
            inputType={InputFieldType.Decimal}
            defaultValue={product.loanGuarantInterestPerc}
            required
          />
        </MediumGridField>
        <MediumGridField>
          <NumberInputField
            id={LOAN_GUARANT_PERIOD_DAYS_ID}
            label={LOAN_GUARANT_PERIOD_DAYS_LABEL}
            register={register}
            errors={errors}
            minNumber={LOAN_GUARANT_PERIOD_DAYS_MIN_NUMBER}
            inputType={InputFieldType.Number}
            defaultValue={product.loanGuarantPeriodDays}
            required
          />
        </MediumGridField>
        <MediumGridField>
          <NumberInputField
            id={YEAR_DAYS_BASE_ID}
            label={YEAR_DAYS_BASE_LABEL}
            register={register}
            errors={errors}
            minNumber={YEAR_DAYS_BASE_MIN_NUMBER}
            inputType={InputFieldType.Number}
            defaultValue={product.yearDaysBase}
            allowedValues={ALLOWED_PRODUCT_YEAR_DAYS_BASE}
            required
          />
        </MediumGridField>
        <LargeGridField>
          <SelectField
            id={APPLICATION_MODE_ID}
            label={APPLICATION_MODE_LABEL}
            control={control}
            choices={ApplicationModeMockData as SelectorChoicesType[]}
            defaultValue={product.applicationMode}
            required
          />
        </LargeGridField>
      </Grid>
    </Box>
  );
};
