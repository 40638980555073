import React from 'react';
import { Outlet } from 'react-router-dom';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import { authLayoutContainerStyles, authLayoutGridStyles } from '../../styles/layoutStyles';
import { GridOptions } from '../../utils/enums/GridOptions';

export const AuthLayout = () => {
  return (
    <Grid container sx={authLayoutGridStyles} className="auth-layout-background">
      <Container sx={authLayoutContainerStyles}>
        <Grid
          item
          xs={GridOptions.ExtraLarge}
          sm={GridOptions.ExtraLarge}
          md={GridOptions.Medium}
          xl={GridOptions.Medium}
        >
          <Outlet />
        </Grid>
      </Container>
    </Grid>
  );
};
