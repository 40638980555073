import React from 'react';

import { GridColumns } from '@mui/x-data-grid-pro';

import { borrowerDataLoanTypesColumns } from '../../../../common/tableColumns/borrowerData/borrowerDataLoanTypesColumns';
import { GRID_ACTIONS } from '../../../../utils/constants/common';
import { GridColType } from '../../../../utils/enums/GridColType';
import { getGridColumns } from '../../../../utils/helpers/getGridColumns';
import { BorrowerDataLoanTypesTableProps } from '../../../../utils/interfaces/borrowerData/loans/BorrowerDataLoanTypesTableProps';
import { DataGridView } from '../../../common/grid/DataGridView';
import { GridActionsView } from '../../../common/grid/GridActionsView';

export const BorrowerDataLoanTypesTableView = (props: BorrowerDataLoanTypesTableProps) => {
  const { handleEdit, loanTypes, permissionType, canRead } = props;

  const actions: GridColumns = [
    {
      field: GRID_ACTIONS,
      type: GridColType.Actions,
      getActions: ({ id }) => [
        <GridActionsView
          key={id}
          handleEditClick={() => (handleEdit ? handleEdit(id) : null)}
          permissionType={permissionType}
          canRead={canRead}
        />,
      ],
    },
  ];

  const columns: GridColumns = [...borrowerDataLoanTypesColumns, ...actions];

  return <DataGridView data={loanTypes} columns={getGridColumns(columns)} />;
};
