// Transaction details tab indexes
// Tabs should be incremented with 1 for every new added tab

export enum TransactionTabs {
  InfoTab = 0,
  LoansTab = 1,
  BorrowerDataTab = 2,
  PaySchedulesTab = 3,
  PaymentsTab = 4,
  FeesTab = 5,
}
