import { Dispatch } from 'react';

import { nameof } from 'ts-simple-nameof';

import { appConfig } from '../../config/app.config';
import { ACCESS_TOKEN, BEARER_TOKEN } from '../../utils/constants/common';
import { REPORT_ID } from '../../utils/constants/identifiers';
import { SUCCESS_EXPORTED_MESSAGE } from '../../utils/constants/messages';
import { HttpStatusCodes } from '../../utils/enums/HttpStatusCodes';
import { hideLoader, showLoader } from '../../utils/helpers/commonHelper';
import { downloadFile } from '../../utils/helpers/downloadFileHelper';
import { errorResultHandler } from '../../utils/helpers/errorResultHandler';
import { closeDialog } from '../../utils/helpers/reportHelper';
import { successResultHandler } from '../../utils/helpers/successResultHandler';
import { useGetRequest } from '../../utils/hooks/query/useGetRequest';
import { useMutationRequest } from '../../utils/hooks/query/useMutationRequest';
import { UserMapped } from '../../utils/interfaces/Permissions';
import { Action } from '../../utils/store/action';
import { originatorServiceClient } from '../apiClient';
import { Api, Error, LoanExportInfo } from '../types/originatorTypes';

export const useCreateLoanExport = () =>
  useMutationRequest(
    (request) => originatorServiceClient.api.createLoanExport({ filter: JSON.stringify(request) }),
    nameof<Api<unknown>>((x) => x.api.createLoanExport),
  );

export const useCreateOriginatorLoanExport = () =>
  useMutationRequest(
    (request: { [key: string]: number }) =>
      originatorServiceClient.api.createOriginatorLoanExport(request.originatorId, {
        filter: JSON.stringify(request.filter),
      }),
    nameof<Api<unknown>>((x) => x.api.createOriginatorLoanExport),
  );

export const downloadLoanExportFile = async (
  id: number | undefined,
  fileName: string | undefined,
  dispatch: Dispatch<Action>,
  signal: AbortSignal | undefined,
): Promise<void> => {
  const url = `${appConfig.originatorServiceApiUrl}/api/exports/${id}/download`;
  const token = localStorage.getItem(ACCESS_TOKEN);

  const requestHeaders = {
    headers: {
      Authorization: `${BEARER_TOKEN} ${token}`,
    },
    signal: signal,
  };

  showLoader(dispatch, REPORT_ID);

  await fetch(url, requestHeaders)
    .then((response) => {
      if (response.status === HttpStatusCodes.Success) {
        downloadFile(response, fileName as string);
        hideLoader(dispatch);
        closeDialog(dispatch);

        return successResultHandler(response, SUCCESS_EXPORTED_MESSAGE);
      }
    })
    .catch((error: Error) => {
      hideLoader(dispatch);
      if (!error.detail) {
        errorResultHandler({ detail: (error as { message: string }).message });
      } else {
        errorResultHandler(error);
      }
    });
};

export const useGetAllUserLoanExports = (user: UserMapped | undefined): LoanExportInfo[] => {
  const { data: userLoanExports } = useGetRequest(
    {
      key: nameof<Api<unknown>>((x) => x.api.getAllUserLoanExports),
      func: async () =>
        (await originatorServiceClient.api.getAllUserLoanExports(user?.user?.id as number)).data,
    },
    user,
  );

  if (!userLoanExports || !userLoanExports.data) {
    return [];
  }

  return userLoanExports.data;
};
