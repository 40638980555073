import React from 'react';

import List from '@mui/material/List';
import Typography from '@mui/material/Typography';

import {
  sidebarOriginatorSelectContainerStyles,
  sidebarOriginatorSelectStyles,
} from '../../../../styles/sidebarStyles';
import { DIV_TAG } from '../../../../utils/constants/components';
import { CURRENT_LOAN_ORIGINATOR_LABEL } from '../../../../utils/constants/labels';
import { OriginatorTabs } from './OriginatorTabs';

export const OriginatorSelect = () => {
  return (
    <List sx={sidebarOriginatorSelectContainerStyles}>
      <List component={DIV_TAG} disablePadding>
        <Typography sx={sidebarOriginatorSelectStyles}>{CURRENT_LOAN_ORIGINATOR_LABEL}</Typography>
        <OriginatorTabs />
      </List>
    </List>
  );
};
