import { CountrySpv } from '../../api/types/originatorTypes';
import { SpvTableColumnProps } from '../interfaces/originator/details/SpvTableColumnProps';

export const getOriginatorCountrySpvData = (countrySpvData?: CountrySpv[]) => {
  const spvTableData: SpvTableColumnProps[] = [];

  countrySpvData?.map((country) => {
    const countrySpv = {
      id: country.id,
      country: [country.countryId.toString()],
    } as SpvTableColumnProps;

    spvTableData.push(countrySpv);

    if (country.spvs) {
      country.spvs?.map((spv) => {
        spvTableData.push({
          id: `spv:${spv.id}`,
          country: [...countrySpv.country, `${spv.spvId.toString()}/${spv.validToDate}`],
          spvId: spv.spvId.toString(),
          fee: spv.fee,
          facilityAgreementNumber: spv.facilityAgreementNumber,
          validToDate: spv.validToDate,
        } as SpvTableColumnProps);
      });
    }
  });

  return spvTableData;
};
