import React from 'react';

import Popover from '@mui/material/Popover';

import { popoverPointerStyles, popoverPositionStyles } from '../../styles/popoverStyles';
import { getDataGridError } from '../../utils/helpers/getDataGridError';

export const PopoverView = (props: {
  value?: object;
  handleClose?: () => void;
  anchorEl?: HTMLElement | null;
}) => {
  const { value, handleClose, anchorEl } = props;

  return (
    <Popover
      sx={popoverPointerStyles}
      anchorEl={anchorEl}
      open={Boolean(value)}
      anchorOrigin={popoverPositionStyles}
      onClose={handleClose}
    >
      {value && getDataGridError(value)}
    </Popover>
  );
};
