import React, { useState } from 'react';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import { dataExchangeTabs } from '../../../../common/sidebar/dataExchangeTabs';
import { DATA_EXCHANGE_LABEL } from '../../../../utils/constants/labels';
import { TabCollapse } from './TabCollapse';

export const DataExchangeTabs = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = (): void => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <ListItemButton onClick={handleToggle}>
        <ListItemText primary={DATA_EXCHANGE_LABEL} />
        {isOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <TabCollapse isOpen={isOpen} tabs={dataExchangeTabs} />
      {!isOpen && <Divider />}
    </>
  );
};
