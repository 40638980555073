import React, { useContext } from 'react';

import { GridRowId } from '@mui/x-data-grid-pro';

import { deleteAccount, getAllAccounts } from '../../../api/controllers/AccountsController';
import {
  CLOSE_CONFIRMATION_DIALOG,
  OPEN_CONFIRMATION_DIALOG,
  OPEN_FORM_DIALOG,
} from '../../../utils/constants/actions';
import { ADD_ACCOUNT_BUTTON } from '../../../utils/constants/buttons';
import { DELETE_ACCOUNT_CONFIRMATION } from '../../../utils/constants/confirmations';
import { DELETE_ACCOUNT_DIALOG_ID } from '../../../utils/constants/identifiers';
import { USERS_PERMISSION, USER_ROLES_PERMISSION } from '../../../utils/constants/permissions';
import {
  ADD_ACCOUNT_TITLE,
  DELETE_ACCOUNT_TITLE,
  EDIT_ACCOUNT_TITLE,
} from '../../../utils/constants/titles';
import { usePermissions } from '../../../utils/hooks/permissions/usePermissions';
import { Context } from '../../../utils/store';
import { GridHeaderView } from '../../common/grid/GridHeaderView';
import { AddAccountStepper } from '../../views/accounts/AddAccountStepper';
import { AccountsDataGrid } from '../../views/accounts/tables/AccountsDataGrid';

export const AccountsScreen = () => {
  const { dispatch } = useContext(Context);

  const accounts = getAllAccounts();
  const deleteAccountMutation = deleteAccount();

  const permissions = usePermissions();

  const usersPermissionType = USERS_PERMISSION;
  const userRolesPermissionType = USER_ROLES_PERMISSION;

  const handleAddClick = () => {
    dispatch({
      type: OPEN_FORM_DIALOG,
      payload: {
        title: ADD_ACCOUNT_TITLE,
        content: <AddAccountStepper />,
      },
    });
  };

  const handleEditClick = (id: GridRowId) => {
    dispatch({
      type: OPEN_FORM_DIALOG,
      payload: {
        title: EDIT_ACCOUNT_TITLE,
        content: <AddAccountStepper userId={Number(id)} />,
      },
    });
  };

  const handleDeleteConfirm = (id: number): void => {
    deleteAccountMutation.mutateAsync(id);

    dispatch({ type: CLOSE_CONFIRMATION_DIALOG });
  };

  const handleDeleteClick = (id: number): void => {
    dispatch({
      type: OPEN_CONFIRMATION_DIALOG,
      payload: {
        dialogId: DELETE_ACCOUNT_DIALOG_ID,
        title: DELETE_ACCOUNT_TITLE,
        content: DELETE_ACCOUNT_CONFIRMATION,
        onAgree: () => handleDeleteConfirm(id),
      },
    });
  };

  return (
    <>
      <GridHeaderView
        buttonLabel={ADD_ACCOUNT_BUTTON}
        onButtonClick={handleAddClick}
        permissionType={usersPermissionType}
      />
      <AccountsDataGrid
        data={accounts}
        handleEdit={handleEditClick}
        handleDelete={handleDeleteClick}
        permissionType={usersPermissionType}
        canRead={permissions.hasReadPermission(userRolesPermissionType)}
      />
    </>
  );
};
