import React, { Dispatch, SetStateAction, useContext } from 'react';

import { GridSortDirection } from '@mui/x-data-grid-pro';

import { getAllOriginatorLoans } from '../../../../../api/controllers/LoansController';
import { getAllLoans } from '../../../../../api/controllers/LoansController';
import { BeFiltersInterface } from '../../../../../utils/interfaces/filter/BeFiltersInterface';
import { SortingState } from '../../../../../utils/interfaces/transaction/SortingState';
import { Context } from '../../../../../utils/store';
import { LoansLoanTableView } from './LoansLoanTableView';

export const LoansLoanTab = (props: {
  filters: BeFiltersInterface;
  sort: { sorting: SortingState; setSorting: Dispatch<SetStateAction<SortingState>> };
}) => {
  const { filters, sort } = props;
  const { state } = useContext(Context);
  const originatorId = state.originatorID;

  const setNewSort = (newSort: [string, GridSortDirection][] | []) => {
    sort.setSorting({
      ...sort.sorting,
      loans: newSort,
    });
  };

  const loans = originatorId
    ? getAllOriginatorLoans(originatorId, filters, sort.sorting.loans)
    : getAllLoans(filters, sort.sorting.loans);

  return <LoansLoanTableView loans={loans} filters={filters} setNewSort={setNewSort} />;
};
