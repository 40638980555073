import { DRAWER_WIDTH } from '../utils/constants/styles';

export const sidebarMainStyles = {
  width: { sm: DRAWER_WIDTH },
  flexShrink: { sm: 0 },
};

export const sidebarDrawerMobileStyles = {
  display: { xs: 'block', sm: 'none' },
  '& .MuiDrawer-paper': { boxSizing: 'border-box', width: DRAWER_WIDTH },
};

export const sidebarDrawerDesktopStyles = {
  display: { xs: 'none', sm: 'block' },
  '& .MuiDrawer-paper': {
    boxSizing: 'border-box',
    width: DRAWER_WIDTH,
    justifyContent: 'space-between',
  },
};

export const sidebarUserAvatarStyles = {
  // TODO: Avatar is hidden untill the functionality is implemented
  display: 'none',
  paddingBottom: 0,
};

export const userItemStyles = {
  textTransform: 'uppercase',
  wordBreak: 'break-word',
};

export const sidebarCollapseTabStyles = {
  pl: 4,
};

export const sidebarOriginatorSelectStyles = {
  pl: 2,
  fontSize: 12,
};

export const sidebarOriginatorSelectContainerStyles = {
  pt: 0,
};

export const sidebarSingleOriginator = {
  pl: 2,
};
