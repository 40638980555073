export const BORROWER_DATA_FIELDS_PERMISSION = 'BORROWER_DATA_FIELDS';
export const IMPORT_DRAFT_PERMISSION = 'IMPORT_DRAFT';
export const IMPORT_PERMISSION = 'IMPORT';
export const INVESTMENTS_PERMISSION = 'INVESTMENTS';
export const LOAN_BORROWER_DATA_FIELDS_PERMISSION = 'LOAN_BORROWER_DATA_FIELDS';
export const ORIGINATOR_COUNTRIES_SPV_PERMISSION = 'ORIGINATOR_COUNTRIES_SPV';
export const ORIGINATOR_COUNTRIES_PERMISSION = 'ORIGINATOR_COUNTRIES';
export const ORIGINATOR_GROUPS_PERMISSION = 'ORIGINATOR_GROUPS';
export const ORIGINATOR_LOAN_BORROWER_DATA_FIELDS_PERMISSION =
  'ORIGINATOR_LOAN_BORROWER_DATA_FIELDS';
export const ORIGINATOR_SCORE_CLASS_PERMISSION = 'ORIGINATOR_SCORE_CLASS';
export const ORIGINATOR_SCORE_CLASS_MARGIN_PERMISSION = 'ORIGINATOR_SCORE_CLASS_MARGIN';
export const ORIGINATORS_PERMISSION = 'ORIGINATORS';
export const PRODUCTS_PERMISSION = 'PRODUCTS';
export const SCORE_CLASS_PERMISSION = 'SCORE_CLASS';
export const SPV_PERMISSION = 'SPV';
export const TRANSACTIONS_ALL_PERMISSION = 'TRANSACTIONS_ALL';
export const TRANSACTIONS_PERMISSION = 'TRANSACTIONS';
export const USER_ORIGINATORS_PERMISSION = 'USER_ORIGINATORS';
export const USER_ROLES_PERMISSION = 'USER_ROLES';
export const USERS_PERMISSION = 'USERS';
