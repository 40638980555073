import React from 'react';

import { GridRowId } from '@mui/x-data-grid-pro';

import { GridActionsView } from '../../components/common/grid/GridActionsView';
import { SpvTableColumnProps } from '../interfaces/originator/details/SpvTableColumnProps';

export const spvGridActionsHelper = (
  rows: SpvTableColumnProps[],
  id: GridRowId,
  permissionType: string,
  handleEdit?: (id: number) => void,
  handleDelete?: (id: number) => void,
) => {
  const row = rows.find((x) => x.id === id);
  if (row?.country.length !== 1) {
    return [];
  }

  return [
    <GridActionsView
      key={id}
      handleEditClick={() => (handleEdit ? handleEdit(id as number) : null)}
      handleDeleteClick={() => (handleDelete ? handleDelete(id as number) : null)}
      permissionType={permissionType}
    />,
  ];
};
