import React, { useContext } from 'react';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { dialogActionStyles, dialogTitleStyles } from '../../../styles/dialogStyles';
import { CLOSE_FORM_DIALOG } from '../../../utils/constants/actions';
import {
  ADD_BUTTON,
  BUTTON_SUBMIT_TYPE,
  CANCEL_BUTTON,
  EDIT_BUTTON,
  EXPORT_BUTTON,
  SEND_BUTTON,
} from '../../../utils/constants/buttons';
import { EMPTY_VALUE } from '../../../utils/constants/common';
import { BUTTON_LOADER_SIZE } from '../../../utils/constants/styles';
import { ButtonLoaderTriggerLocation } from '../../../utils/enums/ButtonLoaderTriggerLocation';
import { ButtonVariants } from '../../../utils/enums/ButtonVariants';
import { DialogOptions } from '../../../utils/enums/DialogOptions';
import { FormDialogSubmitType } from '../../../utils/enums/FormDialogSubmitType';
import { handleButtonLoader } from '../../../utils/helpers/commonHelper';
import { Context } from '../../../utils/store';

export const DialogFormWrapper = () => {
  const { state, dispatch } = useContext(Context);

  const handleClose = (controller: AbortController | undefined) => {
    controller && controller.abort();
    dispatch({ type: CLOSE_FORM_DIALOG });
  };

  let submitButtonText = EMPTY_VALUE;

  switch (state.formDialog?.submitType) {
    case FormDialogSubmitType.Add:
      submitButtonText = ADD_BUTTON;
      break;
    case FormDialogSubmitType.Edit:
      submitButtonText = EDIT_BUTTON;
      break;
    case FormDialogSubmitType.Send:
      submitButtonText = SEND_BUTTON;
      break;
    case FormDialogSubmitType.Export:
      submitButtonText = EXPORT_BUTTON;
      break;
  }

  const handleLoader = () => handleButtonLoader(state, ButtonLoaderTriggerLocation.FormDialog);

  return state.formDialog ? (
    <Dialog open={true} maxWidth={DialogOptions.Medium}>
      <DialogTitle sx={dialogTitleStyles}>{state.formDialog.title}</DialogTitle>
      <DialogContent>{state.formDialog?.content}</DialogContent>
      {state.formDialog.submitType && (
        <DialogActions sx={dialogActionStyles}>
          <Button onClick={() => handleClose(state.controller)} variant={ButtonVariants.Outlined}>
            {CANCEL_BUTTON}
          </Button>
          {state.formDialog.formId && (
            <Button
              id={state.formDialog.formId}
              form={state.formDialog?.formId}
              type={BUTTON_SUBMIT_TYPE}
              variant={ButtonVariants.Contained}
              disabled={handleLoader()}
            >
              {submitButtonText}
              {handleLoader() && <CircularProgress sx={{ ml: 1 }} size={BUTTON_LOADER_SIZE} />}
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  ) : null;
};
