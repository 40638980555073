import React, { useContext, useEffect, useState } from 'react';

import {
  useCreateLoanExport,
  useCreateOriginatorLoanExport,
} from '../../../../../src/api/controllers/ExportController';
import { LoanExportCreate } from '../../../../../src/api/types/originatorTypes';
import { ExportReport } from '../../../../../src/components/views/loans/tabs/ExportReport';
import {
  FILTERS_ADMIN_INITIAL_STATE,
  FILTERS_INITIAL_STATE,
} from '../../../../../src/utils/constants/advancedFilters';
import { TRANSACTIONS_PERMISSION } from '../../../../../src/utils/constants/permissions';
import { FILTERS_TITLE } from '../../../../../src/utils/constants/titles';
import { closeDialog, openDialog } from '../../../../../src/utils/helpers/reportHelper';
import { Context } from '../../../../../src/utils/store';
import { getAllNomenclaturesByType } from '../../../../api/controllers/NomenclatureController';
import { getAllOriginators } from '../../../../api/controllers/OriginatorController';
import { NomenclatureType } from '../../../../api/types/nomenclatureTypes';
import { EXPORT_REPORT_BUTTON } from '../../../../utils/constants/buttons';
import { TransactionStatus } from '../../../../utils/enums/TransactionStatus';
import { FiltersInitialState } from '../../../../utils/interfaces/filter/FiltersInitialState';
import { SortingState } from '../../../../utils/interfaces/transaction/SortingState';
import { AdvancedFilters } from '../../../common/filter/AdvancedFilters';
import { GridHeaderView } from '../../../common/grid/GridHeaderView';
import { Reports } from '../../../common/reports/Reports';
import { LoansTabsView } from '../../../views/loans/tabs/LoansTabsView';

export const LoansScreen = () => {
  /**
   * This permission type is not for current functionality yet, when BE return current permission we have to change it.
   */
  const { state, dispatch } = useContext(Context);
  const { user } = state;
  const isSystemUser = user?.user?.isSystemUser;

  const originatorId = state.originatorID;

  const transactionsPermission = TRANSACTIONS_PERMISSION;

  const [isAdvancedFiltersOpen, setIsAdvancedFiltersOpen] = useState(false);
  const [isReportsOpen, setIsReportsOpen] = useState(false);
  const [sorting, setSorting] = useState<SortingState>({
    loans: [],
    paySchedules: [],
    payments: [],
    fees: [],
  });
  const [exportData, setExportData] = useState<LoanExportCreate | null>(null);
  const [filters, setFilters] = useState<FiltersInitialState[]>(
    JSON.parse(JSON.stringify(isSystemUser ? FILTERS_ADMIN_INITIAL_STATE : FILTERS_INITIAL_STATE)),
  );

  const [beFilters, setBeFilters] = useState<any>({
    tableSettings: {
      pageSize: 10,
      pageNumber: 0,
    },
  });

  const originators = isSystemUser ? getAllOriginators() : [];
  const loanStatuses = getAllNomenclaturesByType(NomenclatureType.LOAN_STATUSES);
  const transcationStatuses = Object.keys(TransactionStatus).map((content: string) => {
    return { name: content, id: TransactionStatus[content] };
  });

  const nomenclatures = {
    originatorId: originators,
    loanStatusId: loanStatuses,
    transactionStatus: transcationStatuses,
  };

  const loanExport = useCreateLoanExport();
  const originatorLoanExport = useCreateOriginatorLoanExport();

  const toggleHeaderSections = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    if ((e.target as HTMLElement).innerText.includes(FILTERS_TITLE)) {
      setIsAdvancedFiltersOpen(!isAdvancedFiltersOpen);
      setIsReportsOpen(false);
    } else {
      setIsReportsOpen(!isReportsOpen);
      setIsAdvancedFiltersOpen(false);
    }
  };

  const handleExportClick = () => {
    openDialog(dispatch, <ExportReport exportData={exportData} setExportData={setExportData} />);

    const loanExportPromise =
      originatorId === undefined
        ? loanExport.mutateAsync(beFilters.formFields)
        : originatorLoanExport.mutateAsync({ originatorId, filter: beFilters.formFields });

    loanExportPromise
      .then((response) => {
        setExportData(response.data.data as LoanExportCreate);
      })
      .catch(() => {
        closeDialog(dispatch);
      });
  };

  useEffect(() => {
    if (exportData) {
      openDialog(dispatch, <ExportReport exportData={exportData} setExportData={setExportData} />);
    } else {
      // we need this because of the hook warning in the console

      closeDialog(dispatch);
    }
  }, [exportData]);

  return (
    <>
      <GridHeaderView
        buttonLabel={EXPORT_REPORT_BUTTON}
        onButtonClick={handleExportClick}
        toggleHeaderSections={toggleHeaderSections}
        permissionType={transactionsPermission}
        isAdvancedFiltersOpen={isAdvancedFiltersOpen}
        isReportsOpen={isReportsOpen}
        showReports={true}
      />
      <AdvancedFilters
        nomenclatures={nomenclatures}
        setIsAdvancedFiltersOpen={setIsAdvancedFiltersOpen}
        setBeFilters={setBeFilters}
        filters={filters}
        setFilters={setFilters}
        isAdvancedFiltersOpen={isAdvancedFiltersOpen}
      />
      <Reports setExportData={setExportData} isReportsOpen={isReportsOpen} />
      <LoansTabsView
        filters={beFilters}
        permissionType={transactionsPermission}
        sort={{ sorting, setSorting }}
      />
    </>
  );
};
