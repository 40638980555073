import React from 'react';

import { GridColumns } from '@mui/x-data-grid-pro';

import { CountryOfRegistrationCell } from '../../../components/common/grid/gridCells/CountryOfRegistrationCell';
import {
  ADDRESS_ID,
  CEO_ID,
  COUNTRY_OF_REGISTRATION_ID,
  FEE,
  NAME_ID,
  ORIGINATOR_GROUP,
  PUBLIC_NAME_ID,
  REGISTRATION_NUMBER_ID,
} from '../../../utils/constants/identifiers';
import {
  ADDRESS_LABEL,
  CEO_LABEL,
  COUNTRY_OF_REGISTRATION_LABEL,
  FEE_LABEL,
  GROUP_LABEL,
  NAME_LABEL,
  PUBLIC_NAME_LABEL,
  REGISTRATION_NUMBER_LABEL,
} from '../../../utils/constants/labels';
import { EMPTY_GRID_CELL_STRING_VALUE } from '../../../utils/constants/options';
import { GRID_ACTIONS_COLUMN_FLEX } from '../../../utils/constants/styles';
import { GridColType } from '../../../utils/enums/GridColType';

export const originatorColumns: GridColumns = [
  { field: NAME_ID, headerName: NAME_LABEL, flex: GRID_ACTIONS_COLUMN_FLEX },
  { field: PUBLIC_NAME_ID, headerName: PUBLIC_NAME_LABEL, flex: GRID_ACTIONS_COLUMN_FLEX },
  {
    field: REGISTRATION_NUMBER_ID,
    headerName: REGISTRATION_NUMBER_LABEL,
    flex: GRID_ACTIONS_COLUMN_FLEX,
  },
  {
    field: CEO_ID,
    headerName: CEO_LABEL,
    flex: GRID_ACTIONS_COLUMN_FLEX,
  },
  {
    field: COUNTRY_OF_REGISTRATION_ID,
    headerName: COUNTRY_OF_REGISTRATION_LABEL,
    flex: GRID_ACTIONS_COLUMN_FLEX,
    renderCell: (value) => (
      <CountryOfRegistrationCell countryOfRegistrationId={value.row[COUNTRY_OF_REGISTRATION_ID]} />
    ),
  },
  { field: ADDRESS_ID, headerName: ADDRESS_LABEL, flex: GRID_ACTIONS_COLUMN_FLEX },
  {
    field: ORIGINATOR_GROUP,
    headerName: GROUP_LABEL,
    flex: GRID_ACTIONS_COLUMN_FLEX,
    valueGetter: ({ value }) => value?.name || EMPTY_GRID_CELL_STRING_VALUE,
  },
  {
    field: FEE,
    headerName: FEE_LABEL,
    type: GridColType.Number,
  },
];
