import React, { useContext } from 'react';

import { Button, CircularProgress, Link, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { downloadLoanExportFile } from '../../../../api/controllers/ExportController';
import {
  cancelButtonStyles,
  linkStyles,
  linkWrapperStyles,
  loaderStyles,
  typographyStyles,
  wrapperStyles,
} from '../../../../styles/exportReport';
import { fileUploadBoxStyles } from '../../../../styles/fileUploadStyles';
import { formGridSpacing } from '../../../../styles/formStyles';
import { CANCEL_BUTTON } from '../../../../utils/constants/buttons';
import { FILE_SIZE, FINISHED, IN_PROGRESS, ROWS } from '../../../../utils/constants/common';
import { DIV_TAG, FORM_TAG } from '../../../../utils/constants/components';
import { IMPORT_TRANSACTION_FORM_ID } from '../../../../utils/constants/identifiers';
import { ButtonVariants } from '../../../../utils/enums/ButtonVariants';
import { closeDialog } from '../../../../utils/helpers/reportHelper';
import { ExportReportProps } from '../../../../utils/interfaces/ExportReportProps';
import { Context } from '../../../../utils/store';
import { LargeGridField } from '../../../common/form/grid/LargeGridField';

export const ExportReport = (props: ExportReportProps) => {
  const { state, dispatch } = useContext(Context);
  const { exportData, setExportData } = props;

  const handleClick = () => {
    closeDialog(dispatch);
    setExportData(null);
  };

  const handleDownload = () => {
    const signal = state.controller && state.controller.signal;
    downloadLoanExportFile(exportData?.id, exportData?.name, dispatch, signal);
    setExportData(null);
  };

  return exportData ? (
    <Box component={FORM_TAG} id={IMPORT_TRANSACTION_FORM_ID} sx={wrapperStyles}>
      <Grid container rowSpacing={1} columnSpacing={formGridSpacing}>
        <LargeGridField>
          <Box component={DIV_TAG} sx={fileUploadBoxStyles}>
            <Typography>{ROWS}:</Typography>
            <Typography>{exportData?.rows}</Typography>
          </Box>
          {exportData?.status === FINISHED ? (
            <Box component={DIV_TAG} sx={fileUploadBoxStyles}>
              <Typography>{FILE_SIZE}:</Typography>
              <Typography>{`${exportData?.fileSize} mb`}</Typography>
            </Box>
          ) : null}
          <Box sx={linkWrapperStyles}>
            {exportData?.status === IN_PROGRESS ? (
              <Typography sx={typographyStyles}>{exportData?.name}</Typography>
            ) : (
              exportData?.status === FINISHED && (
                <Link onClick={handleDownload} sx={linkStyles}>
                  {exportData?.name}
                </Link>
              )
            )}
          </Box>
          <Box component={DIV_TAG} sx={fileUploadBoxStyles}>
            {exportData?.status === IN_PROGRESS ? (
              <Typography>
                * The report will take longer to export. It can be found later in the report
                section.
              </Typography>
            ) : (
              <Typography>* The new report can be found in the report section.</Typography>
            )}
          </Box>
          <Box sx={cancelButtonStyles}>
            <Button variant={ButtonVariants.Outlined} onClick={handleClick}>
              {CANCEL_BUTTON}
            </Button>
          </Box>
        </LargeGridField>
      </Grid>
    </Box>
  ) : (
    <Box sx={wrapperStyles}>
      <Grid container rowSpacing={1} columnSpacing={formGridSpacing}>
        <CircularProgress sx={loaderStyles} />
      </Grid>
    </Box>
  );
};
