import React, { useState } from 'react';
import { Controller } from 'react-hook-form';

import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { REQUIRED_FIELD_MESSAGE } from '../../../utils/constants/messages';
import { DATEPICKER_DATE_FORMAT } from '../../../utils/constants/options';
import { FORM_FIELD_MARGIN } from '../../../utils/constants/styles';
import { processDatePickerDate } from '../../../utils/helpers/commonHelper';
import { DatePickerFieldProps } from '../../../utils/interfaces/form/DatePickerFieldProps';

export const DatePickerField = (props: DatePickerFieldProps) => {
  const { id, label, control, defaultValue, required } = props;

  const [value, setValue] = useState<Date | null>(null);

  return (
    <Controller
      name={id}
      control={control}
      rules={{ required: required }}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => {
        return (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              inputFormat={DATEPICKER_DATE_FORMAT}
              value={value}
              onChange={(date) => {
                setValue(date);
                field.onChange(processDatePickerDate(date));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={label}
                  margin={FORM_FIELD_MARGIN}
                  error={Boolean(fieldState.error)}
                  InputLabelProps={{ required: required }}
                  helperText={fieldState.error && REQUIRED_FIELD_MESSAGE}
                  fullWidth
                />
              )}
            />
          </LocalizationProvider>
        );
      }}
    />
  );
};
