import {
  ACCOUNTS_KEY,
  BORROWER_DATA_KEY,
  CURRENCY_INVEST_AMOUNT_KEY,
  GROUPS_KEY,
  ORIGINATORS_KEY,
  SCORE_CLASSES_KEY,
  SPVS_KEY,
} from '../../utils/constants/keys';
import {
  ACCOUNTS_LABEL,
  BORROWER_DATA_LABEL,
  CURRENCY_INVEST_AMOUNT_LABEL,
  GROUPS_LABEL,
  ORIGINATORS_LABEL,
  SCORE_CLASSES_LABEL,
  SPVS_LABEL,
} from '../../utils/constants/labels';
import {
  ROUTE_PATH_ACCOUNTS,
  ROUTE_PATH_BORROWER_DATA,
  ROUTE_PATH_CURRENCY_INVEST_AMOUNT,
  ROUTE_PATH_GROUPS,
  ROUTE_PATH_ORIGINATORS,
  ROUTE_PATH_SCORE_CLASSES,
  ROUTE_PATH_SPVS,
} from '../../utils/constants/routes';
import { SidebarTabsProps } from '../../utils/interfaces/layout/SidebarTabsProps';

export const adminTabs: SidebarTabsProps[] = [
  {
    key: ORIGINATORS_KEY,
    title: ORIGINATORS_LABEL,
    path: ROUTE_PATH_ORIGINATORS,
  },
  {
    key: SPVS_KEY,
    title: SPVS_LABEL,
    path: ROUTE_PATH_SPVS,
  },
  {
    key: GROUPS_KEY,
    title: GROUPS_LABEL,
    path: ROUTE_PATH_GROUPS,
  },
  {
    key: BORROWER_DATA_KEY,
    title: BORROWER_DATA_LABEL,
    path: ROUTE_PATH_BORROWER_DATA,
  },
  {
    key: SCORE_CLASSES_KEY,
    title: SCORE_CLASSES_LABEL,
    path: ROUTE_PATH_SCORE_CLASSES,
  },
  {
    key: CURRENCY_INVEST_AMOUNT_KEY,
    title: CURRENCY_INVEST_AMOUNT_LABEL,
    path: ROUTE_PATH_CURRENCY_INVEST_AMOUNT,
  },
  {
    key: ACCOUNTS_KEY,
    title: ACCOUNTS_LABEL,
    path: ROUTE_PATH_ACCOUNTS,
  },
];
