import {
  FEES_KEY,
  LOANS_KEY,
  PAYMENTS_KEY,
  PAYMENT_SCHEDULES_KEY,
} from '../../utils/constants/keys';
import {
  FEES_LABEL,
  LOANS_LABEL,
  PAYMENTS_LABEL,
  PAYMENT_SCHEDULES_LABEL,
} from '../../utils/constants/labels';
import { TabsProps } from '../../utils/interfaces/tabs/TabsProps';

export const loansTabs: TabsProps[] = [
  {
    key: LOANS_KEY,
    label: LOANS_LABEL,
  },
  {
    key: PAYMENT_SCHEDULES_KEY,
    label: PAYMENT_SCHEDULES_LABEL,
  },
  {
    key: PAYMENTS_KEY,
    label: PAYMENTS_LABEL,
  },
  {
    key: FEES_KEY,
    label: FEES_LABEL,
  },
];
