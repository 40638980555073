import {
  DRAFT_STATUS_LABEL,
  NO_STATUS_LABEL,
  PARTIALLY_PUBLISHED_STATUS_LABEL,
  PROCESSING_STATUS_LABEL,
  PUBLISHED_STATUS_LABEL,
} from '../constants/labels';
import { StatusOptions } from '../enums/StatusOptions';
import { TransactionStatus } from '../enums/TransactionStatus';

export const getStatusColor = (color: string): StatusOptions => {
  switch (color) {
    case TransactionStatus.Draft:
      return StatusOptions.Error;
    case TransactionStatus.Published:
      return StatusOptions.Success;
    case TransactionStatus.PartiallyPublished:
      return StatusOptions.Success;
    case TransactionStatus.Processing:
      return StatusOptions.Warning;
    default:
      return StatusOptions.Default;
  }
};

export const getStatusLabel = (status?: string): string => {
  switch (status) {
    case TransactionStatus.Draft:
      return DRAFT_STATUS_LABEL;
    case TransactionStatus.Published:
      return PUBLISHED_STATUS_LABEL;
    case TransactionStatus.PartiallyPublished:
      return PARTIALLY_PUBLISHED_STATUS_LABEL;
    case TransactionStatus.Processing:
      return PROCESSING_STATUS_LABEL;
    default:
      return NO_STATUS_LABEL;
  }
};
