import React, { useContext } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import {
  originatorProductsBoxStyles,
  originatorProductsGridCardCountryStyles,
  originatorProductsGridNoDataStyles,
} from '../../../../../../styles/originatorDetailsStyles';
import { OPEN_INFO_DIALOG } from '../../../../../../utils/constants/actions';
import { MORE_BUTTON } from '../../../../../../utils/constants/buttons';
import { INTEREST_RATE_LABEL, NO_DATA_LABEL } from '../../../../../../utils/constants/labels';
import {
  SIZE_SMALL,
  TYPOGRAPHY_SECONDARY_TEXT_STYLE,
} from '../../../../../../utils/constants/styles';
import { CardOptions } from '../../../../../../utils/enums/CardOptions';
import { GridOptions } from '../../../../../../utils/enums/GridOptions';
import { TypographyVariants } from '../../../../../../utils/enums/TypographyVariants';
import {
  getCountryOfRegistration,
  getPercentValue,
} from '../../../../../../utils/helpers/commonHelper';
import { OriginatorProductDataProps } from '../../../../../../utils/interfaces/originator/details/OriginatorProductsDataProps';
import { Context } from '../../../../../../utils/store';
import { OriginatorProductDialog } from './OriginatorProductDialog';

export const OriginatorProductsGrid = (props: OriginatorProductDataProps) => {
  const {
    originatorId,
    products,
    countries,
    loanTypes,
    loanCurrencies,
    amortizationMethods,
    installmentTypes,
  } = props;
  const { dispatch } = useContext(Context);

  const handleRowClick = (id: number) => {
    const product = products.find((x) => x.id === id);

    if (product && product.id) {
      dispatch({
        type: OPEN_INFO_DIALOG,
        payload: {
          content: (
            <OriginatorProductDialog
              originatorId={originatorId}
              productId={product.id}
              countries={countries}
              loanTypes={loanTypes}
              loanCurrencies={loanCurrencies}
              amortizationMethods={amortizationMethods}
              installmentTypes={installmentTypes}
            />
          ),
        },
      });
    }
  };

  return (
    <Box sx={originatorProductsBoxStyles}>
      {!products || products.length === 0 ? (
        <Typography sx={originatorProductsGridNoDataStyles}>{NO_DATA_LABEL}</Typography>
      ) : (
        <Grid container spacing={2}>
          {products.map((x) => {
            const country = getCountryOfRegistration(x.countryId);

            return (
              <Grid key={x.id} item xs={GridOptions.Small}>
                <Card variant={CardOptions.Outlined}>
                  <CardContent>
                    <Typography color={TYPOGRAPHY_SECONDARY_TEXT_STYLE} gutterBottom>
                      {x.loanTypeId}
                    </Typography>
                    <Typography variant={TypographyVariants.H5}>{x.name}</Typography>
                    <Typography
                      sx={originatorProductsGridCardCountryStyles}
                      color={TYPOGRAPHY_SECONDARY_TEXT_STYLE}
                    >
                      {country?.name}
                    </Typography>
                    <Typography>{`${INTEREST_RATE_LABEL}: ${getPercentValue(
                      x.interestRateMin,
                    )} - ${getPercentValue(x.interestRateMax)}`}</Typography>
                  </CardContent>
                  <CardActions>
                    <Button size={SIZE_SMALL} onClick={() => x.id && handleRowClick(x.id)}>
                      {MORE_BUTTON}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      )}
    </Box>
  );
};
