import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Box, Button, CircularProgress, Grid, Paper, Tab, Tabs, TextField } from '@mui/material';

import { getTransactionData } from '../../../../api/controllers/TransactionController';
import { transactionTabs } from '../../../../common/transactions/transactionTabs';
import { transactionTabsStyles } from '../../../../styles/transactionsStyles';
import { SEARCH_BUTTON } from '../../../../utils/constants/buttons';
import { EMPTY_VALUE, TABS_VIEW_SCROLL_BUTTONS_TYPE } from '../../../../utils/constants/common';
import { ButtonVariants } from '../../../../utils/enums/ButtonVariants';
import { GridOptions } from '../../../../utils/enums/GridOptions';
import { TabsVariants } from '../../../../utils/enums/TabsVariants';
import { TransactionOptions } from '../../../../utils/enums/TransactionOptions';
import { TransactionTabs } from '../../../../utils/enums/TransactionTabs';
import { checkIfValidUUID } from '../../../../utils/helpers/commonHelper';
import { TransactionTabsView } from '../../../views/transactions/details/TransactionTabsView';

export const TransactionDetailsScreen = () => {
  const params = useParams();

  const [selectedTab, setSelectedTab] = useState(TransactionTabs.InfoTab);
  const [loanId, setLoanId] = useState('');
  const [tempLoanId, setTempLoanId] = useState('');
  const [hasError, setHasError] = useState(false);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const { data, remove } = getTransactionData(
    params.id || EMPTY_VALUE,
    TransactionOptions.All,
    loanId,
  );

  useEffect(() => {
    return remove;
  }, []);

  const filter = () => {
    if (!hasError) {
      setLoanId(tempLoanId);
    }
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.target.value.length === 0) {
      setHasError(false);
    } else if (!checkIfValidUUID(event.target.value)) {
      setHasError(true);
    } else {
      setHasError(false);
    }
    setTempLoanId(event.target.value);
  };

  const showSearchInput =
    selectedTab === TransactionTabs.BorrowerDataTab ||
    selectedTab === TransactionTabs.PaySchedulesTab ||
    selectedTab === TransactionTabs.PaymentsTab ||
    selectedTab === TransactionTabs.FeesTab;

  return data && Object.keys(data).length > 0 ? (
    <Grid container>
      <Paper square sx={transactionTabsStyles}>
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          variant={TabsVariants.Scrollable}
          scrollButtons={TABS_VIEW_SCROLL_BUTTONS_TYPE}
        >
          {transactionTabs.map((tab) => {
            return <Tab key={tab.key} label={tab.label} />;
          })}
        </Tabs>
      </Paper>
      <Grid item xs={GridOptions.ExtraLarge}>
        {showSearchInput ? (
          <Paper
            sx={{
              py: 2,
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              width: '100%',
              padding: '16px 16px 0 16px',
              boxShadow: 'none',
              borderRadius: '0',
            }}
          >
            <Box sx={{ width: '100%', marginBottom: '16px' }}>
              <b>Current Loan ID:</b> {data.prefetchedForLoanId}
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'baseline', width: '100%' }}>
              <TextField
                sx={{ width: '40%', mr: 2 }}
                label="Loan id"
                variant="outlined"
                value={tempLoanId}
                onChange={(event) => onChange(event)}
                error={hasError}
                helperText={hasError ? 'The value is not a valid Loan ID.' : ''}
              />
              <Button onClick={filter} variant={ButtonVariants.Contained}>
                {SEARCH_BUTTON}
              </Button>
            </Box>
          </Paper>
        ) : null}
        <TransactionTabsView transactionData={data} selectedTab={selectedTab} />
      </Grid>
    </Grid>
  ) : (
    <Box sx={{ display: 'flex', justifyContent: 'center', margin: '40px 0px' }}>
      <CircularProgress />
    </Box>
  );
};
