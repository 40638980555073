import React, { useContext } from 'react';

import { getAllOriginatorAccounts } from '../../../../../../api/controllers/AccountsController';
import { OPEN_FORM_DIALOG } from '../../../../../../utils/constants/actions';
import { ADD_ACCOUNT_BUTTON } from '../../../../../../utils/constants/buttons';
import { USER_ORIGINATORS_PERMISSION } from '../../../../../../utils/constants/permissions';
import { ADD_ACCOUNT_TITLE } from '../../../../../../utils/constants/titles';
import { Context } from '../../../../../../utils/store';
import { AddAccountStepper } from '../../../../accounts/AddAccountStepper';
import { OriginatorTabsHeaderView } from '../../OriginatorTabsHeaderView';
import { OriginatorAccountsTableView } from './OriginatorAccountsTableView';

export const OriginatorAccountsTab = (props: { originatorId: number; permissionType: string }) => {
  const { originatorId, permissionType } = props;
  const { dispatch } = useContext(Context);

  const allOriginatorAccounts = getAllOriginatorAccounts(originatorId);

  const handleAdd = (): void => {
    dispatch({
      type: OPEN_FORM_DIALOG,
      payload: {
        title: ADD_ACCOUNT_TITLE,
        content: <AddAccountStepper originatorId={originatorId} />,
      },
    });
  };

  return (
    <>
      <OriginatorTabsHeaderView
        buttonLabel={ADD_ACCOUNT_BUTTON}
        onButtonClick={handleAdd}
        permissionType={USER_ORIGINATORS_PERMISSION}
      />
      <OriginatorAccountsTableView
        originatorAccountsData={allOriginatorAccounts}
        originatorId={originatorId}
        permissionType={permissionType}
      />
    </>
  );
};
