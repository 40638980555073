import React from 'react';

import Box from '@mui/material/Box';

import { getAllOriginatorsSpvs } from '../../../api/controllers/OriginatorSpvController';
import { OriginatorsSpvsData } from '../../../utils/interfaces/originatorsSpvs/OriginaotrsSpvsDataType';
import { OriginatorsSpvsDataGrid } from '../../views/originatorsSpvs/OriginatorsSpvsDataGrid';

export const OriginatorsSpvsScreen = () => {
  const originatorsSpvs = getAllOriginatorsSpvs();
  const originatorsSpvsData = [] as OriginatorsSpvsData[];

  originatorsSpvs.map((x, index) => {
    originatorsSpvsData.push({
      id: index,
      ...x,
    });
  });

  return (
    <Box sx={{ mt: 3 }}>
      <OriginatorsSpvsDataGrid originatorsSpvs={originatorsSpvsData} />
    </Box>
  );
};
