// export dialog

export const wrapperStyles = {
  width: '550px',
  height: '190px',
};

export const typographyStyles = {
  color: '#868686',
};

export const linkWrapperStyles = {
  marginTop: '24px',
  marginBottom: '8px',
};

export const linkStyles = {
  color: 'primary.main',
  cursor: 'pointer',
};

export const cancelButtonStyles = {
  textAlign: 'end',
  margin: '24px 0px 0px 24px',
};

export const loaderStyles = {
  margin: '16px auto',
};

//show reports

export const reportWrapperStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '18px 24px',
};

export const textWrapperStyles = {
  display: 'flex',
  gap: '16px',
};

export const iconStyles = {
  cursor: 'pointer',
};

export const reportNameStyles = {
  width: '220px',
};

export const additionalInfoWrapperStyles = {
  padding: '0px 64px',
};

export const additionalInfoStyles = {
  display: 'flex',
  gap: '24px',
};

export const filterTypographyStyles = {
  textTransform: 'capitalize',
};
