import React from 'react';

import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { multipleSelectWrapperStyles } from '../../../../src/styles/advancedFiltersStyles';
import { MultipleSelectProps } from '../../../utils/interfaces/filter/MultipleSelectProps';

export const MultipleSelect = (props: MultipleSelectProps) => {
  const { label, data, value, setValue } = props;

  const handleChange = (event: SelectChangeEvent) => {
    const targetValue = event.target.value;
    setValue(targetValue);
  };

  const getValue = () => {
    return value.map((object: { [key: string]: string }, index: number) => {
      if (!object || !object.name) {
        return '';
      }
      return index === value.length - 1 ? `${object.name}` : `${object.name}, `;
    });
  };

  return (
    <FormControl sx={multipleSelectWrapperStyles}>
      <InputLabel id="multiple-select-label">{label}</InputLabel>
      <Select
        labelId="multiple-select-label-id"
        id="multiple-select-id"
        multiple
        value={value as unknown as string}
        onChange={handleChange}
        input={<OutlinedInput label={label} />}
        renderValue={getValue}
        MenuProps={{
          anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
      >
        {data &&
          data.map((object: any) => (
            <MenuItem key={object.id} value={object}>
              <Checkbox checked={value.indexOf(object as never) > -1} />
              <ListItemText primary={object.name} />
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};
