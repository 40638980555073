/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

type UtilRequiredKeys<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>;

/** This is the all originators response object */
export interface MultiOriginatorsResponse {
  data?: Originator[];
  /** This is the error object */
  error?: Error;
}

/** This is the single originator response object */
export interface SingleOriginatorResponse {
  /** This is the originator object */
  data?: Originator;
  /** This is the error object */
  error?: Error;
}

/** This is a simple originator object */
export interface OriginatorSimple {
  /** @format int64 */
  id?: number;
  /** @pattern ^.*[^ ].*$ */
  name?: string;
}

/** This is the originator object */
export type Originator = OriginatorBaseProperties & {
  /** @format int64 */
  id?: number;
  /** This is the originator group object */
  originatorGroup?: OriginatorGroup;
};

/** This is the response object for single originator settings */
export interface SingleOriginatorSettingsResponse {
  /** This is the originator object that holds originator settings */
  data?: OriginatorSettings;
  /** This is the error object */
  error?: Error;
}

/** This is the originator object that holds originator settings */
export interface OriginatorSettings {
  isActiveImportLoans: boolean;
  isActiveImportPayments: boolean;
  isActivePrimaryMarket: boolean;
  isActiveSecondaryMarket: boolean;
}

/** This is the originator object that holds related countries data */
export type OriginatorSpv = Originator & {
  /** @format int64 */
  id?: number;
  /** This is the originator group object */
  originatorGroup?: OriginatorGroup;
  countryList?: CountrySpv[];
};

/** This is the create originator object */
export type OriginatorBase = UtilRequiredKeys<
  OriginatorBaseProperties,
  | 'name'
  | 'publicName'
  | 'fee'
  | 'ceo'
  | 'address'
  | 'legalName'
  | 'registrationNumber'
  | 'tradeName'
  | 'countryOfRegistrationId'
  | 'businessStatusId'
> & {
  /**
   * @format int64
   * @min 1
   */
  originatorGroupId?: number;
};

/** This is the object holding the originator base properties */
export interface OriginatorBaseProperties {
  /** @pattern ^.*[^ ].*$ */
  name?: string;
  /** @pattern ^.*[^ ].*$ */
  publicName?: string;
  /**
   * @min 0
   * @max 1
   */
  fee?: number;
  /** @pattern ^.*[^ ].*$ */
  ceo?: string;
  /** @pattern ^.*[^ ].*$ */
  address?: string;
  /** @pattern ^.*[^ ].*$ */
  legalName?: string;
  /** @pattern ^.*[^ ].*$ */
  registrationNumber?: string;
  /** @pattern ^.*[^ ].*$ */
  tradeName?: string;
  /**
   * @format int64
   * @min 1
   */
  countryOfRegistrationId?: number;
  /**
   * @format int64
   * @min 1
   */
  businessStatusId?: number;
}

/** This is the all investment settings response object */
export interface MultiInvestmentSettingsResponse {
  data?: InvestmentSetting[];
  /** This is the error object */
  error?: Error;
}

/** This is the single investment setting response object */
export interface SingleInvestmentSettingResponse {
  /** This is the investment setting object */
  data?: InvestmentSetting;
  /** This is the error object */
  error?: Error;
}

/** This is the investment setting object */
export type InvestmentSetting = InvestmentSettingBaseProperties & {
  /** @format int64 */
  id?: number;
};

/** This is the create investment setting object */
export type InvestmentSettingBase = UtilRequiredKeys<
  InvestmentSettingBaseProperties,
  'minAmountPrimary' | 'minAmountSecondary' | 'currencyId'
>;

/** This is the object holding the investment setting base properties */
export interface InvestmentSettingBaseProperties {
  /** @min 0 */
  minAmountPrimary?: number;
  /** @min 0 */
  minAmountSecondary?: number;
  /**
   * @format int64
   * @min 1
   */
  currencyId?: number;
}

/** This is the single spv response */
export interface SingleSpvResponse {
  /** This is the spv object */
  data?: Spv;
  /** This is the error object */
  error?: Error;
}

/** This is the all spvs response */
export interface MultiSpvsResponse {
  data?: Spv[];
  /** This is the error object */
  error?: Error;
}

/** This is the spv object */
export type Spv = SpvBaseProperties & {
  /** @format int64 */
  id?: number;
};

/** This is the create spv object */
export type SpvBase = UtilRequiredKeys<
  SpvBaseProperties,
  | 'name'
  | 'ceo'
  | 'address'
  | 'legalName'
  | 'registrationNumber'
  | 'tradeName'
  | 'countryOfRegistrationId'
>;

/** This is the object holding the spv base properties */
export interface SpvBaseProperties {
  /** @pattern ^.*[^ ].*$ */
  name?: string;
  /** @pattern ^.*[^ ].*$ */
  ceo?: string;
  /** @pattern ^.*[^ ].*$ */
  address?: string;
  /** @pattern ^.*[^ ].*$ */
  legalName?: string;
  /** @pattern ^.*[^ ].*$ */
  registrationNumber?: string;
  /** @pattern ^.*[^ ].*$ */
  tradeName?: string;
  /**
   * @format int64
   * @min 1
   */
  countryOfRegistrationId?: number;
}

/** This is the all score classes response object */
export interface MultiScoreClassResponse {
  data?: ScoreClass[];
  /** This is the error object */
  error?: Error;
}

/** This is the single score class response object */
export interface SingleScoreClassResponse {
  /** This is the score class object */
  data?: ScoreClass;
  /** This is the error object */
  error?: Error;
}

/** This is the score class object */
export type ScoreClass = UtilRequiredKeys<
  ScoreClassBaseProperties,
  'name' | 'publicName' | 'scoreTo'
> & {
  /**
   * @format int64
   * @min 1
   */
  id: number;
};

/** This is the base score class object */
export type ScoreClassBase = UtilRequiredKeys<
  ScoreClassBaseProperties,
  'name' | 'publicName' | 'scoreTo'
>;

/** This is the object holding the score class base properties */
export interface ScoreClassBaseProperties {
  /** @pattern ^.*[^ ].*$ */
  name?: string;
  /** @pattern ^.*[^ ].*$ */
  publicName?: string;
  /**
   * @min 0
   * @max 1
   */
  scoreTo?: number;
}

/** This is the originator response object */
export interface UserOriginatorsResponse {
  data?: OriginatorSimple[];
  /** This is the error object */
  error?: Error;
}

/** This is the user roles request object */
export interface UserRolesRequest {
  assignedRoleList: string[];
}

/** This is the user roles response object */
export interface UserRolesResponse {
  /** This is the object holding the user roles data */
  data?: UserRolesResponseData;
  /** This is the error object */
  error?: Error;
}

/** This is the object holding the user roles data */
export interface UserRolesResponseData {
  multiOriginatorUser?: boolean;
  assignedRoleList?: string[];
  availableRoleList?: UserAvailableRoles[];
}

/** This is the object holding the available roles along with their descriptions */
export interface UserAvailableRoles {
  availableRole?: string;
  availableRoleDescription?: string;
}

/** This is the user response object */
export interface UserResponse {
  /** This is the object holding the user's data */
  data?: User;
  /** This is the error object */
  error?: Error;
}

/** This is the multi users response object */
export interface MultiUsersResponse {
  data?: User[];
  /** This is the error object */
  error?: Error;
}

/** This is the object holding the user's data */
export interface User {
  /** @format int64 */
  id?: number;
  username?: string;
  isSystemUser?: boolean;
}

/** This is the object holding the user's data and user's roles data */
export type UserData = User & UserRolesResponseData;

/** This is the object holding the user's and user's permission data */
export interface UserLogin {
  /** This is the object holding the user's data */
  user?: User;
  originators?: OriginatorSimple[];
  permissions?: Permission[];
}

/** This is the permission object */
export interface Permission {
  /** This is the resource object */
  resource?: Resource;
  /** This is the scope holder object */
  scopeHolder?: ScopeHolder;
}

/** This is the resource object */
export interface Resource {
  resource?: string;
}

/** This is the scope holder object */
export interface ScopeHolder {
  create?: boolean;
  read?: boolean;
  update?: boolean;
  delete?: boolean;
}

/** This is the login response */
export interface LoginResponse {
  /** This is the object holding the user's and user's permission data */
  data?: UserLogin;
  /** This is the error object */
  error?: Error;
}

/** This is the user data response object */
export interface MultiUserDataResponse {
  data?: UserData[];
  /** This is the error object */
  error?: Error;
}

/** This is the user data response object */
export interface SingleUserDataResponse {
  /** This is the object holding the user's data and user's roles data */
  data?: UserData;
  /** This is the error object */
  error?: Error;
}

/** This is the all originator groups response object */
export interface MultiOriginatorGroupsResponse {
  data?: OriginatorGroup[];
  /** This is the error object */
  error?: Error;
}

/** This is the originator group response object */
export interface SingleOriginatorGroupResponse {
  /** This is the originator group object */
  data?: OriginatorGroup;
  /** This is the error object */
  error?: Error;
}

/** This is the originator group object */
export type OriginatorGroup = OriginatorGroupBaseProperties & {
  /**
   * @format int64
   * @min 1
   */
  id?: number;
};

/** This is the create originator group object */
export type OriginatorGroupBase = UtilRequiredKeys<
  OriginatorGroupBaseProperties,
  | 'name'
  | 'ceo'
  | 'address'
  | 'legalName'
  | 'registrationNumber'
  | 'tradeName'
  | 'countryOfRegistrationId'
>;

/** This is the object holding the originator group base properties */
export interface OriginatorGroupBaseProperties {
  /** @pattern ^.*[^ ].*$ */
  name?: string;
  /** @pattern ^.*[^ ].*$ */
  ceo?: string;
  /** @pattern ^.*[^ ].*$ */
  address?: string;
  /** @pattern ^.*[^ ].*$ */
  legalName?: string;
  /** @pattern ^.*[^ ].*$ */
  registrationNumber?: string;
  /** @pattern ^.*[^ ].*$ */
  tradeName?: string;
  /**
   * @format int64
   * @min 1
   */
  countryOfRegistrationId?: number;
}

/** This is the all originator country response object */
export interface MultiOriginatorCountriesResponse {
  data?: OriginatorCountry[];
  /** This is the error object */
  error?: Error;
}

/** This is the single originator country response object */
export interface SingleOriginatorCountryResponse {
  /** This is the originator country object */
  data?: OriginatorCountry;
  /** This is the error object */
  error?: Error;
}

/** This is the originator country object */
export type OriginatorCountry = OriginatorCountryBase & {
  /** @format int64 */
  id?: number;
  /**
   * @format int64
   * @min 1
   */
  originatorId?: number;
};

/** This is the originator country object that holds its related spv data */
export type CountrySpv = OriginatorCountry & {
  /** @format int64 */
  id?: number;
  spvs?: OriginatorCountrySpvSetting[];
};

/** This is the originator country base object */
export interface OriginatorCountryBase {
  /**
   * @format int64
   * @min 1
   */
  countryId: number;
}

/** This is the all originator to country to spv response object */
export interface MultiOriginatorCountrySpvSettingsResponse {
  data?: OriginatorCountrySpvSetting[];
  /** This is the error object */
  error?: Error;
}

/** This is the single originator to country to spv response object */
export interface SingleOriginatorCountrySpvSettingResponse {
  /** This is the originator to country to spv object */
  data?: OriginatorCountrySpvSetting;
  /** This is the error object */
  error?: Error;
}

/** This is the originator to country to spv object */
export type OriginatorCountrySpvSetting = OriginatorCountrySpvSettingBase & {
  /** @format int64 */
  id?: number;
  /**
   * @format int64
   * @min 1
   */
  originatorCountryId?: number;
};

/** This is the create originator country spv setting object */
export interface OriginatorCountrySpvSettingBase {
  /**
   * @format int64
   * @min 1
   */
  spvId: number;
  legacySpv?: boolean;
  /**
   * @min 0
   * @max 1
   */
  fee: number;
  /** @pattern ^.*[^ ].*$ */
  facilityAgreementNumber: string;
  /** @format date */
  validToDate: string;
}

/** This is the all products response object */
export interface MultiProductsResponse {
  data?: Product[];
  /** This is the error object */
  error?: Error;
}

/** This is the single product object */
export interface SingleProductResponse {
  /** This is the product object */
  data?: Product;
  /** This is the error object */
  error?: Error;
}

/** This is the product object */
export type Product = ProductBase & {
  /** @format int64 */
  id?: number;
  /**
   * @format int64
   * @min 1
   */
  originatorId?: number;
  isDraft?: boolean;
};

/** This is the create product object */
export interface ProductBase {
  /** @pattern ^.*[^ ].*$ */
  name: string;
  /**
   * @format int64
   * @min 1
   */
  countryId: number;
  /**
   * @format int64
   * @min 1
   */
  loanTypeId: number;
  /**
   * @min 0
   * @max 1
   */
  interestRateMin: number;
  /**
   * @min 0
   * @max 1
   */
  interestRateMax: number;
  /** @min 0 */
  investPartPercent: number;
  /**
   * @format int64
   * @min 1
   */
  loanCurrencyId: number;
  /**
   * @format int64
   * @min 1
   */
  amortizationMethodId: number;
  /**
   * @min 1
   * @max 249
   */
  numberOfInstalmentsMin: number;
  /**
   * @min 1
   * @max 249
   */
  numberOfInstalmentsMax: number;
  /**
   * @min 0
   * @max 1
   */
  loanGuarantPrincipalPerc: number;
  /**
   * @min 0
   * @max 1
   */
  loanGuarantInterestPerc: number;
  /**
   * @min 1
   * @max 180
   */
  loanGuarantPeriodDays: number;
  /** @min 1 */
  yearDaysBase: number;
  /** @pattern ^.*[^ ].*$ */
  applicationMode: string;
  /**
   * @format int64
   * @min 1
   */
  installmentTypeId: number;
  /**
   * @min 1
   * @max 31
   */
  installmentTypeNumber: number;
}

/** This is the all originator score classes response object */
export interface MultiOriginatorScoreClassesResponse {
  data?: OriginatorScoreClass[];
  /** This is the error object */
  error?: Error;
}

/** This is the single originator score class response object */
export interface SingleOriginatorScoreClassResponse {
  /** This is the originator score class object */
  data?: OriginatorScoreClass;
  /** This is the error object */
  error?: Error;
}

/** This is the originator score class object */
export type OriginatorScoreClass = ScoreClassBaseProperties & {
  /**
   * @format int64
   * @min 1
   */
  id: number;
  /**
   * @format int64
   * @min 1
   */
  originatorId: number;
  /**
   * @format int64
   * @min 1
   */
  scoreClassId: number;
};

/** This is the all originator score classes with margins response object */
export interface MultiOriginatorScoreClassMarginsResponse {
  data?: OriginatorScoreClassMargin[];
  /** This is the error object */
  error?: Error;
}

/** This is the single originator score class with margin response object */
export interface SingleOriginatorScoreClassMarginResponse {
  /** This is the originator score class with margin object */
  data?: OriginatorScoreClassMargin;
  /** This is the error object */
  error?: Error;
}

/** This is the originator score class with margin object */
export type OriginatorScoreClassMargin = ScoreClassBaseProperties & {
  /**
   * @format int64
   * @min 1
   */
  id: number;
  /**
   * @format int64
   * @min 1
   */
  originatorId: number;
  /**
   * @format int64
   * @min 1
   */
  scoreClassId: number;
  /**
   * @min 0
   * @max 1
   */
  interestMargin: number;
};

/** This is the originator score class object */
export type OriginatorScoreClassMarginCreate = ScoreClassBaseProperties & {
  /**
   * @format int64
   * @min 1
   */
  originatorId: number;
  /**
   * @format int64
   * @min 1
   */
  scoreClassId: number;
  /**
   * @min 0
   * @max 1
   */
  interestMargin: number;
};

/** This is the object holding a generated transaction id */
export interface TransactionId {
  /** @format uuid */
  transId?: string;
}

/** This is the transaction id response object */
export interface TransactionIdResponse {
  /** This is the object holding a generated transaction id */
  data?: TransactionId;
  /** This is the error object */
  error?: Error;
}

/** This is the object holding information about the number of imported data for a transaction id */
export interface TransactionIdInfo {
  /** @format int64 */
  originatorId?: number;
  loanTotalCount?: number;
  payScheduleTotalCount?: number;
  paymentTotalCount?: number;
  feeTotalCount?: number;
  isDraft?: boolean;
}

/** This is the object hodling information about the transaction */
export interface Transaction {
  /** @format uuid */
  transId?: string;
  /** @format int64 */
  originatorId?: number;
  loanTotalCount?: number;
  loanProcessedCount?: number;
  payScheduleTotalCount?: number;
  payScheduleProcessedCount?: number;
  paymentTotalCount?: number;
  paymentProcessedCount?: number;
  feeTotalCount?: number;
  feeProcessedCount?: number;
  loanSubmittedCount?: number;
  payScheduleSubmittedCount?: number;
  paymentSubmittedCount?: number;
  feeSubmittedCount?: number;
  loanErrorCount?: number;
  loanWarningCount?: number;
  transactionStatus?: TransactionStatus;
  /** @format date-time */
  insertedAt?: string;
}

/** This is the transaction response object */
export interface TransactionResponse {
  /** This is the object hodling information about the transaction */
  data?: Transaction;
  /** This is the error object */
  error?: Error;
}

/** This is the multi transaction response object */
export interface MultiTransactionResponse {
  /** This is the page details object that holds information about the pageable response data */
  page?: PageData;
  data?: Transaction[];
  /** This is the error object */
  error?: Error;
}

/** This is an object holding the status update of a transaction */
export interface TransactionUpdate {
  status?: TransactionStatus;
}

/** @example "DRAFT" */
export type TransactionStatus = 'PROCESSING' | 'DRAFT' | 'PARTIALLY_PUBLISHED' | 'PUBLISHED';

/** This is the originator loan object */
export interface LoanInfo {
  /** @format int64 */
  id?: number;
  /** @format uuid */
  loanId?: string;
  /** @format uuid */
  transId?: string;
  /** @format int64 */
  originatorId?: number;
  kid?: string;
  country?: string;
  /** @format int64 */
  typeId?: number;
  /** @format int64 */
  useOfLoanId?: number;
  loanImportStatus?: string;
  /** @format int64 */
  loanStatusId?: number;
  /** @format int64 */
  collateralTypeId?: number;
  /** @format double */
  collateralAmount?: number;
  /** @format int64 */
  amortizationMethodId?: number;
  yearDaysBase?: number;
  currency?: string;
  /** @format double */
  amount?: number;
  /** @format double */
  guarantPrincipalPerc?: number;
  /** @format double */
  guarantInterestPerc?: number;
  guarantPeriodDays?: number;
  /** @format date-time */
  guarantActivationDate?: string;
  payTermPolicy?: number;
  defaultedOnDay?: number;
  /** @format double */
  investPartPerc?: number;
  /** @format double */
  investPartAmount?: number;
  /** @format double */
  pdPerc?: number;
  /** @format date-time */
  issueDate?: string;
  /** @format date-time */
  endDate?: string;
  instCount?: number;
  instPeriodNum?: number;
  /** @format int64 */
  instPeriodId?: number;
  origCurrency?: string;
  /** @format int64 */
  daysDelay?: number;
  /** @format int64 */
  delayStatusId?: number;
  /** @format int64 */
  termNotPaid?: number;
  /** @format double */
  interestPerc?: number;
  /** @format double */
  origInterestPerc?: number;
  /** @format double */
  origInterestAmount?: number;
  /** @format double */
  interestAmount?: number;
  /** @format int64 */
  productId?: number;
  productName?: string;
  /** @format date-time */
  bussinessDay?: string;
  isListed?: boolean;
  /** @format double */
  minimumInvestAmount?: number;
  lateFeesPayable?: boolean;
  lateFeeTerms?: string;
  /** @format int64 */
  originatorScoreClassId?: number;
  originatorScoreClassPublicName?: string;
  /** @format double */
  marginInterestPerc?: number;
  /** @format int64 */
  spvId?: number;
  legacySpv?: boolean;
  hasError?: boolean;
  errorType?: string;
  errorDesc?: string;
  warning?: string;
  /** @default false */
  isFromAggregated?: boolean;
  /** @format date-time */
  insertedAt?: string;
}

/** This is the originator pay schedule object */
export interface PayScheduleInfo {
  /** @format int64 */
  id?: number;
  /** @format uuid */
  payScheduleId?: string;
  /** @format uuid */
  transId?: string;
  /** @format int64 */
  originatorId?: number;
  /** @format uuid */
  loanId?: string;
  instNumber?: number;
  /** @format date-time */
  maturityDate?: string;
  /** @format double */
  principalAmount?: number;
  /** @format double */
  origPrincipalAmount?: number;
  /** @format double */
  interestAmount?: number;
  /** @format double */
  origInterestAmount?: number;
  isPaid?: boolean;
  /** @format date-time */
  bussinessDay?: string;
  wasSumCorrected?: boolean;
  hasError?: boolean;
  errorType?: string;
  errorDesc?: string;
  warning?: string;
  /** @default false */
  isFromAggregated?: boolean;
  /** @format date-time */
  insertedAt?: string;
}

/** This is the originator payment object */
export interface PaymentInfo {
  /** @format int64 */
  id?: number;
  /** @format uuid */
  payId?: string;
  /** @format uuid */
  transId?: string;
  /** @format int64 */
  originatorId?: number;
  /** @format uuid */
  payScheduleId?: string;
  /** @format uuid */
  loanId?: string;
  /** @format date-time */
  date?: string;
  /** @format double */
  principalAmount?: number;
  /** @format double */
  origPrincipalAmount?: number;
  /** @format double */
  interestAmount?: number;
  /** @format double */
  origInterestAmount?: number;
  isGuarant?: boolean;
  isEarly?: boolean;
  /** @format date-time */
  bussinessDay?: string;
  wasSumCorrected?: boolean;
  hasError?: boolean;
  errorType?: string;
  errorDesc?: string;
  warning?: string;
  /** @format date-time */
  insertedAt?: string;
}

/** This is the originator fee object */
export interface FeeInfo {
  /** @format int64 */
  id?: number;
  /** @format uuid */
  feeId?: string;
  /** @format uuid */
  transId?: string;
  /** @format int64 */
  originatorId?: number;
  /** @format uuid */
  loanId?: string;
  /** @format double */
  amount?: number;
  /** @format double */
  payAmount?: number;
  /** @format date-time */
  payDate?: string;
  type?: string;
  /** @format date-time */
  bussinessDay?: string;
  /** @format date-time */
  date?: string;
  hasError?: boolean;
  errorType?: string;
  errorDesc?: string;
  warning?: string;
  /** @format date-time */
  insertedAt?: string;
}

/** This is the originator borrower data object */
export interface BorrowerDataInfo {
  /** @format int64 */
  id?: number;
  /** @format uuid */
  loanId?: string;
  /** @format uuid */
  transId?: string;
  /** @format int64 */
  originatorId?: number;
  age?: number;
  carCount?: number;
  /** @format double */
  freeCash?: number;
  /** @format int64 */
  genderId?: number;
  /** @format int64 */
  jobTypeId?: number;
  /** @format int64 */
  educationId?: number;
  /** @format double */
  incomeTotal?: number;
  /** @format int64 */
  getSalaryId?: number;
  /** @format double */
  incomeSalary?: number;
  getSalaryDay?: number;
  /** @format double */
  incomePension?: number;
  residencyCity?: string;
  /** @format int64 */
  borrowerTypeId?: number;
  /** @format int64 */
  carOwnershipId?: number;
  residencyRegion?: string;
  /** @format int64 */
  salaryPeriodId?: number;
  /** @format double */
  liabilitiesPhone?: number;
  /** @format double */
  liabilitiesTotal?: number;
  /** @format int64 */
  maritalStatusId?: number;
  residencyCountry?: string;
  /** @format int64 */
  occupationAreaId?: number;
  /** @format int64 */
  employmentStatusId?: number;
  familyMembersCount?: number;
  /** @format double */
  incomePartnerSalary?: number;
  propertyOwnershipIds?: number[];
  workExpirienceYears?: number;
  /** @format int64 */
  homeOwnershipTypeId?: number;
  /** @format double */
  liabilitiesNetAndTv?: number;
  /** @format double */
  liabilitiesElectricity?: number;
  workExpirienceTotalMonths?: number;
  workExpirienceCurrentEmployerYears?: number;
  workExpirienceCurrentEmployerMonths?: number;
  realEstateSize?: number;
  realEstateCountryCode?: string;
  realEstateRegion?: string;
  brandAndModel?: string;
  appraiser?: string;
  manufacturingYear?: number;
  /** @format date-time */
  registrationDate?: string;
  /** @format int64 */
  businessSectorId?: number;
  activityDescr?: string;
  /** @format date-time */
  collateralValuationDate?: string;
  collateralTypeIds?: number[];
  registrationCountryCode?: string;
  sellerBusinessDescr?: string;
  buyerBusinessDescr?: string;
  /** @format double */
  advancePerc?: number;
  factoringTransTypeIds?: number[];
  hasRecourse?: boolean;
  hasInvoiceInsurance?: boolean;
  hasError?: boolean;
  errorType?: string;
  errorDesc?: string;
}

/** This is the object holding the information about a transaction */
export interface TransactionData {
  /** This is the object hodling information about the transaction */
  transaction?: Transaction;
  loans?: LoanInfo[];
  paySchedules?: PayScheduleInfo[];
  payments?: PaymentInfo[];
  fees?: FeeInfo[];
  borrowerData?: BorrowerDataInfo[];
  /** @format uuid */
  prefetchedForLoanId?: string;
}

/** This is the transaction data object */
export interface TransactionDataResponse {
  /** This is the object holding the information about a transaction */
  data?: TransactionData;
  /** This is the error object */
  error?: Error;
}

/**
 * The type of transaction data to return
 * @example "ALL"
 */
export type TransactionDataType =
  | 'LOANS'
  | 'PAY_SCHEDULES'
  | 'PAYMENTS'
  | 'FEES'
  | 'BORROWER_DATA'
  | 'ALL';

/** This is the all originators with their related countries and SPVs response object */
export interface MultiOriginatorCountrySpvResponse {
  data?: OriginatorCountrySpvResponseData[];
  /** This is the error object */
  error?: Error;
}

/** This is a single originator with its related countries and SPVs data object */
export interface OriginatorCountrySpvResponseData {
  /** @format int64 */
  originatorId?: number;
  originatorName?: string;
  /** @format int64 */
  countryId?: number;
  /** @format int64 */
  spvId?: number;
  fee?: number;
  spvFacilityAgreementNumber?: string;
  /** @format date */
  spvValidToDate?: string;
}

/** This is a single originator with its related countries and SPVs response object */
export interface SingleOriginatorCountrySpvResponse {
  /** This is the originator object that holds related countries data */
  data?: OriginatorSpv;
  /** This is the error object */
  error?: Error;
}

/** This is the all borrower data fields response object */
export interface MultiBorrowerDataFieldResponse {
  data?: BorrowerDataField[];
  /** This is the error object */
  error?: Error;
}

/** This is the single borrower data field response object */
export interface SingleBorrowerDataFieldResponse {
  /** This is the borrower data field object */
  data?: BorrowerDataField;
  /** This is the error object */
  error?: Error;
}

/** This is the borrower data field object */
export type BorrowerDataField = BorrowerDataFieldBase & {
  /** @format int64 */
  id?: number;
};

/** This object hold the borrower data field id */
export interface BorrowerDataFieldId {
  /**
   * @format int64
   * @min 1
   */
  id?: number;
}

/** This is the borrower data field base object */
export interface BorrowerDataFieldBase {
  label?: string;
  description?: string;
}

/** This is the loan borrower data fields reponse object */
export interface LoanBorrowerDataFieldsResponse {
  data?: LoanBorrowerDataField[];
  /** This is the error object */
  error?: Error;
}

/** This is the loan borrower data field object */
export interface LoanBorrowerDataField {
  /** @format int64 */
  id?: number;
  /** @format int64 */
  loanTypeId?: number;
  /** This is the borrower data field object */
  borrowerDataField?: BorrowerDataField;
}

/** This is the loan borrower data fields for a given originator response object */
export interface OriginatorLoanBorrowerDataFieldsResponse {
  data?: OriginatorLoanBorrowerDataField[];
  /** This is the error object */
  error?: Error;
}

/** This is the loan borrower data field object for a given originator */
export interface OriginatorLoanBorrowerDataField {
  /** @format int64 */
  id?: number;
  /** @format int64 */
  originatorId?: number;
  /** @format int64 */
  loanTypeId?: number;
  /** This is the borrower data field object */
  borrowerDataField?: BorrowerDataField;
}

/** This is the filter operation model */
export interface FilterOperator {
  operator?: '=' | '!=' | '>' | '<' | '>=' | '<=' | 'in' | 'like' | 'between';
}

/** This is the object holding the information about loans */
export interface LoansDataResponse {
  /** This is the page details object that holds information about the pageable response data */
  page?: PageData;
  data?: LoanInfo[];
  /** This is the error object */
  error?: Error;
}

/** This is the object holding the information about pay schedules */
export interface PaySchedulesDataResponse {
  /** This is the page details object that holds information about the pageable response data */
  page?: PageData;
  data?: PayScheduleInfo[];
  /** This is the error object */
  error?: Error;
}

/** This is the object holding the information about payments */
export interface PaymentsDataResponse {
  /** This is the page details object that holds information about the pageable response data */
  page?: PageData;
  data?: PaymentInfo[];
  /** This is the error object */
  error?: Error;
}

/** This is the object holding the information about fees */
export interface FeesDataResponse {
  /** This is the page details object that holds information about the pageable response data */
  page?: PageData;
  data?: FeeInfo[];
  /** This is the error object */
  error?: Error;
}

/** This is the object holding the information about borrower data */
export interface BorrowerDataResponse {
  /** This is the page details object that holds information about the pageable response data */
  page?: PageData;
  data?: BorrowerDataInfo[];
  /** This is the error object */
  error?: Error;
}

/**
 * This is processing status of the loan export
 * @example "FINISHED"
 */
export type LoanExportStatus = 'IN_PROGRESS' | 'FAILED' | 'FINISHED';

/** return object for loan export creation */
export interface LoanExportCreate {
  /** @format int64 */
  id?: number;
  name?: string;
  /** This is processing status of the loan export */
  status?: LoanExportStatus;
  message?: string;
  /** @format int64 */
  fileSize?: number;
  /** @format int64 */
  rows?: number;
}

/** return object for loan export visualization */
export interface LoanExportInfo {
  /** @format int64 */
  id?: number;
  name?: string;
  /** This is processing status of the loan export */
  status?: LoanExportStatus;
  filter?: string;
  /** @format int64 */
  rows?: number;
}

/** This is the response object for a single loan export create */
export interface SingleLoanExportCreateResponse {
  /** return object for loan export creation */
  data?: LoanExportCreate;
  /** This is the error object */
  error?: Error;
}

/** This is the response object for a single loan export */
export interface MultiLoanExportInfoResponse {
  data?: LoanExportInfo[];
  /** This is the error object */
  error?: Error;
}

/** This is the response object in case of errors, compliant with RFC7807 */
export interface ErrorResponse {
  /** This is the error object */
  error?: Error;
}

/** This is the error object */
export interface Error {
  type?: string;
  title?: string;
  status?: number;
  detail?: string;
}

/** This is the page details object that holds information about the pageable response data */
export interface PageData {
  /** @format int64 */
  totalElements?: number;
  totalPages?: number;
  size?: number;
  number?: number;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = 'http://localhost:8080/api';
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join('&');
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => 'undefined' !== typeof query[key]);
    return keys
      .map((key) =>
        Array.isArray(query[key])
          ? this.addArrayQueryParam(query, key)
          : this.addQueryParam(query, key),
      )
      .join('&');
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : '';
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === 'object' || typeof input === 'string')
        ? JSON.stringify(input)
        : input,
    [ContentType.Text]: (input: any) =>
      input !== null && typeof input !== 'string' ? JSON.stringify(input) : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === 'object' && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(
      `${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`,
      {
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
        },
        signal: cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal,
        body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body),
      },
    ).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title Originator Service
 * @version 0.16.0
 * @baseUrl http://localhost:8080/api
 *
 * This is a YAML For Iuvo Originator Microservice
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * @description This is a user registration method
     *
     * @tags User
     * @name Register
     * @request POST:/api/register
     * @secure
     */
    register: (params: RequestParams = {}) =>
      this.request<UserResponse, ErrorResponse>({
        path: `/api/register`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method returning a user
     *
     * @tags User
     * @name Login
     * @request GET:/api/login
     * @secure
     */
    login: (params: RequestParams = {}) =>
      this.request<LoginResponse, ErrorResponse>({
        path: `/api/login`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method returning list of all current users
     *
     * @tags User
     * @name GetAllUsers
     * @request GET:/api/users
     * @secure
     */
    getAllUsers: (
      query?: {
        /** Filter users by username */
        username?: string;
        /** Return only users with super_admin, admin and admin_viewer roles */
        isSystemUser?: boolean;
        /**
         * Page number
         * @min 0
         * @default 0
         */
        pageNumber?: number;
        /**
         * Page size
         * @min 0
         * @default 10000
         */
        pageSize?: number;
        /**
         * Sorting order parameter
         * @default "ASC"
         */
        sortOrder?: string;
        /**
         * Sorting parameter
         * @default "id"
         */
        sortBy?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<MultiUsersResponse, ErrorResponse>({
        path: `/api/users`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method that creates user and registers user in KeyCloak system
     *
     * @tags User
     * @name CreateUser
     * @request POST:/api/users
     * @secure
     */
    createUser: (
      query: {
        /**
         * This is the username to be used for registration
         * @format email
         */
        username: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<SingleUserDataResponse, ErrorResponse>({
        path: `/api/users`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method that deletes existing user
     *
     * @tags User
     * @name DeleteUser
     * @request DELETE:/api/users/{id}
     * @secure
     */
    deleteUser: (id: number, params: RequestParams = {}) =>
      this.request<void, ErrorResponse>({
        path: `/api/users/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description This method returns all Loan Exports for a given user.
     *
     * @tags LoanExports
     * @name GetAllUserLoanExports
     * @request GET:/api/users/{userId}/loanExports
     * @secure
     */
    getAllUserLoanExports: (userId: number, params: RequestParams = {}) =>
      this.request<MultiLoanExportInfoResponse, ErrorResponse>({
        path: `/api/users/${userId}/loanExports`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method for assigning users to originators
     *
     * @tags UserOriginators
     * @name AssignUserToOriginator
     * @request POST:/api/originators/{originatorId}/users
     * @secure
     */
    assignUserToOriginator: (
      originatorId: number,
      query: {
        /**
         * This is the username to be used for registration
         * @format email
         */
        username: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<SingleUserDataResponse, ErrorResponse>({
        path: `/api/originators/${originatorId}/users`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method for unassigning users from originators
     *
     * @tags UserOriginators
     * @name RemoveUserFromOriginator
     * @request DELETE:/api/originators/{originatorId}/users/{userId}
     * @secure
     */
    removeUserFromOriginator: (originatorId: number, userId: number, params: RequestParams = {}) =>
      this.request<void, ErrorResponse>({
        path: `/api/originators/${originatorId}/users/${userId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description This is a method returning all originators a user is assigned to
     *
     * @tags UserOriginators
     * @name GetUserOriginators
     * @request GET:/api/users/{id}/originators
     * @secure
     */
    getUserOriginators: (id: number, params: RequestParams = {}) =>
      this.request<UserOriginatorsResponse, ErrorResponse>({
        path: `/api/users/${id}/originators`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method returning the roles of a user
     *
     * @tags UserRoles
     * @name GetUserRoles
     * @request GET:/api/users/{id}/roles
     * @secure
     */
    getUserRoles: (id: number, params: RequestParams = {}) =>
      this.request<UserRolesResponse, ErrorResponse>({
        path: `/api/users/${id}/roles`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method updating the roles of a user and returing the updated roles
     *
     * @tags UserRoles
     * @name UpdateUserRoles
     * @request PUT:/api/users/{id}/roles
     * @secure
     */
    updateUserRoles: (id: number, data: UserRolesRequest, params: RequestParams = {}) =>
      this.request<UserRolesResponse, ErrorResponse>({
        path: `/api/users/${id}/roles`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is method returning all originators
     *
     * @tags Originator
     * @name GetAllOriginators
     * @request GET:/api/originators
     * @secure
     */
    getAllOriginators: (params: RequestParams = {}) =>
      this.request<MultiOriginatorsResponse, ErrorResponse>({
        path: `/api/originators`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method creating a single originator
     *
     * @tags Originator
     * @name CreateOriginator
     * @request POST:/api/originators
     * @secure
     */
    createOriginator: (data: OriginatorBase, params: RequestParams = {}) =>
      this.request<SingleOriginatorResponse, ErrorResponse>({
        path: `/api/originators`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method returning all originators with their related countries and spv settings
     *
     * @tags Originator
     * @name GetAllOriginatorsCountriesSpvs
     * @request GET:/api/originators/spvs
     * @secure
     */
    getAllOriginatorsCountriesSpvs: (params: RequestParams = {}) =>
      this.request<MultiOriginatorCountrySpvResponse, ErrorResponse>({
        path: `/api/originators/spvs`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method returning single originator with its related countries and spv settings
     *
     * @tags Originator
     * @name GetSingleOriginatorCountriesSpvs
     * @request GET:/api/originators/{id}/spvs
     * @secure
     */
    getSingleOriginatorCountriesSpvs: (id: number, params: RequestParams = {}) =>
      this.request<SingleOriginatorCountrySpvResponse, ErrorResponse>({
        path: `/api/originators/${id}/spvs`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method returning a single originator by id
     *
     * @tags Originator
     * @name GetOriginatorById
     * @request GET:/api/originators/{id}
     * @secure
     */
    getOriginatorById: (id: number, params: RequestParams = {}) =>
      this.request<SingleOriginatorResponse, ErrorResponse>({
        path: `/api/originators/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method updating a single originator
     *
     * @tags Originator
     * @name UpdateOriginator
     * @request PUT:/api/originators/{id}
     * @secure
     */
    updateOriginator: (id: number, data: OriginatorBase, params: RequestParams = {}) =>
      this.request<SingleOriginatorResponse, ErrorResponse>({
        path: `/api/originators/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method deleting a single originator
     *
     * @tags Originator
     * @name DeleteOriginator
     * @request DELETE:/api/originators/{id}
     * @secure
     */
    deleteOriginator: (id: number, params: RequestParams = {}) =>
      this.request<void, ErrorResponse>({
        path: `/api/originators/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description This is a method returning a single originator settings by id
     *
     * @tags Originator
     * @name GetOriginatorSettingsById
     * @request GET:/api/originators/{id}/settings
     * @secure
     */
    getOriginatorSettingsById: (id: number, params: RequestParams = {}) =>
      this.request<SingleOriginatorSettingsResponse, ErrorResponse>({
        path: `/api/originators/${id}/settings`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method updating a single originator settings
     *
     * @tags Originator
     * @name UpdateOriginatorSettings
     * @request PUT:/api/originators/{id}/settings
     * @secure
     */
    updateOriginatorSettings: (id: number, data: OriginatorSettings, params: RequestParams = {}) =>
      this.request<SingleOriginatorSettingsResponse, ErrorResponse>({
        path: `/api/originators/${id}/settings`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is method returning all users assigned to originator
     *
     * @tags UserOriginators
     * @name GetOriginatorUsers
     * @request GET:/api/originators/{id}/users
     * @secure
     */
    getOriginatorUsers: (id: number, params: RequestParams = {}) =>
      this.request<MultiUserDataResponse, ErrorResponse>({
        path: `/api/originators/${id}/users`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is method returning all originator groups objects
     *
     * @tags OriginatorGroups
     * @name GetAllOriginatorGroups
     * @request GET:/api/groups/originators
     * @secure
     */
    getAllOriginatorGroups: (params: RequestParams = {}) =>
      this.request<MultiOriginatorGroupsResponse, ErrorResponse>({
        path: `/api/groups/originators`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method creating a single originator group
     *
     * @tags OriginatorGroups
     * @name CreateOriginatorGroup
     * @request POST:/api/groups/originators
     * @secure
     */
    createOriginatorGroup: (data: OriginatorGroupBase, params: RequestParams = {}) =>
      this.request<SingleOriginatorGroupResponse, ErrorResponse>({
        path: `/api/groups/originators`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method returning a single originator group by id
     *
     * @tags OriginatorGroups
     * @name GetOriginatorGroupById
     * @request GET:/api/groups/{id}/originators
     * @secure
     */
    getOriginatorGroupById: (id: number, params: RequestParams = {}) =>
      this.request<SingleOriginatorGroupResponse, ErrorResponse>({
        path: `/api/groups/${id}/originators`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method updating a single group setting
     *
     * @tags OriginatorGroups
     * @name UpdateOriginatorGroup
     * @request PUT:/api/groups/{id}/originators
     * @secure
     */
    updateOriginatorGroup: (id: number, data: OriginatorGroupBase, params: RequestParams = {}) =>
      this.request<SingleOriginatorGroupResponse, ErrorResponse>({
        path: `/api/groups/${id}/originators`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method deleting a single originator group
     *
     * @tags OriginatorGroups
     * @name DeleteOriginatorGroup
     * @request DELETE:/api/groups/{id}/originators
     * @secure
     */
    deleteOriginatorGroup: (id: number, params: RequestParams = {}) =>
      this.request<void, ErrorResponse>({
        path: `/api/groups/${id}/originators`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description This is a method returning all spvs
     *
     * @tags Spv
     * @name GetAllSpvs
     * @request GET:/api/spvs
     * @secure
     */
    getAllSpvs: (params: RequestParams = {}) =>
      this.request<MultiSpvsResponse, ErrorResponse>({
        path: `/api/spvs`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method creating a single spv
     *
     * @tags Spv
     * @name CreateSpv
     * @request POST:/api/spvs
     * @secure
     */
    createSpv: (data: SpvBase, params: RequestParams = {}) =>
      this.request<SingleSpvResponse, ErrorResponse>({
        path: `/api/spvs`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method returning a single spv
     *
     * @tags Spv
     * @name GetSpvById
     * @request GET:/api/spvs/{id}
     * @secure
     */
    getSpvById: (id: number, params: RequestParams = {}) =>
      this.request<SingleSpvResponse, ErrorResponse>({
        path: `/api/spvs/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method updating a single spv
     *
     * @tags Spv
     * @name UpdateSpv
     * @request PUT:/api/spvs/{id}
     * @secure
     */
    updateSpv: (id: number, data: SpvBase, params: RequestParams = {}) =>
      this.request<SingleSpvResponse, ErrorResponse>({
        path: `/api/spvs/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method deleting a single spv
     *
     * @tags Spv
     * @name DeleteSpv
     * @request DELETE:/api/spvs/{id}
     * @secure
     */
    deleteSpv: (id: number, params: RequestParams = {}) =>
      this.request<void, ErrorResponse>({
        path: `/api/spvs/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description This is method returning all investment setting objects
     *
     * @tags InvestmentSetting
     * @name GetAllInvestmentSettings
     * @request GET:/api/investment-settings
     * @secure
     */
    getAllInvestmentSettings: (params: RequestParams = {}) =>
      this.request<MultiInvestmentSettingsResponse, ErrorResponse>({
        path: `/api/investment-settings`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method creating a single investment setting
     *
     * @tags InvestmentSetting
     * @name CreateInvestmentSetting
     * @request POST:/api/investment-settings
     * @secure
     */
    createInvestmentSetting: (data: InvestmentSettingBase, params: RequestParams = {}) =>
      this.request<SingleInvestmentSettingResponse, ErrorResponse>({
        path: `/api/investment-settings`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method returning a single investment setting by id
     *
     * @tags InvestmentSetting
     * @name GetInvestmentSettingById
     * @request GET:/api/investment-settings/{id}
     * @secure
     */
    getInvestmentSettingById: (id: number, params: RequestParams = {}) =>
      this.request<SingleInvestmentSettingResponse, ErrorResponse>({
        path: `/api/investment-settings/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method updating a investment setting
     *
     * @tags InvestmentSetting
     * @name UpdateInvestmentSetting
     * @request PUT:/api/investment-settings/{id}
     * @secure
     */
    updateInvestmentSetting: (
      id: number,
      data: InvestmentSettingBase,
      params: RequestParams = {},
    ) =>
      this.request<SingleInvestmentSettingResponse, ErrorResponse>({
        path: `/api/investment-settings/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method deleting a single investment setting
     *
     * @tags InvestmentSetting
     * @name DeleteInvestmentSetting
     * @request DELETE:/api/investment-settings/{id}
     * @secure
     */
    deleteInvestmentSetting: (id: number, params: RequestParams = {}) =>
      this.request<void, ErrorResponse>({
        path: `/api/investment-settings/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description This is method returning all originator countries for a given originator by id
     *
     * @tags OriginatorCountries
     * @name GetOriginatorCountries
     * @request GET:/api/originators/{originatorId}/countries
     * @secure
     */
    getOriginatorCountries: (originatorId: number, params: RequestParams = {}) =>
      this.request<MultiOriginatorCountriesResponse, ErrorResponse>({
        path: `/api/originators/${originatorId}/countries`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method creating a single originator country for a given originator by id
     *
     * @tags OriginatorCountries
     * @name CreateOriginatorCountry
     * @request POST:/api/originators/{originatorId}/countries
     * @secure
     */
    createOriginatorCountry: (
      originatorId: number,
      data: OriginatorCountryBase,
      params: RequestParams = {},
    ) =>
      this.request<SingleOriginatorCountryResponse, ErrorResponse>({
        path: `/api/originators/${originatorId}/countries`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method returning a single originator country by id for a given originator by id
     *
     * @tags OriginatorCountries
     * @name GetOriginatorCountryById
     * @request GET:/api/originators/{originatorId}/countries/{originatorCountryId}
     * @secure
     */
    getOriginatorCountryById: (
      originatorId: number,
      originatorCountryId: number,
      params: RequestParams = {},
    ) =>
      this.request<SingleOriginatorCountryResponse, ErrorResponse>({
        path: `/api/originators/${originatorId}/countries/${originatorCountryId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method updating a single originator country for a given originator by id
     *
     * @tags OriginatorCountries
     * @name UpdateOriginatorCountry
     * @request PUT:/api/originators/{originatorId}/countries/{originatorCountryId}
     * @secure
     */
    updateOriginatorCountry: (
      originatorId: number,
      originatorCountryId: number,
      data: OriginatorCountryBase,
      params: RequestParams = {},
    ) =>
      this.request<SingleOriginatorCountryResponse, ErrorResponse>({
        path: `/api/originators/${originatorId}/countries/${originatorCountryId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method deleting a single originator country object for a given originator by id
     *
     * @tags OriginatorCountries
     * @name DeleteOriginatorCountry
     * @request DELETE:/api/originators/{originatorId}/countries/{originatorCountryId}
     * @secure
     */
    deleteOriginatorCountry: (
      originatorId: number,
      originatorCountryId: number,
      params: RequestParams = {},
    ) =>
      this.request<void, ErrorResponse>({
        path: `/api/originators/${originatorId}/countries/${originatorCountryId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description This is method returning all originator country spv settings for a given originator country by id
     *
     * @tags OriginatorCountrySpvSettings
     * @name GetOriginatorCountrySpvSettings
     * @request GET:/api/originators/{originatorId}/countries/{originatorCountryId}/spvs
     * @secure
     */
    getOriginatorCountrySpvSettings: (
      originatorId: number,
      originatorCountryId: number,
      params: RequestParams = {},
    ) =>
      this.request<MultiOriginatorCountrySpvSettingsResponse, ErrorResponse>({
        path: `/api/originators/${originatorId}/countries/${originatorCountryId}/spvs`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method creating a single originator country spv setting for a given originator country by id
     *
     * @tags OriginatorCountrySpvSettings
     * @name CreateOriginatorCountrySpvSetting
     * @request POST:/api/originators/{originatorId}/countries/{originatorCountryId}/spvs
     * @secure
     */
    createOriginatorCountrySpvSetting: (
      originatorId: number,
      originatorCountryId: number,
      data: OriginatorCountrySpvSettingBase,
      params: RequestParams = {},
    ) =>
      this.request<SingleOriginatorCountrySpvSettingResponse, ErrorResponse>({
        path: `/api/originators/${originatorId}/countries/${originatorCountryId}/spvs`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method returning a single originator country spv setting by id for a given originator country by id
     *
     * @tags OriginatorCountrySpvSettings
     * @name GetOriginatorCountrySpvSettingById
     * @request GET:/api/originators/{originatorId}/countries/{originatorCountryId}/spvs/{spvSettingId}
     * @secure
     */
    getOriginatorCountrySpvSettingById: (
      originatorId: number,
      originatorCountryId: number,
      spvSettingId: number,
      params: RequestParams = {},
    ) =>
      this.request<SingleOriginatorCountrySpvSettingResponse, ErrorResponse>({
        path: `/api/originators/${originatorId}/countries/${originatorCountryId}/spvs/${spvSettingId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method updating an originator country spv setting by id for a given originator country by id
     *
     * @tags OriginatorCountrySpvSettings
     * @name UpdateOriginatorCountrySpvSetting
     * @request PUT:/api/originators/{originatorId}/countries/{originatorCountryId}/spvs/{spvSettingId}
     * @secure
     */
    updateOriginatorCountrySpvSetting: (
      originatorId: number,
      originatorCountryId: number,
      spvSettingId: number,
      data: OriginatorCountrySpvSettingBase,
      params: RequestParams = {},
    ) =>
      this.request<SingleOriginatorCountrySpvSettingResponse, ErrorResponse>({
        path: `/api/originators/${originatorId}/countries/${originatorCountryId}/spvs/${spvSettingId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method deleting a single originator country spv setting by id for a given originator country by id
     *
     * @tags OriginatorCountrySpvSettings
     * @name DeleteOriginatorCountrySpvSetting
     * @request DELETE:/api/originators/{originatorId}/countries/{originatorCountryId}/spvs/{spvSettingId}
     * @secure
     */
    deleteOriginatorCountrySpvSetting: (
      originatorId: number,
      originatorCountryId: number,
      spvSettingId: number,
      params: RequestParams = {},
    ) =>
      this.request<void, ErrorResponse>({
        path: `/api/originators/${originatorId}/countries/${originatorCountryId}/spvs/${spvSettingId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description This is method returning all score class objects
     *
     * @tags ScoreClass
     * @name GetAllScoreClasses
     * @request GET:/api/score-classes
     * @secure
     */
    getAllScoreClasses: (params: RequestParams = {}) =>
      this.request<MultiScoreClassResponse, ErrorResponse>({
        path: `/api/score-classes`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method creating a single score class
     *
     * @tags ScoreClass
     * @name CreateScoreClass
     * @request POST:/api/score-classes
     * @secure
     */
    createScoreClass: (data: ScoreClassBase, params: RequestParams = {}) =>
      this.request<SingleScoreClassResponse, ErrorResponse>({
        path: `/api/score-classes`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method returning a single score class by id
     *
     * @tags ScoreClass
     * @name GetScoreClassById
     * @request GET:/api/score-classes/{id}
     * @secure
     */
    getScoreClassById: (id: number, params: RequestParams = {}) =>
      this.request<SingleScoreClassResponse, ErrorResponse>({
        path: `/api/score-classes/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method updating a single score classes by id
     *
     * @tags ScoreClass
     * @name UpdateScoreClass
     * @request PUT:/api/score-classes/{id}
     * @secure
     */
    updateScoreClass: (id: number, data: ScoreClassBase, params: RequestParams = {}) =>
      this.request<SingleScoreClassResponse, ErrorResponse>({
        path: `/api/score-classes/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method deleting a single score class
     *
     * @tags ScoreClass
     * @name DeleteScoreClass
     * @request DELETE:/api/score-classes/{id}
     * @secure
     */
    deleteScoreClass: (id: number, params: RequestParams = {}) =>
      this.request<void, ErrorResponse>({
        path: `/api/score-classes/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description This is method returning all score class objects
     *
     * @tags ScoreClass
     * @name GetDefaultScoreClasses
     * @request GET:/api/originators/{originatorId}/default-score-classes
     * @secure
     */
    getDefaultScoreClasses: (originatorId: number, params: RequestParams = {}) =>
      this.request<MultiScoreClassResponse, ErrorResponse>({
        path: `/api/originators/${originatorId}/default-score-classes`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is method returning all score class objects for a given originator by id
     *
     * @tags OriginatorScoreClasses
     * @name GetOriginatorScoreClasses
     * @request GET:/api/originators/{originatorId}/score-classes
     * @secure
     */
    getOriginatorScoreClasses: (originatorId: number, params: RequestParams = {}) =>
      this.request<MultiOriginatorScoreClassesResponse, ErrorResponse>({
        path: `/api/originators/${originatorId}/score-classes`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method returning a single score class by id for a given originator by id
     *
     * @tags OriginatorScoreClasses
     * @name GetOriginatorScoreClassById
     * @request GET:/api/originators/{originatorId}/score-classes/{originatorScoreClassId}
     * @secure
     */
    getOriginatorScoreClassById: (
      originatorId: number,
      originatorScoreClassId: number,
      params: RequestParams = {},
    ) =>
      this.request<SingleOriginatorScoreClassResponse, ErrorResponse>({
        path: `/api/originators/${originatorId}/score-classes/${originatorScoreClassId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is method returning all score class objects for a given originator by id
     *
     * @tags OriginatorScoreClassMargins
     * @name GetOriginatorScoreClassMargins
     * @request GET:/api/originators/{originatorId}/score-class-margins
     * @secure
     */
    getOriginatorScoreClassMargins: (originatorId: number, params: RequestParams = {}) =>
      this.request<MultiOriginatorScoreClassMarginsResponse, ErrorResponse>({
        path: `/api/originators/${originatorId}/score-class-margins`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method creating a single score class for a given originator by id
     *
     * @tags OriginatorScoreClassMargins
     * @name CreateOriginatorScoreClassMargins
     * @request POST:/api/originators/{originatorId}/score-class-margins
     * @secure
     */
    createOriginatorScoreClassMargins: (
      originatorId: number,
      data: OriginatorScoreClassMarginCreate,
      params: RequestParams = {},
    ) =>
      this.request<SingleOriginatorScoreClassMarginResponse, ErrorResponse>({
        path: `/api/originators/${originatorId}/score-class-margins`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method returning a single score class by id for a given originator by id
     *
     * @tags OriginatorScoreClassMargins
     * @name GetOriginatorScoreClassMarginsById
     * @request GET:/api/originators/{originatorId}/score-class-margins/{originatorScoreClassId}
     * @secure
     */
    getOriginatorScoreClassMarginsById: (
      originatorId: number,
      originatorScoreClassId: number,
      params: RequestParams = {},
    ) =>
      this.request<SingleOriginatorScoreClassMarginResponse, ErrorResponse>({
        path: `/api/originators/${originatorId}/score-class-margins/${originatorScoreClassId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method deleting a single score class by id for a given originator by id
     *
     * @tags OriginatorScoreClassMargins
     * @name DeleteOriginatorScoreClassMargins
     * @request DELETE:/api/originators/{originatorId}/score-class-margins/{originatorScoreClassId}
     * @secure
     */
    deleteOriginatorScoreClassMargins: (
      originatorId: number,
      originatorScoreClassId: number,
      params: RequestParams = {},
    ) =>
      this.request<void, ErrorResponse>({
        path: `/api/originators/${originatorId}/score-class-margins/${originatorScoreClassId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description This is a method updating a single score class by id for a given originator by id
     *
     * @tags OriginatorScoreClassMargins
     * @name UpdateOriginatorScoreClassMargins
     * @request PUT:/api/originators/{originatorId}/score-class-margins/{originatorScoreClassId}
     * @secure
     */
    updateOriginatorScoreClassMargins: (
      originatorId: number,
      originatorScoreClassId: number,
      data: OriginatorScoreClassMargin,
      params: RequestParams = {},
    ) =>
      this.request<SingleOriginatorScoreClassMarginResponse, ErrorResponse>({
        path: `/api/originators/${originatorId}/score-class-margins/${originatorScoreClassId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is method returning all products for a given originator by id
     *
     * @tags Product
     * @name GetOriginatorProducts
     * @request GET:/api/originators/{originatorId}/products
     * @secure
     */
    getOriginatorProducts: (originatorId: number, params: RequestParams = {}) =>
      this.request<MultiProductsResponse, ErrorResponse>({
        path: `/api/originators/${originatorId}/products`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method creating a single product to a given originator by id
     *
     * @tags Product
     * @name CreateProduct
     * @request POST:/api/originators/{originatorId}/products
     * @secure
     */
    createProduct: (originatorId: number, data: ProductBase, params: RequestParams = {}) =>
      this.request<SingleProductResponse, ErrorResponse>({
        path: `/api/originators/${originatorId}/products`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method returning a single product by id for a given originator by id
     *
     * @tags Product
     * @name GetProductById
     * @request GET:/api/originators/{originatorId}/products/{productId}
     * @secure
     */
    getProductById: (originatorId: number, productId: number, params: RequestParams = {}) =>
      this.request<SingleProductResponse, ErrorResponse>({
        path: `/api/originators/${originatorId}/products/${productId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method updating a single product for a given originator by id
     *
     * @tags Product
     * @name UpdateProduct
     * @request PUT:/api/originators/{originatorId}/products/{productId}
     * @secure
     */
    updateProduct: (
      originatorId: number,
      productId: number,
      data: ProductBase,
      params: RequestParams = {},
    ) =>
      this.request<SingleProductResponse, ErrorResponse>({
        path: `/api/originators/${originatorId}/products/${productId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method deleting a single product for a given originator by id
     *
     * @tags Product
     * @name DeleteProduct
     * @request DELETE:/api/originators/{originatorId}/products/{productId}
     * @secure
     */
    deleteProduct: (originatorId: number, productId: number, params: RequestParams = {}) =>
      this.request<void, ErrorResponse>({
        path: `/api/originators/${originatorId}/products/${productId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description This is a method creating a single draft product to a given originator by id
     *
     * @tags Product
     * @name CreateProductDraft
     * @request POST:/api/originators/{originatorId}/products/drafts
     * @secure
     */
    createProductDraft: (originatorId: number, data: ProductBase, params: RequestParams = {}) =>
      this.request<SingleProductResponse, ErrorResponse>({
        path: `/api/originators/${originatorId}/products/drafts`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method updating a single product draft for a given originator by id
     *
     * @tags Product
     * @name UpdateProductDraft
     * @request PUT:/api/originators/{originatorId}/products/{productId}/drafts
     * @secure
     */
    updateProductDraft: (
      originatorId: number,
      productId: number,
      data: ProductBase,
      params: RequestParams = {},
    ) =>
      this.request<SingleProductResponse, ErrorResponse>({
        path: `/api/originators/${originatorId}/products/${productId}/drafts`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method approving a single product draft for a given originator by id
     *
     * @tags Product
     * @name ApproveProductDraft
     * @request POST:/api/originators/{originatorId}/products/{productId}/drafts
     * @secure
     */
    approveProductDraft: (originatorId: number, productId: number, params: RequestParams = {}) =>
      this.request<SingleProductResponse, ErrorResponse>({
        path: `/api/originators/${originatorId}/products/${productId}/drafts`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method for retrieving all transactions by a given originator id with the ability to do advanced filtering. When filtering the transactions, send a TransactionDataCriteria model and a operator from FilterOperator model as JSON array in string representation (example below)
     *
     * @tags Transaction
     * @name GetOriginatorTransactions
     * @request GET:/api/originators/{originatorId}/transactions
     * @secure
     */
    getOriginatorTransactions: (
      originatorId: number,
      query?: {
        /**
         * Page size
         * @min 0
         * @default 10
         */
        pageSize?: number;
        /**
         * Page number
         * @min 0
         * @default 0
         */
        pageNumber?: number;
        /** [["createdAt",">","2021-10-07T08:35:26.963184Z[UTC]"],["loanId","=","123e4567-e89b-12d3-a456-426614174000"]] */
        filter?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<MultiTransactionResponse, ErrorResponse>({
        path: `/api/originators/${originatorId}/transactions`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method for generating a new transaction id
     *
     * @tags Import
     * @name GenerateTransactionId
     * @request GET:/api/import/generateTransactionId
     * @secure
     */
    generateTransactionId: (params: RequestParams = {}) =>
      this.request<TransactionIdResponse, ErrorResponse>({
        path: `/api/import/generateTransactionId`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method for saving the number of imported data for a transaction id
     *
     * @tags Import
     * @name CreateTransIdInfo
     * @request POST:/api/import/{transId}
     * @secure
     */
    createTransIdInfo: (transId: string, data: TransactionIdInfo, params: RequestParams = {}) =>
      this.request<void, ErrorResponse>({
        path: `/api/import/${transId}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description This is a method for incrementing the number of imported data for a transaction id
     *
     * @tags Import
     * @name IncrementTransIdInfo
     * @request PUT:/api/import/{transId}
     * @secure
     */
    incrementTransIdInfo: (transId: string, data: TransactionIdInfo, params: RequestParams = {}) =>
      this.request<void, ErrorResponse>({
        path: `/api/import/${transId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description This is a method for retrieving a transaction by id
     *
     * @tags Import
     * @name GetTransIdInfo
     * @request GET:/api/import/{transId}
     * @secure
     */
    getTransIdInfo: (transId: string, params: RequestParams = {}) =>
      this.request<TransactionResponse, ErrorResponse>({
        path: `/api/import/${transId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method for retrieving all transactions with the ability to do advanced filtering. When filtering the transactions, send a TransactionDataCriteria model and a operator from FilterOperator model as JSON array in string representation (example below)
     *
     * @tags Transaction
     * @name GetAllTransactions
     * @request GET:/api/transactions
     * @secure
     */
    getAllTransactions: (
      query?: {
        /**
         * Page size
         * @min 0
         * @default 10
         */
        pageSize?: number;
        /**
         * Page number
         * @min 0
         * @default 0
         */
        pageNumber?: number;
        /** [["loanId","=","123e4567-e89b-12d3-a456-426614174000"]] */
        filter?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<MultiTransactionResponse, ErrorResponse>({
        path: `/api/transactions`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method for updating the transaction status to PUBLISHED and triggering the publish flow
     *
     * @tags Transaction
     * @name PublishTransaction
     * @request PATCH:/api/transactions/{transId}
     * @secure
     */
    publishTransaction: (transId: string, data: TransactionUpdate, params: RequestParams = {}) =>
      this.request<void, ErrorResponse>({
        path: `/api/transactions/${transId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description This is a method for retrieving a transaction
     *
     * @tags Transaction
     * @name GetTransaction
     * @request GET:/api/transactions/{transId}
     * @secure
     */
    getTransaction: (transId: string, params: RequestParams = {}) =>
      this.request<TransactionResponse, ErrorResponse>({
        path: `/api/transactions/${transId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method for retrieving the transaction data. By specifying dataType, you choose which type of data to be returned. Specifying ALL will return all transaction data
     *
     * @tags Transaction
     * @name GetTransactionData
     * @request GET:/api/transactions/{transId}/{dataType}
     * @secure
     */
    getTransactionData: (
      transId: string,
      dataType: TransactionDataType,
      query?: {
        /** Filter transactions by hasError field */
        hasError?: boolean;
        /**
         * The loan id
         * @format uuid
         */
        loanId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<TransactionDataResponse, ErrorResponse>({
        path: `/api/transactions/${transId}/${dataType}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method for retrieving only loans for given transaction
     *
     * @tags Loans
     * @name GetAllLoansByTransId
     * @request GET:/api/transactions/{transId}/paged/loans
     * @secure
     */
    getAllLoansByTransId: (
      transId: string,
      query?: {
        /**
         * @min 0
         * @default 0
         */
        pageNumber?: number;
        /**
         * Page size
         * @min 0
         * @default 10
         */
        pageSize?: number;
        /** Filter transactions by hasError field */
        hasError?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<LoansDataResponse, ErrorResponse>({
        path: `/api/transactions/${transId}/paged/loans`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method for retrieving only pay schedules for given transaction
     *
     * @tags PaySchedules
     * @name GetAllPaySchedulesByTransId
     * @request GET:/api/transactions/{transId}/paged/pay-schedules
     * @secure
     */
    getAllPaySchedulesByTransId: (
      transId: string,
      query?: {
        /**
         * @min 0
         * @default 0
         */
        pageNumber?: number;
        /**
         * @min 0
         * @default 10
         */
        pageSize?: number;
        /** Filter transactions by hasError field */
        hasError?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaySchedulesDataResponse, ErrorResponse>({
        path: `/api/transactions/${transId}/paged/pay-schedules`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method for retrieving only payments for given transaction
     *
     * @tags Payments
     * @name GetAllPaymentsByTransId
     * @request GET:/api/transactions/{transId}/paged/payments
     * @secure
     */
    getAllPaymentsByTransId: (
      transId: string,
      query?: {
        /**
         * @min 0
         * @default 0
         */
        pageNumber?: number;
        /**
         * @min 0
         * @default 10
         */
        pageSize?: number;
        /** Filter transactions by hasError field */
        hasError?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaymentsDataResponse, ErrorResponse>({
        path: `/api/transactions/${transId}/paged/payments`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method for retrieving only fees for given transaction
     *
     * @tags Fees
     * @name GetAllFeesByTransId
     * @request GET:/api/transactions/{transId}/paged/fees
     * @secure
     */
    getAllFeesByTransId: (
      transId: string,
      query?: {
        /**
         * @min 0
         * @default 0
         */
        pageNumber?: number;
        /**
         * @min 0
         * @default 10
         */
        pageSize?: number;
        /** Filter transactions by hasError field */
        hasError?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<FeesDataResponse, ErrorResponse>({
        path: `/api/transactions/${transId}/paged/fees`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method for retrieving only fees for given transaction
     *
     * @tags BorrowerData
     * @name GetAllBorrowerDataByTransId
     * @request GET:/api/transactions/{transId}/paged/borrower-data
     * @secure
     */
    getAllBorrowerDataByTransId: (
      transId: string,
      query?: {
        /**
         * @min 0
         * @default 0
         */
        pageNumber?: number;
        /**
         * @min 0
         * @default 10
         */
        pageSize?: number;
        /** Filter transactions by hasError field */
        hasError?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<BorrowerDataResponse, ErrorResponse>({
        path: `/api/transactions/${transId}/paged/borrower-data`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method returning all borrower data fields
     *
     * @tags BorrowerDataField
     * @name GetAllBorrowerDataFields
     * @request GET:/api/borrowerDataFields
     * @secure
     */
    getAllBorrowerDataFields: (params: RequestParams = {}) =>
      this.request<MultiBorrowerDataFieldResponse, ErrorResponse>({
        path: `/api/borrowerDataFields`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method creating a single borrower data field
     *
     * @tags BorrowerDataField
     * @name CreateBorrowerDataField
     * @request POST:/api/borrowerDataFields
     * @secure
     */
    createBorrowerDataField: (data: BorrowerDataFieldBase, params: RequestParams = {}) =>
      this.request<SingleBorrowerDataFieldResponse, ErrorResponse>({
        path: `/api/borrowerDataFields`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method returning a single borrower data field by id
     *
     * @tags BorrowerDataField
     * @name GetBorrowerDataFieldbyId
     * @request GET:/api/borrowerDataFields/{id}
     * @secure
     */
    getBorrowerDataFieldbyId: (id: number, params: RequestParams = {}) =>
      this.request<SingleBorrowerDataFieldResponse, ErrorResponse>({
        path: `/api/borrowerDataFields/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method updating a single borrower data field
     *
     * @tags BorrowerDataField
     * @name UpdateBorrowerDataField
     * @request PUT:/api/borrowerDataFields/{id}
     * @secure
     */
    updateBorrowerDataField: (
      id: number,
      data: BorrowerDataFieldBase,
      params: RequestParams = {},
    ) =>
      this.request<SingleBorrowerDataFieldResponse, ErrorResponse>({
        path: `/api/borrowerDataFields/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method deleting a single borrower data field
     *
     * @tags BorrowerDataField
     * @name DeleteBorrowerDataField
     * @request DELETE:/api/borrowerDataFields/{id}
     * @secure
     */
    deleteBorrowerDataField: (id: number, params: RequestParams = {}) =>
      this.request<void, ErrorResponse>({
        path: `/api/borrowerDataFields/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description This is a method returning all borrower data fields for a given loan type
     *
     * @tags LoanBorrowerDataField
     * @name GetLoanBorrowerDataFields
     * @request GET:/api/loanTypes/{loanTypeId}/loanBorrowerDataFields
     * @secure
     */
    getLoanBorrowerDataFields: (loanTypeId: number, params: RequestParams = {}) =>
      this.request<LoanBorrowerDataFieldsResponse, ErrorResponse>({
        path: `/api/loanTypes/${loanTypeId}/loanBorrowerDataFields`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method for adding a borrower data field to a given loan type
     *
     * @tags LoanBorrowerDataField
     * @name CreateLoanBorrowerDataField
     * @request POST:/api/loanTypes/{loanTypeId}/loanBorrowerDataFields
     * @secure
     */
    createLoanBorrowerDataField: (
      loanTypeId: number,
      data: BorrowerDataFieldId,
      params: RequestParams = {},
    ) =>
      this.request<void, ErrorResponse>({
        path: `/api/loanTypes/${loanTypeId}/loanBorrowerDataFields`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description This is a method for removing a borrower data field from a given loan type
     *
     * @tags LoanBorrowerDataField
     * @name DeleteLoanBorrowerDataField
     * @request DELETE:/api/loanTypes/{loanTypeId}/loanBorrowerDataFields/{loanBorrowerDataFieldId}
     * @secure
     */
    deleteLoanBorrowerDataField: (
      loanTypeId: number,
      loanBorrowerDataFieldId: number,
      params: RequestParams = {},
    ) =>
      this.request<void, ErrorResponse>({
        path: `/api/loanTypes/${loanTypeId}/loanBorrowerDataFields/${loanBorrowerDataFieldId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description This is a method returning all borrower data fields for a given loan type
     *
     * @tags OriginatorLoanBorrowerDataField
     * @name GetOriginatorLoanBorrowerDataFields
     * @request GET:/api/originators/{originatorId}/loanTypes/{loanTypeId}/originatorLoanBorrowerDataFields
     * @secure
     */
    getOriginatorLoanBorrowerDataFields: (
      originatorId: number,
      loanTypeId: number,
      params: RequestParams = {},
    ) =>
      this.request<OriginatorLoanBorrowerDataFieldsResponse, ErrorResponse>({
        path: `/api/originators/${originatorId}/loanTypes/${loanTypeId}/originatorLoanBorrowerDataFields`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method for adding a borrower data field to a given loan type for a given originator
     *
     * @tags OriginatorLoanBorrowerDataField
     * @name CreateOriginatorLoanBorrowerDataField
     * @request POST:/api/originators/{originatorId}/loanTypes/{loanTypeId}/originatorLoanBorrowerDataFields
     * @secure
     */
    createOriginatorLoanBorrowerDataField: (
      originatorId: number,
      loanTypeId: number,
      data: BorrowerDataFieldId,
      params: RequestParams = {},
    ) =>
      this.request<void, ErrorResponse>({
        path: `/api/originators/${originatorId}/loanTypes/${loanTypeId}/originatorLoanBorrowerDataFields`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description This is a method for removing a borrower data field from a given loan type for a given originator
     *
     * @tags OriginatorLoanBorrowerDataField
     * @name DeleteOriginatorLoanBorrowerDataField
     * @request DELETE:/api/originators/{originatorId}/loanTypes/{loanTypeId}/originatorLoanBorrowerDataFields/{originatorLoanBorrowerDataFieldId}
     * @secure
     */
    deleteOriginatorLoanBorrowerDataField: (
      originatorId: number,
      loanTypeId: number,
      originatorLoanBorrowerDataFieldId: number,
      params: RequestParams = {},
    ) =>
      this.request<void, ErrorResponse>({
        path: `/api/originators/${originatorId}/loanTypes/${loanTypeId}/originatorLoanBorrowerDataFields/${originatorLoanBorrowerDataFieldId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description This is a method for returning all loans with the ability to do advanced filtering. When filtering the loans, send a LoanDataCriteria model and a operator from FilterOperator model as JSON array in string representation (example below)
     *
     * @tags Loans
     * @name GetAllLoans
     * @request GET:/api/loans
     * @secure
     */
    getAllLoans: (
      query?: {
        /**
         * Page size
         * @min 0
         * @default 10
         */
        pageSize?: number;
        /**
         * Page number
         * @min 0
         * @default 0
         */
        pageNumber?: number;
        /** [["insertedDate","between","[\"2021-10-07T08:35:26.963184Z[UTC]\",\"2022-10-07T08:35:26.963184Z[UTC]\"]"],["originatorId","=","15"],["paymentId","!=","123e4567-e89b-12d3-a456-426614174000"]] */
        filter?: string;
        /** ["paymentId","desc"] */
        sort?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<LoansDataResponse, ErrorResponse>({
        path: `/api/loans`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method for returning all pay schedules with the ability to do advanced filtering. When filtering the loans, send a LoanDataCriteria model and a operator from FilterOperator model as JSON array in string representation (example below)
     *
     * @tags PaySchedules
     * @name GetAllPaySchedules
     * @request GET:/api/pay-schedules
     * @secure
     */
    getAllPaySchedules: (
      query?: {
        /**
         * Page size
         * @min 0
         * @default 10
         */
        pageSize?: number;
        /**
         * Page number
         * @min 0
         * @default 0
         */
        pageNumber?: number;
        /** [["createdDate",">=","2021-10-07T08:35:26.963184Z[UTC]"],["loanId","=","123e4567-e89b-12d3-a456-426614174000"]] */
        filter?: string;
        /** ["paymentId","desc"] */
        sort?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaySchedulesDataResponse, ErrorResponse>({
        path: `/api/pay-schedules`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method for returning all payments with the ability to do advanced filtering. When filtering the loans, send a LoanDataCriteria model and a operator from FilterOperator model as JSON array in string representation (example below)
     *
     * @tags Payments
     * @name GetAllPayments
     * @request GET:/api/payments
     * @secure
     */
    getAllPayments: (
      query?: {
        /**
         * Page size
         * @min 0
         * @default 10
         */
        pageSize?: number;
        /**
         * Page number
         * @min 0
         * @default 0
         */
        pageNumber?: number;
        /** [["originatorId","in","[\"2\",\"5\"]"]] */
        filter?: string;
        /** ["paymentId","desc"] */
        sort?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaymentsDataResponse, ErrorResponse>({
        path: `/api/payments`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method for returning all fees with the ability to do advanced filtering. When filtering the loans, send a LoanDataCriteria model and a operator from FilterOperator model as JSON array in string representation (example below)
     *
     * @tags Fees
     * @name GetAllFees
     * @request GET:/api/fees
     * @secure
     */
    getAllFees: (
      query?: {
        /**
         * Page size
         * @min 0
         * @default 10
         */
        pageSize?: number;
        /**
         * Page number
         * @min 0
         * @default 0
         */
        pageNumber?: number;
        /** [["createdDate","<","2021-10-07T08:35:26.963184Z[UTC]"],["loanId","=","123e4567-e89b-12d3-a456-426614174000"]] */
        filter?: string;
        /** ["paymentId","desc"] */
        sort?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<FeesDataResponse, ErrorResponse>({
        path: `/api/fees`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method for returning all originator's loans with the ability to do advanced filtering. When filtering the loans, send a LoanDataCriteria model and a operator from FilterOperator model as JSON array in string representation (example below)
     *
     * @tags Loans
     * @name GetAllOriginatorLoans
     * @request GET:/api/originators/{originatorId}/loans
     * @secure
     */
    getAllOriginatorLoans: (
      originatorId: number,
      query?: {
        /**
         * Page size
         * @min 0
         * @default 10
         */
        pageSize?: number;
        /**
         * Page number
         * @min 0
         * @default 0
         */
        pageNumber?: number;
        /** [["insertedDate","between","[\"2021-10-07T08:35:26.963184Z[UTC]\",\"2022-10-07T08:35:26.963184Z[UTC]\"]"],["paymentId","!=","123e4567-e89b-12d3-a456-426614174000"]] */
        filter?: string;
        /** ["paymentId","desc"] */
        sort?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<LoansDataResponse, ErrorResponse>({
        path: `/api/originators/${originatorId}/loans`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method for returning all originator's pay-schedules with the ability to do advanced filtering. When filtering the loans, send a LoanDataCriteria model and a operator from FilterOperator model as JSON array in string representation (example below)
     *
     * @tags PaySchedules
     * @name GetAllOriginatorPaySchedules
     * @request GET:/api/originators/{originatorId}/pay-schedules
     * @secure
     */
    getAllOriginatorPaySchedules: (
      originatorId: number,
      query?: {
        /**
         * Page size
         * @min 0
         * @default 10
         */
        pageSize?: number;
        /**
         * Page number
         * @min 0
         * @default 0
         */
        pageNumber?: number;
        /** [["createdDate",">=","2021-10-07T08:35:26.963184Z[UTC]"],["loanId","=","123e4567-e89b-12d3-a456-426614174000"]] */
        filter?: string;
        /** ["paymentId","desc"] */
        sort?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaySchedulesDataResponse, ErrorResponse>({
        path: `/api/originators/${originatorId}/pay-schedules`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method for returning all originator's payments with the ability to do advanced filtering. When filtering the loans, send a LoanDataCriteria model and a operator from FilterOperator model as JSON array in string representation (example below)
     *
     * @tags Payments
     * @name GetAllOriginatorPayments
     * @request GET:/api/originators/{originatorId}/payments
     * @secure
     */
    getAllOriginatorPayments: (
      originatorId: number,
      query?: {
        /**
         * Page size
         * @min 0
         * @default 10
         */
        pageSize?: number;
        /**
         * Page number
         * @min 0
         * @default 0
         */
        pageNumber?: number;
        /** [["createdDate",">=","2021-10-07T08:35:26.963184Z[UTC]"],["loanId","=","123e4567-e89b-12d3-a456-426614174000"]] */
        filter?: string;
        /** ["paymentId","desc"] */
        sort?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaymentsDataResponse, ErrorResponse>({
        path: `/api/originators/${originatorId}/payments`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method for returning all originator's fees with the ability to do advanced filtering. When filtering the loans, send a LoanDataCriteria model and a operator from FilterOperator model as JSON array in string representation (example below)
     *
     * @tags Fees
     * @name GetAllOriginatorFees
     * @request GET:/api/originators/{originatorId}/fees
     * @secure
     */
    getAllOriginatorFees: (
      originatorId: number,
      query?: {
        /**
         * Page size
         * @min 0
         * @default 10
         */
        pageSize?: number;
        /**
         * Page number
         * @min 0
         * @default 0
         */
        pageNumber?: number;
        /** [["createdDate",">=","2021-10-07T08:35:26.963184Z[UTC]"],["loanId","=","123e4567-e89b-12d3-a456-426614174000"]] */
        filter?: string;
        /** ["paymentId","desc"] */
        sort?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<FeesDataResponse, ErrorResponse>({
        path: `/api/originators/${originatorId}/fees`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method for a loan export generation and its state persistence
     *
     * @tags LoanExports
     * @name CreateLoanExport
     * @request POST:/api/exports
     * @secure
     */
    createLoanExport: (
      query?: {
        /** [["createdDate",">=","2021-10-07T08:35:26.963184Z[UTC]"],["loanId","=","123e4567-e89b-12d3-a456-426614174000"]] */
        filter?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<SingleLoanExportCreateResponse, ErrorResponse>({
        path: `/api/exports`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method that returns the exported file
     *
     * @tags LoanExports
     * @name DownloadLoanExportFile
     * @request GET:/api/exports/{exportId}/download
     * @secure
     */
    downloadLoanExportFile: (exportId: number, params: RequestParams = {}) =>
      this.request<File, ErrorResponse>({
        path: `/api/exports/${exportId}/download`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description This is a method for a loan export generation of a given originator and its state persistence
     *
     * @tags LoanExports
     * @name CreateOriginatorLoanExport
     * @request POST:/api/originators/{originatorId}/exports
     * @secure
     */
    createOriginatorLoanExport: (
      originatorId: number,
      query?: {
        /** [["createdDate",">=","2021-10-07T08:35:26.963184Z[UTC]"],["loanId","=","123e4567-e89b-12d3-a456-426614174000"]] */
        filter?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<SingleLoanExportCreateResponse, ErrorResponse>({
        path: `/api/originators/${originatorId}/exports`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
}
