import React, { useContext, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';

import { getCurrentUser } from '../../api/controllers/UsersController';
import { displayFlex } from '../../styles/commonStyles';
import { SET_ORIGINATOR_ID, SET_USER } from '../../utils/constants/actions';
import { Context } from '../../utils/store';
import { BreadcrumbsView } from '../common/breadcrumb/BreadcrumbsView';
import { MainContentView } from './MainContentView';
import { MainHeaderView } from './MainHeaderView';
import { SidebarView } from './sidebar/SidebarView';

export const MainLayout = () => {
  const { dispatch, state } = useContext(Context);
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);

  const toggleDrawer = (): void => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  const currentUser = getCurrentUser();
  const firstOriginator = currentUser?.originators && currentUser?.originators[0];

  useEffect(() => {
    if (currentUser) {
      dispatch({
        type: SET_USER,
        payload: currentUser,
      });
    }
  }, [currentUser?.user?.id]);

  useEffect(() => {
    dispatch({
      type: SET_ORIGINATOR_ID,
      payload: firstOriginator?.id as number,
    });
  }, [firstOriginator]);

  return (
    <>
      {state.user?.user?.id ? (
        <>
          <CssBaseline />
          <Box sx={displayFlex}>
            <MainHeaderView toggleDrawer={toggleDrawer} />
            <SidebarView visible={isSidebarVisible} />
            <MainContentView sidebarVisible={isSidebarVisible}>
              <BreadcrumbsView />
              <Outlet />
            </MainContentView>
          </Box>
        </>
      ) : null}
    </>
  );
};
