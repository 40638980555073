import React from 'react';

import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';

import {
  popoverErrorStyles,
  popoverPaperStyles,
  popoverWarningStyles,
} from '../../styles/popoverStyles';
import { ERROR_LABEL, WARNING_LABEL } from '../constants/labels';
import { DataGridError } from '../interfaces/grid/DataGridError';

export const getDataGridError = (value: DataGridError) => {
  return (
    <>
      {value.errorDesc && (
        <>
          <Paper sx={popoverErrorStyles}>{ERROR_LABEL.toUpperCase()}</Paper>
          <Divider />
          <Paper sx={popoverPaperStyles}>{value.errorDesc}</Paper>
        </>
      )}
      {value.warning && (
        <>
          <Paper sx={popoverWarningStyles}>{WARNING_LABEL.toUpperCase()}</Paper>
          <Divider />
          <Paper sx={popoverPaperStyles}>{value.warning}</Paper>
        </>
      )}
    </>
  );
};
