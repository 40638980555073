import {
  APP_MODE_ADD_IF_MISSING_ID,
  APP_MODE_OVERRIDE_ID,
  APP_MODE_VALIDATE_ID,
} from '../../utils/constants/identifiers';
import {
  APP_MODE_ADD_IF_MISSING_LABEL,
  APP_MODE_OVERRIDE_LABEL,
  APP_MODE_VALIDATE_LABEL,
} from '../../utils/constants/labels';

export const ApplicationModeMockData = [
  {
    id: APP_MODE_VALIDATE_ID,
    name: APP_MODE_VALIDATE_LABEL,
  },
  {
    id: APP_MODE_ADD_IF_MISSING_ID,
    name: APP_MODE_ADD_IF_MISSING_LABEL,
  },
  {
    id: APP_MODE_OVERRIDE_ID,
    name: APP_MODE_OVERRIDE_LABEL,
  },
];
