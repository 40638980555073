import { GridColumns } from '@mui/x-data-grid-pro';

import { EMPTY_VALUE } from '../../../../utils/constants/common';
import {
  COUNT_ID,
  NAME_ID,
  PROCESSED_ID,
  SUBMITTED_ID,
} from '../../../../utils/constants/identifiers';
import { GRID_ACTIONS_COLUMN_FLEX } from '../../../../utils/constants/styles';
import {
  COUNT_LABEL,
  PROCESSED_LABEL,
  SUBMITTED_LABEL,
} from './../../../../utils/constants/labels';
import { GridColType } from './../../../../utils/enums/GridColType';

export const infoColumns: GridColumns = [
  { field: NAME_ID, headerName: EMPTY_VALUE, flex: GRID_ACTIONS_COLUMN_FLEX },
  {
    field: COUNT_ID,
    headerName: COUNT_LABEL,
    flex: GRID_ACTIONS_COLUMN_FLEX,
    type: GridColType.Number,
  },
  {
    field: PROCESSED_ID,
    headerName: PROCESSED_LABEL,
    flex: GRID_ACTIONS_COLUMN_FLEX,
    type: GridColType.Number,
  },
  {
    field: SUBMITTED_ID,
    headerName: SUBMITTED_LABEL,
    flex: GRID_ACTIONS_COLUMN_FLEX,
    type: GridColType.Number,
  },
];
