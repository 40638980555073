import React from 'react';
import { useForm } from 'react-hook-form';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { formGridSpacing } from '../../../../styles/formStyles';
import { FORM_TAG } from '../../../../utils/constants/components';
import {
  ADDRESS_ID,
  CEO_ID,
  COUNTRY_OF_REGISTRATION_ID,
  LEGAL_NAME_ID,
  NAME_ID,
  REGISTRATION_NUMBER_ID,
  TRADE_NAME_ID,
  UPDATE_GROUP_FORM_ID,
} from '../../../../utils/constants/identifiers';
import {
  ADDRESS_LABEL,
  CEO_LABEL,
  COUNTRY_OF_REGISTRATION_LABEL,
  LEGAL_NAME_LABEL,
  NAME_LABEL,
  REGISTRATION_NUMBER_LABEL,
  TRADE_NAME_LABEL,
} from '../../../../utils/constants/labels';
import { UpdateGroupFormProps } from '../../../../utils/interfaces/group/UpdateGroupFormProps';
import { InputField } from '../../../common/form/InputField';
import { SelectField } from '../../../common/form/SelectField';
import { LargeGridField } from '../../../common/form/grid/LargeGridField';
import { MediumGridField } from '../../../common/form/grid/MediumGridField';

export const UpdateGroupForm = (props: UpdateGroupFormProps) => {
  const { onSubmit, group, countries } = props;

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
  } = useForm();

  return (
    <Box component={FORM_TAG} id={UPDATE_GROUP_FORM_ID} onSubmit={handleSubmit(onSubmit)}>
      <Grid container rowSpacing={1} columnSpacing={formGridSpacing}>
        <MediumGridField>
          <InputField
            id={NAME_ID}
            label={NAME_LABEL}
            register={register}
            errors={errors}
            defaultValue={group.name}
            required
          />
        </MediumGridField>
        <MediumGridField>
          <InputField
            id={LEGAL_NAME_ID}
            label={LEGAL_NAME_LABEL}
            register={register}
            errors={errors}
            defaultValue={group.legalName}
            required
          />
        </MediumGridField>
        <MediumGridField>
          <InputField
            id={TRADE_NAME_ID}
            label={TRADE_NAME_LABEL}
            register={register}
            errors={errors}
            defaultValue={group.tradeName}
            required
          />
        </MediumGridField>
        <MediumGridField>
          <InputField
            id={REGISTRATION_NUMBER_ID}
            label={REGISTRATION_NUMBER_LABEL}
            register={register}
            errors={errors}
            defaultValue={group.registrationNumber}
            required
          />
        </MediumGridField>
        <MediumGridField>
          <InputField
            id={CEO_ID}
            label={CEO_LABEL}
            register={register}
            errors={errors}
            defaultValue={group.ceo}
            required
          />
        </MediumGridField>
        <MediumGridField>
          <SelectField
            id={COUNTRY_OF_REGISTRATION_ID}
            label={COUNTRY_OF_REGISTRATION_LABEL}
            choices={countries}
            control={control}
            defaultValue={group.countryOfRegistrationId}
            required
          />
        </MediumGridField>
        <LargeGridField>
          <InputField
            id={ADDRESS_ID}
            label={ADDRESS_LABEL}
            register={register}
            errors={errors}
            defaultValue={group.address}
            required
          />
        </LargeGridField>
      </Grid>
    </Box>
  );
};
