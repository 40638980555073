import React from 'react';

import { PaymentInfo } from '../../../../../api/types/originatorTypes';
import { paymentsColumns } from '../../../../../common/tableColumns/transactions/transactionDetails/paymentsColumns';
import { PAY_ID } from '../../../../../utils/constants/identifiers';
import { getGridColumns } from '../../../../../utils/helpers/getGridColumns';
import { DataGridView } from '../../../../common/grid/DataGridView';

export const TransactionPaymentsTab = (props: { data: PaymentInfo[] }) => {
  const { data } = props;

  return (
    <DataGridView
      data={data}
      columns={getGridColumns(paymentsColumns)}
      rowId={PAY_ID}
      disableSelectionOnClick
      applyErrorOnRows
    />
  );
};
