import React from 'react';

import TextField from '@mui/material/TextField';

import { EMPTY_VALUE } from '../../../utils/constants/common';
import { REQUIRED_FIELD_MESSAGE } from '../../../utils/constants/messages';
import { DECIMAL_ACCURACY } from '../../../utils/constants/options';
import { FORM_FIELD_MARGIN } from '../../../utils/constants/styles';
import { InputFieldType } from '../../../utils/enums/InputFieldType';
import { convertExponentialToDecimal } from '../../../utils/helpers/commonHelper';
import {
  decimalValidator,
  helperTextHandler,
  integerValidator,
} from '../../../utils/helpers/formFieldsHelper';
import { NumberInputFieldProps } from '../../../utils/interfaces/form/NumberInputFieldProps';

export const NumberInputField = (props: NumberInputFieldProps) => {
  const {
    id,
    label,
    register,
    errors,
    defaultValue,
    minNumber,
    maxNumber,
    required,
    decimalAccuracy,
    inputType,
    allowedValues,
    lowerValue,
    biggerValue,
  } = props;

  const validDecimalAccuracy = decimalAccuracy ? decimalAccuracy : DECIMAL_ACCURACY;

  return (
    <TextField
      margin={FORM_FIELD_MARGIN}
      id={id}
      label={label}
      InputLabelProps={{ required: required }}
      {...register(id, {
        required: required && REQUIRED_FIELD_MESSAGE,
        validate: (value: number | string) => {
          switch (inputType) {
            case InputFieldType.Decimal:
              return decimalValidator(
                value,
                minNumber,
                maxNumber,
                decimalAccuracy,
                lowerValue,
                biggerValue,
              );
            case InputFieldType.Number:
              return integerValidator(value, minNumber, maxNumber, allowedValues);
            default:
              return undefined;
          }
        },
        // First check if value is empty and then parse it, beacause if we parse it to number from string
        // we gonna get NaN and cant proceed with validations.
        setValueAs: (value) => (value === EMPTY_VALUE ? value : Number(value)),
      })}
      defaultValue={defaultValue && convertExponentialToDecimal(defaultValue)}
      error={Boolean(errors[id])}
      helperText={helperTextHandler(errors, id)}
      fullWidth
      inputProps={{
        type: InputFieldType.Float,
        maxLength:
          // Adding 2 here because the length is capturing first 2 decimals: 0.141 - it adds "0 and ." to the length.
          inputType === InputFieldType.Decimal ? validDecimalAccuracy + 2 : Number.MAX_SAFE_INTEGER,
      }}
    />
  );
};
