import { GridColumns } from '@mui/x-data-grid-pro';

import {
  COUNTRY_ID,
  FEE,
  ORIGINATOR_NAME_ID,
  SPV_FACILITY_AGREEMENT_NUMBER,
  SPV_ID,
  SPV_VALID_TO_DATE_ID,
} from '../../../utils/constants/identifiers';
import {
  COUNTRY_LABEL,
  FACILITY_AGREEMENT_NUMBER_LABEL,
  FEE_LABEL,
  ORIGINATOR_LABEL,
  SPV_LABEL,
  VALID_FROM_LABEL,
} from '../../../utils/constants/labels';
import { GRID_ACTIONS_COLUMN_FLEX } from '../../../utils/constants/styles';
import { GridColType } from '../../../utils/enums/GridColType';

export const originatorsSpvsColumns: GridColumns = [
  { field: ORIGINATOR_NAME_ID, headerName: ORIGINATOR_LABEL, flex: GRID_ACTIONS_COLUMN_FLEX },
  { field: COUNTRY_ID, headerName: COUNTRY_LABEL, flex: GRID_ACTIONS_COLUMN_FLEX },
  {
    field: SPV_ID,
    headerName: SPV_LABEL,
    flex: GRID_ACTIONS_COLUMN_FLEX,
  },
  {
    field: FEE,
    headerName: FEE_LABEL,
    flex: GRID_ACTIONS_COLUMN_FLEX,
    type: GridColType.Decimal,
  },
  {
    field: SPV_FACILITY_AGREEMENT_NUMBER,
    headerName: FACILITY_AGREEMENT_NUMBER_LABEL,
    flex: GRID_ACTIONS_COLUMN_FLEX,
    type: GridColType.Number,
  },
  {
    field: SPV_VALID_TO_DATE_ID,
    headerName: VALID_FROM_LABEL,
    type: GridColType.Date,
  },
];
