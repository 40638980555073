import React from 'react';

import { GridColumns } from '@mui/x-data-grid-pro';

import { CountryOfRegistrationCell } from '../../../../components/common/grid/gridCells/CountryOfRegistrationCell';
import {
  COUNTRY,
  COUNTRY_ID,
  ID,
  INTEREST_RATE_MAX_ID,
  INTEREST_RATE_MIN_ID,
  LOAN_TYPE_ID,
  NAME_ID,
} from '../../../../utils/constants/identifiers';
import {
  COUNTRY_LABEL,
  ID_LABEL,
  INTEREST_RATE_MAX_LABEL,
  INTEREST_RATE_MIN_LABEL,
  LOAN_TYPE_LABEL,
  NAME_LABEL,
} from '../../../../utils/constants/labels';
import { GRID_ACTIONS_COLUMN_FLEX } from '../../../../utils/constants/styles';
import { GridColType } from '../../../../utils/enums/GridColType';
import { getPercentValue } from '../../../../utils/helpers/commonHelper';

export const originatorProductsColumns: GridColumns = [
  {
    field: ID,
    headerName: ID_LABEL,
    type: GridColType.Number,
  },
  {
    field: NAME_ID,
    headerName: NAME_LABEL,
    type: GridColType.Number,
    flex: GRID_ACTIONS_COLUMN_FLEX,
  },
  {
    field: COUNTRY,
    headerName: COUNTRY_LABEL,
    renderCell: (value) => (
      <CountryOfRegistrationCell countryOfRegistrationId={value.row[COUNTRY_ID]} />
    ),
    flex: GRID_ACTIONS_COLUMN_FLEX,
  },
  {
    field: LOAN_TYPE_ID,
    headerName: LOAN_TYPE_LABEL,
    type: GridColType.Number,
    flex: GRID_ACTIONS_COLUMN_FLEX,
  },
  {
    field: INTEREST_RATE_MIN_ID,
    headerName: INTEREST_RATE_MIN_LABEL,
    valueGetter: ({ value }) => getPercentValue(value),
    type: GridColType.Number,
    flex: GRID_ACTIONS_COLUMN_FLEX,
  },
  {
    field: INTEREST_RATE_MAX_ID,
    headerName: INTEREST_RATE_MAX_LABEL,
    valueGetter: ({ value }) => getPercentValue(value),
    type: GridColType.Number,
    flex: GRID_ACTIONS_COLUMN_FLEX,
  },
];
