import React from 'react';

import TextField from '@mui/material/TextField';

import { REQUIRED_FIELD_MESSAGE } from '../../../utils/constants/messages';
import { FORM_FIELD_MARGIN } from '../../../utils/constants/styles';
import {
  handleRequiredFields,
  handleSpacesForRequiredFields,
} from '../../../utils/helpers/formFieldsHelper';
import { InputFieldProps } from '../../../utils/interfaces/form/InputFieldProps';

export const InputField = (props: InputFieldProps) => {
  const { id, label, register, errors, defaultValue, required } = props;

  return (
    <TextField
      id={id}
      label={label}
      defaultValue={defaultValue}
      InputLabelProps={{ required: required }}
      error={Boolean(errors[id])}
      helperText={handleRequiredFields(errors, id)}
      margin={FORM_FIELD_MARGIN}
      fullWidth
      {...register(id, {
        required: required && REQUIRED_FIELD_MESSAGE,
        validate: handleSpacesForRequiredFields,
      })}
    />
  );
};
