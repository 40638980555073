import React from 'react';

import Grid from '@mui/material/Grid';

import { GridOptions } from '../../../utils/enums/GridOptions';
import { BorrowerDataTabsView } from '../../views/borrowerData/BorrowerDataTabsView';

export const BorrowerDataScreen = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={GridOptions.ExtraLarge} lg={GridOptions.Large}>
        <BorrowerDataTabsView />
      </Grid>
    </Grid>
  );
};
