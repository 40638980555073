import { useContext } from 'react';

import {
  COUNT_FEE_CHILD_COL,
  COUNT_LOAN_CHILD_KEY,
  COUNT_PAYMENT_CHILD_COL,
  COUNT_PS_CHILD_COL,
  ERROR_CHILD_KEY,
  ORIGINATOR_CHILD_KEY,
  PROCESSED_FEE_CHILD_COL,
  PROCESSED_LOAN_CHILD_KEY,
  PROCESSED_PAYMENT_CHILD_COL,
  PROCESSED_PS_CHILD_COL,
  STATUS_CHILD_COL,
  SUBMITTED_FEE_CHILD_COL,
  SUBMITTED_LOAN_CHILD_KEY,
  SUBMITTED_PAYMENT_CHILD_COL,
  SUBMITTED_PS_CHILD_COL,
  WARNING_CHILD_KEY,
} from '../../../utils/constants/keys';
import {
  COUNT_LABEL,
  ERRORS_LABEL,
  ORIGINATOR_LABEL,
  PROCESSED_LABEL,
  STATUS_LABEL,
  SUBMITTED_LABEL,
  WARNINGS_LABEL,
} from '../../../utils/constants/labels';
import { ColumnDefinition } from '../../../utils/interfaces/table/ColumnDefinition';
import { Context } from '../../../utils/store';
import { ContextType } from '../../../utils/store/types/ContextType';

export const transactionChildColumns = (): ColumnDefinition[] => {
  const { state } = useContext<ContextType>(Context);

  if (!state.originatorID || state.user?.user?.isSystemUser) {
    return [...originatorColumn, ...columns];
  }

  return columns;
};

const columns: ColumnDefinition[] = [
  { key: ERROR_CHILD_KEY, label: ERRORS_LABEL },
  { key: WARNING_CHILD_KEY, label: WARNINGS_LABEL, applyBorderRight: true },
  { key: COUNT_LOAN_CHILD_KEY, label: COUNT_LABEL },
  { key: PROCESSED_LOAN_CHILD_KEY, label: PROCESSED_LABEL },
  { key: SUBMITTED_LOAN_CHILD_KEY, label: SUBMITTED_LABEL, applyBorderRight: true },
  { key: COUNT_PS_CHILD_COL, label: COUNT_LABEL },
  { key: PROCESSED_PS_CHILD_COL, label: PROCESSED_LABEL },
  { key: SUBMITTED_PS_CHILD_COL, label: SUBMITTED_LABEL, applyBorderRight: true },
  { key: COUNT_PAYMENT_CHILD_COL, label: COUNT_LABEL },
  { key: PROCESSED_PAYMENT_CHILD_COL, label: PROCESSED_LABEL },
  { key: SUBMITTED_PAYMENT_CHILD_COL, label: SUBMITTED_LABEL, applyBorderRight: true },
  { key: COUNT_FEE_CHILD_COL, label: COUNT_LABEL },
  { key: PROCESSED_FEE_CHILD_COL, label: PROCESSED_LABEL },
  { key: SUBMITTED_FEE_CHILD_COL, label: SUBMITTED_LABEL, applyBorderRight: true },
  { key: STATUS_CHILD_COL, label: STATUS_LABEL, hasStatus: true },
];

const originatorColumn: ColumnDefinition[] = [
  { key: ORIGINATOR_CHILD_KEY, label: ORIGINATOR_LABEL, applyBorderRight: true },
];
