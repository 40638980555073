import * as React from 'react';

import { originatorColumns } from '../../../../common/tableColumns/originators/originatorColumns';
import { getGridColumns } from '../../../../utils/helpers/getGridColumns';
import { DataGridActionProps } from '../../../../utils/interfaces/grid/DataGridActionProps';
import { DataGridView } from '../../../common/grid/DataGridView';

export const OriginatorsDataGrid = <T extends object>(props: DataGridActionProps<T>) => {
  const { data, handleRowClick } = props;

  return (
    <DataGridView
      data={data}
      columns={getGridColumns(originatorColumns)}
      handleRowClick={handleRowClick}
    />
  );
};
