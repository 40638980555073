import React from 'react';

import { DataGridProProps, GridColumns } from '@mui/x-data-grid-pro';

import { getOriginatorSpvs } from '../../../../../../api/controllers/OriginatorSpvController';
import { originatorCountrySpvColumns } from '../../../../../../common/tableColumns/originators/details/originatorCountrySpvColumns';
import { EMPTY_VALUE, GRID_ACTIONS } from '../../../../../../utils/constants/common';
import { COUNTRY_LABEL } from '../../../../../../utils/constants/labels';
import { GRID_ACTIONS_COLUMN_FLEX } from '../../../../../../utils/constants/styles';
import { GridColType } from '../../../../../../utils/enums/GridColType';
import { GridColWidth } from '../../../../../../utils/enums/GridColWidth';
import { getGridColumns } from '../../../../../../utils/helpers/getGridColumns';
import { getOriginatorCountrySpvData } from '../../../../../../utils/helpers/getOriginatorCountrySpvData';
import { spvGridActionsHelper } from '../../../../../../utils/helpers/spvGridActionHelper';
import { OriginatorSpvTableProps } from '../../../../../../utils/interfaces/originator/details/OriginatorSpvTableProps';
import { SpvTableColumnProps } from '../../../../../../utils/interfaces/originator/details/SpvTableColumnProps';
import { DataGridView } from '../../../../../common/grid/DataGridView';

export const OriginatorSpvTableView = (props: OriginatorSpvTableProps) => {
  const { originatorId, handleEdit, handleDelete, permissionType } = props;

  const originatorCountryListSpvs = getOriginatorSpvs(originatorId);

  const data = getOriginatorCountrySpvData(originatorCountryListSpvs?.countryList);

  const actionColumns: GridColumns = [
    {
      field: GRID_ACTIONS,
      type: GridColType.Actions,
      width: GridColWidth.Small,
      flex: GRID_ACTIONS_COLUMN_FLEX,
      getActions: ({ id }) =>
        spvGridActionsHelper(
          data as SpvTableColumnProps[],
          id,
          permissionType,
          handleEdit,
          handleDelete,
        ),
    },
  ];

  const columns = [...originatorCountrySpvColumns, ...actionColumns];

  const getTreeDataPath: DataGridProProps['getTreeDataPath'] = (row) => row.country;

  const groupingColDef: DataGridProProps['groupingColDef'] = {
    headerName: COUNTRY_LABEL,
    valueGetter: ({ row }) => row.country && (row.country.length !== 1 ? EMPTY_VALUE : row.country),
  };

  return (
    <DataGridView
      data={data ? data : []}
      columns={getGridColumns(columns)}
      treeData
      getTreeDataPath={getTreeDataPath}
      groupingColDef={groupingColDef}
    />
  );
};
