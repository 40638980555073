import React from 'react';

import TextField from '@mui/material/TextField';

import { authFieldStyles } from '../../../styles/authFormStyles';
import { EMAIL_FIELD_ID } from '../../../utils/constants/identifiers';
import { EMAIL_LABEL } from '../../../utils/constants/labels';
import {
  INVALID_EMAIL_ADDRESS_MESSAGE,
  REQUIRED_FIELD_MESSAGE,
} from '../../../utils/constants/messages';
import { FORM_FIELD_MARGIN } from '../../../utils/constants/styles';
import { INVALID_EMAIL_REGEX_PATTERN } from '../../../utils/constants/validations';
import {
  handleRequiredFields,
  handleSpacesForRequiredFields,
} from '../../../utils/helpers/formFieldsHelper';
import { FormFieldProps } from '../../../utils/interfaces/form/FormFieldProps';

export const EmailField = (props: FormFieldProps) => {
  const { register, errors } = props;

  return (
    <TextField
      margin={FORM_FIELD_MARGIN}
      id={EMAIL_FIELD_ID}
      label={EMAIL_LABEL}
      InputLabelProps={{ required: true }}
      {...register(EMAIL_FIELD_ID, {
        required: REQUIRED_FIELD_MESSAGE,
        pattern: {
          value: INVALID_EMAIL_REGEX_PATTERN,
          message: INVALID_EMAIL_ADDRESS_MESSAGE,
        },
        validate: handleSpacesForRequiredFields,
      })}
      error={Boolean(errors.email)}
      helperText={handleRequiredFields(errors, EMAIL_FIELD_ID)}
      sx={authFieldStyles}
    />
  );
};
