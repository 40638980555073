export const borrowerDataLoanFieldAddBtnStyles = {
  mt: 1,
  mr: 2,
  ml: 1,
  height: '38px',
};

export const borrowerDataFormGridStyles = {
  border: '1px dashed #7070704D',
  pb: 1,
  pl: 2,
  mt: 3,
};

export const borrowerDataSelectFieldStyles = {
  width: '90%',
};
