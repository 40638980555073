import React, { useContext } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { updateOriginator } from '../../../../api/controllers/OriginatorController';
import { Country } from '../../../../api/types/nomenclatureTypes';
import { Originator, OriginatorBase, OriginatorGroup } from '../../../../api/types/originatorTypes';
import { originatorDetailsFields } from '../../../../common/details/originatorDetailsFields';
import { tabToolbarStyles, tabToolbarTitleStyles } from '../../../../styles/commonStyles';
import {
  originatorDetailsCardStyles,
  originatorDetailsEditButtonStyles,
} from '../../../../styles/originatorDetailsStyles';
import { OPEN_FORM_DIALOG } from '../../../../utils/constants/actions';
import {
  BUSINESS_STATUS_ID,
  COUNTRY_OF_REGISTRATION_ID,
  ORIGINATOR_GROUP,
  UPDATE_ORIGINATOR_FORM_ID,
} from '../../../../utils/constants/identifiers';
import { ORIGINATOR_DETAILS_LABEL } from '../../../../utils/constants/labels';
import { TOOLBAR_DENSE_VARIANT } from '../../../../utils/constants/options';
import { ORIGINATORS_PERMISSION } from '../../../../utils/constants/permissions';
import { UPDATE_ORIGINATOR_TITLE } from '../../../../utils/constants/titles';
import { FormDialogSubmitType } from '../../../../utils/enums/FormDialogSubmitType';
import { closeFormHandler } from '../../../../utils/helpers/closeFormHandler';
import { getBusinessStatus } from '../../../../utils/helpers/commonHelper';
import { getCountryOfRegistration } from '../../../../utils/helpers/commonHelper';
import { usePermissions } from '../../../../utils/hooks/permissions/usePermissions';
import { SelectorChoicesType } from '../../../../utils/interfaces/form/SelectorChoicesType';
import { Context } from '../../../../utils/store';
import { ContextType } from '../../../../utils/store/types/ContextType';
import { UpdateOriginatorForm } from '../forms/UpdateOriginatorForm';
import { StyledDetail } from './StyledDetail';

export const OriginatorDetailsView = (props: {
  originator: Originator;
  countries: Country[];
  groups: OriginatorGroup[];
}) => {
  const { originator, countries, groups } = props;
  const { dispatch } = useContext<ContextType>(Context);

  if (!originator || !originator.countryOfRegistrationId) {
    return <></>;
  }

  const countryOfRegistration = getCountryOfRegistration(originator.countryOfRegistrationId);
  const businessStatus = getBusinessStatus(originator.businessStatusId);
  const permissions = usePermissions();

  const editMutation = updateOriginator(originator.id as number);

  const handleSubmit = (data: OriginatorBase): void => {
    editMutation.mutateAsync(data).then((response) => {
      closeFormHandler(dispatch, response.status);
    });
  };

  const handleEditClick = (): void => {
    dispatch({
      type: OPEN_FORM_DIALOG,
      payload: {
        title: UPDATE_ORIGINATOR_TITLE,
        content: (
          <UpdateOriginatorForm
            onSubmit={handleSubmit}
            originator={originator}
            countries={countries as SelectorChoicesType[]}
            groups={groups as SelectorChoicesType[]}
          />
        ),
        formId: UPDATE_ORIGINATOR_FORM_ID,
        submitType: FormDialogSubmitType.Edit,
      },
    });
  };

  return (
    <Card sx={originatorDetailsCardStyles}>
      <CardContent>
        <Toolbar variant={TOOLBAR_DENSE_VARIANT} sx={tabToolbarStyles} disableGutters>
          <Typography sx={tabToolbarTitleStyles} gutterBottom>
            {ORIGINATOR_DETAILS_LABEL}
          </Typography>
          {permissions.hasUpdatePermission(ORIGINATORS_PERMISSION) && (
            <IconButton onClick={handleEditClick}>
              <EditIcon sx={originatorDetailsEditButtonStyles} />
            </IconButton>
          )}
        </Toolbar>
        {originatorDetailsFields.map((x, index) => {
          let value = originator && originator[x.id];

          switch (x.id) {
            case COUNTRY_OF_REGISTRATION_ID:
              value = countryOfRegistration?.name;
              break;
            case ORIGINATOR_GROUP:
              value = originator.originatorGroup?.name;
              break;
            case BUSINESS_STATUS_ID:
              value = businessStatus?.name;
              break;
          }

          return <StyledDetail key={index} title={x.title} value={value} />;
        })}
      </CardContent>
    </Card>
  );
};
