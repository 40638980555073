import { GridColumns } from '@mui/x-data-grid-pro';

import {
  BUSINESS_DAY_ID,
  FEE_AMOUNT_ID,
  FEE_DATE_ID,
  FEE_PAY_DATE_ID,
  LOAN_ID,
  ORIGINATOR_ID,
  PAY_AMOUNT_ID,
  TRANS_ID,
  TYPE,
} from '../../../utils/constants/identifiers';
import {
  BUSINESS_DAY_LABEL,
  FEE_AMOUNT_LABEL,
  FEE_DATE_LABEL,
  FEE_TYPE_LABEL,
  LOAN_ID_LABEL,
  ORIGINATOR_LABEL,
  PAY_AMOUNT_LABEL,
  PAY_DATE_LABEL,
  TRANS_ID_LABEL,
} from '../../../utils/constants/labels';
import { GRID_ACTIONS_COLUMN_FLEX } from '../../../utils/constants/styles';
import { GridColType } from '../../../utils/enums/GridColType';

export const feesTabColumns: GridColumns = [
  { field: LOAN_ID, headerName: LOAN_ID_LABEL, flex: GRID_ACTIONS_COLUMN_FLEX },
  { field: FEE_AMOUNT_ID, headerName: FEE_AMOUNT_LABEL, flex: GRID_ACTIONS_COLUMN_FLEX },
  {
    field: PAY_AMOUNT_ID,
    headerName: PAY_AMOUNT_LABEL,
    flex: GRID_ACTIONS_COLUMN_FLEX,
    type: GridColType.Number,
  },
  {
    field: FEE_PAY_DATE_ID,
    headerName: PAY_DATE_LABEL,
    flex: GRID_ACTIONS_COLUMN_FLEX,
    type: GridColType.Date,
  },
  {
    field: TYPE,
    headerName: FEE_TYPE_LABEL,
    flex: GRID_ACTIONS_COLUMN_FLEX,
    type: GridColType.Number,
  },
  {
    field: BUSINESS_DAY_ID,
    headerName: BUSINESS_DAY_LABEL,
    flex: GRID_ACTIONS_COLUMN_FLEX,
    type: GridColType.Date,
  },
  {
    field: FEE_DATE_ID,
    headerName: FEE_DATE_LABEL,
    flex: GRID_ACTIONS_COLUMN_FLEX,
    type: GridColType.Date,
  },
  {
    field: ORIGINATOR_ID,
    headerName: ORIGINATOR_LABEL,
    flex: GRID_ACTIONS_COLUMN_FLEX,
    type: GridColType.Number,
  },
  {
    field: TRANS_ID,
    headerName: TRANS_ID_LABEL,
    flex: GRID_ACTIONS_COLUMN_FLEX,
  },
];
