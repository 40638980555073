import React from 'react';
import { useContext } from 'react';

import { Menu, MenuItem } from '@mui/material';

import {
  accountMenuAnchorOrigin,
  accountMenuTransformOrigin,
} from '../../styles/accountMenuStyles';
import { OPEN_FORM_DIALOG } from '../../utils/constants/actions';
import { ACCOUNT_MENU_ID, ACCOUNT_MENU_ITEM_CHANGE_PASSWORD } from '../../utils/constants/common';
import { CHANGE_PASSWORD_FORM_ID } from '../../utils/constants/identifiers';
import { CHANGE_PASSWORD_TITLE } from '../../utils/constants/titles';
import { FormDialogSubmitType } from '../../utils/enums/FormDialogSubmitType';
import { AccountMenuProps } from '../../utils/interfaces/layout/AccountMenuProps';
import { Context } from '../../utils/store';
import { ChangePassword } from '../views/authentication/ChangePassword';

export const AccountMenu = (props: AccountMenuProps) => {
  const { dispatch } = useContext(Context);
  const { anchorEl, handleClose, isOpen } = props;

  const handleChangePasswordClick = (): void => {
    dispatch({
      type: OPEN_FORM_DIALOG,
      payload: {
        title: CHANGE_PASSWORD_TITLE,
        content: <ChangePassword />,
        formId: CHANGE_PASSWORD_FORM_ID,
        submitType: FormDialogSubmitType.Send,
      },
    });
  };

  return (
    <Menu
      anchorEl={anchorEl}
      id={ACCOUNT_MENU_ID}
      open={isOpen}
      onClose={handleClose}
      onClick={handleClose}
      transformOrigin={accountMenuTransformOrigin}
      anchorOrigin={accountMenuAnchorOrigin}
    >
      <MenuItem onClick={handleChangePasswordClick}>{ACCOUNT_MENU_ITEM_CHANGE_PASSWORD}</MenuItem>
    </Menu>
  );
};
