import React from 'react';

import { BorrowerDataInfo } from '../../../../../api/types/originatorTypes';
import { borrowerDataColumns } from '../../../../../common/tableColumns/transactions/transactionDetails/borrowerDataColumns';
import { LOAN_ID } from '../../../../../utils/constants/identifiers';
import { getGridColumns } from '../../../../../utils/helpers/getGridColumns';
import { DataGridView } from '../../../../common/grid/DataGridView';

export const TransactionBorrowerDataTab = (props: { data: BorrowerDataInfo[] }) => {
  const { data } = props;

  return (
    <DataGridView
      data={data}
      columns={getGridColumns(borrowerDataColumns)}
      rowId={LOAN_ID}
      disableSelectionOnClick
      applyErrorOnRows
    />
  );
};
