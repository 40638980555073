export const chooseFileButtonStyle = {
  height: '40px',
  minWidth: '100%',
};

export const directSubmitStyles = {
  pl: 1,
  pt: 2,
};

export const inputFieldAlignment = {
  display: 'inline-block',
  verticalAlign: 'top',
};

export const dropFileStyle = {
  ...inputFieldAlignment,
  marginLeft: '20px',
  justifyContent: 'center',
};

export const fileUploadBoxStyles = { display: 'flex', alignItems: 'center', minWidth: '100%' };

export const fileOptionsStyles = {
  fontWeight: 'bold',
  pl: 1,
};
