interface EnvironmentConfig {
  originatorServiceApiUrl: string;
  nomenclatureServiceApiUrl: string;
  importerServiceApiUrl: string;
  keycloakClientId: string;
  keycloakBaseUrl: string;
  keycloakRealm: string;
  keycloakTokenStrategyKey: string;
  keycloakRefreshTokenStrategyKey: string;
  environment: string;
  gatewayBaseUrl: string;
  muiLicenseKey: string;
}

export const appConfig: EnvironmentConfig = {
  originatorServiceApiUrl: process.env.REACT_APP_ORIGINATOR_SERVICE_API_URL || '',
  nomenclatureServiceApiUrl: process.env.REACT_APP_NOMENCLATURE_SERVICE_API_URL || '',
  importerServiceApiUrl: process.env.REACT_APP_IMPORTER_SERVICE_API_URL || '',
  keycloakBaseUrl: process.env.REACT_APP_KEYCLOAK_BASE_URL || '',
  keycloakClientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID || '',
  keycloakRealm: process.env.REACT_APP_KEYCLOAK_REALM || '',
  keycloakTokenStrategyKey: process.env.REACT_APP_KEYCLOAK_TOKEN_STRATEGY_KEY || '',
  keycloakRefreshTokenStrategyKey: process.env.REACT_APP_KEYCLOAK_REFRESH_TOKEN_STRATEGY_KEY || '',
  environment: process.env.REACT_APP_ENVIRONMENT || '',
  gatewayBaseUrl: process.env.REACT_APP_GATEWAY_BASE_URL || '',
  muiLicenseKey: process.env.REACT_APP_MUI_LICENSE_KEY || '',
};
