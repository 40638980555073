import React from 'react';

import { PaySchedulesDataResponse } from '../../../../../api/types/originatorTypes';
import { paymnetsScheduleTabColumns } from '../../../../../common/tableColumns/loans/PaymentSchedulesTabColumns';
import { ID } from '../../../../../utils/constants/identifiers';
import { getGridColumns } from '../../../../../utils/helpers/getGridColumns';
import { sortModelChange } from '../../../../../utils/helpers/sorting';
import { LoansPaymentSchedulesTableProps } from '../../../../../utils/interfaces/loans/LoansPaymentSchedulesTableProps';
import { DataGridView } from '../../../../common/grid/DataGridView';

export const LoansPaymentSchedulesTableView = (props: LoansPaymentSchedulesTableProps) => {
  const { paymentSchedules, filters, setNewSort } = props;

  return (
    <DataGridView
      rowId={ID}
      data={(paymentSchedules as PaySchedulesDataResponse)?.data || null}
      dataCount={(paymentSchedules as PaySchedulesDataResponse)?.page?.totalElements}
      columns={getGridColumns(paymnetsScheduleTabColumns)}
      disableColumnMenu={true}
      tableSettings={filters?.tableSettings}
      disableColumnResize={false}
      paginationMode="server"
      setNewSort={setNewSort}
      sortModelChange={sortModelChange}
      sortingMode="server"
    />
  );
};
