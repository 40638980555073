import React, { useContext } from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import { GridColumns, GridRowId } from '@mui/x-data-grid-pro';

import {
  deleteBorrowerDataLoanTypeField,
  deleteOriginatorBDLoanField,
} from '../../../../../api/controllers/BorrowerDataController';
import { mapBorrowerLoanFields } from '../../../../../api/mappers/BorrowerDataMapper';
import { borrowerDataLoanFieldsColumns } from '../../../../../common/tableColumns/borrowerData/borrowerDataLoanFieldsColumns';
import { CLOSE_CONFIRMATION_DIALOG } from '../../../../../utils/constants/actions';
import { GRID_ACTIONS } from '../../../../../utils/constants/common';
import { GridColType } from '../../../../../utils/enums/GridColType';
import { BorrowerDataLoanFieldsProps } from '../../../../../utils/interfaces/borrowerData/loans/BorrowerDataLoanFieldsProps';
import { DeleteBorrowerDataLoanFieldRequest } from '../../../../../utils/interfaces/borrowerData/loans/DeleteBorrowerDataLoanFieldRequest';
import { DeleteOriginatorBDLoanRequest } from '../../../../../utils/interfaces/borrowerData/loans/DeleteOriginatorBDLoanRequest';
import { Context } from '../../../../../utils/store';
import { ContextType } from '../../../../../utils/store/types/ContextType';
import { GridActionsView } from '../../../../common/grid/GridActionsView';
import { FormGridView } from '../../../../common/grid/formGrid/FormGridView';

export const BorrowerDataLoanFieldsTable = (props: BorrowerDataLoanFieldsProps) => {
  const { fields, loanTypeId, originatorId, permissionType } = props;
  const { dispatch } = useContext<ContextType>(Context);

  const borrowerLoanFields = mapBorrowerLoanFields(fields);

  const deleteLoanFieldMutation = deleteBorrowerDataLoanTypeField();
  const deleteOriginatorLoanFieldMutation = deleteOriginatorBDLoanField();

  const handleDelete = (id: GridRowId): void => {
    const borrowerDataRequest = {
      loanTypeId: loanTypeId,
      borrowerDataFieldId: id,
    } as DeleteBorrowerDataLoanFieldRequest;

    const originatorBDRequest = {
      originatorId: originatorId,
      loanTypeId: loanTypeId,
      originatorLoanBorrowerDataFieldId: id,
    } as DeleteOriginatorBDLoanRequest;

    originatorId
      ? deleteOriginatorLoanFieldMutation.mutateAsync(originatorBDRequest)
      : deleteLoanFieldMutation.mutateAsync(borrowerDataRequest);

    dispatch({ type: CLOSE_CONFIRMATION_DIALOG });
  };

  const actions: GridColumns = [
    {
      field: GRID_ACTIONS,
      type: GridColType.Actions,
      getActions: ({ id }) => [
        <GridActionsView
          key={id}
          CustomDeleteIcon={ClearIcon}
          handleDeleteClick={() => (handleDelete ? handleDelete(id) : null)}
          permissionType={permissionType}
        />,
      ],
    },
  ];

  const columns = [...borrowerDataLoanFieldsColumns, ...actions];

  return <FormGridView columns={columns} data={borrowerLoanFields} />;
};
