import { KeycloakConfigCustom } from 'virava';

import { appConfig } from './app.config';

export const authConfig: KeycloakConfigCustom = {
  clientId: appConfig.keycloakClientId,
  baseUrl: appConfig.keycloakBaseUrl,
  realm: appConfig.keycloakRealm,
  tokenStorageKey: appConfig.keycloakTokenStrategyKey,
  refreshTokenStorageKey: appConfig.keycloakRefreshTokenStrategyKey,
  gatewayBaseUrl: appConfig.gatewayBaseUrl,
};
