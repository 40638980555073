import React, { useContext } from 'react';
import { FieldValues } from 'react-hook-form';
import { useNavigate } from 'react-router';

import { getAllCountries } from '../../../../api/controllers/NomenclatureController';
import {
  createOriginator,
  getAllOriginators,
} from '../../../../api/controllers/OriginatorController';
import { getAllOriginatorGroups } from '../../../../api/controllers/OriginatorGroupController';
import { OriginatorBase } from '../../../../api/types/originatorTypes';
import { OPEN_FORM_DIALOG } from '../../../../utils/constants/actions';
import { ADD_ORIGINATOR_BUTTON } from '../../../../utils/constants/buttons';
import { CREATE_ORIGINATOR_FORM_ID } from '../../../../utils/constants/identifiers';
import { ORIGINATORS_PERMISSION } from '../../../../utils/constants/permissions';
import { ROUTE_PATH_ORIGINATORS } from '../../../../utils/constants/routes';
import { ADD_ORIGINATOR_TITLE } from '../../../../utils/constants/titles';
import { FormDialogSubmitType } from '../../../../utils/enums/FormDialogSubmitType';
import { closeFormHandler } from '../../../../utils/helpers/closeFormHandler';
import { usePermissions } from '../../../../utils/hooks/permissions/usePermissions';
import { SelectorChoicesType } from '../../../../utils/interfaces/form/SelectorChoicesType';
import { Context } from '../../../../utils/store';
import { ContextType } from '../../../../utils/store/types/ContextType';
import { GridHeaderView } from '../../../common/grid/GridHeaderView';
import { CreateOriginatorForm } from '../../../views/originators/forms/CreateOriginatorForm';
import { OriginatorsDataGrid } from '../../../views/originators/tables/OriginatorsDataGrid';

export const OriginatorsScreen = () => {
  const { dispatch } = useContext<ContextType>(Context);
  const history = useNavigate();

  const originators = getAllOriginators();
  const countries = getAllCountries();
  const groups = getAllOriginatorGroups();
  const createMutation = createOriginator();
  const permissions = usePermissions();

  const handleSubmit = (data: FieldValues): void => {
    createMutation.mutateAsync(data as OriginatorBase).then((response) => {
      closeFormHandler(dispatch, response.status);
    });
  };

  const handleAddClick = (): void => {
    dispatch({
      type: OPEN_FORM_DIALOG,
      payload: {
        title: ADD_ORIGINATOR_TITLE,
        content: (
          <CreateOriginatorForm
            onSubmit={handleSubmit}
            countries={countries as SelectorChoicesType[]}
            groups={groups as SelectorChoicesType[]}
          />
        ),
        formId: CREATE_ORIGINATOR_FORM_ID,
        submitType: FormDialogSubmitType.Add,
      },
    });
  };

  const handleRowClick = (id: number) => {
    if (permissions.hasReadPermission(ORIGINATORS_PERMISSION)) {
      history(`${ROUTE_PATH_ORIGINATORS}/${id}`);
    }
  };

  return (
    <>
      <GridHeaderView
        buttonLabel={ADD_ORIGINATOR_BUTTON}
        onButtonClick={handleAddClick}
        permissionType={ORIGINATORS_PERMISSION}
      />
      <OriginatorsDataGrid data={originators} handleRowClick={handleRowClick} />
    </>
  );
};
