import { Breadcrumb } from '../interfaces/breadcrumb/Breadcrumb';

export const extractBreadcrumbs = (location: string): Breadcrumb[] => {
  // Splitting location into crumbs
  // example -> /dashboard/admin/originators becomes ['dashboard', 'admin', 'originators']
  while (location.charAt(location.length - 1) === '/') {
    location = location.slice(0, -1);
  }

  const crumbParts = location.split('/').splice(1);
  const crumbObjects: Breadcrumb[] = [];

  for (let index = crumbParts.length - 1; index >= 0; index--) {
    const crumb = crumbParts[index];

    // Check if crumb includes numbers and pass it without changing the value, otherway removing the '-' symbol if current crumb has it.
    // Uppercasing the first letter of crumb even if its two worded.
    const name = /\d/.test(crumb)
      ? crumb
      : crumb
          .split('-')
          .map((x) => x[0].toUpperCase() + x.slice(1))
          .join(' ');

    // Taking the correct part of the location.
    // Index + 1 to take the last part of route, otherwise its cutted.
    let path = location.split(crumbParts[index + 1])[0];

    if (path.charAt(path.length - 1) === '/') {
      path = path.slice(0, -1);
    }

    const tmpCrumb: Breadcrumb = {
      name,
      path,
    };

    crumbObjects.push(tmpCrumb);
  }

  return crumbObjects.reverse();
};
