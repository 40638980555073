import React, { Dispatch, SetStateAction, useContext } from 'react';

import { GridSortDirection } from '@mui/x-data-grid-pro';

import {
  getAllOriginatorPaySchedules,
  getAllPaySchedules,
} from '../../../../../api/controllers/LoansController';
import { BeFiltersInterface } from '../../../../../utils/interfaces/filter/BeFiltersInterface';
import { SortingState } from '../../../../../utils/interfaces/transaction/SortingState';
import { Context } from '../../../../../utils/store';
import { LoansPaymentSchedulesTableView } from './LoansPaymentScheduleTableView';

export const LoansPaymentSchedulesTab = (props: {
  filters: BeFiltersInterface;
  sort: { sorting: SortingState; setSorting: Dispatch<SetStateAction<SortingState>> };
}) => {
  const { filters, sort } = props;
  const { state } = useContext(Context);
  const originatorId = state.originatorID;

  const setNewSort = (newSort: [string, GridSortDirection][] | []) => {
    sort.setSorting({
      ...sort.sorting,
      paySchedules: newSort,
    });
  };

  const paymentSchedules = originatorId
    ? getAllOriginatorPaySchedules(originatorId, filters, sort.sorting.paySchedules)
    : getAllPaySchedules(filters, sort.sorting.paySchedules);

  return (
    <LoansPaymentSchedulesTableView
      paymentSchedules={paymentSchedules}
      filters={filters}
      setNewSort={setNewSort}
    />
  );
};
