import {
  ALL_TRANSACTION_ID,
  BORROWER_DATA_TRANSACTION_ID,
  FEES_TRANSACTION_ID,
  LOANS_TRANSACTION_ID,
  PAYMENTS_TRANSACTION_ID,
  PAYMENT_SCHEDULES_TRANSACTION_ID,
} from '../../../../utils/constants/identifiers';
import {
  ALL_LABEL,
  BORROWER_DATA_LABEL,
  FEES_LABEL,
  LOANS_LABEL,
  PAYMENTS_LABEL,
  PAYMENT_SCHEDULES_LABEL,
} from '../../../../utils/constants/labels';
import { TransactionType } from '../../../../utils/interfaces/transaction/TransactionType';

export const TransactionTypeMockData: TransactionType[] = [
  {
    id: LOANS_TRANSACTION_ID,
    name: LOANS_LABEL,
  },
  {
    id: BORROWER_DATA_TRANSACTION_ID,
    name: BORROWER_DATA_LABEL,
  },
  {
    id: PAYMENT_SCHEDULES_TRANSACTION_ID,
    name: PAYMENT_SCHEDULES_LABEL,
  },
  {
    id: PAYMENTS_TRANSACTION_ID,
    name: PAYMENTS_LABEL,
  },
  {
    id: FEES_TRANSACTION_ID,
    name: FEES_LABEL,
  },
  {
    id: ALL_TRANSACTION_ID,
    name: ALL_LABEL,
  },
];
