import React from 'react';
import { useForm } from 'react-hook-form';

import { Box, Button, Grid } from '@mui/material';

import { USER_ROLES_PERMISSION } from '../../../../../src/utils/constants/permissions';
import { getUserRolesById } from '../../../../api/controllers/AccountsController';
import { UserAvailableRoles } from '../../../../api/types/originatorTypes';
import {
  multiOriginatorTitleStyles,
  originatorRolesStyles,
} from '../../../../styles/accountsStyles';
import {
  stepperButtonsWrapperStyles,
  stepperCancelButtonStyles,
} from '../../../../styles/stepperStyles';
import { availableRoleListMock } from '../../../../test/mocks/AccountsMockData';
import { ADD_BUTTON, BUTTON_SUBMIT_TYPE, CANCEL_BUTTON } from '../../../../utils/constants/buttons';
import { EMPTY_VALUE } from '../../../../utils/constants/common';
import { FORM_TAG } from '../../../../utils/constants/components';
import { EDIT_PERMISSIONS_FORM_ID } from '../../../../utils/constants/identifiers';
import {
  MULTI_ORIGINATOR_ACCOUNT_LABEL,
  ORIGINATOR_ROLES_LABEL,
} from '../../../../utils/constants/labels';
import { EMPTY_QUERY_ID } from '../../../../utils/constants/options';
import { ROLES_IUVO, ROLES_ORIGINATORS } from '../../../../utils/constants/roles';
import { ButtonVariants } from '../../../../utils/enums/ButtonVariants';
import { getGroupedRoles, getRoleLabel } from '../../../../utils/helpers/rolesHelpers';
import { usePermissions } from '../../../../utils/hooks/permissions/usePermissions';
import { EditAccountFormsProps } from '../../../../utils/interfaces/originator/details/EditAccountFormsProps';
import { PermissionItem } from './PermissionItem';

export const EditPermissionsForm = (props: EditAccountFormsProps) => {
  const { onSubmit, handleClose, userId, originatorId, hasAssignedUserRoles } = props;

  const { handleSubmit, register, setValue } = useForm();

  const permissions = usePermissions();

  const userRoles = getUserRolesById(userId || EMPTY_QUERY_ID);
  const defaultRoles = userRoles && userRoles?.assignedRoleList ? userRoles?.assignedRoleList : [];
  const availableUserRoles =
    (userRoles && userRoles.availableRoleList?.length !== 0 && userRoles.availableRoleList) ||
    availableRoleListMock;

  const iuvoRoles = getGroupedRoles(ROLES_IUVO, availableUserRoles);
  const originatorRoles = getGroupedRoles(ROLES_ORIGINATORS, availableUserRoles);

  const handleChecked = (role: UserAvailableRoles) =>
    defaultRoles?.includes(role.availableRole || EMPTY_VALUE);

  const hasUserRolesUpdatePermission = permissions.hasUpdatePermission(USER_ROLES_PERMISSION);
  const isMultiOriginator = userRoles?.multiOriginatorUser;

  const handleDisabled =
    (hasAssignedUserRoles && defaultRoles.length !== 0) || (isMultiOriginator && !!originatorId);

  const isCheckboxDisabled = !hasUserRolesUpdatePermission || handleDisabled;

  return (
    <Box component={FORM_TAG} id={EDIT_PERMISSIONS_FORM_ID} onSubmit={handleSubmit(onSubmit)}>
      <Grid container>
        {(isMultiOriginator || handleDisabled) && (
          <Grid item sx={multiOriginatorTitleStyles}>
            {MULTI_ORIGINATOR_ACCOUNT_LABEL}
          </Grid>
        )}
        {!originatorId &&
          iuvoRoles.map((role) => {
            return (
              <PermissionItem
                key={role.availableRole}
                availableRole={role.availableRole}
                availableRoleDescription={getRoleLabel(role.availableRoleDescription as string)}
                register={register}
                isChecked={handleChecked(role as UserAvailableRoles)}
                disabled={!hasUserRolesUpdatePermission}
                setValue={setValue}
              />
            );
          })}
        <Grid item sx={originatorRolesStyles}>
          {ORIGINATOR_ROLES_LABEL}
        </Grid>

        {originatorRoles.map((role) => {
          return (
            <PermissionItem
              key={role.availableRole}
              availableRole={role.availableRole}
              availableRoleDescription={getRoleLabel(role.availableRoleDescription as string)}
              register={register}
              isChecked={handleChecked(role as UserAvailableRoles)}
              disabled={isCheckboxDisabled}
              setValue={setValue}
            />
          );
        })}
      </Grid>

      <Box sx={stepperButtonsWrapperStyles}>
        <Button
          variant={ButtonVariants.Outlined}
          sx={stepperCancelButtonStyles}
          onClick={handleClose}
        >
          {CANCEL_BUTTON}
        </Button>
        {hasUserRolesUpdatePermission && !isCheckboxDisabled && (
          <Button
            type={BUTTON_SUBMIT_TYPE}
            form={EDIT_PERMISSIONS_FORM_ID}
            variant={ButtonVariants.Contained}
          >
            {ADD_BUTTON}
          </Button>
        )}
      </Box>
    </Box>
  );
};
