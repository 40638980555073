import { GridColumns } from '@mui/x-data-grid-pro';

import { DESCRIPTION_ID, LABEL_ID } from '../../../utils/constants/identifiers';
import { DESCRIPTION_LABEL, LABEL_LABEL } from '../../../utils/constants/labels';
import { GRID_ACTIONS_COLUMN_FLEX } from '../../../utils/constants/styles';

export const borrowerDataFieldsColumns: GridColumns = [
  { field: LABEL_ID, headerName: LABEL_LABEL, flex: GRID_ACTIONS_COLUMN_FLEX },
  { field: DESCRIPTION_ID, headerName: DESCRIPTION_LABEL, flex: GRID_ACTIONS_COLUMN_FLEX },
];
