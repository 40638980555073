import { DRAWER_WIDTH, FULL_SCREEN_WIDTH } from '../utils/constants/styles';

export const layoutMainContentStyles = {
  flexGrow: 1,
  p: 4,
  width: { sm: `calc(${FULL_SCREEN_WIDTH} - ${DRAWER_WIDTH}px)` },
};

export const authLayoutGridStyles = {
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100vh',
  position: 'fixed',
  top: 0,
};

export const authLayoutContainerStyles = {
  paddingLeft: 0,
};
