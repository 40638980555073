import { GridColumns } from '@mui/x-data-grid-pro';

import {
  CURRENCY_INVEST_ID,
  MIN_PRIMARY_AMOUNT_ID,
  MIN_SECONDARY_AMOUNT_ID,
} from '../../../utils/constants/identifiers';
import {
  CURRENCY_LABEL,
  MIN_PRIMARY_AMOUNT_LABEL,
  MIN_SECONDNARY_AMOUNT_LABEL,
} from '../../../utils/constants/labels';
import { GRID_ACTIONS_COLUMN_FLEX } from '../../../utils/constants/styles';
import { GridColType } from '../../../utils/enums/GridColType';

export const currencyInvestAmountColumns: GridColumns = [
  { field: CURRENCY_INVEST_ID, headerName: CURRENCY_LABEL, flex: GRID_ACTIONS_COLUMN_FLEX },
  {
    field: MIN_PRIMARY_AMOUNT_ID,
    headerName: MIN_PRIMARY_AMOUNT_LABEL,
    flex: GRID_ACTIONS_COLUMN_FLEX,
    type: GridColType.Decimal,
  },
  {
    field: MIN_SECONDARY_AMOUNT_ID,
    headerName: MIN_SECONDNARY_AMOUNT_LABEL,
    flex: GRID_ACTIONS_COLUMN_FLEX,
    type: GridColType.Decimal,
  },
];
