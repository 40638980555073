import React from 'react';
import { useForm } from 'react-hook-form';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import {
  getBorrowerDataLoanTypeFields,
  getOriginatorBDLoanFields,
} from '../../../../../api/controllers/BorrowerDataController';
import { mapBorrowerDataFieldsSelector } from '../../../../../api/mappers/BorrowerDataMapper';
import {
  borrowerDataFormGridStyles,
  borrowerDataLoanFieldAddBtnStyles,
  borrowerDataSelectFieldStyles,
} from '../../../../../styles/borrowerDataStyles';
import { flexGrowStyle } from '../../../../../styles/commonStyles';
import { baseFormStyles } from '../../../../../styles/formStyles';
import { ADD_BUTTON } from '../../../../../utils/constants/buttons';
import { FORM_TAG } from '../../../../../utils/constants/components';
import { FIELD_ID } from '../../../../../utils/constants/identifiers';
import { FIELD_LABEL } from '../../../../../utils/constants/labels';
import { ButtonVariants } from '../../../../../utils/enums/ButtonVariants';
import { TextFieldSize } from '../../../../../utils/enums/TextFieldSize';
import { usePermissions } from '../../../../../utils/hooks/permissions/usePermissions';
import { UpdateBorrowerDataLoanProps } from '../../../../../utils/interfaces/borrowerData/loans/UpdateBorrowerDataLoanProps';
import { SelectField } from '../../../../common/form/SelectField';
import { BorrowerDataLoanFieldsTable } from '../tables/BorrowerDataLoanFieldsTable';

export const UpdateBorrowerDataLoanForm = (props: UpdateBorrowerDataLoanProps) => {
  const { onSubmit, loanTypeId, originatorId, permissionType } = props;
  const permissions = usePermissions();

  const borrowerDataLoanTypeFields = originatorId
    ? getOriginatorBDLoanFields(originatorId, loanTypeId)
    : getBorrowerDataLoanTypeFields(loanTypeId);

  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm();

  return (
    <Box component={FORM_TAG} sx={baseFormStyles}>
      <BorrowerDataLoanFieldsTable
        originatorId={originatorId}
        fields={borrowerDataLoanTypeFields}
        loanTypeId={loanTypeId}
        permissionType={permissionType}
      />
      {permissions.hasCreatePermission(permissionType) && (
        <Box sx={borrowerDataFormGridStyles}>
          <Toolbar disableGutters>
            <Box sx={borrowerDataSelectFieldStyles}>
              <SelectField
                id={FIELD_ID}
                label={FIELD_LABEL}
                choices={mapBorrowerDataFieldsSelector()}
                size={TextFieldSize.Small}
                control={control}
                required
              />
            </Box>
            <Typography noWrap sx={flexGrowStyle} />
            <Button
              sx={
                !errors[FIELD_ID]
                  ? { ...borrowerDataLoanFieldAddBtnStyles }
                  : { ...borrowerDataLoanFieldAddBtnStyles, mb: 3 }
              }
              variant={ButtonVariants.Outlined}
              onClick={() => {
                handleSubmit(onSubmit)();

                // Resetting the field.
                setValue(FIELD_ID, null);
              }}
            >
              {ADD_BUTTON}
            </Button>
          </Toolbar>
        </Box>
      )}
    </Box>
  );
};
