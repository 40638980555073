import React, { useContext, useState } from 'react';
import { FieldValues } from 'react-hook-form';

import {
  getAllCountries,
  getAllCurrencies,
  getAllNomenclaturesByType,
} from '../../../../../../api/controllers/NomenclatureController';
import {
  createOriginatorProduct,
  getOriginatorProducts,
} from '../../../../../../api/controllers/ProductController';
import { NomenclatureType } from '../../../../../../api/types/nomenclatureTypes';
import { ProductBase } from '../../../../../../api/types/originatorTypes';
import { OPEN_FORM_DIALOG } from '../../../../../../utils/constants/actions';
import { ADD_PRODUCT_BUTTON } from '../../../../../../utils/constants/buttons';
import { CREATE_PRODUCT_FORM_ID } from '../../../../../../utils/constants/identifiers';
import { PRODUCTS_PERMISSION } from '../../../../../../utils/constants/permissions';
import { ADD_PRODUCT_TITLE } from '../../../../../../utils/constants/titles';
import { FormDialogSubmitType } from '../../../../../../utils/enums/FormDialogSubmitType';
import { ToggleGridOption } from '../../../../../../utils/enums/ToggleGridOption';
import { closeFormHandler } from '../../../../../../utils/helpers/closeFormHandler';
import { OriginatorProductProps } from '../../../../../../utils/interfaces/originator/details/OriginatorProductsProps';
import { Context } from '../../../../../../utils/store';
import { ContextType } from '../../../../../../utils/store/types/ContextType';
import { OriginatorTabsHeaderView } from '../../OriginatorTabsHeaderView';
import { OriginatorProductsGrid } from './OriginatorProductsGrid';
import { OriginatorProductsTable } from './OriginatorProductsTable';
import { CreateProductForm } from './forms/CreateProductForm';

export const OriginatorProductsTab = (props: OriginatorProductProps) => {
  const { originatorId } = props;
  const { dispatch } = useContext<ContextType>(Context);
  const [view, setView] = useState<ToggleGridOption>(ToggleGridOption.List);

  const countries = getAllCountries();
  const loanTypes = getAllNomenclaturesByType(NomenclatureType.LOAN_TYPES);
  const loanCurrencies = getAllCurrencies();
  const amortizationMethods = getAllNomenclaturesByType(NomenclatureType.AMORTIZATION_METHODS);
  const installmentTypes = getAllNomenclaturesByType(NomenclatureType.INSTALLMENT_TYPES);
  const createProductMutation = createOriginatorProduct(originatorId);
  const originatorProducts = getOriginatorProducts(originatorId);

  const handleSubmit = (data: FieldValues): void => {
    createProductMutation.mutateAsync(data as ProductBase).then((response) => {
      closeFormHandler(dispatch, response.status);
    });
  };

  const handleAdd = (): void => {
    dispatch({
      type: OPEN_FORM_DIALOG,
      payload: {
        title: ADD_PRODUCT_TITLE,
        content: (
          <CreateProductForm
            onSubmit={handleSubmit}
            countries={countries}
            loanTypes={loanTypes}
            loanCurrencies={loanCurrencies}
            amortizationMethods={amortizationMethods}
            installmentTypes={installmentTypes}
          />
        ),
        formId: CREATE_PRODUCT_FORM_ID,
        submitType: FormDialogSubmitType.Add,
      },
    });
  };

  return (
    <>
      <OriginatorTabsHeaderView
        onGridToggle={setView}
        gridView={view}
        buttonLabel={ADD_PRODUCT_BUTTON}
        onButtonClick={handleAdd}
        permissionType={PRODUCTS_PERMISSION}
      />

      {view === ToggleGridOption.List ? (
        <OriginatorProductsTable
          products={originatorProducts}
          originatorId={originatorId}
          countries={countries}
          loanTypes={loanTypes}
          loanCurrencies={loanCurrencies}
          amortizationMethods={amortizationMethods}
          installmentTypes={installmentTypes}
        />
      ) : (
        <OriginatorProductsGrid
          products={originatorProducts}
          originatorId={originatorId}
          countries={countries}
          loanTypes={loanTypes}
          loanCurrencies={loanCurrencies}
          amortizationMethods={amortizationMethods}
          installmentTypes={installmentTypes}
        />
      )}
    </>
  );
};
