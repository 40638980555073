import { Dispatch } from 'react';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { getAllCountries } from '../../api/controllers/NomenclatureController';
import { BusinessStatusMockData } from '../../test/mocks/BusinessStatusMockData';
import { HIDE_LOADER, SHOW_LOADER } from '../constants/actions';
import { NO_LABEL, YES_LABEL } from '../constants/labels';
import { ButtonLoaderTriggerLocation } from '../enums/ButtonLoaderTriggerLocation';
import { Action } from '../store/action';
import { State } from '../store/types/State';

export const getBusinessStatus = (businessStatusId?: number) =>
  BusinessStatusMockData.find((x) => x.id === businessStatusId);

export const getPercentValue = (value: number): string => `${(value * 100).toFixed(2)}%`;

export const convertExponentialToDecimal = (exponentialNumber: number): number | string => {
  // sanity check - is it exponential number
  const str = exponentialNumber.toString();
  const radix = 10;

  if (str.indexOf('e') !== -1) {
    const exponent = parseInt(str.split('-')[1], radix);
    // Unfortunately I can not return 1e-8 as 0.00000001, because even if I call parseFloat() on it,
    // it will still return the exponential representation
    // So I have to use .toFixed()
    const result = exponentialNumber.toFixed(exponent);
    return result;
  } else {
    return exponentialNumber;
  }
};

export const handleButtonLoader = (state: State, type: string) => {
  if (state.loader) {
    switch (type) {
      case ButtonLoaderTriggerLocation.FormDialog:
        return state.loader.id === state.formDialog?.formId;
      case ButtonLoaderTriggerLocation.ConfirmationDialog:
        return state.loader.id === state.confirmationDialog?.dialogId;
      default:
        return false;
    }
  }

  return false;
};

export const showLoader = (dispatch: Dispatch<Action>, id: string) =>
  dispatch({ type: SHOW_LOADER, payload: { id: id } });

export const hideLoader = (dispatch: Dispatch<Action>) => dispatch({ type: HIDE_LOADER });

export const getCountryOfRegistration = (countryOfRegistrationId: number) =>
  getAllCountries().find((x) => x.id === countryOfRegistrationId);

export const processDatePickerDate = (date: Date | null) => {
  if (!date) {
    return null;
  }

  //Increasing day with 1 here because when we choose 2022/05/25 it sends 2022/05/24 through request.
  //The problem is that it subtract 1 day from our chosen date.
  return new AdapterDateFns().addDays(date, +1);
};

export const originatorSettingsResultHelper = (result: boolean) => (result ? YES_LABEL : NO_LABEL);

export const checkIfValidUUID = (str: string) => {
  const regexExp =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
  return regexExp.test(str);
};
