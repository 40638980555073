import React from 'react';

import GridViewIcon from '@mui/icons-material/GridView';
import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { SIZE_SMALL } from '../../utils/constants/styles';
import { ToggleGridOption } from '../../utils/enums/ToggleGridOption';
import { OriginatorDetailsGridTogglerProps } from '../../utils/interfaces/originator/details/OriginatorDetailsGridTogglerProps';

export const GridToggler = (props: OriginatorDetailsGridTogglerProps) => {
  const { setGridValue, view } = props;

  const handleChange = (_: React.MouseEvent<HTMLElement>, view: ToggleGridOption) => {
    setGridValue(view);
  };

  return (
    <ToggleButtonGroup value={view} exclusive onChange={handleChange} size={SIZE_SMALL}>
      <ToggleButton disabled={view === ToggleGridOption.List} value={ToggleGridOption.List}>
        <ViewListOutlinedIcon />
      </ToggleButton>
      <ToggleButton disabled={view === ToggleGridOption.Module} value={ToggleGridOption.Module}>
        <GridViewIcon />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
