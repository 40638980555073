import * as React from 'react';

import { GridColumns } from '@mui/x-data-grid-pro';

import { groupColumns } from '../../../../common/tableColumns/groups/groupColumns';
import { GRID_ACTIONS } from '../../../../utils/constants/common';
import { GRID_ACTIONS_COLUMN_FLEX } from '../../../../utils/constants/styles';
import { GridColType } from '../../../../utils/enums/GridColType';
import { getGridColumns } from '../../../../utils/helpers/getGridColumns';
import { DataGridActionProps } from '../../../../utils/interfaces/grid/DataGridActionProps';
import { DataGridView } from '../../../common/grid/DataGridView';
import { GridActionsView } from '../../../common/grid/GridActionsView';

export const GroupsDataGrid = <T extends object>(props: DataGridActionProps<T>) => {
  const { handleEdit, data, permissionType } = props;

  const actions: GridColumns = [
    {
      field: GRID_ACTIONS,
      type: GridColType.Actions,
      flex: GRID_ACTIONS_COLUMN_FLEX,
      getActions: ({ id }) => [
        <GridActionsView
          key={id}
          handleEditClick={() => (handleEdit ? handleEdit(id) : null)}
          permissionType={permissionType}
        />,
      ],
    },
  ];

  const columns: GridColumns = [...groupColumns, ...actions];

  return <DataGridView data={data} columns={getGridColumns(columns)} />;
};
