import { UserAvailableRoles } from '../../api/types/originatorTypes';

// TODO: Get from BE
export const availableRoleListMock: UserAvailableRoles[] = [
  {
    availableRole: 'ROLE_SUPER_ADMIN',
    availableRoleDescription: 'ROLE_SUPER_ADMIN',
  },
  {
    availableRole: 'ROLE_ORIGINATOR_ADMIN',
    availableRoleDescription: 'ROLE_ORIGINATOR_ADMIN',
  },
  {
    availableRole: 'ROLE_IMPORT_VIEW',
    availableRoleDescription: 'ROLE_IMPORT_VIEW',
  },
  {
    availableRole: 'ROLE_IMPORT_DRAFT',
    availableRoleDescription: 'ROLE_IMPORT_DRAFT',
  },
  {
    availableRole: 'ROLE_IMPORT_FULL',
    availableRoleDescription: 'ROLE_IMPORT_FULL',
  },
  {
    availableRole: 'ROLE_CONTROL_PANEL_VIEW',
    availableRoleDescription: 'ROLE_CONTROL_PANEL_VIEW',
  },
  {
    availableRole: 'ROLE_CONTROL_PANEL_FULL',
    availableRoleDescription: 'ROLE_CONTROL_PANEL_FULL',
  },
  {
    availableRole: 'ROLE_REPORTS_VIEW',
    availableRoleDescription: 'ROLE_REPORTS_VIEW',
  },
  {
    availableRole: 'ROLE_REPORTS_FULL',
    availableRoleDescription: 'ROLE_REPORTS_FULL',
  },
  {
    availableRole: 'ROLE_NOTIFICATIONS_VIEW',
    availableRoleDescription: 'ROLE_NOTIFICATIONS_VIEW',
  },
  {
    availableRole: 'ROLE_NOTIFICATIONS_FULL',
    availableRoleDescription: 'ROLE_NOTIFICATIONS_FULL',
  },
  {
    availableRole: 'ROLE_ACCESS_MANAGEMENT_VIEW',
    availableRoleDescription: 'ROLE_ACCESS_MANAGEMENT_VIEW',
  },
  {
    availableRole: 'ROLE_ACCESS_MANAGEMENT_FULL',
    availableRoleDescription: 'ROLE_ACCESS_MANAGEMENT_FULL',
  },
  {
    availableRole: 'ROLE_ADMIN',
    availableRoleDescription: 'ROLE_ADMIN',
  },
  {
    availableRole: 'ROLE_ADMIN_VIEWER',
    availableRoleDescription: 'ROLE_ADMIN_VIEWER',
  },
  {
    availableRole: 'ROLE_ORIGINATOR_SUPER_ADMIN',
    availableRoleDescription: 'ROLE_ORIGINATOR_SUPER_ADMIN',
  },
];
