import {
  ADDRESS_ID,
  BUSINESS_STATUS_ID,
  CEO_ID,
  COUNTRY_OF_REGISTRATION_ID,
  FEE,
  LEGAL_NAME_ID,
  NAME_ID,
  ORIGINATOR_GROUP,
  PUBLIC_NAME_ID,
  REGISTRATION_NUMBER_ID,
  TRADE_NAME_ID,
} from '../../utils/constants/identifiers';
import {
  ADDRESS_LABEL,
  BUSINESS_STATUS_LABEL,
  CEO_LABEL,
  COUNTRY_OF_REGISTRATION_LABEL,
  FEE_LABEL,
  GROUP_LABEL,
  LEGAL_NAME_LABEL,
  NAME_LABEL,
  PUBLIC_NAME_LABEL,
  REGISTRATION_NUMBER_LABEL,
  TRADE_NAME_LABEL,
} from '../../utils/constants/labels';
import { OriginatorDetailsFieldProps } from '../../utils/interfaces/originator/OriginatorDetailsFieldsProps';

export const originatorDetailsFields: OriginatorDetailsFieldProps[] = [
  { title: NAME_LABEL, id: NAME_ID },
  { title: PUBLIC_NAME_LABEL, id: PUBLIC_NAME_ID },
  { title: LEGAL_NAME_LABEL, id: LEGAL_NAME_ID },
  { title: TRADE_NAME_LABEL, id: TRADE_NAME_ID },
  { title: REGISTRATION_NUMBER_LABEL, id: REGISTRATION_NUMBER_ID },
  { title: CEO_LABEL, id: CEO_ID },
  { title: COUNTRY_OF_REGISTRATION_LABEL, id: COUNTRY_OF_REGISTRATION_ID },
  { title: GROUP_LABEL, id: ORIGINATOR_GROUP },
  { title: ADDRESS_LABEL, id: ADDRESS_ID },
  { title: FEE_LABEL, id: FEE },
  { title: BUSINESS_STATUS_LABEL, id: BUSINESS_STATUS_ID },
];
