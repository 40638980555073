import React, { useContext } from 'react';

import { deleteOriginatorCountry } from '../../../../../../api/controllers/OriginatorSpvController';
import {
  CLOSE_CONFIRMATION_DIALOG,
  OPEN_CONFIRMATION_DIALOG,
  OPEN_FORM_DIALOG,
} from '../../../../../../utils/constants/actions';
import { ADD_COUNTRY_BUTTON } from '../../../../../../utils/constants/buttons';
import { DELETE_ORIGINATOR_COUNTRY } from '../../../../../../utils/constants/confirmations';
import { DELETE_ORIGINATOR_COUNTRY_SPV_ID } from '../../../../../../utils/constants/identifiers';
import {
  ORIGINATOR_COUNTRIES_PERMISSION,
  ORIGINATOR_COUNTRIES_SPV_PERMISSION,
} from '../../../../../../utils/constants/permissions';
import {
  ADD_COUNTRY_TITLE,
  DELETE_COUNTRY_TITLE,
  EDIT_COUNTRY_TITLE,
} from '../../../../../../utils/constants/titles';
import { Context } from '../../../../../../utils/store';
import { AddCountryStepper } from '../../../forms/spvs/AddCountryStepper';
import { OriginatorTabsHeaderView } from '../../OriginatorTabsHeaderView';
import { OriginatorSpvTableView } from './OriginatorSpvTableView';

export const OriginatorSpvsTab = (props: { originatorId: number }) => {
  const { originatorId } = props;

  const { dispatch } = useContext(Context);

  const deleteCountryMutation = deleteOriginatorCountry();

  const originatorCountriesSpvPermissionType = ORIGINATOR_COUNTRIES_SPV_PERMISSION;
  const originatorCountriesPermissionType = ORIGINATOR_COUNTRIES_PERMISSION;

  const handleAdd = () => {
    dispatch({
      type: OPEN_FORM_DIALOG,
      payload: {
        title: ADD_COUNTRY_TITLE,
        content: <AddCountryStepper originatorId={originatorId} />,
      },
    });
  };

  const handleEditClick = (id: number) => {
    dispatch({
      type: OPEN_FORM_DIALOG,
      payload: {
        title: EDIT_COUNTRY_TITLE,
        content: <AddCountryStepper originatorId={originatorId} originatorCountryId={id} />,
      },
    });
  };

  const handleDeleteConfirm = (id: number): void => {
    deleteCountryMutation.mutateAsync({ originatorId: originatorId, originatorCountryId: id });

    dispatch({ type: CLOSE_CONFIRMATION_DIALOG });
  };

  const handleDeleteClick = (id: number): void => {
    dispatch({
      type: OPEN_CONFIRMATION_DIALOG,
      payload: {
        dialogId: DELETE_ORIGINATOR_COUNTRY_SPV_ID,
        title: DELETE_COUNTRY_TITLE,
        content: DELETE_ORIGINATOR_COUNTRY,
        onAgree: () => handleDeleteConfirm(id),
      },
    });
  };

  return (
    <>
      <OriginatorTabsHeaderView
        buttonLabel={ADD_COUNTRY_BUTTON}
        onButtonClick={handleAdd}
        permissionType={[originatorCountriesSpvPermissionType, originatorCountriesPermissionType]}
      />
      <OriginatorSpvTableView
        originatorId={originatorId}
        handleEdit={handleEditClick}
        handleDelete={handleDeleteClick}
        permissionType={originatorCountriesSpvPermissionType}
      />
    </>
  );
};
