import { GridColumns } from '@mui/x-data-grid-pro';

import {
  BUSINESS_DAY_ID,
  DATE_ID,
  INTEREST_AMOUNT_ID,
  IS_EARLY_ID,
  IS_GUARANT_ID,
  LOAN_ID,
  ORIGINATOR_ID,
  PAYMENT_ID,
  PAY_SCHEDULE_ID,
  PRINCIPAL_AMOUNT_ID,
  TRANS_ID,
} from '../../../utils/constants/identifiers';
import {
  BUSINESS_DAY_LABEL,
  INTEREST_AMOUNT_LABEL,
  IS_EARLY_LABEL,
  IS_GUARANTEED_LABEL,
  LOAN_ID_LABEL,
  ORIGINATOR_LABEL,
  PAYMENT_ID_LABEL,
  PAY_DATE_LABEL,
  PAY_SCHEDULE_ID_LABEL,
  PRINCIPAL_AMOUNT_LABEL,
  TRANS_ID_LABEL,
} from '../../../utils/constants/labels';
import { GRID_ACTIONS_COLUMN_FLEX } from '../../../utils/constants/styles';
import { GridColType } from '../../../utils/enums/GridColType';

export const paymnetsTabColumns: GridColumns = [
  { field: PAYMENT_ID, headerName: PAYMENT_ID_LABEL, flex: GRID_ACTIONS_COLUMN_FLEX },
  { field: LOAN_ID, headerName: LOAN_ID_LABEL, flex: GRID_ACTIONS_COLUMN_FLEX },
  {
    field: DATE_ID,
    headerName: PAY_DATE_LABEL,
    flex: GRID_ACTIONS_COLUMN_FLEX,
    type: GridColType.Date,
  },
  {
    field: PRINCIPAL_AMOUNT_ID,
    headerName: PRINCIPAL_AMOUNT_LABEL,
    flex: GRID_ACTIONS_COLUMN_FLEX,
    type: GridColType.Number,
  },
  {
    field: INTEREST_AMOUNT_ID,
    headerName: INTEREST_AMOUNT_LABEL,
    flex: GRID_ACTIONS_COLUMN_FLEX,
    type: GridColType.Decimal,
  },
  {
    field: IS_GUARANT_ID,
    headerName: IS_GUARANTEED_LABEL,
    flex: GRID_ACTIONS_COLUMN_FLEX,
    type: GridColType.Boolean,
  },
  {
    field: IS_EARLY_ID,
    headerName: IS_EARLY_LABEL,
    flex: GRID_ACTIONS_COLUMN_FLEX,
    type: GridColType.Boolean,
  },
  {
    field: BUSINESS_DAY_ID,
    headerName: BUSINESS_DAY_LABEL,
    flex: GRID_ACTIONS_COLUMN_FLEX,
    type: GridColType.Date,
  },
  {
    field: ORIGINATOR_ID,
    headerName: ORIGINATOR_LABEL,
    type: GridColType.Number,
  },
  {
    field: PAY_SCHEDULE_ID,
    headerName: PAY_SCHEDULE_ID_LABEL,
    flex: GRID_ACTIONS_COLUMN_FLEX,
  },
  {
    field: TRANS_ID,
    headerName: TRANS_ID_LABEL,
    flex: GRID_ACTIONS_COLUMN_FLEX,
  },
];
