import React from 'react';
import { useForm } from 'react-hook-form';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { formGridSpacing } from '../../../../styles/formStyles';
import { FORM_TAG } from '../../../../utils/constants/components';
import {
  NAME_ID,
  PUBLIC_NAME_ID,
  SCORE_TO_ID,
  UPDATE_SCORE_CLASS_FORM_ID,
} from '../../../../utils/constants/identifiers';
import {
  NAME_LABEL,
  PUBLIC_NAME_LABEL,
  SCORE_NUMBER_LABEL,
} from '../../../../utils/constants/labels';
import { InputFieldType } from '../../../../utils/enums/InputFieldType';
import { UpdateScoreClassFormProps } from '../../../../utils/interfaces/scoreClass/UpdateScoreClassFormProps';
import { InputField } from '../../../common/form/InputField';
import { NumberInputField } from '../../../common/form/NumberInputField';
import { MediumGridField } from '../../../common/form/grid/MediumGridField';

export const UpdateScoreClassForm = (props: UpdateScoreClassFormProps) => {
  const { onSubmit, scoreClass } = props;

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  return (
    <Box component={FORM_TAG} id={UPDATE_SCORE_CLASS_FORM_ID} onSubmit={handleSubmit(onSubmit)}>
      <Grid container rowSpacing={1} columnSpacing={formGridSpacing}>
        <MediumGridField>
          <InputField
            id={NAME_ID}
            label={NAME_LABEL}
            register={register}
            errors={errors}
            required
            defaultValue={scoreClass.name}
          />
        </MediumGridField>
        <MediumGridField>
          <InputField
            id={PUBLIC_NAME_ID}
            label={PUBLIC_NAME_LABEL}
            register={register}
            errors={errors}
            required
            defaultValue={scoreClass.publicName}
          />
        </MediumGridField>
        <MediumGridField>
          <NumberInputField
            id={SCORE_TO_ID}
            label={SCORE_NUMBER_LABEL}
            register={register}
            errors={errors}
            defaultValue={scoreClass.scoreTo}
            inputType={InputFieldType.Decimal}
            required
          />
        </MediumGridField>
      </Grid>
    </Box>
  );
};
