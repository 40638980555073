import {
  ACCOUNTS_KEY,
  BORROWER_DATA_KEY,
  PRODUCTS_KEY,
  SCORE_CLASSES_KEY,
  SETTINGS_KEY,
  SPVS_KEY,
} from '../../utils/constants/keys';
import {
  ACCOUNTS_LABEL,
  BORROWER_DATA_LABEL,
  PRODUCTS_LABEL,
  SCORE_CLASSES_LABEL,
  SETTINGS_LABEL,
  SPVS_LABEL,
} from '../../utils/constants/labels';
import { TabsProps } from '../../utils/interfaces/tabs/TabsProps';

export const originatorDetailsTabs: TabsProps[] = [
  {
    key: PRODUCTS_KEY,
    label: PRODUCTS_LABEL,
  },
  {
    key: SPVS_KEY,
    label: SPVS_LABEL,
  },
  {
    key: SCORE_CLASSES_KEY,
    label: SCORE_CLASSES_LABEL,
  },
  {
    key: BORROWER_DATA_KEY,
    label: BORROWER_DATA_LABEL,
  },
  {
    key: ACCOUNTS_KEY,
    label: ACCOUNTS_LABEL,
  },
  {
    key: SETTINGS_KEY,
    label: SETTINGS_LABEL,
  },
];
