import React from 'react';

import { GridColumns, GridRenderCellParams, GridValueFormatterParams } from '@mui/x-data-grid-pro';

import { DecimalNumberCell } from '../../components/common/grid/gridCells/DecimalNumberCell';
import { AlignOptions } from '../enums/AlignOptions';
import { GridColType } from '../enums/GridColType';
import { gridCellValueFormatter } from './gridCellValueFormatter';

export const getGridColumns = (columns: GridColumns) => {
  return columns.map((x) => {
    if (x.type === GridColType.Decimal) {
      return {
        ...x,
        headerAlign: AlignOptions.Left,
        align: AlignOptions.Left,
        renderCell: (value: GridRenderCellParams<any, any, any>) => (
          <DecimalNumberCell value={value.row[x.field]} />
        ),
      };
    }

    const valueFormatter = {
      valueFormatter: (params: GridValueFormatterParams<any>) =>
        gridCellValueFormatter(x.type, params.value),
    };

    if (x.type === GridColType.Number || x.type === GridColType.Boolean) {
      return {
        ...x,
        headerAlign: AlignOptions.Left,
        align: AlignOptions.Left,
        ...valueFormatter,
      };
    }

    return {
      ...x,
      ...valueFormatter,
    };
  });
};
