import { nameof } from 'ts-simple-nameof';

import { useGetRequest } from '../../utils/hooks/query/useGetRequest';
import { useMutationRequest } from '../../utils/hooks/query/useMutationRequest';
import { OriginatorScoreClassRequest } from '../../utils/interfaces/originator/details/OriginatorScoreClassRequest';
import { UpdateOriginatorScoreClassRequest } from '../../utils/interfaces/originator/details/UpdateOriginatorScoreClassRequest';
import { originatorServiceClient } from '../apiClient';
import {
  Api,
  OriginatorScoreClass,
  OriginatorScoreClassMargin,
  OriginatorScoreClassMarginCreate,
  ScoreClass,
  ScoreClassBase,
} from '../types/originatorTypes';

export const getAllScoreClasses = (): ScoreClass[] => {
  const { data: scoreClasses } = useGetRequest({
    key: nameof<Api<unknown>>((x) => x.api.getAllScoreClasses),
    func: async () => (await originatorServiceClient.api.getAllScoreClasses()).data,
  });

  if (!scoreClasses || !scoreClasses.data) {
    return [];
  }

  return scoreClasses.data;
};

export const getOriginatorScoreClasses = (originatorId: number): OriginatorScoreClass[] => {
  const { data: originatorScoreClasses } = useGetRequest({
    key: nameof<Api<unknown>>((x) => x.api.getOriginatorScoreClasses),
    func: async () =>
      (await originatorServiceClient.api.getOriginatorScoreClasses(originatorId)).data,
  });

  if (!originatorScoreClasses || !originatorScoreClasses.data) {
    return [];
  }

  return originatorScoreClasses.data;
};

export const getOriginatorScoreClassMargins = (
  originatorId: number,
): OriginatorScoreClassMargin[] => {
  const { data: originatorScoreClasses } = useGetRequest({
    key: nameof<Api<unknown>>((x) => x.api.getOriginatorScoreClassMargins),
    func: async () =>
      (await originatorServiceClient.api.getOriginatorScoreClassMargins(originatorId)).data,
  });

  if (!originatorScoreClasses || !originatorScoreClasses.data) {
    return [];
  }

  return originatorScoreClasses.data;
};

export const addOriginatorScoreClass = (originatorId: number) =>
  useMutationRequest(
    (request: OriginatorScoreClassMarginCreate) =>
      originatorServiceClient.api.createOriginatorScoreClassMargins(originatorId, request),
    nameof<Api<unknown>>((x) => x.api.getOriginatorScoreClassMargins),
  );

export const updateOriginatorScoreClass = () =>
  useMutationRequest(
    (request: UpdateOriginatorScoreClassRequest) =>
      originatorServiceClient.api.updateOriginatorScoreClassMargins(
        request.originatorData.originatorId,
        request.originatorData.originatorScoreClassId,
        request.formData,
      ),
    nameof<Api<unknown>>((x) => x.api.getOriginatorScoreClassMargins),
  );

export const deleteOriginatorScoreClass = () =>
  useMutationRequest(
    (request: OriginatorScoreClassRequest) =>
      originatorServiceClient.api.deleteOriginatorScoreClassMargins(
        request.originatorId,
        request.originatorScoreClassId,
      ),
    nameof<Api<unknown>>((x) => x.api.getOriginatorScoreClassMargins),
  );

export const createScoreClass = () =>
  useMutationRequest(
    (request: ScoreClassBase) => originatorServiceClient.api.createScoreClass(request),
    nameof<Api<unknown>>((x) => x.api.getAllScoreClasses),
  );

export const updateScoreClass = (id: number) =>
  useMutationRequest(
    (request: ScoreClassBase) => originatorServiceClient.api.updateScoreClass(id, request),
    nameof<Api<unknown>>((x) => x.api.getAllScoreClasses),
  );

export const deleteScoreClass = () =>
  useMutationRequest(
    (id: number) => originatorServiceClient.api.deleteScoreClass(id),
    nameof<Api<unknown>>((x) => x.api.getAllScoreClasses),
  );

export const getDefaultScoreClasses = (originatorId: number): ScoreClass[] => {
  const { data: scoreClasses } = useGetRequest({
    key: nameof<Api<unknown>>((x) => x.api.getDefaultScoreClasses),
    func: async () => (await originatorServiceClient.api.getDefaultScoreClasses(originatorId)).data,
  });

  if (!scoreClasses || !scoreClasses.data) {
    return [];
  }

  return scoreClasses.data;
};
