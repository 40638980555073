// Tags

export const BUTTON_TAG = 'button';
export const HYPERLINK_TAG = 'a';
export const MAIN_TAG = 'main';
export const DIV_TAG = 'div';
export const FORM_TAG = 'form';
export const LABEL_TAG = 'label';

// Icons
export const ICON_SIZE_LARGE = 'large';
