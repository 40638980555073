import { viravaService } from '../../api/apiClient';
import { ACCESS_TOKEN } from '../constants/common';
import { AuthErrorType } from '../interfaces/authentication/AuthErrorType';

export const getToken = () => localStorage.getItem(ACCESS_TOKEN);

export const isAuthenticated = (): boolean => {
  const token = getToken();
  if (token && token !== 'undefined' && token !== 'null') {
    return true;
  }

  return false;
};

export const logIn = async (
  email: string,
  password: string,
  onSuccess: () => void,
  onError: (error: AuthErrorType) => void,
): Promise<void> => {
  try {
    await viravaService.login(email, password);
    onSuccess();
  } catch (e: unknown) {
    onError(e as AuthErrorType);
  }
};

export const signUp = async (
  email: string,
  password: string,
  confirmPassword: string,
  onSuccess: () => void,
  onError: (error: AuthErrorType) => void,
): Promise<void> => {
  try {
    await viravaService.register(email, password, confirmPassword);
    onSuccess();
  } catch (e: unknown) {
    onError(e as AuthErrorType);
  }
};

export const resetPassword = async (
  email: string,
  onSuccess: () => void,
  onError: (error: AuthErrorType) => void,
): Promise<void> => {
  try {
    await viravaService.resetPassword(email);
    onSuccess();
  } catch (e: unknown) {
    onError(e as AuthErrorType);
  }
};

export const logOut = async (): Promise<void> => {
  await viravaService.logout();
};

export const changePassword = async (
  email: string,
  currentPassword: string,
  newPassword: string,
  confirmPassword: string,
  onSuccess: () => void,
  onError: (error: AuthErrorType) => void,
): Promise<void> => {
  try {
    await viravaService.changePassword(email, currentPassword, newPassword, confirmPassword);
    onSuccess();
  } catch (e: unknown) {
    onError(e as AuthErrorType);
  }
};
