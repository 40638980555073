import { useContext } from 'react';
import { useMutation } from 'react-query';
import { MutationFunction } from 'react-query/types/core/types';

import { Error } from '../../../api/types/originatorTypes';
import { hideLoader } from '../../helpers/commonHelper';
import { errorResultHandler } from '../../helpers/errorResultHandler';
import { successResultHandler } from '../../helpers/successResultHandler';
import { Context } from '../../store';
import { ContextType } from '../../store/types/ContextType';
import { queryClient } from './queryClient';

export const useMutationRequest = <TData, TVariables>(
  func: MutationFunction<TData, TVariables>,
  invalidateKeys?: string | string[],
  customSuccessMessage?: string,
) => {
  const { dispatch } = useContext<ContextType>(Context);

  return useMutation(func, {
    onSuccess: async <TResponse>(response: TResponse) => {
      let keys = invalidateKeys;

      if (keys) {
        if (!Array.isArray(keys)) {
          keys = [keys];
        }
        keys.forEach((x) => queryClient.invalidateQueries(x));
      }
      hideLoader(dispatch);
      successResultHandler(response, customSuccessMessage);
    },
    onError: async (error: Error) => {
      hideLoader(dispatch);
      errorResultHandler(error);
    },
  });
};
