import React, { useContext } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import { CLOSE_INFO_DIALOG } from '../../../utils/constants/actions';
import { CANCEL_BUTTON } from '../../../utils/constants/buttons';
import { ButtonVariants } from '../../../utils/enums/ButtonVariants';
import { DialogOptions } from '../../../utils/enums/DialogOptions';
import { Context } from '../../../utils/store';

export const CommonDialogView = () => {
  const { state, dispatch } = useContext(Context);

  const handleCancel = (): void => {
    dispatch({ type: CLOSE_INFO_DIALOG });
  };

  return state.infoDialog ? (
    <Dialog open={true} maxWidth={DialogOptions.Small} fullWidth={true}>
      <DialogContent>{state.infoDialog?.content}</DialogContent>
      <DialogActions>
        <Button variant={ButtonVariants.Outlined} onClick={handleCancel}>
          {CANCEL_BUTTON}
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;
};
