import React, { useState } from 'react';

import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import { GridActionsCellItem } from '@mui/x-data-grid-pro';
import { GridColumns } from '@mui/x-data-grid/models';

import { LoanInfo } from '../../../../../api/types/originatorTypes';
import { loansColumns } from '../../../../../common/tableColumns/transactions/transactionDetails/loansColumns';
import { PopoverView } from '../../../../../components/common/PopoverView';
import { errorIconStyles, warningIconStyles } from '../../../../../styles/dataGridStyles';
import { LOAN_ID } from '../../../../../utils/constants/identifiers';
import { HAS_ERROR_ID, WARNING_ID } from '../../../../../utils/constants/identifiers';
import { ACTIONS_LABEL, ERROR_LABEL, WARNING_LABEL } from '../../../../../utils/constants/labels';
import { getGridColumns } from '../../../../../utils/helpers/getGridColumns';
import { DataGridView } from '../../../../common/grid/DataGridView';

export const TransactionLoansTab = (props: { data: LoanInfo[] }) => {
  const { data } = props;
  const [popoverValue, setPopoverValue] = useState<object>();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopperOpen = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, params: any) => {
    const row = params.row;

    if ((row && row[HAS_ERROR_ID]) || (row && row[WARNING_ID])) {
      setPopoverValue(row);
      setAnchorEl(e.currentTarget);
    }
  };

  const handlePopoverClose = () => {
    setPopoverValue(undefined);
  };

  const loansColumnsWithError: GridColumns = [
    {
      field: ACTIONS_LABEL,
      type: ACTIONS_LABEL,
      width: 60,
      getActions: (params) => {
        if (params.row.loanImportStatus === ERROR_LABEL.toUpperCase()) {
          return [
            <GridActionsCellItem
              key="1"
              icon={<ErrorIcon sx={errorIconStyles} />}
              label={ERROR_LABEL}
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
                handlePopperOpen(e, params)
              }
              onMouseLeave={handlePopoverClose}
            />,
          ];
        } else if (params.row.warning) {
          return [
            <GridActionsCellItem
              key="2"
              icon={<WarningIcon sx={warningIconStyles} />}
              label={WARNING_LABEL}
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
                handlePopperOpen(e, params)
              }
              onMouseLeave={handlePopoverClose}
            />,
          ];
        } else {
          return [];
        }
      },
    },
    ...loansColumns,
  ];

  return (
    <>
      <DataGridView
        data={data}
        columns={getGridColumns(loansColumnsWithError)}
        rowId={LOAN_ID}
        applyErrorOnRows
        disableSelectionOnClick
      />
      <PopoverView anchorEl={anchorEl} value={popoverValue} handleClose={handlePopoverClose} />
    </>
  );
};
