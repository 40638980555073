import React from 'react';
import { ReactNode } from 'react';

import Grid from '@mui/material/Grid';

import { GridOptions } from '../../../../utils/enums/GridOptions';

export const MediumGridField = (props: { children: ReactNode }) => {
  const { children } = props;

  return (
    <Grid item xs={GridOptions.Medium}>
      {children}
    </Grid>
  );
};
