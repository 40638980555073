import React, { useContext, useState } from 'react';
import { FieldValues } from 'react-hook-form';

import Grid from '@mui/material/Grid';

import { addBorrowerDataField } from '../../../api/controllers/BorrowerDataController';
import { borrowerDataTabs } from '../../../common/borrowerData/borrowerDataTabs';
import { OPEN_FORM_DIALOG } from '../../../utils/constants/actions';
import { ADD_FIELD_BUTTON } from '../../../utils/constants/buttons';
import { CREATE_BORROWER_DATA_FIELD_FORM_ID } from '../../../utils/constants/identifiers';
import { BORROWER_DATA_FIELDS_PERMISSION } from '../../../utils/constants/permissions';
import { ADD_FIELD_TITLE } from '../../../utils/constants/titles';
import { BorrowerDataTabs } from '../../../utils/enums/BorrowerDataTabs';
import { FormDialogSubmitType } from '../../../utils/enums/FormDialogSubmitType';
import { GridOptions } from '../../../utils/enums/GridOptions';
import { closeFormHandler } from '../../../utils/helpers/closeFormHandler';
import { usePermissions } from '../../../utils/hooks/permissions/usePermissions';
import { Context } from '../../../utils/store';
import { ContextType } from '../../../utils/store/types/ContextType';
import { TabsView } from '../../common/tabs/TabsView';
import { BorrowerDataTabsContentView } from './BorrowerDataTabsContentView';
import { CreateBorrowerDataFieldForm } from './forms/CreateBorrowerDataFieldForm';

export const BorrowerDataTabsView = () => {
  const [selectedTab, setSelectedTab] = useState(BorrowerDataTabs.LoanTypes);

  const { dispatch } = useContext<ContextType>(Context);

  const permissions = usePermissions();

  const createBorrowerDataField = addBorrowerDataField();

  const handleAddClick = (): void => {
    dispatch({
      type: OPEN_FORM_DIALOG,
      payload: {
        title: ADD_FIELD_TITLE,
        content: <CreateBorrowerDataFieldForm onSubmit={handleSubmit} />,
        formId: CREATE_BORROWER_DATA_FIELD_FORM_ID,
        submitType: FormDialogSubmitType.Add,
      },
    });
  };

  const handleSubmit = (data: FieldValues): void => {
    createBorrowerDataField
      .mutateAsync(data)
      .then((response) => closeFormHandler(dispatch, response.status));
  };

  return (
    <Grid>
      <Grid item sm={GridOptions.ExtraLarge} md={GridOptions.MediumLarge}>
        <TabsView
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          tabs={borrowerDataTabs}
          onButtonClick={
            selectedTab === BorrowerDataTabs.Fields &&
            permissions.hasCreatePermission(BORROWER_DATA_FIELDS_PERMISSION)
              ? handleAddClick
              : undefined
          }
          buttonLabel={ADD_FIELD_BUTTON}
        />
        <Grid item xs={GridOptions.ExtraLarge}>
          <BorrowerDataTabsContentView selectedTab={selectedTab} />
        </Grid>
      </Grid>
    </Grid>
  );
};
