import { DateRange } from '@mui/x-date-pickers-pro';

import { customHistory } from '../../components/App';
import { START_TIME } from '../constants/common';
import { FilterTypes } from '../enums/FilterTypes';
import { FiltersInitialState } from '../interfaces/filter/FiltersInitialState';

export const modifyFilters = (
  filters: FiltersInitialState[],
  setFilters: (value: FiltersInitialState[]) => void,
): object => {
  const formFields: Array<unknown> = [];
  const tableSettings = {};

  const appliedFilters: FiltersInitialState[] = filters.filter(
    (object: FiltersInitialState, index: number) => {
      if (object.type === FilterTypes.DateRange && object.value) {
        // TODO think for smarter way
        let from = object.value[0];
        let to = object.value[1];

        if (from === null && to === null) {
          return false;
        }

        if (from === null) {
          from = new Date(0);

          [from, to] = dateRangeTime([from, to]);
        } else if (to === null) {
          to = new Date();

          [from, to] = dateRangeTime([from, to]);
        }

        if (typeof object.value[0] !== 'string') {
          [from, to] = dateRangeTime([new Date(from), new Date(to)]);
        } else {
          to = to.split('T');
          to[1] = START_TIME;
          to = `${to[0]}T${to[1]}`;

          from = new Date(from);
          to = new Date(to);
          [from, to] = dateRangeTime([from, to]);
        }

        const filtersCopy = [...filters];
        filtersCopy[index].value = [from, to];
        setFilters(filtersCopy);

        object.value = [from, to];

        return true;
      }

      if (object.type === FilterTypes.Custom) {
        return true;
      }

      if (object.type === FilterTypes.MultipleSelect) {
        return object.value && (object.value as Array<number>).length > 0;
      }

      if (object.type === FilterTypes.Text) {
        return object.value;
      }

      return false;
    },
  );

  appliedFilters.forEach((object: FiltersInitialState) => {
    let val = object.value;
    switch (object.type) {
      case FilterTypes.DateRange:
        val = timeToUTC(val as []) as DateRange<Date>;
        formFields.push([
          object.name,
          'between',
          JSON.stringify(val && [(val[0] as Date).toISOString(), (val[1] as Date).toISOString()]),
        ]);
        break;

      case FilterTypes.MultipleSelect:
        formFields.push([
          object.name,
          'in',
          JSON.stringify(
            val && [...(val as []).map((object: unknown) => `${(object as { id: string })?.id}`)],
          ),
        ]);
        break;

      case FilterTypes.Custom:
        tableSettings[object.name] = object.value;
        break;

      default:
        formFields.push([object.name, 'like', val]);
        break;
    }
  });

  return {
    formFields,
    tableSettings,
  };
};

export const setFiltersQueryParams = (filters: FiltersInitialState[], search: string) => {
  const params = new URLSearchParams(search);
  let ids = [];
  filters.forEach((filter: FiltersInitialState) => {
    if (
      (typeof filter.value !== 'number' &&
        filter.value &&
        filter.value.length &&
        filter.value[0]) ||
      filter.type === FilterTypes.Custom
    ) {
      let from;
      let to;
      switch (filter.type) {
        case FilterTypes.MultipleSelect:
          ids = (filter.value as [])?.map((el: unknown) => (el as { id: string }).id);
          params.set(filter.name, JSON.stringify(ids));
          break;
        case FilterTypes.DateRange:
          // the date parsing is needed because json.stringify() returns the wrong date. it is with one day behind.

          [from, to] = filter.value as DateRange<Date>;
          [from, to] = timeToUTC([from, to] as DateRange<Date>) as DateRange<Date>;

          params.set(
            filter.name,
            `[["${(from as Date).toISOString()}","${(to as Date).toISOString()}"]]`,
          );
          break;
        default:
          params.set(filter.name, JSON.stringify(filter.value));
      }
    } else if (
      !filter.value ||
      (filter.value as []).length === 0 ||
      !(filter.value[0] && filter.value[1])
    ) {
      params.delete(filter.name);
    }
  });
  customHistory.push({ search: params.toString() });
};

export const applyFilters = (
  filters: FiltersInitialState[],
  setBeFilters: (beFilters: []) => void,
  search: string,
  setFilters: (value: FiltersInitialState[]) => void,
) => {
  setBeFilters(modifyFilters(filters, setFilters) as []);
  setFiltersQueryParams(filters, search);
};

export const paginationChange = (value: number, name: string, params: URLSearchParams) => {
  params.set(name, JSON.stringify(value));
  customHistory.push({ search: params.toString() });
};

export const dateRangeTime = (
  pickedDates: string | number | [] | DateRange<unknown> | [null, null] | [Date, Date],
) => {
  const from = new Date(pickedDates[0]).setHours(0, 0, 0);
  const to = new Date(pickedDates[1]).setHours(23, 59, 59);

  return [new Date(from), new Date(to)];
};

export const timeToUTC = (
  pickedDates: string | number | [] | DateRange<Date> | [null, null] | [Date, Date],
) => {
  let [from, to] = pickedDates as DateRange<Date>;

  if (from && to) {
    from = new Date(Date.UTC(from.getFullYear(), from.getMonth(), from.getDate(), 0, 0, 0));
    to = new Date(Date.UTC(to.getFullYear(), to.getMonth(), to.getDate(), 23, 59, 59));

    return [new Date(from), new Date(to)];
  }

  return pickedDates;
};
