import { GridColumns } from '@mui/x-data-grid-pro';

import { NAME_ID, PUBLIC_NAME_ID, SCORE_TO_ID } from '../../../../utils/constants/identifiers';
import { NAME_LABEL, PUBLIC_NAME_LABEL, SCORE_TO_LABEL } from '../../../../utils/constants/labels';
import { GRID_ACTIONS_COLUMN_FLEX } from '../../../../utils/constants/styles';
import { GridColType } from '../../../../utils/enums/GridColType';

export const originatorScoreClassesColumns: GridColumns = [
  { field: NAME_ID, headerName: NAME_LABEL, flex: GRID_ACTIONS_COLUMN_FLEX },
  { field: PUBLIC_NAME_ID, headerName: PUBLIC_NAME_LABEL, flex: GRID_ACTIONS_COLUMN_FLEX },
  {
    field: SCORE_TO_ID,
    headerName: SCORE_TO_LABEL,
    flex: GRID_ACTIONS_COLUMN_FLEX,
    type: GridColType.Decimal,
  },
];
