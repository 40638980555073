import React from 'react';

import Paper from '@mui/material/Paper';

import { transactionContentBoxShadow } from '../../../../styles/transactionsStyles';
import { TRANSACTIONS_PERMISSION } from '../../../../utils/constants/permissions';
import { TransactionTabs } from '../../../../utils/enums/TransactionTabs';
import { TransactionTabsViewProps } from '../../../../utils/interfaces/transaction/TransactionTabsViewProps';
import { TabsContentView } from '../../../common/tabs/TabsContentView';
import { TransactionBorrowerDataTab } from './tabs/TransactionBorroweDataTab';
import { TransactionFeesTab } from './tabs/TransactionFeesTab';
import { TransactionInfoTab } from './tabs/TransactionInfoTab';
import { TransactionLoansTab } from './tabs/TransactionLoansTab';
import { TransactionPaySchedulesTab } from './tabs/TransactionPaySchedulesTab';
import { TransactionPaymentsTab } from './tabs/TransactionPaymentsTab';

export const TransactionTabsView = (props: TransactionTabsViewProps) => {
  const { transactionData, selectedTab } = props;

  const transactionsPermissionType = TRANSACTIONS_PERMISSION;

  return (
    transactionData && (
      <Paper square sx={transactionContentBoxShadow}>
        <TabsContentView
          selectedTab={selectedTab}
          tabIndex={TransactionTabs.InfoTab}
          permissionType={transactionsPermissionType}
        >
          <TransactionInfoTab data={transactionData} />
        </TabsContentView>
        <TabsContentView
          selectedTab={selectedTab}
          tabIndex={TransactionTabs.LoansTab}
          permissionType={transactionsPermissionType}
        >
          {transactionData.loans && <TransactionLoansTab data={transactionData.loans} />}
        </TabsContentView>
        <TabsContentView
          selectedTab={selectedTab}
          tabIndex={TransactionTabs.BorrowerDataTab}
          permissionType={transactionsPermissionType}
        >
          {transactionData.borrowerData && (
            <TransactionBorrowerDataTab data={transactionData.borrowerData} />
          )}
        </TabsContentView>
        <TabsContentView
          selectedTab={selectedTab}
          tabIndex={TransactionTabs.PaySchedulesTab}
          permissionType={transactionsPermissionType}
        >
          {transactionData.paySchedules && (
            <TransactionPaySchedulesTab data={transactionData.paySchedules} />
          )}
        </TabsContentView>
        <TabsContentView
          selectedTab={selectedTab}
          tabIndex={TransactionTabs.PaymentsTab}
          permissionType={transactionsPermissionType}
        >
          {transactionData.payments && <TransactionPaymentsTab data={transactionData.payments} />}
        </TabsContentView>
        <TabsContentView
          selectedTab={selectedTab}
          tabIndex={TransactionTabs.FeesTab}
          permissionType={transactionsPermissionType}
        >
          {transactionData.fees && <TransactionFeesTab data={transactionData.fees} />}
        </TabsContentView>
      </Paper>
    )
  );
};
