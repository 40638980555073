import React from 'react';

import { Box, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRange, DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';

import { datePickerWrapperStyles, dateStyles } from '../../../../src/styles/advancedFiltersStyles';
import { dateRangeTime } from '../../../utils/helpers/advancedFilters';
import { DateRangePickerProps } from '../../../utils/interfaces/filter/DateRangePickerProps';

export const DateRangePickerComponent = (props: DateRangePickerProps) => {
  let { label, value, setValue } = props;

  let [from, to] = value as DateRange<Date>;

  if (value && typeof from === 'string' && typeof to === 'string') {
    from = new Date(from);
    to = new Date(to);

    [from, to] = dateRangeTime([from, to]);
    to.setDate(to.getDate() - 1);
    value = [from, to];
  }

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      localeText={{ start: `From ${label}`, end: `To  ${label}` }}
    >
      <DateRangePicker
        value={value as DateRange<Date>}
        onChange={(newDate) => {
          setValue(newDate);
        }}
        renderInput={(startProps, endProps) => (
          <Box sx={datePickerWrapperStyles}>
            <TextField sx={dateStyles} {...startProps} />
            <TextField sx={dateStyles} {...endProps} />
          </Box>
        )}
      />
    </LocalizationProvider>
  );
};
