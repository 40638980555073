import React from 'react';

import { LoansDataResponse } from '../../../../../api/types/originatorTypes';
import { loansLoanColumns } from '../../../../../common/loans/loansLoanColumns';
import { ID } from '../../../../../utils/constants/identifiers';
import { getGridColumns } from '../../../../../utils/helpers/getGridColumns';
import { sortModelChange } from '../../../../../utils/helpers/sorting';
import { LoansLoanTableProps } from '../../../../../utils/interfaces/loans/LoansLoanTableProps';
import { DataGridView } from '../../../../common/grid/DataGridView';

export const LoansLoanTableView = (props: LoansLoanTableProps) => {
  const { loans, filters, setNewSort } = props;

  return (
    <DataGridView
      rowId={ID}
      data={(loans as LoansDataResponse)?.data || null}
      dataCount={(loans as LoansDataResponse)?.page?.totalElements}
      columns={getGridColumns(loansLoanColumns)}
      disableColumnMenu={true}
      tableSettings={filters?.tableSettings}
      disableColumnResize={false}
      paginationMode="server"
      setNewSort={setNewSort}
      sortModelChange={sortModelChange}
      sortingMode="server"
    />
  );
};
