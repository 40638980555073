import * as React from 'react';

import {
  getAllOriginatorTransactions,
  getAllTransactions,
} from '../../../../api/controllers/TransactionController';
import { MultiTransactionResponse } from '../../../../api/types/originatorTypes';
import { transactionChildColumns } from '../../../../common/tableColumns/transactions/transactionChildColumns';
import { transactionParentColumns } from '../../../../common/tableColumns/transactions/transactionParentColumns';
import { TableDataProps } from '../../../../utils/interfaces/table/TableDataProps';
import { TableView } from '../../../common/table/TableView';

export const TransactionDataGrid = <T extends object>(props: TableDataProps<T>) => {
  const { handleRowClick, filters, originatorId } = props;

  const allTransactions = originatorId
    ? getAllOriginatorTransactions(originatorId, filters)
    : getAllTransactions(filters);

  return (
    <TableView
      dataCount={(allTransactions as MultiTransactionResponse)?.page?.totalElements}
      parentColumns={transactionParentColumns()}
      mainColumns={transactionChildColumns()}
      data={(allTransactions as MultiTransactionResponse)?.data || []}
      handleRowClick={handleRowClick}
      tableSettings={filters?.tableSettings}
    />
  );
};
