import React from 'react';

import TextField from '@mui/material/TextField';

import { authFieldStyles } from '../../../styles/authFormStyles';
import { PASSWORD_FIELD_ID } from '../../../utils/constants/identifiers';
import { PASSWORD_LABEL } from '../../../utils/constants/labels';
import { REQUIRED_FIELD_MESSAGE } from '../../../utils/constants/messages';
import { FORM_FIELD_MARGIN } from '../../../utils/constants/styles';
import {
  handleRequiredFields,
  handleSpacesForRequiredFields,
} from '../../../utils/helpers/formFieldsHelper';
import { PasswordFieldProps } from '../../../utils/interfaces/form/FormFieldProps';

export const PasswordField = (props: PasswordFieldProps) => {
  const { register, errors, id, label } = props;

  const passwordId = id || PASSWORD_FIELD_ID;
  const passwordLabel = label || PASSWORD_LABEL;

  return (
    <TextField
      margin={FORM_FIELD_MARGIN}
      id={passwordId}
      label={passwordLabel}
      type="password"
      InputLabelProps={{ required: true }}
      {...register(passwordId, {
        required: REQUIRED_FIELD_MESSAGE,
        validate: handleSpacesForRequiredFields,
      })}
      error={Boolean(errors[passwordId])}
      helperText={handleRequiredFields(errors, passwordId)}
      sx={authFieldStyles}
    />
  );
};
