import {
  APP_MODE_ADD_IF_MISSING_ID,
  APP_MODE_OVERRIDE_ID,
  APP_MODE_VALIDATE_ID,
} from '../constants/identifiers';
import {
  APPLICATION_MODE_LABEL,
  APP_MODE_ADD_IF_MISSING_LABEL,
  APP_MODE_OVERRIDE_LABEL,
  APP_MODE_VALIDATE_LABEL,
} from '../constants/labels';

export const styledDetailValueMapper = (title: string, value?: string | number) => {
  let val = value;

  if (title === APPLICATION_MODE_LABEL) {
    switch (value) {
      case APP_MODE_VALIDATE_ID:
        val = APP_MODE_VALIDATE_LABEL;
        break;
      case APP_MODE_ADD_IF_MISSING_ID:
        val = APP_MODE_ADD_IF_MISSING_LABEL;
        break;
      case APP_MODE_OVERRIDE_ID:
        val = APP_MODE_OVERRIDE_LABEL;
        break;
      default:
        val = value;
        break;
    }
  }

  return val;
};
