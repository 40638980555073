import { BorrowerDataLoanField } from '../../utils/interfaces/borrowerData/loans/BorrowerDataLoanField';
import { SelectorChoicesType } from '../../utils/interfaces/form/SelectorChoicesType';
import { getBorrowerDataFields } from '../controllers/BorrowerDataController';
import { LoanBorrowerDataField } from '../types/originatorTypes';

export const mapBorrowerLoanFields = (fields: LoanBorrowerDataField[]): BorrowerDataLoanField[] => {
  let borrowerLoanFieldsMapped: BorrowerDataLoanField[] = [];

  fields.forEach((x) => {
    borrowerLoanFieldsMapped.push({
      id: x.id,
      fieldId: x.borrowerDataField?.id,
      label: x.borrowerDataField?.label,
      description: x.borrowerDataField?.description,
    } as BorrowerDataLoanField);
  });

  return borrowerLoanFieldsMapped;
};

export const mapBorrowerDataFieldsSelector = (): SelectorChoicesType[] => {
  const borrowerDataFields = getBorrowerDataFields();

  const selectorFields: SelectorChoicesType[] = [];

  borrowerDataFields.forEach((field) => {
    if (field.id && field.label) {
      selectorFields.push({
        id: field.id,
        name: field.label,
        description: field.description,
      });
    }
  });

  return selectorFields;
};
