import React from 'react';
import { useNavigate } from 'react-router';

import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import {
  authLinkButtonStyles,
  authRequiredFieldStyles,
  authSubTitleStyles,
} from '../../../styles/authFormStyles';
import { justifyCenterContent } from '../../../styles/commonStyles';
import { BUTTON_TAG } from '../../../utils/constants/components';
import { ROUTE_PATH_LOGIN } from '../../../utils/constants/routes';
import { REQUIRED_MARKED_FIELDS } from '../../../utils/constants/validations';
import { TypographyVariants } from '../../../utils/enums/TypographyVariants';
import { AuthFormFooterViewProps } from '../../../utils/interfaces/authentication/AuthFormFooterViewProps';
import { AuthButtonView } from './AuthButtonView';

export const AuthFormFooterView = (props: AuthFormFooterViewProps) => {
  const { title, details, linkPath, buttonLabel } = props;

  const history = useNavigate();

  return (
    <>
      <Typography sx={authRequiredFieldStyles}>{REQUIRED_MARKED_FIELDS}</Typography>
      <AuthButtonView label={buttonLabel} />
      <Grid container sx={justifyCenterContent}>
        <Typography variant={TypographyVariants.H6} sx={authSubTitleStyles}>
          {title}
        </Typography>
        <Link
          component={BUTTON_TAG}
          variant={TypographyVariants.H6}
          sx={authLinkButtonStyles}
          onClick={() => history(linkPath || ROUTE_PATH_LOGIN)}
        >
          {details}
        </Link>
      </Grid>
    </>
  );
};
