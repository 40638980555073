import React from 'react';

import { Box, Typography } from '@mui/material';

import {
  additionalInfoStyles,
  additionalInfoWrapperStyles,
  filterTypographyStyles,
} from '../../../styles/exportReport';
import { FILTERS_ADMIN_INITIAL_STATE } from '../../../utils/constants/advancedFilters';
import { ROWS_LABEL } from '../../../utils/constants/labels';
import { AdditionalInfoProps } from '../../../utils/interfaces/reports/AdditionalInfoProps';

export const AdditionalInfo = (props: AdditionalInfoProps) => {
  const { report, setFilterValue } = props;

  const filters = report.filter && JSON.parse(report.filter);

  return (
    <Box sx={additionalInfoWrapperStyles}>
      <Typography>
        {ROWS_LABEL} {report.rows}
      </Typography>

      <Box sx={additionalInfoStyles}>
        {filters && filters.length > 0
          ? filters.map((filter: string) => {
              const filterName = filter[0];
              const filterValue = filter[2];

              let filterLabel;
              FILTERS_ADMIN_INITIAL_STATE.forEach((filter) => {
                if (filter.name === filterName) {
                  filterLabel = filter.label as string;
                }
              });

              return (
                <Typography key={filterName} sx={filterTypographyStyles}>
                  {filterLabel}: {setFilterValue(filterValue)}
                </Typography>
              );
            })
          : null}
      </Box>
    </Box>
  );
};
