import React, { Suspense as SuspenseCom } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryErrorResetBoundary } from 'react-query';

import Button from '@mui/material/Button';

import { TRY_AGAIN_LABEL } from '../utils/constants/labels';

export const Suspense = ({ children }: { children: React.ReactNode }) => {
  return (
    <SuspenseCom fallback={<LoadingScreen />}>
      <QueryErrorResetBoundary>
        {({ reset }) => (
          <ErrorBoundary
            onReset={reset}
            fallbackRender={({ resetErrorBoundary }) => (
              <>
                <ErrorScreen />
                <Button onClick={() => resetErrorBoundary()}>{TRY_AGAIN_LABEL}</Button>
              </>
            )}
          >
            {children}
          </ErrorBoundary>
        )}
      </QueryErrorResetBoundary>
    </SuspenseCom>
  );
};

// TODO: Implement loading screen.
const LoadingScreen = () => <h5>Loading...</h5>;

// TODO: Discuss and implement Error screen.
const ErrorScreen = () => <h5>Error!</h5>;
