import { COLOR_MAPPING } from './errorCardStyles';

export const dataGridContainerStyles = {
  height: '632px',
  '& .super-app-theme--error': {
    bgcolor: () => 'rgba(211, 47, 47, 0.1)',
  },
  '& .super-app-theme--warning': {
    bgcolor: () => 'rgba(237, 108, 2, 0.1)',
  },
};

export const dataGridColumnStyles = {
  '& .MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator': {
    display: 'none',
  },
  fontFamily: 'BlinkMacSystemFont, Roboto',
};

export const formViewGridStyles = { height: 215, width: '100%' };

export const warningIconStyles = {
  color: COLOR_MAPPING[0],
};

export const errorIconStyles = {
  color: COLOR_MAPPING[1],
};
