export const createCountrySpvFormStyles = {
  border: '1px dashed #7070704D',
  pl: 2,
  pr: 2,
  pb: 2,
};

export const countrySpvGridButtonStyles = {
  border: '1px dashed #7070704D',
  textAlign: 'center',
  mt: 2,
  pt: 2,
  pb: 2,
};
