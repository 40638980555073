import React from 'react';

import Box from '@mui/material/Box';

import { tabsContentStyles } from '../../../styles/commonStyles';
import { usePermissions } from '../../../utils/hooks/permissions/usePermissions';
import { TabsContentProps } from '../../../utils/interfaces/tabs/TabsContentProps';

export const TabsContentView = (props: TabsContentProps) => {
  const { children, selectedTab, tabIndex, disablePadding, permissionType } = props;
  const permissions = usePermissions();

  const hasPermission =
    typeof permissionType === 'string'
      ? permissions.hasReadPermission(permissionType)
      : permissionType &&
        permissions.hasReadPermission(permissionType[0]) &&
        permissions.hasReadPermission(permissionType[1]);

  return (
    <>
      {selectedTab === tabIndex && hasPermission && (
        <Box sx={disablePadding ? null : tabsContentStyles}>{children}</Box>
      )}
    </>
  );
};
