import { nameof } from 'ts-simple-nameof';

import { useGetRequest } from '../../utils/hooks/query/useGetRequest';
import { nomenclatureServiceClient } from '../apiClient';
import { Api, BaseModel, Country, Currency, NomenclatureType } from '../types/nomenclatureTypes';

export const getAllCountries = (): Country[] => {
  const { data: countries } = useGetRequest({
    key: nameof<Api<unknown>>((x) => x.api.getAllCountries()),
    func: async () => (await nomenclatureServiceClient.api.getAllCountries()).data,
  });

  if (!countries || !countries.data) {
    return [];
  }

  return countries.data;
};

export const getAllNomenclaturesByType = (type: NomenclatureType): BaseModel[] => {
  const params = { type };

  const { data: nomenclatureType } = useGetRequest({
    key: nameof<Api<unknown>>((x) => x.api.getAllNomenclaturesByType(params)),
    func: async () => (await nomenclatureServiceClient.api.getAllNomenclaturesByType(params)).data,
    params,
  });

  if (!nomenclatureType || !nomenclatureType.data) {
    return [];
  }

  return nomenclatureType.data;
};

export const getAllCurrencies = (): Currency[] => {
  const { data: currencies } = useGetRequest({
    key: nameof<Api<unknown>>((x) => x.api.getAllCurrencies),
    func: async () => (await nomenclatureServiceClient.api.getAllCurrencies()).data,
  });

  if (!currencies || !currencies.data) {
    return [];
  }

  return currencies.data;
};
