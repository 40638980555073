import { GridRowClassNameParams } from '@mui/x-data-grid-pro';

import { HAS_ERROR_ID } from '../constants/identifiers';
import { GridRowStatus } from '../enums/GridRowStatus';

export const getGridRowStatus = (
  params: GridRowClassNameParams<any>,
  applyErrorOnRows?: boolean,
) => {
  if (applyErrorOnRows) {
    if (params.row[HAS_ERROR_ID]) {
      return GridRowStatus.Error;
    }

    if (params.row.warning) {
      return GridRowStatus.Warning;
    }
  }

  return GridRowStatus.Default;
};
