import React from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';

import { formMainStyles } from '../../../styles/authFormStyles';
import { resetPassword } from '../../../utils/authentication/authentication';
import { RESET_PASSWORD_BUTTON } from '../../../utils/constants/buttons';
import {
  BACK_TO_SIGN_IN_LABEL,
  ENTER_EMAIL_PASSWORD_RESET_LABEL,
  ERROR_LABEL,
  PASSWORD_RESET_LABEL,
  WARNING_LABEL,
} from '../../../utils/constants/labels';
import {
  NO_CONNECTION_TO_THE_SERVER_MESSAGE,
  RESET_PASSWORD_SUCCESS_MESSAGE,
  UNEXPECTED_ERROR_MESSAGE,
} from '../../../utils/constants/messages';
import { AUTH_REDIRECT_MS } from '../../../utils/constants/options';
import { ROUTE_PATH_LOGIN } from '../../../utils/constants/routes';
import { HttpStatusCodes } from '../../../utils/enums/HttpStatusCodes';
import { useAuthResponseHandler } from '../../../utils/hooks/useAuthResponseHandler';
import { AuthErrorType } from '../../../utils/interfaces/authentication/AuthErrorType';
import { AlertView } from '../../common/AlertView';
import { EmailField } from '../../common/form/EmailField';
import { AuthFormFooterView } from './AuthFormFooterView';
import { AuthFormHeaderView } from './AuthFormHeaderView';

export const ResetPasswordForm = () => {
  const authResponseHandler = useAuthResponseHandler();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onError = (error: AuthErrorType): void => {
    authResponseHandler.handleError(error);
  };

  const history = useNavigate();

  const onSuccess = (): void => {
    authResponseHandler.successResultHandle(HttpStatusCodes.Created);

    reset();

    setTimeout((): void => {
      history(ROUTE_PATH_LOGIN);
    }, AUTH_REDIRECT_MS);
  };

  const onSubmit = (data: FieldValues): void => {
    resetPassword(data.email, onSuccess, onError);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={formMainStyles}>
      <AuthFormHeaderView title={PASSWORD_RESET_LABEL} details={ENTER_EMAIL_PASSWORD_RESET_LABEL} />
      {authResponseHandler.isSuccess && <AlertView message={RESET_PASSWORD_SUCCESS_MESSAGE} />}
      {authResponseHandler.authenticationError && (
        <AlertView
          severity={WARNING_LABEL}
          message={authResponseHandler.authenticationError.detail}
        />
      )}
      {authResponseHandler.isServerError && (
        <AlertView severity={ERROR_LABEL} message={NO_CONNECTION_TO_THE_SERVER_MESSAGE} />
      )}
      {authResponseHandler.isClientError && (
        <AlertView severity={ERROR_LABEL} message={UNEXPECTED_ERROR_MESSAGE} />
      )}
      <EmailField register={register} errors={errors} />
      <AuthFormFooterView
        buttonLabel={RESET_PASSWORD_BUTTON}
        details={BACK_TO_SIGN_IN_LABEL}
        linkPath={ROUTE_PATH_LOGIN}
      />
    </form>
  );
};
