import React from 'react';

import { GridColumns } from '@mui/x-data-grid-pro';

import { borrowerDataFieldsColumns } from '../../../../common/tableColumns/borrowerData/borrowerDataFieldsColumns';
import { GRID_ACTIONS } from '../../../../utils/constants/common';
import { GridColType } from '../../../../utils/enums/GridColType';
import { getGridColumns } from '../../../../utils/helpers/getGridColumns';
import { BorrowerDataFieldsTableProps } from '../../../../utils/interfaces/borrowerData/fields/BorrowerDataFieldsTableProps';
import { DataGridView } from '../../../common/grid/DataGridView';
import { GridActionsView } from '../../../common/grid/GridActionsView';

export const BorrowerDataFieldsTableView = (props: BorrowerDataFieldsTableProps) => {
  const { handleEdit, handleDelete, fieldsData, permissionType } = props;

  const actions: GridColumns = [
    {
      field: GRID_ACTIONS,
      type: GridColType.Actions,
      getActions: ({ id }) => [
        <GridActionsView
          key={id}
          handleEditClick={() => (handleEdit ? handleEdit(id) : null)}
          handleDeleteClick={() => (handleDelete ? handleDelete(id) : null)}
          permissionType={permissionType}
        />,
      ],
    },
  ];

  const columns: GridColumns = [...borrowerDataFieldsColumns, ...actions];

  return <DataGridView data={fieldsData} columns={getGridColumns(columns)} />;
};
