import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { flexGrowStyle } from '../../../styles/commonStyles';
import { tabsBoxStyles } from '../../../styles/tabsStyles';
import { TABS_VIEW_SCROLL_BUTTONS_TYPE } from '../../../utils/constants/common';
import { ButtonVariants } from '../../../utils/enums/ButtonVariants';
import { TabsViewProps } from '../../../utils/interfaces/tabs/TabsViewProps';

export const TabsView = (props: TabsViewProps) => {
  const { setSelectedTab, selectedTab, tabs, tabsVariant, onButtonClick, buttonLabel } = props;

  const handleChange = (event: React.SyntheticEvent, newValue: number): void => {
    setSelectedTab(newValue);
  };

  return (
    <Toolbar disableGutters>
      <Paper square sx={tabsBoxStyles}>
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          variant={tabsVariant}
          scrollButtons={TABS_VIEW_SCROLL_BUTTONS_TYPE}
        >
          {tabs.map((tab) => {
            return <Tab key={tab.key} label={tab.label} />;
          })}
        </Tabs>
      </Paper>
      <Typography noWrap sx={flexGrowStyle} />
      {onButtonClick && (
        <Button
          onClick={onButtonClick}
          variant={ButtonVariants.Contained}
          startIcon={<AddIcon />}
          sx={{ mt: 2 }}
        >
          {buttonLabel}
        </Button>
      )}
    </Toolbar>
  );
};
