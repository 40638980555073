export const ADD_ORIGINATOR_BUTTON = 'add originator';
export const CREATE_ACCOUNT_BUTTON = 'create account';
export const RESET_PASSWORD_BUTTON = 'reset password';
export const SIGN_IN_BUTTON = 'sign in';
export const LOG_OUT_BUTTON = 'logout';
export const ADD_SPV_BUTTON = 'add spv';
export const ADD_GROUP_BUTTON = 'add group';
export const ADD_SCORE_CLASS_BUTTON = 'add score class';
export const ADD_BUTTON = 'add';
export const EDIT_BUTTON = 'edit';
export const SEND_BUTTON = 'send';
export const CANCEL_BUTTON = 'cancel';
export const AGREE_BUTTON = 'agree';
export const DISAGREE_BUTTON = 'disagree';
export const IMPORT_BUTTON = 'import';
export const SHOW_ADVANCED_FILTERS_BUTTON = 'show advanced filters';
export const HIDE_ADVANCED_FILTERS_BUTTON = 'hide advanced filters';
export const SHOW_REPORTS_BUTTON = 'show reports';
export const HIDE_REPORTS_BUTTON = 'hide reports';
export const SEARCH_BUTTON = 'search';
export const CHOOSE_FILE_BUTTON = 'choose file';
export const APPROVE_BUTTON = 'approve';
export const EXPORT_BUTTON = 'export';
export const UPDATE_BUTTON = 'update';
export const ADD_PRODUCT_BUTTON = 'add product';
export const ADD_COUNTRY_BUTTON = 'add country';
export const ADD_ACCOUNT_BUTTON = 'add account';
export const MORE_BUTTON = 'More';
export const ADD_CURRENCY_BUTTON = 'add currency';
export const CONTINUE_BUTTON = 'Continue';
export const CONTINUE_TO_SPVS_BUTTON = 'continue to spvs';
export const ADD_FIELD_BUTTON = 'add field';
export const EXPORT_REPORT_BUTTON = 'export report';

// Button types
export const BUTTON_SUBMIT_TYPE = 'submit';
export const BUTTON_TYPE = 'button';
