import React from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { GridActionsCellItem } from '@mui/x-data-grid-pro';

import { DELETE_LABEL, EDIT_LABEL } from '../../../utils/constants/labels';
import { COLOR_INHERIT } from '../../../utils/constants/styles';
import { usePermissions } from '../../../utils/hooks/permissions/usePermissions';
import { GridActionsProps } from '../../../utils/interfaces/grid/GridActionsProps';

export const GridActionsView = (props: GridActionsProps) => {
  const { handleEditClick, handleDeleteClick, CustomDeleteIcon, permissionType, canRead } = props;

  const permissions = usePermissions();

  const allowEdit =
    canRead ||
    ((permissionType ? permissions.hasUpdatePermission(permissionType) : false) && handleEditClick);

  const allowDelete =
    (permissionType ? permissions.hasDeletePermission(permissionType) : false) && handleDeleteClick;

  return (
    <>
      {allowEdit && (
        <GridActionsCellItem
          icon={<EditIcon />}
          label={EDIT_LABEL}
          onClick={handleEditClick}
          color={COLOR_INHERIT}
        />
      )}
      {allowDelete && (
        <GridActionsCellItem
          icon={CustomDeleteIcon ? <CustomDeleteIcon /> : <DeleteIcon />}
          label={DELETE_LABEL}
          onClick={handleDeleteClick}
          color={COLOR_INHERIT}
        />
      )}
    </>
  );
};
