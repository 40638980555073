import React from 'react';
import { useForm } from 'react-hook-form';

import { Box, Grid, Typography } from '@mui/material';

import { tabToolbarTitleStyles } from '../../../../../../styles/commonStyles';
import { baseFormStyles, formGridSpacing } from '../../../../../../styles/formStyles';
import { FORM_TAG } from '../../../../../../utils/constants/components';
import {
  IS_ACTIVE_IMPORT_LOANS_ID,
  IS_ACTIVE_IMPORT_PAYMENTS_ID,
  UPDATE_SETTINGS_FORM_ID,
} from '../../../../../../utils/constants/identifiers';
import {
  ACTIVE_IMPORT_LOANS,
  ACTIVE_IMPORT_PAYMENTS,
  GENERAL_SETTINGS_LABEL,
} from '../../../../../../utils/constants/labels';
import { OriginatorSettingsUpdateProps } from '../../../../../../utils/interfaces/originator/details/OriginatorSettingsViewProps';
import { CheckboxField } from '../../../../../common/form/CheckboxField';
import { LargeGridField } from '../../../../../common/form/grid/LargeGridField';
import { MediumGridField } from '../../../../../common/form/grid/MediumGridField';

export const UpdateSettingsForm = (props: OriginatorSettingsUpdateProps) => {
  const { originatorSettings, onSubmit } = props;

  const { handleSubmit, register, setValue } = useForm();

  return (
    <Box
      sx={baseFormStyles}
      component={FORM_TAG}
      id={UPDATE_SETTINGS_FORM_ID}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid container rowSpacing={1} columnSpacing={formGridSpacing}>
        <LargeGridField>
          <Typography sx={tabToolbarTitleStyles} gutterBottom>
            {GENERAL_SETTINGS_LABEL}
          </Typography>
        </LargeGridField>
        <MediumGridField>
          <CheckboxField
            id={IS_ACTIVE_IMPORT_LOANS_ID}
            label={ACTIVE_IMPORT_LOANS}
            register={register}
            isChecked={originatorSettings.isActiveImportLoans}
            setValue={setValue}
          />
        </MediumGridField>
        <MediumGridField>
          <CheckboxField
            id={IS_ACTIVE_IMPORT_PAYMENTS_ID}
            label={ACTIVE_IMPORT_PAYMENTS}
            register={register}
            isChecked={originatorSettings.isActiveImportPayments}
            setValue={setValue}
          />
        </MediumGridField>
      </Grid>
    </Box>
  );
};
