import { KeycloakServiceCustom } from 'virava';

import { importerApiConfig } from '../config/importer.api.config';
import { nomenclatureOriginatorApiConfig } from '../config/nomenclature.api.config';
import { originatorApiConfig } from '../config/originator.api.config';
import { Api as ImporterApi } from './types/importerTypes';
import { Api as NomenclatureApi } from './types/nomenclatureTypes';
import { Api as OriginatorApi } from './types/originatorTypes';

export const viravaService = new KeycloakServiceCustom();

export const originatorServiceClient = new OriginatorApi(originatorApiConfig);
export const nomenclatureServiceClient = new NomenclatureApi(nomenclatureOriginatorApiConfig);
export const importerServiceClient = new ImporterApi(importerApiConfig);
