import { nameof } from 'ts-simple-nameof';

import { useGetRequest } from '../../utils/hooks/query/useGetRequest';
import { useMutationRequest } from '../../utils/hooks/query/useMutationRequest';
import { originatorServiceClient } from '../apiClient';
import { Api, Spv, SpvBase } from '../types/originatorTypes';

export const getAllSpvs = (): Spv[] => {
  const { data: spvs } = useGetRequest({
    key: nameof<Api<unknown>>((x) => x.api.getAllSpvs),
    func: async () => (await originatorServiceClient.api.getAllSpvs()).data,
  });

  if (!spvs || !spvs.data) {
    return [];
  }

  return spvs.data;
};

export const getSpvById = (id: number): Spv => {
  const params = { id };

  const { data: spv } = useGetRequest({
    func: async () => (await originatorServiceClient.api.getSpvById(id)).data,
    key: nameof<Api<unknown>>((x) => x.api.getSpvById),
    params,
  });

  return spv?.data as Spv;
};

export const createSpv = () =>
  useMutationRequest(
    (request: SpvBase) => originatorServiceClient.api.createSpv(request),
    nameof<Api<unknown>>((x) => x.api.getAllSpvs),
  );

export const updateSpv = (id: number) =>
  useMutationRequest(
    (request: SpvBase) => originatorServiceClient.api.updateSpv(id, request),
    nameof<Api<unknown>>((x) => x.api.getAllSpvs),
  );
