export const styledTextBoxStyles = {
  display: 'flex',
  alignItems: 'center',
  minWidth: '100%',
  mt: 1,
};

export const originatorTabsContentHeight = {
  maxHeight: '600px',
  height: '600px',
};

export const styledTextValueStyles = {
  pl: 1,
};

export const originatorDetailsCardStyles = {
  mt: 2,
};

export const originatorDetailsEditButtonStyles = {
  color: '#000000',
};

export const originatorDetailsHeaderStyles = {
  marginTop: '-15px',
};

export const originatorProductsBoxStyles = {
  overflowY: 'auto',
  display: 'flex',
  flexGrow: 1,
  flexDirection: 'column',
  ...originatorTabsContentHeight,
};

export const originatorProductsGridNoDataStyles = {
  textAlign: ' center',
};

export const originatorProductsGridCardCountryStyles = {
  mb: 1.5,
};
