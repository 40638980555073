import React, { useContext } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { GridColumns, GridRowId } from '@mui/x-data-grid-pro';

import {
  deleteOriginatorCountrySpv,
  getOriginatorCountrySpvs,
} from '../../../../../api/controllers/OriginatorSpvController';
import { originatorCountrySpvColumns } from '../../../../../common/tableColumns/originators/details/originatorCountrySpvColumns';
import { countrySpvGridButtonStyles } from '../../../../../styles/countrySpvStyles';
import {
  CLOSE_CONFIRMATION_DIALOG,
  OPEN_CONFIRMATION_DIALOG,
} from '../../../../../utils/constants/actions';
import { ADD_SPV_BUTTON } from '../../../../../utils/constants/buttons';
import { GRID_ACTIONS } from '../../../../../utils/constants/common';
import { DELETE_COUNTRY_SPV_CONFIRMATION } from '../../../../../utils/constants/confirmations';
import { DELETE_COUNTRY_SPV_DIALOG_ID } from '../../../../../utils/constants/identifiers';
import { ORIGINATOR_COUNTRIES_SPV_PERMISSION } from '../../../../../utils/constants/permissions';
import { GRID_ACTIONS_COLUMN_FLEX } from '../../../../../utils/constants/styles';
import { DELETE_COUNTRY_SPV_TITLE } from '../../../../../utils/constants/titles';
import { ButtonVariants } from '../../../../../utils/enums/ButtonVariants';
import { CountrySpvViewType } from '../../../../../utils/enums/CountrySpvViewType';
import { GridColType } from '../../../../../utils/enums/GridColType';
import { Context } from '../../../../../utils/store';
import { GridActionsView } from '../../../../common/grid/GridActionsView';
import { FormGridView } from '../../../../common/grid/formGrid/FormGridView';

export const CountrySpvGrid = (props: {
  setView(view: CountrySpvViewType): void;
  originatorId: number;
  originatorCountryId: number;
}) => {
  const { setView, originatorId, originatorCountryId } = props;
  const { dispatch } = useContext(Context);

  const originatorCountrySpvs = getOriginatorCountrySpvs(originatorId, originatorCountryId);

  const deleteSpvMutation = deleteOriginatorCountrySpv(originatorId, originatorCountryId);

  const handleDeleteConfirm = (id: number): void => {
    deleteSpvMutation.mutateAsync(id);

    dispatch({ type: CLOSE_CONFIRMATION_DIALOG });
  };

  const handleDelete = (id: GridRowId) => {
    dispatch({
      type: OPEN_CONFIRMATION_DIALOG,
      payload: {
        dialogId: DELETE_COUNTRY_SPV_DIALOG_ID,
        title: DELETE_COUNTRY_SPV_TITLE,
        content: DELETE_COUNTRY_SPV_CONFIRMATION,
        onAgree: () => handleDeleteConfirm(Number(id)),
      },
    });
  };

  const handleAdd = () => {
    setView(CountrySpvViewType.Form);
  };

  const actions: GridColumns = [
    {
      field: GRID_ACTIONS,
      type: GridColType.Actions,
      flex: GRID_ACTIONS_COLUMN_FLEX,
      getActions: ({ id }) => [
        <GridActionsView
          key={id}
          handleDeleteClick={() => (handleDelete ? handleDelete(id) : null)}
          permissionType={ORIGINATOR_COUNTRIES_SPV_PERMISSION}
        />,
      ],
    },
  ];

  const columns = [...originatorCountrySpvColumns, ...actions];

  return (
    <>
      <FormGridView columns={columns} data={originatorCountrySpvs} />
      <Box sx={countrySpvGridButtonStyles}>
        <Button variant={ButtonVariants.Outlined} onClick={handleAdd}>
          {ADD_SPV_BUTTON}
        </Button>
      </Box>
    </>
  );
};
