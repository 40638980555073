import { DRAWER_WIDTH } from '../utils/constants/styles';

export const gridHeaderStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  paddingTop: 2,
  paddingBottom: 2,
};

export const gridButtonWrapperStyles = {
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
};

export const justifyCenterContent = {
  justifyContent: 'center',
};

export const linkUnderlineStyles = {
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
  fontSize: 16,
};

export const displayFlex = {
  display: 'flex',
};

export const versionContainer = {
  textAlign: 'center',
  padding: '16px',
  fontSize: '12px',
};

export const mainContentVisibleSidebar = {
  flexGrow: 1,
  p: 3,
  width: { sm: `calc(100% - ${DRAWER_WIDTH}px)` },
};

export const mainContentInvisibleSidebar = {
  ...mainContentVisibleSidebar,
  marginLeft: { sm: `-${DRAWER_WIDTH}px` },
};

export const flexGrowStyle = {
  flexGrow: 1,
};

export const fontWeightBold = {
  fontWeight: 'bold',
};

export const responseHandlerAlertViewStyles = {
  width: '100%',
  mt: 9,
};

export const tabsContentStyles = {
  p: 2,
};

export const tabToolbarStyles = {
  minHeight: 0,
};

export const tabToolbarTitleStyles = {
  fontSize: 14,
  color: '#00000061',
  flexGrow: 1,
};
