import React, { useEffect, useState } from 'react';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import { CheckboxFieldProps } from '../../../utils/interfaces/form/CheckboxFieldProps';
import { TooltipInfoView } from '../TooltipInfoView';

export const CheckboxField = (props: CheckboxFieldProps) => {
  const { id, label, tooltip, register, isChecked, disabled, setValue } = props;

  const [currentValue, setCurrentValue] = useState(isChecked);

  setValue(id, currentValue);

  useEffect(() => {
    setCurrentValue(isChecked);
  }, [isChecked]);

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            checked={currentValue}
            onChange={() => setCurrentValue(!currentValue)}
            disabled={disabled}
          />
        }
        label={label}
        {...register(id)}
      />
      {tooltip && <TooltipInfoView title={tooltip} />}
    </>
  );
};
