import React from 'react';
import { useForm } from 'react-hook-form';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { formGridSpacing } from '../../../../styles/formStyles';
import { BusinessStatusMockData } from '../../../../test/mocks/BusinessStatusMockData';
import { FORM_TAG } from '../../../../utils/constants/components';
import {
  ADDRESS_ID,
  BUSINESS_STATUS_ID,
  CEO_ID,
  COUNTRY_OF_REGISTRATION_ID,
  CREATE_ORIGINATOR_FORM_ID,
  FEE,
  LEGAL_NAME_ID,
  NAME_ID,
  ORIGINATOR_GROUP_ID,
  PUBLIC_NAME_ID,
  REGISTRATION_NUMBER_ID,
  TRADE_NAME_ID,
} from '../../../../utils/constants/identifiers';
import {
  ADDRESS_LABEL,
  BUSINESS_STATUS_LABEL,
  CEO_LABEL,
  COUNTRY_OF_REGISTRATION_LABEL,
  FEE_LABEL,
  GROUP_LABEL,
  LEGAL_NAME_LABEL,
  NAME_LABEL,
  PUBLIC_NAME_LABEL,
  REGISTRATION_NUMBER_LABEL,
  TRADE_NAME_LABEL,
} from '../../../../utils/constants/labels';
import { ORIGINATOR_FEE_DECIMAL_ACCURACY } from '../../../../utils/constants/options';
import { InputFieldType } from '../../../../utils/enums/InputFieldType';
import { SelectorChoicesType } from '../../../../utils/interfaces/form/SelectorChoicesType';
import { CreateOriginatorFormProps } from '../../../../utils/interfaces/originator/CreateOriginatorFormProps';
import { InputField } from '../../../common/form/InputField';
import { NumberInputField } from '../../../common/form/NumberInputField';
import { SelectField } from '../../../common/form/SelectField';
import { LargeGridField } from '../../../common/form/grid/LargeGridField';
import { MediumGridField } from '../../../common/form/grid/MediumGridField';

export const CreateOriginatorForm = (props: CreateOriginatorFormProps) => {
  const { onSubmit, countries, groups } = props;

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
  } = useForm();

  return (
    <Box component={FORM_TAG} id={CREATE_ORIGINATOR_FORM_ID} onSubmit={handleSubmit(onSubmit)}>
      <Grid container rowSpacing={1} columnSpacing={formGridSpacing}>
        <MediumGridField>
          <InputField
            id={NAME_ID}
            label={NAME_LABEL}
            register={register}
            errors={errors}
            required
          />
        </MediumGridField>
        <MediumGridField>
          <InputField
            id={PUBLIC_NAME_ID}
            label={PUBLIC_NAME_LABEL}
            register={register}
            errors={errors}
            required
          />
        </MediumGridField>
        <MediumGridField>
          <InputField
            id={LEGAL_NAME_ID}
            label={LEGAL_NAME_LABEL}
            register={register}
            errors={errors}
            required
          />
        </MediumGridField>
        <MediumGridField>
          <InputField
            id={TRADE_NAME_ID}
            label={TRADE_NAME_LABEL}
            register={register}
            errors={errors}
            required
          />
        </MediumGridField>
        <MediumGridField>
          <InputField
            id={REGISTRATION_NUMBER_ID}
            label={REGISTRATION_NUMBER_LABEL}
            register={register}
            errors={errors}
            required
          />
        </MediumGridField>
        <MediumGridField>
          <InputField id={CEO_ID} label={CEO_LABEL} register={register} errors={errors} required />
        </MediumGridField>
        <MediumGridField>
          <SelectField
            id={COUNTRY_OF_REGISTRATION_ID}
            label={COUNTRY_OF_REGISTRATION_LABEL}
            control={control}
            choices={countries}
            required
          />
        </MediumGridField>
        <MediumGridField>
          <SelectField
            id={ORIGINATOR_GROUP_ID}
            label={GROUP_LABEL}
            control={control}
            choices={groups}
            required={false}
          />
        </MediumGridField>
        <LargeGridField>
          <InputField
            id={ADDRESS_ID}
            label={ADDRESS_LABEL}
            register={register}
            errors={errors}
            required
          />
        </LargeGridField>
        <LargeGridField>
          <NumberInputField
            id={FEE}
            label={FEE_LABEL}
            register={register}
            errors={errors}
            inputType={InputFieldType.Decimal}
            decimalAccuracy={ORIGINATOR_FEE_DECIMAL_ACCURACY}
            required
          />
        </LargeGridField>
        <LargeGridField>
          <SelectField
            id={BUSINESS_STATUS_ID}
            label={BUSINESS_STATUS_LABEL}
            control={control}
            choices={BusinessStatusMockData as SelectorChoicesType[]}
            required
          />
        </LargeGridField>
      </Grid>
    </Box>
  );
};
