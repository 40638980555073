import React from 'react';

import { Card, CardContent, CardHeader, Typography } from '@mui/material';

import {
  COLOR_MAPPING,
  errorCardContentStyles,
  errorCardHeaderStyles,
  errorCardStyles,
  errorCardTitleStyles,
} from '../../styles/errorCardStyles';
import { ErrorCardProps } from '../../utils/interfaces/ErrorCardProps';

export const ErrorCard = (props: ErrorCardProps) => {
  const { title, content, type } = props;

  const contentColor = COLOR_MAPPING[type];

  return (
    <Card sx={errorCardStyles}>
      <CardHeader
        title={<Typography sx={errorCardHeaderStyles}>{title}</Typography>}
        sx={errorCardTitleStyles}
      />
      <CardContent
        sx={{
          ...errorCardContentStyles,
          color: contentColor,
        }}
      >
        {content}
      </CardContent>
    </Card>
  );
};
