import React from 'react';

import { GridColumns } from '@mui/x-data-grid-pro';

import { CountryOfRegistrationCell } from '../../../components/common/grid/gridCells/CountryOfRegistrationCell';
import {
  ADDRESS_ID,
  CEO_ID,
  COUNTRY_OF_REGISTRATION_ID,
  LEGAL_NAME_ID,
  NAME_ID,
  REGISTRATION_NUMBER_ID,
  TRADE_NAME_ID,
} from '../../../utils/constants/identifiers';
import {
  ADDRESS_LABEL,
  CEO_LABEL,
  COUNTRY_OF_REGISTRATION_LABEL,
  LEGAL_NAME_LABEL,
  NAME_LABEL,
  REGISTRATION_NUMBER_LABEL,
  TRADE_NAME_LABEL,
} from '../../../utils/constants/labels';
import { GRID_ACTIONS_COLUMN_FLEX } from '../../../utils/constants/styles';

export const spvsColumns: GridColumns = [
  { field: NAME_ID, headerName: NAME_LABEL, flex: GRID_ACTIONS_COLUMN_FLEX },
  { field: LEGAL_NAME_ID, headerName: LEGAL_NAME_LABEL, flex: GRID_ACTIONS_COLUMN_FLEX },
  { field: TRADE_NAME_ID, headerName: TRADE_NAME_LABEL, flex: GRID_ACTIONS_COLUMN_FLEX },
  {
    field: REGISTRATION_NUMBER_ID,
    headerName: REGISTRATION_NUMBER_LABEL,
    flex: GRID_ACTIONS_COLUMN_FLEX,
  },
  { field: CEO_ID, headerName: CEO_LABEL, flex: GRID_ACTIONS_COLUMN_FLEX },
  {
    field: COUNTRY_OF_REGISTRATION_ID,
    headerName: COUNTRY_OF_REGISTRATION_LABEL,
    flex: GRID_ACTIONS_COLUMN_FLEX,
    renderCell: (value) => (
      <CountryOfRegistrationCell countryOfRegistrationId={value.row[COUNTRY_OF_REGISTRATION_ID]} />
    ),
  },
  { field: ADDRESS_ID, headerName: ADDRESS_LABEL, flex: GRID_ACTIONS_COLUMN_FLEX },
];
