import React from 'react';

import TableCell from '@mui/material/TableCell';

import { fontWeightBold } from '../../../styles/commonStyles';
import { cellBorderStyles } from '../../../styles/styledTableStyles';
import { AlignOptions } from '../../../utils/enums/AlignOptions';
import { StyledTableLayoutProps } from '../../../utils/interfaces/table/StyledTableLayoutProps';

export const StyledTableHeader = (props: StyledTableLayoutProps) => {
  const { label, colSpan, applyBorderRight } = props;

  return (
    <TableCell
      colSpan={colSpan}
      align={AlignOptions.Center}
      sx={applyBorderRight ? cellBorderStyles : fontWeightBold}
    >
      {label}
    </TableCell>
  );
};
