import { nameof } from 'ts-simple-nameof';

import { useGetRequest } from '../../utils/hooks/query/useGetRequest';
import { useMutationRequest } from '../../utils/hooks/query/useMutationRequest';
import { originatorServiceClient } from '../apiClient';
import { Api, ProductBase } from '../types/originatorTypes';

export const getOriginatorProducts = (originatorId: number) => {
  const { data: originatorProducts } = useGetRequest({
    key: nameof<Api<unknown>>((x) => x.api.getOriginatorProducts),
    func: async () => (await originatorServiceClient.api.getOriginatorProducts(originatorId)).data,
  });

  if (!originatorProducts || !originatorProducts.data) {
    return [];
  }

  return originatorProducts.data;
};

export const getOriginatorProductById = (originatorId: number, productId: number) => {
  const { data: originatorProduct } = useGetRequest({
    key: nameof<Api<unknown>>((x) => x.api.getProductById(originatorId, productId)),
    func: async () =>
      (await originatorServiceClient.api.getProductById(originatorId, productId)).data,
  });

  return originatorProduct?.data;
};

export const createOriginatorProduct = (originatorId: number) =>
  useMutationRequest(
    (request: ProductBase) => originatorServiceClient.api.createProduct(originatorId, request),
    nameof<Api<unknown>>((x) => x.api.getOriginatorProducts),
  );

export const updateOriginatorProduct = (originatorId: number, productId: number) =>
  useMutationRequest(
    (request: ProductBase) =>
      originatorServiceClient.api.updateProduct(originatorId, productId, request),
    [
      nameof<Api<unknown>>((x) => x.api.getProductById(originatorId, productId)),
      nameof<Api<unknown>>((x) => x.api.getOriginatorProducts),
    ],
  );
