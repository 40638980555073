import { createTheme } from '@mui/material/styles';

import { DEFAULT_APP_FONT_SIZE } from '../utils/constants/common';

export const themeConfig = createTheme({
  typography: {
    fontSize: DEFAULT_APP_FONT_SIZE,
  },
  palette: {
    background: {
      default: '#F4F4F4',
    },
    primary: {
      main: '#00A844',
      light: '#72BF44',
      dark: '#007E33',
    },
    secondary: {
      main: '#2077B4',
      light: '#A5C8E0',
      dark: '#175782',
    },
    text: {
      primary: '#000000DE',
      secondary: '#00000099',
      disabled: '#00000061',
    },
    action: {
      disabled: '#00000042',
      hover: '#0000000A',
      selected: '#00000014',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          alignItems: 'flex-start',
        },
      },
    },
  },
});
