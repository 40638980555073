import React, { useContext } from 'react';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { dialogTitleStyles } from '../../../styles/dialogStyles';
import { CLOSE_CONFIRMATION_DIALOG } from '../../../utils/constants/actions';
import { AGREE_BUTTON, DISAGREE_BUTTON } from '../../../utils/constants/buttons';
import { BUTTON_LOADER_SIZE } from '../../../utils/constants/styles';
import { ButtonLoaderTriggerLocation } from '../../../utils/enums/ButtonLoaderTriggerLocation';
import { DialogOptions } from '../../../utils/enums/DialogOptions';
import { handleButtonLoader } from '../../../utils/helpers/commonHelper';
import { Context } from '../../../utils/store';

export const ConfirmationDialog = () => {
  const { state, dispatch } = useContext(Context);

  const handleDisagree = (): void => {
    dispatch({ type: CLOSE_CONFIRMATION_DIALOG });
  };

  const handleAgree = (): void => {
    state.confirmationDialog?.onAgree();
  };

  const handleLoader = () =>
    handleButtonLoader(state, ButtonLoaderTriggerLocation.ConfirmationDialog);

  return state.confirmationDialog ? (
    <Dialog open={true} fullWidth={true} maxWidth={DialogOptions.Small}>
      <DialogTitle sx={dialogTitleStyles}>{state.confirmationDialog.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{state.confirmationDialog.content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDisagree}>{DISAGREE_BUTTON}</Button>
        <Button onClick={handleAgree} autoFocus disabled={handleLoader()}>
          {AGREE_BUTTON}
          {handleLoader() && <CircularProgress sx={{ ml: 1 }} size={BUTTON_LOADER_SIZE} />}
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;
};
