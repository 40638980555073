import React from 'react';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';

export const TooltipInfoView = (props: { title: string }) => (
  <Tooltip title={props.title}>
    <InfoOutlinedIcon />
  </Tooltip>
);
