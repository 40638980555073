export enum ErrorCardType {
  Warning,
  Error,
}

export interface ErrorCardProps {
  title: string;
  content?: string | number;
  type: ErrorCardType;
}
