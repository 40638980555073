import { UserAvailableRoles } from '../../api/types/originatorTypes';

export const getGroupedRoles = (
  roles: string[],
  availableRoles: UserAvailableRoles[],
): UserAvailableRoles[] => {
  const groupedRoles: UserAvailableRoles[] = [];

  roles.forEach((role) => {
    const foundRole = availableRoles.find((ar) => ar.availableRole === role);

    if (foundRole) {
      groupedRoles.push(foundRole);
    }
  });

  return groupedRoles;
};

export const getRoleLabel = (role: string): string => {
  const roleArray = role.split('_');
  roleArray.shift();
  const roleLabel = roleArray.join(' ').toLocaleLowerCase();

  return roleLabel.charAt(0).toUpperCase() + roleLabel.slice(1);
};
