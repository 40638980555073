import React, { useEffect } from 'react';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { ThemeProvider } from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-license-pro';
import { createBrowserHistory } from 'history';

import { viravaService } from '../api/apiClient';
import { appConfig } from '../config/app.config';
import { authConfig } from '../config/auth.config';
import { themeConfig } from '../config/theme.config';
import { queryClient } from '../utils/hooks/query/queryClient';
import { AppRoutes } from './AppRoutes';
import { Suspense } from './Suspense';
import { CustomRouter } from './common/CustomRouter';
import { ToastMessageView } from './common/ToastMessageView';
import { CommonDialogView } from './common/dialog/CommonDialogView';
import { ConfirmationDialog } from './common/dialog/ConfirmationDialog';
import { DialogFormWrapper } from './common/form/DialogFormWrapper';

export const customHistory = createBrowserHistory();

LicenseInfo.setLicenseKey(appConfig.muiLicenseKey);

export const App = () => {
  useEffect(() => {
    viravaService.init(authConfig);
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <CustomRouter history={customHistory}>
        <ThemeProvider theme={themeConfig}>
          <DialogFormWrapper />
          <ConfirmationDialog />
          <ToastMessageView />
          <CommonDialogView />
          <Suspense>
            <AppRoutes />
          </Suspense>
        </ThemeProvider>
        <ReactQueryDevtools />
      </CustomRouter>
    </QueryClientProvider>
  );
};
