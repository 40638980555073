import { STATUS_ID } from '../constants/identifiers';
import {
  SUCCESS_CREATED_MESSAGE,
  SUCCESS_DELETED_MESSAGE,
  SUCCESS_MESSAGE,
  SUCCESS_UPDATED_MESSAGE,
} from '../constants/messages';
import { HttpStatusCodes } from '../enums/HttpStatusCodes';
import { showSuccessToast } from './toastHelper';

export const successResultHandler = <TResponse>(response: TResponse, message?: string) => {
  const statusCode: number = response[STATUS_ID];

  if (message) {
    return showSuccessToast(message);
  }

  switch (statusCode) {
    case HttpStatusCodes.Created:
      showSuccessToast(SUCCESS_CREATED_MESSAGE);
      break;
    case HttpStatusCodes.Success:
      showSuccessToast(SUCCESS_UPDATED_MESSAGE);
      break;
    case HttpStatusCodes.NoContent:
      showSuccessToast(SUCCESS_DELETED_MESSAGE);
      break;

    default:
      showSuccessToast(SUCCESS_MESSAGE);
      break;
  }
};
