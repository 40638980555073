import React, { Dispatch, SetStateAction, useContext } from 'react';

import { GridSortDirection } from '@mui/x-data-grid-pro';

import {
  getAllOriginatorPayments,
  getAllPayments,
} from '../../../../../api/controllers/LoansController';
import { BeFiltersInterface } from '../../../../../utils/interfaces/filter/BeFiltersInterface';
import { SortingState } from '../../../../../utils/interfaces/transaction/SortingState';
import { Context } from '../../../../../utils/store';
import { LoansPaymentsTableView } from './LoansPaymentsTableView';

export const LoansPaymentsTab = (props: {
  filters: BeFiltersInterface;
  sort: { sorting: SortingState; setSorting: Dispatch<SetStateAction<SortingState>> };
}) => {
  const { filters, sort } = props;
  const { state } = useContext(Context);
  const originatorId = state.originatorID;

  const setNewSort = (newSort: [string, GridSortDirection][] | []) => {
    sort.setSorting({
      ...sort.sorting,
      payments: newSort,
    });
  };

  const payments = originatorId
    ? getAllOriginatorPayments(originatorId, filters, sort.sorting.payments)
    : getAllPayments(filters, sort.sorting.payments);

  return <LoansPaymentsTableView payments={payments} filters={filters} setNewSort={setNewSort} />;
};
