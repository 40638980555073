import React, { useLayoutEffect, useState } from 'react';
import { Router } from 'react-router';
import { BrowserRouterProps } from 'react-router-dom';

import { BrowserHistory } from 'history';

import {
  BORROWER_DATA_FIELDS_PERMISSION,
  LOAN_BORROWER_DATA_FIELDS_PERMISSION,
  ORIGINATORS_PERMISSION,
  ORIGINATOR_COUNTRIES_SPV_PERMISSION,
  ORIGINATOR_GROUPS_PERMISSION,
  SCORE_CLASS_PERMISSION,
  SPV_PERMISSION,
  TRANSACTIONS_ALL_PERMISSION,
  TRANSACTIONS_PERMISSION,
  USERS_PERMISSION,
} from '../../utils/constants/permissions';
import {
  ROUTE_PATH_ACCOUNTS,
  ROUTE_PATH_BORROWER_DATA,
  ROUTE_PATH_DASHBOARD,
  ROUTE_PATH_GROUPS,
  ROUTE_PATH_ORIGINATORS,
  ROUTE_PATH_ORIGINATORS_SPVS,
  ROUTE_PATH_ORIGINATOR_DETAILS,
  ROUTE_PATH_SCORE_CLASSES,
  ROUTE_PATH_SPVS,
  ROUTE_PATH_TRANSACTIONS,
  ROUTE_PATH_TRANSACTION_DETAILS,
} from '../../utils/constants/routes';
import { usePermissions } from '../../utils/hooks/permissions/usePermissions';

interface CustomRouterProps extends BrowserRouterProps {
  history: BrowserHistory;
}

export const CustomRouter = ({ history, ...props }: CustomRouterProps) => {
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  });

  useLayoutEffect(() => history.listen(setState), [history]);

  const permissions = usePermissions();
  let hasPermission = false;

  /*
    The switch compares pathname is equal to a requested path if it is equal,
    checks in the case if the user has permission to see the page.
    If the user does not have read permissions for the selected page is redirected to dashboard screen.
    Pages that don't require permission by default are allowed to be seen.
  */

  switch (state.location.pathname) {
    case ROUTE_PATH_ORIGINATORS:
      hasPermission = permissions.hasReadPermission(ORIGINATORS_PERMISSION);
      break;
    case ROUTE_PATH_ORIGINATORS_SPVS:
      hasPermission = permissions.hasReadPermission(ORIGINATOR_COUNTRIES_SPV_PERMISSION);
      break;
    case ROUTE_PATH_SPVS:
      hasPermission = permissions.hasReadPermission(SPV_PERMISSION);
      break;
    case ROUTE_PATH_ORIGINATOR_DETAILS:
      hasPermission = permissions.hasReadPermission(ORIGINATORS_PERMISSION);
      break;
    case ROUTE_PATH_GROUPS:
      hasPermission = permissions.hasReadPermission(ORIGINATOR_GROUPS_PERMISSION);
      break;
    case ROUTE_PATH_SCORE_CLASSES:
      hasPermission = permissions.hasReadPermission(SCORE_CLASS_PERMISSION);
      break;
    case ROUTE_PATH_ACCOUNTS:
      hasPermission = permissions.hasReadPermission(USERS_PERMISSION);
      break;
    case ROUTE_PATH_TRANSACTIONS:
      hasPermission =
        permissions.hasReadPermission(TRANSACTIONS_ALL_PERMISSION) ||
        permissions.hasReadPermission(TRANSACTIONS_PERMISSION);
      break;
    case ROUTE_PATH_TRANSACTION_DETAILS:
      hasPermission = permissions.hasReadPermission(TRANSACTIONS_PERMISSION);
      break;
    case ROUTE_PATH_BORROWER_DATA:
      hasPermission =
        permissions.hasReadPermission(BORROWER_DATA_FIELDS_PERMISSION) ||
        permissions.hasReadPermission(LOAN_BORROWER_DATA_FIELDS_PERMISSION);
      break;
    default:
      hasPermission = true;
      break;
  }

  return (
    <>
      <Router
        {...props}
        location={hasPermission ? state.location : ROUTE_PATH_DASHBOARD}
        navigationType={state.action}
        navigator={history}
      />
    </>
  );
};
