import React from 'react';
import { useForm } from 'react-hook-form';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { formGridSpacing } from '../../../../styles/formStyles';
import { FORM_TAG } from '../../../../utils/constants/components';
import {
  DESCRIPTION_ID,
  LABEL_ID,
  UPDATE_BORROWER_DATA_FIELD_FORM_ID,
} from '../../../../utils/constants/identifiers';
import { DESCRIPTION_LABEL, LABEL_LABEL } from '../../../../utils/constants/labels';
import { UpdateBorrowerDataFieldProps } from '../../../../utils/interfaces/borrowerData/fields/UpdateBorrowerDataFieldProps';
import { InputField } from '../../../common/form/InputField';
import { LargeGridField } from '../../../common/form/grid/LargeGridField';

export const UpdateBorrowerDataFieldForm = (props: UpdateBorrowerDataFieldProps) => {
  const { onSubmit, field } = props;

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  return (
    <Box
      component={FORM_TAG}
      id={UPDATE_BORROWER_DATA_FIELD_FORM_ID}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid container rowSpacing={1} columnSpacing={formGridSpacing}>
        <LargeGridField>
          <InputField
            id={LABEL_ID}
            label={LABEL_LABEL}
            register={register}
            errors={errors}
            required
            defaultValue={field.label}
          />
        </LargeGridField>
        <LargeGridField>
          <InputField
            id={DESCRIPTION_ID}
            label={DESCRIPTION_LABEL}
            register={register}
            errors={errors}
            defaultValue={field.description}
          />
        </LargeGridField>
      </Grid>
    </Box>
  );
};
