import React from 'react';

import Paper from '@mui/material/Paper';

import { tabsPaperStyles } from '../../../../styles/tabsStyles';
import {
  ORIGINATOR_COUNTRIES_PERMISSION,
  ORIGINATOR_COUNTRIES_SPV_PERMISSION,
  ORIGINATOR_LOAN_BORROWER_DATA_FIELDS_PERMISSION,
  ORIGINATOR_SCORE_CLASS_PERMISSION,
  PRODUCTS_PERMISSION,
  USER_ORIGINATORS_PERMISSION,
} from '../../../../utils/constants/permissions';
import { OriginatorTabs } from '../../../../utils/enums/OriginatorTabs';
import { OriginatorTabsProps } from '../../../../utils/interfaces/originator/details/OriginatorTabsProps';
import { TabsContentView } from '../../../common/tabs/TabsContentView';
import { OriginatorAccountsTab } from './tabs/accounts/OriginatorAccountsTab';
import { OriginatorBorrowerDataTab } from './tabs/borrowerData/OriginatorBorrowerDataTab';
import { OriginatorProductsTab } from './tabs/products/OriginatorProductsTab';
import { OriginatorScoreClassesTab } from './tabs/scoreClasses/OriginatorScoreClassesTab';
import { OriginatorSettingsTab } from './tabs/settings/OriginatorSettingsTab';
import { OriginatorSpvsTab } from './tabs/spvs/OriginatorSpvsTab';

export const OriginatorTabsContentView = (props: OriginatorTabsProps) => {
  const { selectedTab, originatorId } = props;

  const userOriginatorsPermissionType = USER_ORIGINATORS_PERMISSION;

  return (
    <Paper square sx={tabsPaperStyles}>
      <TabsContentView
        selectedTab={selectedTab}
        tabIndex={OriginatorTabs.Products}
        permissionType={PRODUCTS_PERMISSION}
      >
        <OriginatorProductsTab originatorId={originatorId} />
      </TabsContentView>
      <TabsContentView
        selectedTab={selectedTab}
        tabIndex={OriginatorTabs.Spvs}
        permissionType={[ORIGINATOR_COUNTRIES_SPV_PERMISSION, ORIGINATOR_COUNTRIES_PERMISSION]}
      >
        <OriginatorSpvsTab originatorId={originatorId} />
      </TabsContentView>
      <TabsContentView
        selectedTab={selectedTab}
        tabIndex={OriginatorTabs.ScoreClasses}
        permissionType={ORIGINATOR_SCORE_CLASS_PERMISSION}
      >
        <OriginatorScoreClassesTab originatorId={originatorId} />
      </TabsContentView>
      <TabsContentView
        selectedTab={selectedTab}
        tabIndex={OriginatorTabs.BorrowerData}
        permissionType={ORIGINATOR_LOAN_BORROWER_DATA_FIELDS_PERMISSION}
      >
        <OriginatorBorrowerDataTab originatorId={originatorId} />
      </TabsContentView>
      <TabsContentView
        selectedTab={selectedTab}
        tabIndex={OriginatorTabs.Accounts}
        permissionType={userOriginatorsPermissionType}
      >
        <OriginatorAccountsTab
          originatorId={originatorId}
          permissionType={userOriginatorsPermissionType}
        />
      </TabsContentView>
      <TabsContentView
        selectedTab={selectedTab}
        tabIndex={OriginatorTabs.Settings}
        permissionType={userOriginatorsPermissionType}
      >
        <OriginatorSettingsTab
          originatorId={originatorId}
          permissionType={userOriginatorsPermissionType}
        />
      </TabsContentView>
    </Paper>
  );
};
