import React from 'react';
import { useForm } from 'react-hook-form';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { createCountrySpvFormStyles } from '../../../../../styles/countrySpvStyles';
import {
  stepperButtonsWrapperStyles,
  stepperCancelButtonStyles,
} from '../../../../../styles/stepperStyles';
import {
  ADD_BUTTON,
  BUTTON_SUBMIT_TYPE,
  CANCEL_BUTTON,
} from '../../../../../utils/constants/buttons';
import { FORM_TAG } from '../../../../../utils/constants/components';
import {
  CREATE_ORIGINATOR_COUNTRY_FORM_ID,
  FACILITY_AGREEMENT_NUMBER,
  FEE,
  SPV_ID,
  VALID_TO_DATE_ID,
} from '../../../../../utils/constants/identifiers';
import {
  FACILITY_AGREEMENT_NUMBER_LABEL,
  FEE_LABEL,
  SPV_LABEL,
  VALID_TO_LABEL,
} from '../../../../../utils/constants/labels';
import { ButtonVariants } from '../../../../../utils/enums/ButtonVariants';
import { InputFieldType } from '../../../../../utils/enums/InputFieldType';
import { SelectorChoicesType } from '../../../../../utils/interfaces/form/SelectorChoicesType';
import { CreateCountrySpvProps } from '../../../../../utils/interfaces/originator/details/CreateCountrySpvProps';
import { DatePickerField } from '../../../../common/form/DatePickerField';
import { InputField } from '../../../../common/form/InputField';
import { NumberInputField } from '../../../../common/form/NumberInputField';
import { SelectField } from '../../../../common/form/SelectField';

export const CreateCountrySpvForm = (props: CreateCountrySpvProps) => {
  const { handleClose, spvs, onSubmit } = props;

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
  } = useForm();

  return (
    <>
      <Box
        component={FORM_TAG}
        id={CREATE_ORIGINATOR_COUNTRY_FORM_ID}
        onSubmit={handleSubmit(onSubmit)}
        sx={createCountrySpvFormStyles}
      >
        <SelectField
          id={SPV_ID}
          label={SPV_LABEL}
          control={control}
          choices={spvs as SelectorChoicesType[]}
          required
        />
        <NumberInputField
          id={FEE}
          label={FEE_LABEL}
          inputType={InputFieldType.Decimal}
          register={register}
          errors={errors}
          required
        />
        <InputField
          id={FACILITY_AGREEMENT_NUMBER}
          label={FACILITY_AGREEMENT_NUMBER_LABEL}
          register={register}
          errors={errors}
          required
        />
        <DatePickerField id={VALID_TO_DATE_ID} label={VALID_TO_LABEL} control={control} required />
        <Box sx={stepperButtonsWrapperStyles}>
          <Button
            variant={ButtonVariants.Outlined}
            sx={stepperCancelButtonStyles}
            onClick={handleClose}
          >
            {CANCEL_BUTTON}
          </Button>
          <Button
            type={BUTTON_SUBMIT_TYPE}
            id={CREATE_ORIGINATOR_COUNTRY_FORM_ID}
            variant={ButtonVariants.Contained}
          >
            {ADD_BUTTON}
          </Button>
        </Box>
      </Box>
    </>
  );
};
