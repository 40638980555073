import { fontWeightBold } from './commonStyles';

export const borderRightStyles = {
  borderRight: '1px solid rgba(224, 224, 224, 1)',
};

export const transactionsTableStyles = {
  minWidth: '1500px',
  ...borderRightStyles,
};

export const cellBorderStyles = {
  ...borderRightStyles,
  ...fontWeightBold,
};

export const tablePaginationStyles = { marginLeft: '50%', marginTop: 4 };

export const styledCellChipStyles = { paddingTop: '1px' };
