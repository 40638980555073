export const BASIC_ERROR_MESSAGE = 'Basic error';
export const GRID_DATA_ERROR_MESSAGE = 'Grid data error';
export const INVALID_LOGIN_CREDENTIALS_MESSAGE =
  'Your password is incorrect or this account doesn’t exist!';
export const NO_CONNECTION_TO_THE_SERVER_MESSAGE =
  'There is currently no connection to the server.';
export const RESET_PASSWORD_SUCCESS_MESSAGE = 'We have emailed you your password reset link!';
export const REGISTER_SUCCESS_MESSAGE = 'Successful registration!';
export const ALREADY_REGISTERED_USER_MESSAGE = 'User with this email is already registered!';
export const UNEXPECTED_ERROR_MESSAGE = 'An unexpected error occured, please try again!';
export const INVALID_FEE_DECIMAL_MESSAGE = 'The value is too long.';
export const REQUIRED_FIELD_MESSAGE = 'Required field';
export const INVALID_CONFIRM_PASSWORD_MESSAGE = "The passwords don't match";
export const INVALID_EMAIL_ADDRESS_MESSAGE = 'Please, enter a valid e-mail address';
export const ONLY_DECIMAL_VALUES_ARE_ALLOWED_MESSAGE =
  'Only digits with decimal point (".") separator are allowed';
export const OUT_OF_RANGE_NUMBER_MESSAGE =
  'The value of the field must be between 0 and 1 included';
export const UPLOADED_FILE_MESSAGE = 'Uploaded';
export const SUCCESS_MESSAGE = 'Success';
export const SUCCESS_CREATED_MESSAGE = 'Success created';
export const SUCCESS_UPDATED_MESSAGE = 'Success updated';
export const SUCCESS_DELETED_MESSAGE = 'Success deleted';
export const SUCCESS_APPROVED_MESSAGE = 'Success approved';
export const SUCCESS_EXPORTED_MESSAGE = 'Success exported';
export const SUCCESS_UPLOADED_MESSAGE = 'Success uploaded';
export const EXPIRED_TOKEN = 'Invalid or expired token';
export const GREATER_THAN_ZERO_VALUE_MESSAGE = 'The value must be greater than 0';
export const ONLY_NUMBERS_ARE_ALLOWED_MESSAGE = 'Only numbers are allowed';
export const REPORT_IN_PROGRESS_MESSAGE = 'This report is with status in progress';
export const NO_REPORTS_MESSAGE = 'There are currently no reports generated';
