export const CLOSE_FORM_DIALOG = 'CLOSE_FORM_DIALOG_ACTION' as const;
export const OPEN_FORM_DIALOG = 'OPEN_FORM_DIALOG_ACTION' as const;
export const CLOSE_CONFIRMATION_DIALOG = 'CLOSE_CONFIRMATION_DIALOG_ACTION' as const;
export const OPEN_CONFIRMATION_DIALOG = 'OPEN_CONFIRMATION_DIALOG_ACTION' as const;
export const OPEN_INFO_DIALOG = 'OPEN_INFO_DIALOG' as const;
export const CLOSE_INFO_DIALOG = 'CLOSE_INFO_DIALOG' as const;
export const SHOW_TRANSACTION_STATUS = 'SHOW_TRANSACTION_STATUS' as const;
export const HIDE_TRANSACTION_STATUS = 'HIDE_TRANSACTION_STATUS' as const;
export const SHOW_LOADER = 'SHOW_LOADER' as const;
export const HIDE_LOADER = 'HIDE_LOADER' as const;
export const SET_USER = 'SET_USER' as const;
export const SET_ORIGINATOR_ID = 'SET_ORIGINATOR_ID' as const;
export const CREATED_ACCOUNT_DATA = 'CREATED_ACCOUNT_DATA' as const;
