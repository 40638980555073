export const EMAIL_FIELD_ID = 'email';
export const PASSWORD_FIELD_ID = 'password';
export const PASSWORD_FIELD_CONFIRM_ID = 'confirmPassword';
export const PASSWORD_FIELD_CURRENT_ID = 'currentPassword';
export const PASSWORD_FIELD_NEW_ID = 'newPassword';
export const ORIGINATORS_KEY = 'originators';
export const SPVS_KEY = 'spvs';
export const GROUPS_KEY = 'groups';
export const BORROWER_DATA_KEY = 'borrowerData';
export const SCORE_CLASSES_KEY = 'scoreClasses';
export const CURRENCY_INVEST_AMOUNT_KEY = 'currencyInvestAmount';
export const SPV_GRID_ACTIONS_EDIT_KEY = 'spvActionEdit';
export const GROUP_GRID_ACTIONS_EDIT_KEY = 'groupActionEdit';
export const SIDEBAR_USER_AVATAR_KEY = 'userAvatar';
export const SIDEBAR_USER_INFO_KEY = 'userInfo';
export const TRANSACTIONS_KEY = 'transactions';
export const LOANS_KEY = 'loans';
export const INFO_KEY = 'info';
export const PAYMENT_SCHEDULES_KEY = 'paymentSchedules';
export const PAYMENTS_KEY = 'payments';
export const FEES_KEY = 'fee';

export const ORIGINATOR_PARENT_KEY = 'loanOriginatorParentCol';
export const ERRORS_PARENT_KEY = 'errorsParentcol';
export const LOANS_PARENT_KEY = 'loansParentCol';
export const PAYMENT_SCHEDULES_PARENT_KEY = 'paymentSchedulesParentCol';
export const PAYMENTS_PARENT_KEY = 'paymentsParentCol';
export const FEES_PARENT_KEY = 'feeParentCol';
export const STATUS_PARENT_KEY = 'statusParentCol';

export const ORIGINATOR_CHILD_KEY = 'originatorId';
export const ERROR_CHILD_KEY = 'loanErrorCount';
export const WARNING_CHILD_KEY = 'loanWarningCount';
export const COUNT_LOAN_CHILD_KEY = 'loanTotalCount';
export const PROCESSED_LOAN_CHILD_KEY = 'loanProcessedCount';
export const SUBMITTED_LOAN_CHILD_KEY = 'loanSubmittedCount';
export const COUNT_PS_CHILD_COL = 'payScheduleTotalCount';
export const PROCESSED_PS_CHILD_COL = 'payScheduleProcessedCount';
export const SUBMITTED_PS_CHILD_COL = 'payScheduleSubmittedCount';
export const COUNT_PAYMENT_CHILD_COL = 'paymentTotalCount';
export const PROCESSED_PAYMENT_CHILD_COL = 'paymentProcessedCount';
export const SUBMITTED_PAYMENT_CHILD_COL = 'paymentSubmittedCount';
export const COUNT_FEE_CHILD_COL = 'feeTotalCount';
export const PROCESSED_FEE_CHILD_COL = 'feeProcessedCount';
export const SUBMITTED_FEE_CHILD_COL = 'feeSubmittedCount';
export const STATUS_CHILD_COL = 'transactionStatus';
export const PRODUCTS_KEY = 'products';
export const ACCOUNTS_KEY = 'accounts';
export const SETTINGS_KEY = 'settings';
export const LOAN_TYPES_KEY = 'loanTypes';
export const FIELDS_KEY = 'fields';
