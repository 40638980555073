import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';

import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import {
  EMPTY_VALUE,
  SELECT_FIELD_INPUT_CHANGE_CLEAR_REASON,
} from '../../../utils/constants/common';
import { REQUIRED_FIELD_MESSAGE } from '../../../utils/constants/messages';
import { FORM_FIELD_MARGIN } from '../../../utils/constants/styles';
import { SelectFieldProps } from '../../../utils/interfaces/form/SelectFieldProps';
import { SelectorChoicesType } from '../../../utils/interfaces/form/SelectorChoicesType';
import { TooltipInfoView } from '../TooltipInfoView';

export const SelectField = (props: SelectFieldProps) => {
  const { id, label, control, choices, required, defaultValue, size } = props;
  const [currentValue, setCurrentValue] = useState<SelectorChoicesType | null>();

  const value = currentValue ? currentValue : choices.find((x) => x.id === defaultValue);

  // We get field value beacuse when we clear it from setValue(null), its not enought and dont clear the text from input.
  const fieldValue = control._formValues[id];

  useEffect(() => {
    // We check here if we have fieldValue, if its undefined we clear the text from input, else we set default value.
    setCurrentValue(fieldValue ? value : null);
  }, [currentValue, fieldValue]);

  return (
    <Controller
      name={id}
      control={control}
      rules={{ required: required }}
      defaultValue={value?.id}
      render={({ field: { onChange }, fieldState }) => {
        return (
          <Autocomplete
            id={id}
            options={choices}
            value={currentValue || null}
            getOptionLabel={(option) => option.name}
            onInputChange={(_, val, reason) => {
              // We need the reason to clear field, because if we click 'X' at the end of the field, we have to set null value there.
              if (reason === SELECT_FIELD_INPUT_CHANGE_CLEAR_REASON) {
                setCurrentValue(null);
              }
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                <Typography sx={{ flexGrow: 1 }}>{option.name}</Typography>
                {option.description && option.description !== EMPTY_VALUE && (
                  <TooltipInfoView title={option.description} />
                )}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                margin={FORM_FIELD_MARGIN}
                error={Boolean(fieldState.error)}
                InputLabelProps={{ required: required }}
                helperText={fieldState.error && REQUIRED_FIELD_MESSAGE}
                size={size}
                fullWidth
              />
            )}
            onChange={(_, data) => {
              onChange(data?.id);
              setCurrentValue(data as SelectorChoicesType);
            }}
            defaultValue={value}
          />
        );
      }}
    />
  );
};
