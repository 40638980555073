import React, { createContext, useReducer } from 'react';

import { reducer } from './reducer';
import { ContextType } from './types/ContextType';
import { State } from './types/State';

const initialState: State = {
  formDialog: undefined,
  confirmationDialog: undefined,
  alertDialog: undefined,
  infoDialog: undefined,
  user: undefined,
  originatorID: undefined,
  loader: undefined,
  controller: new AbortController(),
};

export const Context = createContext<ContextType>({
  state: initialState,
  dispatch: () => {},
});

export const Store = (props: { children: React.ReactNode }) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  return <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>;
};
