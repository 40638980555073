import React, { useContext, useState } from 'react';

import { Box, Paper, Typography } from '@mui/material';
import moment from 'moment';

import {
  downloadLoanExportFile,
  useGetAllUserLoanExports,
} from '../../../api/controllers/ExportController';
import { LoanExportInfo } from '../../../api/types/originatorTypes';
import { reportsWrapperStyles } from '../../../styles/advancedFiltersStyles';
import { tabsPaperStyles } from '../../../styles/tabsStyles';
import { NO_REPORTS_MESSAGE } from '../../../utils/constants/messages';
import { REPORT_DATE_FORMAT } from '../../../utils/constants/options';
import { ReportProps } from '../../../utils/interfaces/reports/ReportProps';
import { Context } from '../../../utils/store';
import { AdditionalInfo } from './AdditionalInfo';
import { GeneralInfo } from './GeneralInfo';

export const Reports = (props: ReportProps) => {
  const { setExportData, isReportsOpen } = props;
  const { state, dispatch } = useContext(Context);
  const { user } = state;

  const [openedRows, setOpenedRows] = useState({});

  const userLoanExports = useGetAllUserLoanExports(user);

  const openAdditionalInfo = (id: number) => {
    setOpenedRows({ ...openedRows, [id]: { isOpen: true } });
  };

  const closeAdditionalInfo = (id: number) => {
    const openedRowsCopy = { ...openedRows };
    delete openedRowsCopy[id];
    setOpenedRows(openedRowsCopy);
  };

  const handleDownload = (id: number, name: string) => {
    const signal = state.controller && state.controller.signal;
    downloadLoanExportFile(id, name, dispatch, signal);
    setExportData(null);
  };

  const formatDate = (date: string) => {
    const fromDate = new Date(JSON.parse(date)[0]).toLocaleDateString();

    const toDate = new Date(JSON.parse(date)[1]).toLocaleDateString();

    return `${moment(fromDate).format(REPORT_DATE_FORMAT)}, ${moment(toDate).format(
      REPORT_DATE_FORMAT,
    )}`;
  };

  const setFilterValue = (filterValue: string) => {
    if (JSON.parse(filterValue).length) {
      if (isNaN(new Date(JSON.parse(filterValue)[0]) as unknown as number)) {
        return JSON.parse(filterValue).join(', ');
      } else {
        return formatDate(filterValue);
      }
    } else {
      return filterValue;
    }
  };

  return isReportsOpen ? (
    <Paper square sx={{ ...tabsPaperStyles, ...reportsWrapperStyles }}>
      {userLoanExports.length > 0 ? (
        userLoanExports.map((report: LoanExportInfo) => (
          <Box key={report.id}>
            <GeneralInfo
              report={report}
              openedRows={openedRows}
              handleDownload={handleDownload}
              openAdditionalInfo={openAdditionalInfo}
              closeAdditionalInfo={closeAdditionalInfo}
            />
            {openedRows[report.id as number]?.isOpen && (
              <AdditionalInfo report={report} setFilterValue={setFilterValue} />
            )}
          </Box>
        ))
      ) : (
        <Typography>{NO_REPORTS_MESSAGE}</Typography>
      )}
    </Paper>
  ) : null;
};
