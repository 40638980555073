export const ADD_GROUP_DIALOG_TITLE = 'Add Group';
export const UPDATE_GROUP_DIALOG_TITLE = 'Edit Group';

export const ADD_SPV_DIALOG_TITLE = 'Add SPV';
export const UPDATE_SPV_DIALOG_TITLE = 'Edit SPV';

export const ADD_ORIGINATOR_TITLE = 'Add originator';
export const UPDATE_ORIGINATOR_TITLE = 'Edit originator';

export const ADD_SCORE_CLASS_TITLE = 'Add score class';
export const UPDATE_SCORE_CLASS_TITLE = 'Edit score class';
export const DELETE_SCORE_CLASS_TITLE = 'Delete score class';

export const CHANGE_PASSWORD_TITLE = 'Change password';

export const IMPORT_DIALOG_TITLE = 'Import';
export const EXPORT_DIALOG_TITLE = 'Export';

export const FILTERS_TITLE = 'FILTERS';

export const APPROVE_TRANSACTION_TITLE = 'Approve transaction';

export const ADD_ACCOUNT_TITLE = 'Add account';
export const DELETE_ACCOUNT_TITLE = 'Delete account';
export const EDIT_ACCOUNT_TITLE = 'Edit account';

export const ADD_PRODUCT_TITLE = 'Add product';

export const EDIT_PRODUCT_TITLE = 'Edit product';
export const ADD_COUNTRY_TITLE = 'Add country';
export const EDIT_COUNTRY_TITLE = 'Edit country';
export const ADD_COUNTRY_SPV_TITLE = 'Add SPVs';
export const DELETE_COUNTRY_SPV_TITLE = 'Delete country spv';
export const DELETE_COUNTRY_TITLE = 'Delete country';
export const DELETE_FIELD_TITLE = 'Delete field';
export const ADD_FIELD_TITLE = 'Add field';
export const EDIT_FIELD_TITLE = 'Edit field';
export const EDIT_LOAN_TITLE = 'Edit loan';

export const ADD_CURRENCY_TITLE = 'Add currency';
export const EDIT_CURRENCY_TITLE = 'Edit currency';
export const DELETE_CURRENCY_TITLE = 'Delete currency';

export const EDIT_SETTINGS_TITLE = 'Edit settings';
