import { ReactNode } from 'react';
import { FieldValues } from 'react-hook-form';

import { REQUIRED_FIELD_ERROR } from '../constants/common';
import {
  INVALID_CONFIRM_PASSWORD_MESSAGE,
  ONLY_DECIMAL_VALUES_ARE_ALLOWED_MESSAGE,
  ONLY_NUMBERS_ARE_ALLOWED_MESSAGE,
  OUT_OF_RANGE_NUMBER_MESSAGE,
  REQUIRED_FIELD_MESSAGE,
} from '../constants/messages';
import { DECIMAL_ACCURACY, DECIMAL_MAX_NUMBER, MAX_NUMBER, MIN_NUMBER } from '../constants/options';
import {
  DECIMAL_INPUT_FIELD_MAX_NUMBER,
  DECIMAL_INPUT_FIELD_MIN_NUMBER,
  INVALID_DECIMAL_REGEX_PATTERN,
  INVALID_NUMBER_REGEX_PATTERN,
} from '../constants/validations';
import { convertExponentialToDecimal } from './commonHelper';

export const handleRequiredFields = (errors: FieldValues, id: string): string => {
  return errors[id]?.type === REQUIRED_FIELD_ERROR ? REQUIRED_FIELD_MESSAGE : errors[id]?.message;
};

export const handleSpacesForRequiredFields = (value?: string): boolean =>
  value ? !!value.trim() : true;

export const handlePasswordMatch = (value: string, valueToMatch: string): string | undefined => {
  return value !== valueToMatch.trim() ? INVALID_CONFIRM_PASSWORD_MESSAGE : undefined;
};

export const helperTextHandler = (errors: FieldValues, id: string): ReactNode => {
  // Checking the error types returned from field, and based by returned value, we return specific error message.
  const error =
    errors[id]?.type === DECIMAL_INPUT_FIELD_MIN_NUMBER ||
    errors[id]?.type === DECIMAL_INPUT_FIELD_MAX_NUMBER
      ? OUT_OF_RANGE_NUMBER_MESSAGE
      : errors[id]?.message;

  return error;
};

export const integerValidator = (
  value: number | string,
  minNumber?: number,
  maxNumber?: number,
  allowedValues?: number[],
) => {
  const min = minNumber || MIN_NUMBER;
  const max = maxNumber || MAX_NUMBER;

  if (typeof value !== 'string') {
    if (value < min) {
      return `The value should be greater than ${min}`;
    }

    if (value < min || value > max) {
      return `The value should be between ${min} and ${max}`;
    }

    if (value > max) {
      return `The value should not be greater than ${max}`;
    }

    if (allowedValues) {
      return allowedValues.includes(value) ? true : `You must select one of ${allowedValues}`;
    }

    // Checking if value matches the regex pattern.
    // If it doesn't -> return error message.
    const digitsRegex: RegExp = INVALID_NUMBER_REGEX_PATTERN;
    if (!digitsRegex.test(value.toString())) {
      return ONLY_NUMBERS_ARE_ALLOWED_MESSAGE;
    }
  }

  return true;
};

export const decimalValidator = (
  value: number | string,
  minNumber?: number,
  maxNumber?: number,
  decimalAccuracy?: number,
  lowerValue?: number,
  biggerValue?: number,
): string | boolean => {
  const min = minNumber || MIN_NUMBER;
  const max = maxNumber || DECIMAL_MAX_NUMBER;
  const accuracy = decimalAccuracy || DECIMAL_ACCURACY;

  if (typeof value !== 'string') {
    if (lowerValue) {
      if (lowerValue > value) {
        return `The value should not be lower than ${lowerValue}`;
      }

      return true;
    }

    if (lowerValue && biggerValue) {
      if (biggerValue < lowerValue) {
        return `The value should be bigger than ${lowerValue}`;
      }

      return true;
    }

    if (value < min || value > max) {
      return OUT_OF_RANGE_NUMBER_MESSAGE;
    }

    // Checking if value matches the regex pattern.
    // If it doesn't -> return error message.
    const digitsRegex: RegExp = INVALID_DECIMAL_REGEX_PATTERN;
    if (!digitsRegex.test(value.toString())) {
      return ONLY_DECIMAL_VALUES_ARE_ALLOWED_MESSAGE;
    }

    //Checking if we meet our requirements to the accuracy passed from outside, or default one.
    if (value.toString().includes('.')) {
      if (countDecimals(Number(value)) > accuracy) {
        return `Up to ${accuracy} digits after decimal point are allowed`;
      }
    }
  }

  return true;
};

// This method returns count of numbers after the separator.
const countDecimals = (value: number): number => {
  const val = convertExponentialToDecimal(value);
  if (val !== 0) {
    return val.toString().split('.')[1].length || 0;
  }

  return 0;
};
