export const TABLE_CURRENT_PAGE = 0;
export const TABLE_ROWS_PER_PAGE = 10;
export const TABLE_ROWS_PAGE_OPTIONS = [10, 25, 100];

export const ROWS = 'Rows';
export const FILE_SIZE = 'File size';

export const ACCOUNT_MENU_ID = 'account-menu';
export const ACCOUNT_MENU_ITEM_CHANGE_PASSWORD = 'Change password';

export const ACCESS_TOKEN = 'access_token';
export const REFRESH_TOKEN = 'refresh_token';

export const HEADER_WHITE_LOGO_ALT = 'Iuvo White Logo';
export const HEADER_BLACK_LOGO_ALT = 'Iuvo Black Logo';

export const DISABLED_CRUMB_ADMIN = 'Admin';
export const DISABLED_CRUMB_DATA_EXCHANGE = 'Data Exchange';

export const GRID_ACTIONS = 'actions';

export const NUMBER_INPUT_TYPE = 'number';
export const FLOAT_INPUT_TYPE = 'float';
export const FILE_INPUT_TYPE = 'file';

export const EMPTY_VALUE = '';
export const ZERO_NUMBER_VALUE = 0;

export const VALIDATE_FIELD_ERROR = 'validate';
export const REQUIRED_FIELD_ERROR = 'required';

export const BROKEN_IMAGE = '/broken-image.jpg';

export const ALLOWED_UPLOAD_FILE_EXTENSIONS = 'Allowed file extensions:';
export const ALLOWED_EXTENSIONS = '.xlsx';

export const MAXIMUM_UPLOAD_FILE_SIZE = 'Maximum file size:';
export const MAXIMUM_FILE_SIZE_MB = '100mb';

export const DEFAULT_APP_FONT_SIZE = 13;
export const TABS_VIEW_SCROLL_BUTTONS_TYPE = 'auto';

export const BEARER_TOKEN = 'Bearer';
export const SELECT_FIELD_INPUT_CHANGE_CLEAR_REASON = 'clear';

export const FINISHED = 'FINISHED';
export const IN_PROGRESS = 'IN_PROGRESS';

export const START_TIME = '00:00:00.000Z';
