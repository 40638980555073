import * as React from 'react';

import { originatorsSpvsColumns } from '../../../common/tableColumns/originatorsSpvs/originatorsSpvsColumns';
import { getGridColumns } from '../../../utils/helpers/getGridColumns';
import { OriginatorsSpvsTableProps } from '../../../utils/interfaces/originatorsSpvs/OriginatorsSpvsTableProps';
import { DataGridView } from '../../common/grid/DataGridView';

export const OriginatorsSpvsDataGrid = (props: OriginatorsSpvsTableProps) => {
  const { originatorsSpvs } = props;

  return <DataGridView data={originatorsSpvs} columns={getGridColumns(originatorsSpvsColumns)} />;
};
