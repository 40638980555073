import React, { useContext, useState } from 'react';

import {
  createCurrencyInvestAmount,
  deleteCurrencyInvestAmount,
  getAllCurrecyInvestAmounts,
  updateCurrencyInvestAmount,
} from '../../../api/controllers/CurrencyInvestAmountController';
import { getAllCurrencies } from '../../../api/controllers/NomenclatureController';
import { InvestmentSettingBase } from '../../../api/types/originatorTypes';
import {
  CLOSE_CONFIRMATION_DIALOG,
  OPEN_CONFIRMATION_DIALOG,
  OPEN_FORM_DIALOG,
} from '../../../utils/constants/actions';
import { ADD_CURRENCY_BUTTON } from '../../../utils/constants/buttons';
import { DELETE_CURRENCY_INVEST_AMOUNT_CONFIRMATION } from '../../../utils/constants/confirmations';
import {
  CREATE_CURENCY_INVEST_AMOUNT_FORM_ID,
  DELETE_SCORE_CLASS_DIALOG_ID,
  UPDATE_CURENCY_INVEST_AMOUNT_FORM_ID,
} from '../../../utils/constants/identifiers';
import { INVESTMENTS_PERMISSION } from '../../../utils/constants/permissions';
import {
  ADD_CURRENCY_TITLE,
  DELETE_CURRENCY_TITLE,
  EDIT_CURRENCY_TITLE,
} from '../../../utils/constants/titles';
import { FormDialogSubmitType } from '../../../utils/enums/FormDialogSubmitType';
import { closeFormHandler } from '../../../utils/helpers/closeFormHandler';
import { SelectorChoicesType } from '../../../utils/interfaces/form/SelectorChoicesType';
import { Context } from '../../../utils/store';
import { ContextType } from '../../../utils/store/types/ContextType';
import { GridHeaderView } from '../../common/grid/GridHeaderView';
import { CreateCurrencyInvestAmountForm } from '../../views/currencyInvestAmount/forms/CreateCurrencyInvestAmountForm';
import { UpdateCurrencyInvestAmountForm } from '../../views/currencyInvestAmount/forms/UpdateCurrencyInvestAmountForm';
import { CurrencyInvestAmountDataGrid } from '../../views/currencyInvestAmount/tables/CurrencyInvestAmountDataGrid';

export const CurrencyInvestAmountScreen = () => {
  const { dispatch, state } = useContext<ContextType>(Context);

  const [currencyInvestId, setCurrencyInvestId] = useState<number>();

  const currencyInvests = getAllCurrecyInvestAmounts();
  const currencies = getAllCurrencies();
  const deleteCurrencyInvest = deleteCurrencyInvestAmount();

  const createUpdateMutation =
    state.formDialog?.submitType === FormDialogSubmitType.Edit && currencyInvestId
      ? updateCurrencyInvestAmount(currencyInvestId)
      : createCurrencyInvestAmount();

  const investmentsPermissionType = INVESTMENTS_PERMISSION;

  const handleAddClick = (): void => {
    dispatch({
      type: OPEN_FORM_DIALOG,
      payload: {
        title: ADD_CURRENCY_TITLE,
        content: (
          <CreateCurrencyInvestAmountForm
            onSubmit={handleSubmit}
            currencies={currencies as SelectorChoicesType[]}
          />
        ),
        formId: CREATE_CURENCY_INVEST_AMOUNT_FORM_ID,
        submitType: FormDialogSubmitType.Add,
      },
    });
  };

  const handleEditClick = (id: number): void => {
    const currencyInvest = currencyInvests.find((x) => x.id === id);

    if (!currencyInvest) {
      return;
    }

    setCurrencyInvestId(id);

    dispatch({
      type: OPEN_FORM_DIALOG,
      payload: {
        title: EDIT_CURRENCY_TITLE,
        content: (
          <UpdateCurrencyInvestAmountForm
            onSubmit={handleSubmit}
            currencyInvest={currencyInvest}
            currencies={currencies as SelectorChoicesType[]}
          />
        ),
        formId: UPDATE_CURENCY_INVEST_AMOUNT_FORM_ID,
        submitType: FormDialogSubmitType.Edit,
      },
    });
  };

  const handleDeleteConfirm = (id: number): void => {
    deleteCurrencyInvest.mutateAsync(id);

    dispatch({ type: CLOSE_CONFIRMATION_DIALOG });
  };

  const handleDeleteClick = (id: number): void => {
    dispatch({
      type: OPEN_CONFIRMATION_DIALOG,
      payload: {
        dialogId: DELETE_SCORE_CLASS_DIALOG_ID,
        title: DELETE_CURRENCY_TITLE,
        content: DELETE_CURRENCY_INVEST_AMOUNT_CONFIRMATION,
        onAgree: () => handleDeleteConfirm(id),
      },
    });
  };

  const handleSubmit = (data: InvestmentSettingBase): void => {
    createUpdateMutation.mutateAsync(data).then((response) => {
      closeFormHandler(dispatch, response.status);
    });
  };

  return (
    <>
      <GridHeaderView
        buttonLabel={ADD_CURRENCY_BUTTON}
        onButtonClick={handleAddClick}
        permissionType={investmentsPermissionType}
      />
      <CurrencyInvestAmountDataGrid
        handleEdit={handleEditClick}
        handleDelete={handleDeleteClick}
        data={currencyInvests}
        permissionType={investmentsPermissionType}
      />
    </>
  );
};
