import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { fontWeightBold } from '../../../../styles/commonStyles';
import {
  styledTextBoxStyles,
  styledTextValueStyles,
} from '../../../../styles/originatorDetailsStyles';
import { DIV_TAG } from '../../../../utils/constants/components';
import { styledDetailValueMapper } from '../../../../utils/helpers/styledDetailValueMapper';

export const StyledDetail = (props: { title: string; value?: string | number }) => {
  const { value, title } = props;

  return (
    <Box component={DIV_TAG} sx={styledTextBoxStyles}>
      <Typography sx={fontWeightBold}>{title}:</Typography>
      <Typography sx={styledTextValueStyles}>{styledDetailValueMapper(title, value)}</Typography>
    </Box>
  );
};
