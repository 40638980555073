export const breadcrumbsTitleStyles = {
  marginTop: '65px',
};

export const breadcrumbsContentStyles = {
  top: '146px',
  lineHeight: 2,
};

export const breadcrumbsDividerStyles = {
  paddingTop: 3,
};

export const breadcrumbsDisabledStyles = {
  color: '#00000042',
  textDecoration: 'none',
  fontSize: 16,
};

export const breadcrumbChipStyles = { ml: 2 };
