import React, { Dispatch, SetStateAction, useContext } from 'react';

import { GridSortDirection } from '@mui/x-data-grid-pro';

import { getAllFees, getAllOriginatorFees } from '../../../../../api/controllers/LoansController';
import { BeFiltersInterface } from '../../../../../utils/interfaces/filter/BeFiltersInterface';
import { SortingState } from '../../../../../utils/interfaces/transaction/SortingState';
import { Context } from '../../../../../utils/store';
import { LoansFeesTableView } from './LoansFeesTableView';

export const LoansFeesTab = (props: {
  filters: BeFiltersInterface;
  sort: { sorting: SortingState; setSorting: Dispatch<SetStateAction<SortingState>> };
}) => {
  const { filters, sort } = props;
  const { state } = useContext(Context);
  const originatorId = state.originatorID;

  const setNewSort = (newSort: [string, GridSortDirection][] | []) => {
    sort.setSorting({
      ...sort.sorting,
      fees: newSort,
    });
  };

  const fees = originatorId
    ? getAllOriginatorFees(originatorId, filters, sort.sorting.fees)
    : getAllFees(filters, sort.sorting.fees);

  return <LoansFeesTableView fees={fees} filters={filters} setNewSort={setNewSort} />;
};
