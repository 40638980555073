export const BusinessStatusMockData = [
  {
    id: 1,
    name: 'Active',
  },
  {
    id: 2,
    name: 'Suspended',
  },
  {
    id: 3,
    name: 'Inactive',
  },
  {
    id: 4,
    name: 'Default',
  },
];
