import { Dispatch } from 'react';

import { CLOSE_CONFIRMATION_DIALOG, CLOSE_FORM_DIALOG } from '../constants/actions';
import { HttpStatusCodes } from '../enums/HttpStatusCodes';
import { Action } from '../store/action';

export const closeFormHandler = (dispatch: Dispatch<Action>, responseStatus: number) => {
  switch (responseStatus) {
    case HttpStatusCodes.Created:
      dispatch({ type: CLOSE_FORM_DIALOG });
      break;
    case HttpStatusCodes.Success:
      dispatch({ type: CLOSE_FORM_DIALOG });
      break;
    case HttpStatusCodes.NoContent:
      dispatch({ type: CLOSE_CONFIRMATION_DIALOG });
      break;
  }
};
