import { toast } from 'react-toastify';

import { SUCCESS_MESSAGE, UNEXPECTED_ERROR_MESSAGE } from '../constants/messages';

export const showErrorToast = (errorMessage?: string) => {
  toast.error(errorMessage || UNEXPECTED_ERROR_MESSAGE, {
    toastId: errorMessage || UNEXPECTED_ERROR_MESSAGE,
  });
};

export const showSuccessToast = (message?: string) => {
  toast.success(message || SUCCESS_MESSAGE, {
    toastId: message || SUCCESS_MESSAGE,
  });
};
