import React from 'react';

import Chip from '@mui/material/Chip';
import TableCell from '@mui/material/TableCell';

import { borderRightStyles, styledCellChipStyles } from '../../../styles/styledTableStyles';
import { EMPTY_CELL_VALUE } from '../../../utils/constants/options';
import { SIZE_SMALL } from '../../../utils/constants/styles';
import { AlignOptions } from '../../../utils/enums/AlignOptions';
import { StatusOptions } from '../../../utils/enums/StatusOptions';
import { getStatusLabel } from '../../../utils/helpers/transactionStatusHelper';
import { getStatusColor } from '../../../utils/helpers/transactionStatusHelper';
import { StyledTableLayoutProps } from '../../../utils/interfaces/table/StyledTableLayoutProps';

export const StyledTableCell = (props: StyledTableLayoutProps) => {
  const { label, applyBorderRight, hasStatus } = props;

  return (
    <TableCell sx={applyBorderRight ? borderRightStyles : null} align={AlignOptions.Left}>
      {hasStatus ? (
        <Chip
          size={SIZE_SMALL}
          label={getStatusLabel(label)}
          color={getStatusColor(label || StatusOptions.Default)}
          sx={styledCellChipStyles}
        />
      ) : (
        label || EMPTY_CELL_VALUE
      )}
    </TableCell>
  );
};
