// Route paths constants
export const ALL_ROUTES_WILDCARD = '*';
export const ROUTE_PATH_HOME = '/';

//Authentication
export const BASE_PATH_AUTHENTICATION = '/authentication';
export const ROUTE_PATH_LOGIN = `${BASE_PATH_AUTHENTICATION}/login`;
export const ROUTE_PATH_REGISTER = `${BASE_PATH_AUTHENTICATION}/register`;
export const ROUTE_PATH_RESET_PASSWORD = `${BASE_PATH_AUTHENTICATION}/reset-password`;

//Main
const ROUTE_PATH_ADMIN = '/admin';
const ROUTE_PATH_DATA_EXCHANGE = '/data-exchange';

//Admin routes
export const ROUTE_PATH_DASHBOARD = '/dashboard';
export const ROUTE_PATH_ORIGINATORS_SPVS = `${ROUTE_PATH_DASHBOARD}/originators-spvs`;
export const ROUTE_PATH_ORIGINATORS = `${ROUTE_PATH_DASHBOARD}${ROUTE_PATH_ADMIN}/originators`;
export const ROUTE_PATH_ORIGINATOR_DETAILS = `${ROUTE_PATH_DASHBOARD}${ROUTE_PATH_ADMIN}/originators/:id`;
export const ROUTE_PATH_SPVS = `${ROUTE_PATH_DASHBOARD}${ROUTE_PATH_ADMIN}/spvs`;
export const ROUTE_PATH_GROUPS = `${ROUTE_PATH_DASHBOARD}${ROUTE_PATH_ADMIN}/groups`;
export const ROUTE_PATH_BORROWER_DATA = `${ROUTE_PATH_DASHBOARD}${ROUTE_PATH_ADMIN}/borrower-data`;
export const ROUTE_PATH_SCORE_CLASSES = `${ROUTE_PATH_DASHBOARD}${ROUTE_PATH_ADMIN}/score-classes`;
export const ROUTE_PATH_CURRENCY_INVEST_AMOUNT = `${ROUTE_PATH_DASHBOARD}${ROUTE_PATH_ADMIN}/currency-invest-amount`;
export const ROUTE_PATH_ACCOUNTS = `${ROUTE_PATH_DASHBOARD}${ROUTE_PATH_ADMIN}/accounts`;

//Data exchange routes
export const ROUTE_PATH_TRANSACTIONS = `${ROUTE_PATH_DASHBOARD}${ROUTE_PATH_DATA_EXCHANGE}/transactions`;
export const ROUTE_PATH_TRANSACTION_DETAILS = `${ROUTE_PATH_DASHBOARD}${ROUTE_PATH_DATA_EXCHANGE}/transactions/:id`;

export const ROUTE_PATH_LOANS = `${ROUTE_PATH_DASHBOARD}${ROUTE_PATH_DATA_EXCHANGE}/loans`;
