import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';

import { flexGrowStyle } from '../../../../styles/commonStyles';
import { originatorDetailsHeaderStyles } from '../../../../styles/originatorDetailsStyles';
import { DIV_TAG } from '../../../../utils/constants/components';
import { ButtonVariants } from '../../../../utils/enums/ButtonVariants';
import { usePermissions } from '../../../../utils/hooks/permissions/usePermissions';
import { OriginatorTabsHeaderProps } from '../../../../utils/interfaces/originator/details/OriginatorTabsHeaderProps';
import { GridToggler } from '../../../common/GridToggler';

export const OriginatorTabsHeaderView = (props: OriginatorTabsHeaderProps) => {
  const { buttonLabel, onButtonClick, onGridToggle, gridView, permissionType } = props;
  const permissions = usePermissions();

  const hasPermission =
    typeof permissionType === 'string'
      ? permissions.hasCreatePermission(permissionType)
      : permissionType &&
        permissions.hasCreatePermission(permissionType[0]) &&
        permissions.hasCreatePermission(permissionType[1]);

  return onGridToggle || hasPermission ? (
    <Toolbar disableGutters sx={originatorDetailsHeaderStyles}>
      <Box component={DIV_TAG} sx={flexGrowStyle}>
        {onGridToggle && <GridToggler setGridValue={onGridToggle} view={gridView} />}
      </Box>
      {hasPermission && onButtonClick && (
        <Button onClick={onButtonClick} variant={ButtonVariants.Contained} startIcon={<AddIcon />}>
          {buttonLabel}
        </Button>
      )}
    </Toolbar>
  ) : null;
};
