import { themeConfig } from '../config/theme.config';
import { ErrorCardType } from '../utils/interfaces/ErrorCardProps';

export const COLOR_MAPPING = {
  [ErrorCardType.Error]: '#D32F2F',
  [ErrorCardType.Warning]: '#FF9800',
};

export const errorCardStyles = {
  width: '360px',
  textAlign: 'center',
};

export const errorCardTitleStyles = {
  color: '#ffffff',
  backgroundColor: themeConfig.palette.secondary.main,
};

export const errorCardContentStyles = { fontSize: '40px', px: 6, py: 3 };

export const errorCardHeaderStyles = {
  textTransform: 'uppercase',
  fontWeight: 600,
  fontSize: 14,
};
