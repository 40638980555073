import React, { useContext } from 'react';
import { FieldValues } from 'react-hook-form';

import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import {
  getOriginatorProductById,
  updateOriginatorProduct,
} from '../../../../../../api/controllers/ProductController';
import { Product, ProductBase } from '../../../../../../api/types/originatorTypes';
import { originatorProductDetailsFields } from '../../../../../../common/details/originatorProductDetailsFields';
import { flexGrowStyle, tabToolbarStyles } from '../../../../../../styles/commonStyles';
import { originatorDetailsEditButtonStyles } from '../../../../../../styles/originatorDetailsStyles';
import { OPEN_FORM_DIALOG } from '../../../../../../utils/constants/actions';
import {
  INTEREST_RATE_MAX_ID,
  INTEREST_RATE_MIN_ID,
  UPDATE_PRODUCT_FORM_ID,
} from '../../../../../../utils/constants/identifiers';
import { EMPTY_QUERY_ID, TOOLBAR_DENSE_VARIANT } from '../../../../../../utils/constants/options';
import { PRODUCTS_PERMISSION } from '../../../../../../utils/constants/permissions';
import { EDIT_PRODUCT_TITLE } from '../../../../../../utils/constants/titles';
import { FormDialogSubmitType } from '../../../../../../utils/enums/FormDialogSubmitType';
import { closeFormHandler } from '../../../../../../utils/helpers/closeFormHandler';
import { getPercentValue } from '../../../../../../utils/helpers/commonHelper';
import { usePermissions } from '../../../../../../utils/hooks/permissions/usePermissions';
import { OriginatorProductDialogProps } from '../../../../../../utils/interfaces/originator/details/OriginatorProductDialogProps';
import { Context } from '../../../../../../utils/store';
import { StyledDetail } from '../../StyledDetail';
import { UpdateProductForm } from './forms/UpdateProductForm';

export const OriginatorProductDialog = (props: OriginatorProductDialogProps) => {
  const {
    originatorId,
    productId,
    countries,
    loanCurrencies,
    loanTypes,
    amortizationMethods,
    installmentTypes,
  } = props;
  const { dispatch } = useContext(Context);

  const product = getOriginatorProductById(originatorId, productId);
  const permissions = usePermissions();

  const updateMutation = updateOriginatorProduct(originatorId, product?.id || EMPTY_QUERY_ID);

  const handleSubmit = (data: FieldValues) => {
    updateMutation.mutateAsync(data as ProductBase).then((response) => {
      closeFormHandler(dispatch, response.status);
    });
  };

  const handleEditClick = () => {
    if (product && product.id) {
      dispatch({
        type: OPEN_FORM_DIALOG,
        payload: {
          title: `${EDIT_PRODUCT_TITLE}: ${product.id}`,
          content: (
            <UpdateProductForm
              onSubmit={handleSubmit}
              product={product}
              countries={countries}
              loanTypes={loanTypes}
              loanCurrencies={loanCurrencies}
              amortizationMethods={amortizationMethods}
              installmentTypes={installmentTypes}
            />
          ),
          formId: UPDATE_PRODUCT_FORM_ID,
          submitType: FormDialogSubmitType.Edit,
        },
      });
    }
  };

  return (
    <Box>
      <Toolbar variant={TOOLBAR_DENSE_VARIANT} sx={tabToolbarStyles} disableGutters>
        <Typography sx={flexGrowStyle} gutterBottom>
          {product?.id}
        </Typography>
        {permissions.hasUpdatePermission(PRODUCTS_PERMISSION) && (
          <IconButton onClick={handleEditClick}>
            <EditIcon sx={originatorDetailsEditButtonStyles} />
          </IconButton>
        )}
      </Toolbar>
      {originatorProductDetailsFields.map((x, index) => {
        return (
          <StyledDetail
            key={index}
            title={x.title}
            value={product && getProductValue(product, x.id)}
          />
        );
      })}
    </Box>
  );
};

const getProductValue = (product: Product, fieldId: string) =>
  fieldId === INTEREST_RATE_MIN_ID || fieldId === INTEREST_RATE_MAX_ID
    ? getPercentValue(product[fieldId])
    : product[fieldId];
