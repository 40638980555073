import React from 'react';
import { useContext } from 'react';

import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import { sidebarUserAvatarStyles, userItemStyles } from '../../../styles/sidebarStyles';
import { BROKEN_IMAGE } from '../../../utils/constants/common';
import { SIDEBAR_USER_AVATAR_KEY, SIDEBAR_USER_INFO_KEY } from '../../../utils/constants/keys';
import { ADMIN_IUVO_LABEL, ORIGINATOR_LABEL } from '../../../utils/constants/labels';
import { DividerVariants } from '../../../utils/enums/DividerVariants';
import { Context } from '../../../utils/store';

export const UserInfo = () => {
  const { state } = useContext(Context);
  const { user } = state;

  const userRole = user?.user?.isSystemUser ? ADMIN_IUVO_LABEL : ORIGINATOR_LABEL;
  const userName = user?.user?.username;

  return (
    <List>
      <ListItem sx={sidebarUserAvatarStyles} key={SIDEBAR_USER_AVATAR_KEY}>
        <Avatar src={BROKEN_IMAGE} />
      </ListItem>
      <ListItem key={SIDEBAR_USER_INFO_KEY}>
        <ListItemText primary={userName} secondary={userRole} sx={userItemStyles} />
      </ListItem>
      <Divider variant={DividerVariants.Middle} />
    </List>
  );
};
