import { GridColumns } from '@mui/x-data-grid-pro';

import {
  FACILITY_AGREEMENT_NUMBER,
  FEE,
  SPV_ID,
  VALID_TO_DATE_ID,
} from '../../../../utils/constants/identifiers';
import {
  FACILITY_AGREEMENT_NUMBER_LABEL,
  FEE_LABEL,
  SPV_LABEL,
  VALID_TO_LABEL,
} from '../../../../utils/constants/labels';
import { GridColType } from '../../../../utils/enums/GridColType';
import { GridColWidth } from '../../../../utils/enums/GridColWidth';

export const originatorCountrySpvColumns: GridColumns = [
  { field: SPV_ID, headerName: SPV_LABEL, width: GridColWidth.Medium },
  { field: FEE, headerName: FEE_LABEL, type: GridColType.Number, width: GridColWidth.Medium },
  {
    field: FACILITY_AGREEMENT_NUMBER,
    headerName: FACILITY_AGREEMENT_NUMBER_LABEL,
    type: GridColType.Number,
    width: GridColWidth.Medium,
  },
  { field: VALID_TO_DATE_ID, headerName: VALID_TO_LABEL, width: GridColWidth.Small },
];
