import { nameof } from 'ts-simple-nameof';

import { useGetRequest } from '../../utils/hooks/query/useGetRequest';
import { useMutationRequest } from '../../utils/hooks/query/useMutationRequest';
import { AssignRoleToUserRequest } from '../../utils/interfaces/originator/details/AssignRoleToUserRequest';
import { AssignUserToOriginatorRequest } from '../../utils/interfaces/originator/details/AssignUserToOriginatorRequest';
import { DeleteOriginatorAccountRequest } from '../../utils/interfaces/originator/details/DeleteOriginatorAccountRequest';
import { originatorServiceClient } from '../apiClient';
import { Api, UserData, UserRolesResponseData } from '../types/originatorTypes';

export const getAllAccounts = (): UserData[] => {
  const { data: users } = useGetRequest({
    func: async () => (await originatorServiceClient.api.getAllUsers()).data,
    key: nameof<Api<unknown>>((x) => x.api.getAllUsers),
  });

  if (!users || !users.data) {
    return [];
  }

  return users?.data;
};

export const createAccount = () =>
  useMutationRequest(
    (email: string) => originatorServiceClient.api.createUser({ username: email }),
    nameof<Api<unknown>>((x) => x.api.getAllUsers),
  );

export const getAllOriginatorAccounts = (originatorId: number): UserData[] => {
  const { data: originatorAccounts } = useGetRequest({
    func: async () => (await originatorServiceClient.api.getOriginatorUsers(originatorId)).data,
    key: nameof<Api<unknown>>((x) => x.api.getOriginatorUsers),
  });

  if (!originatorAccounts || !originatorAccounts.data) {
    return [];
  }

  return originatorAccounts?.data;
};

export const assignUserToOriginator = () =>
  useMutationRequest(
    (request: AssignUserToOriginatorRequest) =>
      originatorServiceClient.api.assignUserToOriginator(request.originatorId, {
        username: request.email,
      }),
    nameof<Api<unknown>>((x) => x.api.getOriginatorUsers),
  );

export const getUserRolesById = (id: number): UserRolesResponseData => {
  const params = { id };

  const { data: userRoles } = useGetRequest({
    func: async () => (await originatorServiceClient.api.getUserRoles(id)).data,
    key: nameof<Api<unknown>>((x) => x.api.getUserRoles),
    params,
  });

  return userRoles?.data as UserRolesResponseData;
};

export const assignRolesToUser = () =>
  useMutationRequest(
    (request: AssignRoleToUserRequest) =>
      originatorServiceClient.api.updateUserRoles(request.userId, request.baseRequest),
    [
      nameof<Api<unknown>>((x) => x.api.getAllUsers),
      nameof<Api<unknown>>((x) => x.api.getOriginatorUsers),
      nameof<Api<unknown>>((x) => x.api.getUserRoles),
    ],
  );

export const deleteAccount = () =>
  useMutationRequest(
    (id: number) => originatorServiceClient.api.deleteUser(id),
    nameof<Api<unknown>>((x) => x.api.getAllUsers),
  );

export const deleteOriginatorAccount = () =>
  useMutationRequest(
    (request: DeleteOriginatorAccountRequest) =>
      originatorServiceClient.api.removeUserFromOriginator(request.originatorId, request.userId),
    nameof<Api<unknown>>((x) => x.api.getOriginatorUsers),
  );
