import React, { useState } from 'react';

import Grid from '@mui/material/Grid';

import { loansTabs } from '../../../../common/loans/loansTabs';
import { GridOptions } from '../../../../utils/enums/GridOptions';
import { LoansTabs } from '../../../../utils/enums/LoansTabs';
import { TabsVariants } from '../../../../utils/enums/TabsVariants';
import { LoansTabsViewProps } from '../../../../utils/interfaces/filter/LoansTabsViewProps';
import { TabsView } from '../../../common/tabs/TabsView';
import { LoansTabContentView } from './LoansTabsContentView';

export const LoansTabsView = (props: LoansTabsViewProps) => {
  const { permissionType, filters, sort } = props;

  const [selectedTab, setSelectedTab] = useState(LoansTabs.Loans);

  return (
    <Grid container>
      <Grid item xs={GridOptions.ExtraLarge} lg={GridOptions.Large}>
        <TabsView
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          tabs={loansTabs}
          tabsVariant={TabsVariants.Scrollable}
        />
      </Grid>
      <Grid item xs={GridOptions.ExtraLarge}>
        <LoansTabContentView
          filters={filters}
          selectedTab={selectedTab}
          permissionType={permissionType}
          sort={sort}
        />
      </Grid>
    </Grid>
  );
};
