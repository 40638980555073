import { nameof } from 'ts-simple-nameof';

import { useGetRequest } from '../../utils/hooks/query/useGetRequest';
import { useMutationRequest } from '../../utils/hooks/query/useMutationRequest';
import { AddBorrowerDataLoanFieldRequest } from '../../utils/interfaces/borrowerData/loans/AddBorrowerDataLoanFieldRequest';
import { AddOriginatorBDLoanFieldRequest } from '../../utils/interfaces/borrowerData/loans/AddOriginatorBDLoanFieldRequest';
import { DeleteBorrowerDataLoanFieldRequest } from '../../utils/interfaces/borrowerData/loans/DeleteBorrowerDataLoanFieldRequest';
import { DeleteOriginatorBDLoanRequest } from '../../utils/interfaces/borrowerData/loans/DeleteOriginatorBDLoanRequest';
import { originatorServiceClient } from '../apiClient';
import {
  BorrowerDataField,
  BorrowerDataFieldBase,
  LoanBorrowerDataField,
  Api as OriginatorApi,
} from '../types/originatorTypes';

export const getBorrowerDataFields = (): BorrowerDataField[] => {
  const { data: borrowerDataFields } = useGetRequest({
    key: nameof<OriginatorApi<unknown>>((x) => x.api.getAllBorrowerDataFields),
    func: async () => (await originatorServiceClient.api.getAllBorrowerDataFields()).data,
  });

  if (!borrowerDataFields || !borrowerDataFields.data) {
    return [];
  }

  return borrowerDataFields.data;
};

export const getBorrowerDataLoanTypeFields = (loanTypeId: number): LoanBorrowerDataField[] => {
  const { data: borrowerDataLoanTypeFields } = useGetRequest({
    key: nameof<OriginatorApi<unknown>>((x) => x.api.getLoanBorrowerDataFields),
    func: async () =>
      (await originatorServiceClient.api.getLoanBorrowerDataFields(loanTypeId)).data,
  });

  if (!borrowerDataLoanTypeFields || !borrowerDataLoanTypeFields.data) {
    return [];
  }

  return borrowerDataLoanTypeFields?.data;
};

export const addBorrowerDataLoanField = () =>
  useMutationRequest(
    (request: AddBorrowerDataLoanFieldRequest) =>
      originatorServiceClient.api.createLoanBorrowerDataField(request.loanId, {
        id: request.id,
      }),
    nameof<OriginatorApi<unknown>>((x) => x.api.getLoanBorrowerDataFields),
  );

export const deleteBorrowerDataLoanTypeField = () =>
  useMutationRequest(
    (request: DeleteBorrowerDataLoanFieldRequest) =>
      originatorServiceClient.api.deleteLoanBorrowerDataField(
        request.loanTypeId,
        request.borrowerDataFieldId,
      ),
    nameof<OriginatorApi<unknown>>((x) => x.api.getLoanBorrowerDataFields),
  );

export const addBorrowerDataField = () =>
  useMutationRequest(
    (request: BorrowerDataFieldBase) =>
      originatorServiceClient.api.createBorrowerDataField(request),
    nameof<OriginatorApi<unknown>>((x) => x.api.getAllBorrowerDataFields),
  );

export const updateBorrowerDataField = (id: number) =>
  useMutationRequest(
    (request: BorrowerDataFieldBase) =>
      originatorServiceClient.api.updateBorrowerDataField(id, request),
    nameof<OriginatorApi<unknown>>((x) => x.api.getAllBorrowerDataFields),
  );

export const deleteBorrowerDataField = () =>
  useMutationRequest(
    (id: number) => originatorServiceClient.api.deleteBorrowerDataField(id),
    nameof<OriginatorApi<unknown>>((x) => x.api.getAllBorrowerDataFields),
  );

export const getOriginatorBDLoanFields = (
  originatorId: number,
  loanTypeId: number,
): LoanBorrowerDataField[] => {
  const { data: originatorBdLoanTypeFields } = useGetRequest({
    key: nameof<OriginatorApi<unknown>>((x) => x.api.getOriginatorLoanBorrowerDataFields),
    func: async () =>
      (
        await originatorServiceClient.api.getOriginatorLoanBorrowerDataFields(
          originatorId,
          loanTypeId,
        )
      ).data,
  });

  if (!originatorBdLoanTypeFields || !originatorBdLoanTypeFields.data) {
    return [];
  }

  return originatorBdLoanTypeFields?.data;
};

export const addOriginatorBDLoanField = () =>
  useMutationRequest(
    (request: AddOriginatorBDLoanFieldRequest) =>
      originatorServiceClient.api.createOriginatorLoanBorrowerDataField(
        request.originatorId,
        request.loanId,
        {
          id: request.id,
        },
      ),
    nameof<OriginatorApi<unknown>>((x) => x.api.getOriginatorLoanBorrowerDataFields),
  );

export const deleteOriginatorBDLoanField = () =>
  useMutationRequest(
    (request: DeleteOriginatorBDLoanRequest) =>
      originatorServiceClient.api.deleteOriginatorLoanBorrowerDataField(
        request.originatorId,
        request.loanTypeId,
        request.originatorLoanBorrowerDataFieldId,
      ),
    nameof<OriginatorApi<unknown>>((x) => x.api.getOriginatorLoanBorrowerDataFields),
  );
