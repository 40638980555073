import { GridColumns } from '@mui/x-data-grid-pro';

import {
  IS_SYSTEM_USER_ID,
  MULTI_ORIGINATOR_USER_ID,
  USERNAME_ID,
} from '../../../../utils/constants/identifiers';
import {
  ADMIN_IUVO_LABEL,
  EMAIL_LABEL,
  MULTI_ORIGINATOR_LABEL,
  ORIGINATOR_LABEL,
  ROLE_LABEL,
} from '../../../../utils/constants/labels';
import { GRID_ACTIONS_COLUMN_FLEX } from '../../../../utils/constants/styles';
import { GridColType } from '../../../../utils/enums/GridColType';

export const originatorAccountsColumns: GridColumns = [
  { field: USERNAME_ID, headerName: EMAIL_LABEL, flex: GRID_ACTIONS_COLUMN_FLEX },
  {
    field: IS_SYSTEM_USER_ID,
    headerName: ROLE_LABEL,
    flex: GRID_ACTIONS_COLUMN_FLEX,
    valueGetter: ({ value }) => (value ? ADMIN_IUVO_LABEL : ORIGINATOR_LABEL),
  },
  {
    field: MULTI_ORIGINATOR_USER_ID,
    headerName: MULTI_ORIGINATOR_LABEL,
    flex: GRID_ACTIONS_COLUMN_FLEX,
    type: GridColType.Boolean,
  },
];
