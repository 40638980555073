export const downloadFile = async (response: Response, fileName: string): Promise<void> => {
  response.blob().then((blob) => {
    // Create a blob.
    const url = window.URL.createObjectURL(new Blob([blob]));

    // Create anchor element so we can click it.
    const link = document.createElement('a');

    // Attaching url to anchor element.
    link.href = url;

    // Setting file attributes.
    link.setAttribute('download', `${fileName}.xlsx`);

    // Append to html link element page
    document.body.appendChild(link);

    // Simulate link click behind the scenes to start download.
    link.click();

    // Removing the created link.
    link.remove();
  });
};
