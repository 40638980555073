import { dataGridColumnStyles } from './dataGridStyles';

export const transactionContentBoxShadow = {
  boxShadow: 'none',
};

export const transactionTabsStyles = {
  marginTop: 3,
  ...transactionContentBoxShadow,
};

export const transactionButtonStyles = {
  ml: 2,
};

export const transactionInfoBoxStyles = {
  height: '266px',
};

export const transactionInfoGridStyles = {
  ...dataGridColumnStyles,
  mt: 2,
  maxWidth: '740px',
  width: '100%',
};

export const cardsContainerStyles = {
  width: '100%',
  maxWidth: '740px',
  mt: 5,
  mb: 8,
};
