import React, { ChangeEvent } from 'react';
import { useLocation } from 'react-router-dom';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import { Transaction } from '../../../api/types/originatorTypes';
import { customHistory } from '../../../components/App';
import { tablePaginationStyles } from '../../../styles/styledTableStyles';
import { TABLE_ROWS_PAGE_OPTIONS } from '../../../utils/constants/common';
import { DIV_TAG } from '../../../utils/constants/components';
import { NO_ROWS_LABEL } from '../../../utils/constants/labels';
import { paginationChange } from '../../../utils/helpers/advancedFilters';
import { TableProps } from '../../../utils/interfaces/table/TableProps';
import { StyledTableCell } from './StyledTableCell';
import { StyledTableHeader } from './StyledTableHeader';

export const TableView = (props: TableProps) => {
  const {
    data,
    parentColumns,
    mainColumns,
    rowsPerPageOptions,
    tableSettings,
    dataCount,
    handleRowClick,
  } = props;

  const onRowClick = (id: string): void => {
    handleRowClick && handleRowClick(id);
  };

  const search = useLocation().search;

  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead>
            {parentColumns && (
              <TableRow>
                {parentColumns.map((col) => (
                  <StyledTableHeader
                    key={col.key}
                    colSpan={col.colSpan}
                    applyBorderRight={true}
                    label={col.label}
                  />
                ))}
              </TableRow>
            )}
            <TableRow>
              {mainColumns.map((col) => (
                <StyledTableHeader
                  key={col.key}
                  colSpan={col.colSpan}
                  applyBorderRight={col.applyBorderRight}
                  label={col.label}
                />
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((data: Transaction) => {
              return (
                //TODO: Replace data.transId with data.id when BE change return type of transactions.
                <TableRow
                  hover
                  key={data.transId}
                  onClick={() => onRowClick(data.transId as string)}
                >
                  {mainColumns.map((col) => {
                    const value = data[col.key];
                    return (
                      <StyledTableCell
                        key={col.key}
                        label={value}
                        hasStatus={col.hasStatus}
                        applyBorderRight={col.applyBorderRight}
                      />
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {!data || data.length === 0 ? (
          <Typography sx={tablePaginationStyles}>{NO_ROWS_LABEL}</Typography>
        ) : null}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions ?? TABLE_ROWS_PAGE_OPTIONS}
        component={DIV_TAG}
        rowsPerPage={tableSettings ? tableSettings.pageSize : 10}
        page={data.length > 0 && tableSettings ? tableSettings.pageNumber : 0}
        count={dataCount ? dataCount : 0}
        onRowsPerPageChange={(newPageSize: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
          const params = new URLSearchParams(search);
          paginationChange(+newPageSize.target.value, 'pageSize', params);

          params.set('pageNumber', '0');
          customHistory.push({ search: params.toString() });
        }}
        onPageChange={(e, newPage) => {
          const params = new URLSearchParams(search);
          paginationChange(newPage, 'pageNumber', params);
        }}
      />
    </Paper>
  );
};
