import { Dispatch } from 'react';
import { FieldValues } from 'react-hook-form';

import { nameof } from 'ts-simple-nameof';

import { appConfig } from '../../config/app.config';
import { CLOSE_FORM_DIALOG } from '../../utils/constants/actions';
import { ACCESS_TOKEN, BEARER_TOKEN, EMPTY_VALUE } from '../../utils/constants/common';
import { EXPORT_TRANSACTION_FORM_ID } from '../../utils/constants/identifiers';
import {
  SUCCESS_APPROVED_MESSAGE,
  SUCCESS_EXPORTED_MESSAGE,
  SUCCESS_UPLOADED_MESSAGE,
} from '../../utils/constants/messages';
import { HttpStatusCodes } from '../../utils/enums/HttpStatusCodes';
import { TransactionOptions } from '../../utils/enums/TransactionOptions';
import { hideLoader, showLoader } from '../../utils/helpers/commonHelper';
import { downloadFile } from '../../utils/helpers/downloadFileHelper';
import { errorResultHandler } from '../../utils/helpers/errorResultHandler';
import { successResultHandler } from '../../utils/helpers/successResultHandler';
import { useGetRequest } from '../../utils/hooks/query/useGetRequest';
import { useMutationRequest } from '../../utils/hooks/query/useMutationRequest';
import { BeFiltersInterface } from '../../utils/interfaces/filter/BeFiltersInterface';
import { Action } from '../../utils/store/action';
import { importerServiceClient, originatorServiceClient } from '../apiClient';
import { Error, MultiTransactionResponse } from '../types/originatorTypes';
import { Api as OriginatorApi, TransactionData, TransactionUpdate } from '../types/originatorTypes';

export const importFile = (transId?: string) =>
  useMutationRequest(
    (request: FieldValues) =>
      importerServiceClient.api.upload(
        { originatorId: request.originatorId, transId },
        { file: request.file[0] as File },
      ),
    [
      nameof<OriginatorApi<unknown>>((x) => x.api.getAllTransactions),
      nameof<OriginatorApi<unknown>>((x) => x.api.getOriginatorTransactions),
    ],
    SUCCESS_UPLOADED_MESSAGE,
  );

export const draftImportFile = (transId?: string) =>
  useMutationRequest(
    (request: FieldValues) =>
      importerServiceClient.api.draftUpload(
        { originatorId: request.originatorId, transId },
        { file: request.file[0] as File },
      ),
    [
      nameof<OriginatorApi<unknown>>((x) => x.api.getAllTransactions),
      nameof<OriginatorApi<unknown>>((x) => x.api.getOriginatorTransactions),
    ],
    SUCCESS_UPLOADED_MESSAGE,
  );

export const getAllTransactions = (filters?: BeFiltersInterface): MultiTransactionResponse => {
  const { data: transactions } = useGetRequest(
    {
      key: nameof<OriginatorApi<unknown>>((x) => x.api.getAllTransactions),
      func: async () =>
        (
          await originatorServiceClient.api.getAllTransactions({
            filter: JSON.stringify(filters?.formFields),
            pageSize: filters?.tableSettings.pageSize,
            pageNumber: filters?.tableSettings.pageNumber,
          })
        ).data,
    },
    filters,
  );

  if (!transactions || !transactions.data) {
    return {};
  }

  return transactions;
};

export const getAllOriginatorTransactions = (
  originatorId: number,
  filters?: BeFiltersInterface,
): MultiTransactionResponse => {
  const { data: transactions } = useGetRequest(
    {
      key: nameof<OriginatorApi<unknown>>((x) => x.api.getOriginatorTransactions),
      func: async () =>
        (
          await originatorServiceClient.api.getOriginatorTransactions(originatorId, {
            filter: JSON.stringify(filters?.formFields),
            pageSize: filters?.tableSettings.pageSize,
            pageNumber: filters?.tableSettings.pageNumber,
          })
        ).data,
    },
    filters,
  );

  if (!transactions || !transactions.data) {
    return {};
  }

  return transactions;
};

export const getTransactionData = (
  transId: string,
  dataType: TransactionOptions,
  loanId: string,
): { data: TransactionData; remove: () => void } => {
  const { data: transactionData, remove } = useGetRequest(
    {
      key: nameof<OriginatorApi<unknown>>((x) => x.api.getTransactionData),
      func: async () =>
        (await originatorServiceClient.api.getTransactionData(transId, dataType, { loanId })).data,
    },
    { loanId: loanId },
  );

  return { data: transactionData?.data as TransactionData, remove };
};

export const approveTransaction = (transId: string) =>
  useMutationRequest(
    (request: TransactionUpdate) =>
      originatorServiceClient.api.publishTransaction(transId, request),
    [
      nameof<OriginatorApi<unknown>>((x) => x.api.getAllTransactions),
      nameof<OriginatorApi<unknown>>((x) => x.api.getTransactionData),
    ],
    SUCCESS_APPROVED_MESSAGE,
  );

export const exportTransaction = async (
  transId: string,
  dataType: TransactionOptions,
  hasError: boolean,
  dispatch: Dispatch<Action>,
  signal: AbortSignal | undefined,
): Promise<void> => {
  const exportErrors = `?hasError=${hasError}`;

  const url = `${appConfig.importerServiceApiUrl}/api/export/${transId}/${dataType}${
    hasError ? exportErrors : EMPTY_VALUE
  }`;
  const token = localStorage.getItem(ACCESS_TOKEN);

  const requestHeaders = {
    headers: {
      Authorization: `${BEARER_TOKEN} ${token}`,
    },
    signal: signal,
  };

  showLoader(dispatch, EXPORT_TRANSACTION_FORM_ID);

  await fetch(url, requestHeaders)
    .then((response) => {
      if (response.status === HttpStatusCodes.Success) {
        // Hardcoding the filename for now because we cant acces Content-Disposition header from response to get actual file name.
        // Logic for naming the file is the same as the backend's.
        const fileName = `transactionData_${dataType}_${transId}`;
        downloadFile(response, fileName);
        hideLoader(dispatch);
        dispatch({ type: CLOSE_FORM_DIALOG });

        return successResultHandler(response, SUCCESS_EXPORTED_MESSAGE);
      }
    })
    .catch((error: Error) => {
      hideLoader(dispatch);
      if (!error.detail) {
        errorResultHandler({ detail: (error as { message: string }).message });
      } else {
        errorResultHandler(error);
      }
    });
};
