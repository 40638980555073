import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';

import { Checkbox, FormControlLabel, ListItem, ListItemText, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { getAllOriginators } from '../../../../api/controllers/OriginatorController';
import {
  directSubmitStyles,
  fileOptionsStyles,
  fileUploadBoxStyles,
} from '../../../../styles/fileUploadStyles';
import { formGridSpacing } from '../../../../styles/formStyles';
import {
  ALLOWED_EXTENSIONS,
  ALLOWED_UPLOAD_FILE_EXTENSIONS,
  MAXIMUM_FILE_SIZE_MB,
  MAXIMUM_UPLOAD_FILE_SIZE,
} from '../../../../utils/constants/common';
import { DIV_TAG, FORM_TAG } from '../../../../utils/constants/components';
import {
  FILE_ID,
  IMPORT_TRANSACTION_FORM_ID,
  IS_DRAFT_ID,
  ORIGINATOR_ID,
} from '../../../../utils/constants/identifiers';
import { DIRECT_SUBMIT_LABEL, ORIGINATOR_LABEL } from '../../../../utils/constants/labels';
import { UPLOADED_FILE_MESSAGE } from '../../../../utils/constants/messages';
import { usePermissions } from '../../../../utils/hooks/permissions/usePermissions';
import { ImportTransactionFormProps } from '../../../../utils/interfaces/form/ImportTransactionFormProps';
import { SelectorChoicesType } from '../../../../utils/interfaces/form/SelectorChoicesType';
import { Context } from '../../../../utils/store';
import { FileUploadView } from '../../../common/form/FileUploadView';
import { SelectField } from '../../../common/form/SelectField';
import { LargeGridField } from '../../../common/form/grid/LargeGridField';
import { MediumGridField } from '../../../common/form/grid/MediumGridField';

export const ImportTransactionForm = (props: ImportTransactionFormProps) => {
  const { onSubmit, showOriginatorDropdown, permissionType } = props;
  const { state } = useContext(Context);
  const { user } = state;

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
    getValues,
    control,
  } = useForm();

  const file: File = getValues(FILE_ID);

  const originators = showOriginatorDropdown ? getAllOriginators() : [];

  const isSystemUser = user?.user?.isSystemUser;

  const permissions = usePermissions();

  return (
    <Box component={FORM_TAG} id={IMPORT_TRANSACTION_FORM_ID} onSubmit={handleSubmit(onSubmit)}>
      <Grid container rowSpacing={1} columnSpacing={formGridSpacing}>
        <LargeGridField>
          <FileUploadView id={FILE_ID} register={register} errors={errors} setValue={setValue} />
        </LargeGridField>
        <LargeGridField>
          {file && file[0] && (
            <ListItem key={FILE_ID} disableGutters>
              <ListItemText
                primary={file[0].path}
                secondary={<React.Fragment>{UPLOADED_FILE_MESSAGE}</React.Fragment>}
              />
            </ListItem>
          )}
          <Box component={DIV_TAG} sx={fileUploadBoxStyles}>
            <Typography>{ALLOWED_UPLOAD_FILE_EXTENSIONS}</Typography>
            <Typography sx={fileOptionsStyles}>{ALLOWED_EXTENSIONS}</Typography>
          </Box>
          <Box component={DIV_TAG} sx={fileUploadBoxStyles}>
            <Typography>{MAXIMUM_UPLOAD_FILE_SIZE}</Typography>
            <Typography sx={fileOptionsStyles}>{MAXIMUM_FILE_SIZE_MB}</Typography>
          </Box>
          {showOriginatorDropdown && isSystemUser && (
            <MediumGridField>
              <SelectField
                id={ORIGINATOR_ID}
                label={ORIGINATOR_LABEL}
                control={control}
                choices={originators as SelectorChoicesType[]}
                required
              />
            </MediumGridField>
          )}
          {permissions.hasUpdatePermission(permissionType) && (
            <FormControlLabel
              control={<Checkbox />}
              label={DIRECT_SUBMIT_LABEL}
              sx={directSubmitStyles}
              {...register(IS_DRAFT_ID)}
            />
          )}
        </LargeGridField>
      </Grid>
    </Box>
  );
};
