import { viravaService } from '../../api/apiClient';
import { internalSignUp } from '../../api/controllers/UsersController';
import { Error } from '../../api/types/originatorTypes';
import { customHistory } from '../../components/App';
import { ERROR_ID } from '../constants/identifiers';
import { UNEXPECTED_ERROR_MESSAGE } from '../constants/messages';
import { ROUTE_PATH_DASHBOARD, ROUTE_PATH_LOGIN } from '../constants/routes';
import { HttpStatusCodes } from '../enums/HttpStatusCodes';
import { showErrorToast } from './toastHelper';

async function logoutCall() {
  await viravaService.logout();
  customHistory.replace(ROUTE_PATH_LOGIN);
}

export const errorResultHandler = (responseError: Error) => {
  // We check if in responseError has details.
  // If it has that means the error comes from viravaService
  // !!! If error comes from viravaService it comes like different object. !!!
  // !!! Thats why we handle it in different way. !!!
  if (responseError.detail) {
    return showErrorToast(responseError.detail);
  }

  // We get the error object from response here
  // We do it this way because from response obj we got:
  // Response: {error: error: {details, status ...}}
  // and we return error based on status code.
  const error = responseError[ERROR_ID]?.error as Error;

  if (!error || !error.detail || !error.status) {
    return showErrorToast(UNEXPECTED_ERROR_MESSAGE);
  }

  switch (error.status) {
    case HttpStatusCodes.ExpectationFailed:
      return internalSignUp();
    case HttpStatusCodes.BadRequest:
      return showErrorToast(error.detail);
    case HttpStatusCodes.Forbidden:
      customHistory.replace(ROUTE_PATH_DASHBOARD);
      return showErrorToast(error.detail);
    case HttpStatusCodes.Unauthorized:
      logoutCall();
      return showErrorToast(error.detail);
    default:
      return showErrorToast(UNEXPECTED_ERROR_MESSAGE);
  }
};
