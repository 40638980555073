import React, { useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { Box, Button, Grid, Paper } from '@mui/material';
import TextField from '@mui/material/TextField';
import { DateRange } from '@mui/x-date-pickers-pro';

import {
  advancedFiltersGridStyles,
  advancedFiltersWrapperStyles,
  buttonWrapperStyles,
  textFieldWrapperStyles,
} from '../../../../src/styles/advancedFiltersStyles';
import { tabsPaperStyles } from '../../../styles/tabsStyles';
import { SEARCH_BUTTON } from '../../../utils/constants/buttons';
import { ButtonVariants } from '../../../utils/enums/ButtonVariants';
import { FilterTypes } from '../../../utils/enums/FilterTypes';
import {
  applyFilters,
  modifyFilters,
  setFiltersQueryParams,
} from '../../../utils/helpers/advancedFilters';
import { AdvancedFiltersProps } from '../../../utils/interfaces/filter/AdvancedFiltersProps';
import { FiltersInitialState } from '../../../utils/interfaces/filter/FiltersInitialState';
import { DateRangePickerComponent } from './DateRangePicker';
import { MultipleSelect } from './MultipleSelect';

export const AdvancedFilters = (props: AdvancedFiltersProps) => {
  const {
    filters,
    setFilters,
    setBeFilters,
    setIsAdvancedFiltersOpen,
    nomenclatures,
    isAdvancedFiltersOpen,
  } = props;
  const [searchParams] = useSearchParams();
  const search = useLocation().search;

  const changeFilterValue = (
    value: string | [null, null] | null | [] | DateRange<unknown>,
    name: string,
  ) => {
    const modifiedFilters = filters.map((filter: FiltersInitialState) => {
      if (filter.name === name) {
        return { ...filter, value: value };
      }

      return filter;
    });
    setFilters(modifiedFilters);
  };

  const setFiltersValues = (currFilters: any) => {
    let queryParamsFound = false;

    currFilters.forEach((filter: FiltersInitialState) => {
      if (filter.type === FilterTypes.Custom && !searchParams.get(filter.name)) {
        setFiltersQueryParams(currFilters, search);
      }
    });

    const newFilters = currFilters.map((filter: FiltersInitialState, idx: number) => {
      const param = searchParams.get(filter.name);
      if (param) {
        if (filter.type !== FilterTypes.Custom) {
          queryParamsFound = true;
        }
        let jsonParam = JSON.parse(param);
        if (filter.type === FilterTypes.MultipleSelect) {
          if (filters[idx].data?.length === 0) {
            filter.data = nomenclatures && nomenclatures[filter.name];
          }

          jsonParam = jsonParam.map((el: string) =>
            (filter.data as Array<unknown>).find(
              (dataEl: unknown) => (dataEl as { id: string }).id === el,
            ),
          );
        }

        if (filter.type === FilterTypes.DateRange) {
          jsonParam = jsonParam[0];
        }
        filter.value = jsonParam;
      }

      return filter;
    });

    if (queryParamsFound && setIsAdvancedFiltersOpen) {
      setIsAdvancedFiltersOpen(true);
    }

    return newFilters;
  };

  useEffect(() => {
    const copiedInitialFilters = JSON.parse(JSON.stringify(filters));
    const newFilters = setFiltersValues(copiedInitialFilters);

    setFilters(newFilters);
    setBeFilters(modifyFilters(newFilters, setFilters) as []);
  }, [searchParams]);

  useEffect(() => {
    const newFilters = filters.map((filter: FiltersInitialState) => {
      if (filter.type === FilterTypes.MultipleSelect) {
        filter.data = (nomenclatures && nomenclatures[filter.name]) || [];
      }

      return filter;
    });

    const filtersWithValues = setFiltersValues(newFilters);

    setFilters(filtersWithValues);
  }, [JSON.stringify(nomenclatures)]);

  return isAdvancedFiltersOpen ? (
    <Paper square sx={{ ...tabsPaperStyles, ...advancedFiltersWrapperStyles }}>
      <Grid container columnSpacing={1} rowSpacing={2} sx={advancedFiltersGridStyles}>
        {filters?.map((filter: FiltersInitialState) => {
          switch (filter.type) {
            case FilterTypes.DateRange:
              return (
                <Grid item xs={6} key={filter.name}>
                  <DateRangePickerComponent
                    label={filter.label as string}
                    value={filter.value as []}
                    setValue={(filterValue: DateRange<unknown>) =>
                      changeFilterValue(filterValue, filter.name)
                    }
                  />
                </Grid>
              );
            case FilterTypes.MultipleSelect:
              return (
                <Grid item xs={3} key={filter.name}>
                  <MultipleSelect
                    label={filter.label as string}
                    data={filter.data}
                    value={filter.value as []}
                    setValue={(filterValue) => changeFilterValue(filterValue, filter.name)}
                  />
                </Grid>
              );
            case FilterTypes.Text:
              return (
                <Grid item xs={3} key={filter.name}>
                  <TextField
                    sx={textFieldWrapperStyles}
                    label={filter.label as string}
                    variant="outlined"
                    value={filter.value ? filter.value : ''}
                    onChange={(event) => changeFilterValue(event.target.value, filter.name)}
                  />
                </Grid>
              );
            default:
              return null;
          }
        })}
      </Grid>
      <Box sx={buttonWrapperStyles}>
        <Button
          onClick={() => applyFilters(filters, setBeFilters, search, setFilters)}
          variant={ButtonVariants.Contained}
        >
          {SEARCH_BUTTON}
        </Button>
      </Box>
    </Paper>
  ) : null;
};
