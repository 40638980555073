import { nameof } from 'ts-simple-nameof';

import { useGetRequest } from '../../utils/hooks/query/useGetRequest';
import { useMutationRequest } from '../../utils/hooks/query/useMutationRequest';
import { originatorServiceClient } from '../apiClient';
import { Api, OriginatorGroup, OriginatorGroupBase } from '../types/originatorTypes';

export const getAllOriginatorGroups = (skipRequest?: boolean): OriginatorGroup[] => {
  const { data: originatorGroups } = useGetRequest({
    key: nameof<Api<unknown>>((x) => x.api.getAllOriginatorGroups),
    func: async () => {
      if (skipRequest) {
        return null;
      } else {
        return (await originatorServiceClient.api.getAllOriginatorGroups()).data;
      }
    },
  });

  if (!originatorGroups || !originatorGroups.data) {
    return [];
  }

  return originatorGroups.data;
};

export const getOriginatorGroupById = (id: number): OriginatorGroup => {
  const params = { id };

  const { data: originatorGroup } = useGetRequest({
    func: async () => (await originatorServiceClient.api.getOriginatorGroupById(id)).data,
    key: nameof<Api<unknown>>((x) => x.api.getOriginatorGroupById),
    params,
  });

  return originatorGroup?.data as OriginatorGroup;
};

export const createOriginatorGroup = () =>
  useMutationRequest(
    (request: OriginatorGroupBase) => originatorServiceClient.api.createOriginatorGroup(request),
    nameof<Api<unknown>>((x) => x.api.getAllOriginatorGroups),
  );

export const updateOriginatorGroup = (id: number) =>
  useMutationRequest(
    (request: OriginatorGroupBase) =>
      originatorServiceClient.api.updateOriginatorGroup(id, request),
    nameof<Api<unknown>>((x) => x.api.getAllOriginatorGroups),
  );
