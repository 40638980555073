import { useContext } from 'react';

import {
  ERRORS_PARENT_KEY,
  FEES_PARENT_KEY,
  LOANS_PARENT_KEY,
  ORIGINATOR_PARENT_KEY,
  PAYMENTS_PARENT_KEY,
  PAYMENT_SCHEDULES_PARENT_KEY,
  STATUS_PARENT_KEY,
} from '../../../utils/constants/keys';
import {
  FEE_LABEL,
  LOANS_LABEL,
  PAYMENTS_LABEL,
  PAYMENT_SCHEDULES_LABEL,
} from '../../../utils/constants/labels';
import { ColumnDefinition } from '../../../utils/interfaces/table/ColumnDefinition';
import { Context } from '../../../utils/store';
import { ContextType } from '../../../utils/store/types/ContextType';

export const transactionParentColumns = (): ColumnDefinition[] => {
  const { state } = useContext<ContextType>(Context);

  if (!state.originatorID || state.user?.user?.isSystemUser) {
    return [...originatorColumn, ...columns];
  }

  return columns;
};

const columns: ColumnDefinition[] = [
  { key: ERRORS_PARENT_KEY, colSpan: 2 },
  { key: LOANS_PARENT_KEY, colSpan: 3, label: LOANS_LABEL },
  { key: PAYMENT_SCHEDULES_PARENT_KEY, colSpan: 3, label: PAYMENT_SCHEDULES_LABEL },
  { key: PAYMENTS_PARENT_KEY, colSpan: 3, label: PAYMENTS_LABEL },
  { key: FEES_PARENT_KEY, colSpan: 3, label: FEE_LABEL },
  { key: STATUS_PARENT_KEY, colSpan: 1 },
];

const originatorColumn: ColumnDefinition[] = [{ key: ORIGINATOR_PARENT_KEY, colSpan: 1 }];
