import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { isAuthenticated } from '../../../utils/authentication/authentication';
import { ROUTE_PATH_LOGIN } from '../../../utils/constants/routes';

export const ProtectedRoute = () => {
  if (!isAuthenticated()) {
    return <Navigate to={ROUTE_PATH_LOGIN} replace />;
  }

  return <Outlet />;
};
