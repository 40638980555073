export const roleItemStyles = { width: '50%', alignItems: 'center' };

export const originatorRolesStyles = {
  width: '100%',
  fontWeight: 600,
  mt: 2,
  mb: 1,
  color: '#00000061',
};

export const multiOriginatorTitleStyles = {
  width: '100%',
  mb: 3,
  textAlign: 'center',
};
