import React from 'react';
import { useNavigate } from 'react-router';

import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import {
  authLinkButtonStyles,
  authSubTitleStyles,
  authTitleStyles,
} from '../../../styles/authFormStyles';
import { justifyCenterContent } from '../../../styles/commonStyles';
import { ROUTE_PATH_LOGIN } from '../../../utils/constants/routes';
import { TypographyVariants } from '../../../utils/enums/TypographyVariants';
import { AuthFormHeaderViewProps } from '../../../utils/interfaces/authentication/AuthFormHeaderViewProps';

export const AuthFormHeaderView = (props: AuthFormHeaderViewProps) => {
  const { title, details, detailsLink, linkPath } = props;

  const history = useNavigate();

  return (
    <>
      <Grid container sx={justifyCenterContent}>
        <Typography sx={authTitleStyles}>{title}</Typography>
      </Grid>
      <Grid container sx={justifyCenterContent}>
        <Typography variant={TypographyVariants.H6} sx={authSubTitleStyles}>
          {details}
        </Typography>
        {detailsLink && (
          <Link
            tabIndex={0}
            variant={TypographyVariants.H5}
            sx={authLinkButtonStyles}
            onClick={() => history(linkPath || ROUTE_PATH_LOGIN)}
          >
            {detailsLink}
          </Link>
        )}
      </Grid>
    </>
  );
};
