import React from 'react';

import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import Alert from '@mui/material/Alert';

import { alertStyles, warningAlertIconStyles } from '../../styles/authFormStyles';
import { SUCCESS_LABEL, WARNING_LABEL } from '../../utils/constants/labels';
import { AlertViewProps } from '../../utils/interfaces/AlertViewProps';

export const AlertView = (props: AlertViewProps) => {
  let { severity, message, icon, sx, handleClose } = props;

  if (severity === WARNING_LABEL) {
    icon = <WarningAmberOutlinedIcon sx={warningAlertIconStyles} />;
  }

  return (
    <Alert
      icon={icon}
      severity={severity || SUCCESS_LABEL}
      sx={sx || alertStyles}
      onClose={() => handleClose && handleClose()}
    >
      {message}
    </Alert>
  );
};
