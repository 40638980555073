import { GridColumns } from '@mui/x-data-grid-pro';

import {
  ACTIVITY_DESCRIPTION_ID,
  ADVANCE_PERCENTAGE_ID,
  AGE_ID,
  APPRAISER_ID,
  BORROWER_TYPE_ID,
  BRAND_MODEL_ID,
  BUSINESS_SECTOR_ID,
  BUYER_BUSINESS_DESCRIPTION_ID,
  CAR_COUNT_ID,
  CAR_OWNERSHIP_ID,
  COLLATERAL_VALUATION_DATE_ID,
  EDUCATION_ID,
  EMPLOYMENT_STATUS_ID,
  FACTORING_TRANS_TYPE_ID,
  FAMILY_MEMBERS_COUNT_ID,
  FREE_CASH_ID,
  GENDER_ID,
  GET_SALARY_DAY_ID,
  GET_SALARY_ID,
  HAS_INVOICE_INSURANCE_ID,
  HAS_RECOURSE_ID,
  HOME_OWNERSHIP_TYPE_ID,
  INCOME_PARTNER_SALARY_ID,
  INCOME_PENSION_ID,
  INCOME_SALARY_ID,
  INCOME_TOTAL_ID,
  JOB_TYPE_ID,
  LIABILITIES_ELECTRICITY_ID,
  LIABILITIES_NET_TV_ID,
  LIABILITIES_PHONE_ID,
  LIABILITIES_TOTAL_ID,
  LOAN_ID,
  MANUFACTURING_YEAR_ID,
  MARTIAL_STATUS_ID,
  OCCUPATION_AREA_ID,
  PROPERTY_OWNERSHIP_ID,
  REAL_ESTATE_COUNTRY_CODE_ID,
  REAL_ESTATE_REGION_ID,
  REAL_ESTATE_SIZE_ID,
  REGISTRATION_COUNTRY_CODE_ID,
  REGISTRATION_DATE_ID,
  RESIDENCY_CITY_ID,
  RESIDENCY_COUNTRY_ID,
  RESIDENCY_REGION_ID,
  SALARY_PERIOD_ID,
  SELLER_BUSINESS_DESCRIPTION_ID,
  WORK_EXPERIENCE_CURR_EMPLOYER_MONTHS_ID,
  WORK_EXPERIENCE_CURR_EMPLOYER_YEARS_ID,
  WORK_EXPERIENCE_MONTHS_ID,
  WORK_EXPERIENCE_YEARS_ID,
} from '../../../../utils/constants/identifiers';
import {
  ACTIVITY_DESCRIPTION_LABEL,
  ADVANCE_PERCENTAGE_LABEL,
  AGE_LABEL,
  APPRAISER_LABEL,
  BORROWER_TYPE_LABEL,
  BRAND_MODEL_LABEL,
  BUSINESS_SECTOR_LABEL,
  BUYER_BUSINESS_DESCRIPTION_LABEL,
  CAR_COUNT_LABEL,
  CAR_OWNERSHIP_LABEL,
  COLLATERAL_VALUATION_DATE_LABEL,
  EDUCATION_LABEL,
  EMPLOYMENT_STATUS_LABEL,
  FACTORING_TRANS_TYPE_LABEL,
  FAMILY_MEMBERS_COUNT_LABEL,
  FREE_CASH_LABEL,
  GENDER_LABEL,
  GET_SALARY_DAY_LABEL,
  GET_SALARY_LABEL,
  HAS_INVOICE_INSURANCE_LABEL,
  HAS_RECOURSE_LABEL,
  HOME_OWNERSHIP_TYPE_LABEL,
  INCOME_PARTNER_SALARY_LABEL,
  INCOME_PENSION_LABEL,
  INCOME_SALARY_LABEL,
  INCOME_TOTAL_LABEL,
  JOB_TYPE_LABEL,
  LIABILITIES_ELECTRICITY_LABEL,
  LIABILITIES_NET_TV_LABEL,
  LIABILITIES_PHONE_LABEL,
  LIABILITIES_TOTAL_LABEL,
  LOAN_ID_LABEL,
  MANUFACTURING_YEAR_LABEL,
  MARTIAL_STATUS_LABEL,
  OCCUPATION_AREA_LABEL,
  PROPERTY_OWNERSHIP_LABEL,
  REAL_ESTATE_COUNTRY_CODE_LABEL,
  REAL_ESTATE_REGION_LABEL,
  REAL_ESTATE_SIZE_LABEL,
  REGISTRATION_COUNTRY_CODE_LABEL,
  REGISTRATION_DATE_LABEL,
  RESIDENCY_CITY_LABEL,
  RESIDENCY_COUNTRY_LABEL,
  RESIDENCY_REGION_LABEL,
  SALARY_PERIOD_LABEL,
  SELLER_BUSINESS_DESCRIPTION_LABEL,
  WORK_EXPERIENCE_CURR_EMPLOYER_MONTHS_LABEL,
  WORK_EXPERIENCE_CURR_EMPLOYER_YEARS_LABEL,
  WORK_EXPERIENCE_MONTHS_LABEL,
  WORK_EXPERIENCE_YEARS_LABEL,
} from '../../../../utils/constants/labels';
import { GridColType } from '../../../../utils/enums/GridColType';

export const borrowerDataColumns: GridColumns = [
  { field: LOAN_ID, headerName: LOAN_ID_LABEL },
  {
    field: AGE_ID,
    headerName: AGE_LABEL,
    type: GridColType.Number,
  },
  {
    field: CAR_COUNT_ID,
    headerName: CAR_COUNT_LABEL,
    type: GridColType.Number,
  },
  {
    field: FREE_CASH_ID,
    headerName: FREE_CASH_LABEL,
    type: GridColType.Number,
  },
  {
    field: GENDER_ID,
    headerName: GENDER_LABEL,
    type: GridColType.Number,
  },
  {
    field: JOB_TYPE_ID,
    headerName: JOB_TYPE_LABEL,
    type: GridColType.Number,
  },
  {
    field: EDUCATION_ID,
    headerName: EDUCATION_LABEL,
    type: GridColType.Number,
  },
  {
    field: INCOME_TOTAL_ID,
    headerName: INCOME_TOTAL_LABEL,
    type: GridColType.Number,
  },
  {
    field: GET_SALARY_ID,
    headerName: GET_SALARY_LABEL,
    type: GridColType.Number,
  },
  {
    field: INCOME_SALARY_ID,
    headerName: INCOME_SALARY_LABEL,
    type: GridColType.Number,
  },
  {
    field: GET_SALARY_DAY_ID,
    headerName: GET_SALARY_DAY_LABEL,
    type: GridColType.Number,
  },
  {
    field: INCOME_PENSION_ID,
    headerName: INCOME_PENSION_LABEL,
    type: GridColType.Number,
  },
  {
    field: RESIDENCY_CITY_ID,
    headerName: RESIDENCY_CITY_LABEL,
  },
  {
    field: BORROWER_TYPE_ID,
    headerName: BORROWER_TYPE_LABEL,
    type: GridColType.Number,
  },
  {
    field: CAR_OWNERSHIP_ID,
    headerName: CAR_OWNERSHIP_LABEL,
    type: GridColType.Number,
  },
  {
    field: RESIDENCY_REGION_ID,
    headerName: RESIDENCY_REGION_LABEL,
  },
  {
    field: SALARY_PERIOD_ID,
    headerName: SALARY_PERIOD_LABEL,
    type: GridColType.Number,
  },
  {
    field: LIABILITIES_PHONE_ID,
    headerName: LIABILITIES_PHONE_LABEL,
    type: GridColType.Number,
  },
  {
    field: LIABILITIES_TOTAL_ID,
    headerName: LIABILITIES_TOTAL_LABEL,
    type: GridColType.Number,
  },
  {
    field: MARTIAL_STATUS_ID,
    headerName: MARTIAL_STATUS_LABEL,
    type: GridColType.Number,
  },
  {
    field: RESIDENCY_COUNTRY_ID,
    headerName: RESIDENCY_COUNTRY_LABEL,
  },
  {
    field: OCCUPATION_AREA_ID,
    headerName: OCCUPATION_AREA_LABEL,
    type: GridColType.Number,
  },
  {
    field: EMPLOYMENT_STATUS_ID,
    headerName: EMPLOYMENT_STATUS_LABEL,
    type: GridColType.Number,
  },
  {
    field: FAMILY_MEMBERS_COUNT_ID,
    headerName: FAMILY_MEMBERS_COUNT_LABEL,
    type: GridColType.Number,
  },
  {
    field: INCOME_PARTNER_SALARY_ID,
    headerName: INCOME_PARTNER_SALARY_LABEL,
    type: GridColType.Number,
  },
  {
    field: PROPERTY_OWNERSHIP_ID,
    headerName: PROPERTY_OWNERSHIP_LABEL,
    type: GridColType.Number,
  },
  {
    field: WORK_EXPERIENCE_YEARS_ID,
    headerName: WORK_EXPERIENCE_YEARS_LABEL,
    type: GridColType.Number,
  },
  {
    field: HOME_OWNERSHIP_TYPE_ID,
    headerName: HOME_OWNERSHIP_TYPE_LABEL,
    type: GridColType.Number,
  },
  {
    field: LIABILITIES_NET_TV_ID,
    headerName: LIABILITIES_NET_TV_LABEL,
    type: GridColType.Number,
  },
  {
    field: LIABILITIES_ELECTRICITY_ID,
    headerName: LIABILITIES_ELECTRICITY_LABEL,
    type: GridColType.Number,
  },
  {
    field: WORK_EXPERIENCE_MONTHS_ID,
    headerName: WORK_EXPERIENCE_MONTHS_LABEL,
    type: GridColType.Number,
  },
  {
    field: WORK_EXPERIENCE_CURR_EMPLOYER_YEARS_ID,
    headerName: WORK_EXPERIENCE_CURR_EMPLOYER_YEARS_LABEL,
    type: GridColType.Number,
  },
  {
    field: WORK_EXPERIENCE_CURR_EMPLOYER_MONTHS_ID,
    headerName: WORK_EXPERIENCE_CURR_EMPLOYER_MONTHS_LABEL,
    type: GridColType.Number,
  },
  {
    field: REAL_ESTATE_SIZE_ID,
    headerName: REAL_ESTATE_SIZE_LABEL,
    type: GridColType.Number,
  },
  {
    field: REAL_ESTATE_COUNTRY_CODE_ID,
    headerName: REAL_ESTATE_COUNTRY_CODE_LABEL,
  },
  {
    field: REAL_ESTATE_REGION_ID,
    headerName: REAL_ESTATE_REGION_LABEL,
  },
  {
    field: BRAND_MODEL_ID,
    headerName: BRAND_MODEL_LABEL,
  },
  {
    field: APPRAISER_ID,
    headerName: APPRAISER_LABEL,
  },
  {
    field: MANUFACTURING_YEAR_ID,
    headerName: MANUFACTURING_YEAR_LABEL,
    type: GridColType.Number,
  },
  {
    field: REGISTRATION_DATE_ID,
    headerName: REGISTRATION_DATE_LABEL,
    type: GridColType.Date,
  },
  {
    field: BUSINESS_SECTOR_ID,
    headerName: BUSINESS_SECTOR_LABEL,
    type: GridColType.Number,
  },
  {
    field: ACTIVITY_DESCRIPTION_ID,
    headerName: ACTIVITY_DESCRIPTION_LABEL,
  },
  {
    field: COLLATERAL_VALUATION_DATE_ID,
    headerName: COLLATERAL_VALUATION_DATE_LABEL,
    type: GridColType.Date,
  },
  {
    field: REGISTRATION_COUNTRY_CODE_ID,
    headerName: REGISTRATION_COUNTRY_CODE_LABEL,
  },
  {
    field: SELLER_BUSINESS_DESCRIPTION_ID,
    headerName: SELLER_BUSINESS_DESCRIPTION_LABEL,
  },
  {
    field: BUYER_BUSINESS_DESCRIPTION_ID,
    headerName: BUYER_BUSINESS_DESCRIPTION_LABEL,
  },
  {
    field: ADVANCE_PERCENTAGE_ID,
    headerName: ADVANCE_PERCENTAGE_LABEL,
    type: GridColType.Number,
  },
  {
    field: FACTORING_TRANS_TYPE_ID,
    headerName: FACTORING_TRANS_TYPE_LABEL,
    type: GridColType.Number,
  },
  {
    field: HAS_RECOURSE_ID,
    headerName: HAS_RECOURSE_LABEL,
    type: GridColType.Boolean,
  },
  {
    field: HAS_INVOICE_INSURANCE_ID,
    headerName: HAS_INVOICE_INSURANCE_LABEL,
    type: GridColType.Boolean,
  },
];
