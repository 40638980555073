import React from 'react';

import Box from '@mui/material/Box';

import { mainContentInvisibleSidebar, mainContentVisibleSidebar } from '../../styles/commonStyles';
import { MAIN_TAG } from '../../utils/constants/components';
import { MainContentProps } from '../../utils/interfaces/layout/MainContentProps';

export const MainContentView = ({ children, sidebarVisible }: MainContentProps) => (
  <Box
    component={MAIN_TAG}
    sx={sidebarVisible ? mainContentVisibleSidebar : mainContentInvisibleSidebar}
  >
    {children}
  </Box>
);
