import React from 'react';
import { ToastContainer } from 'react-toastify';
import { Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { TOAST_MESSAGE_DURATION_MS } from '../../utils/constants/options';

export const ToastMessageView = () => {
  return (
    <ToastContainer
      autoClose={TOAST_MESSAGE_DURATION_MS}
      hideProgressBar
      newestOnTop
      closeOnClick
      transition={Slide}
      pauseOnFocusLoss={false}
      className="toast-message"
    />
  );
};
