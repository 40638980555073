import React from 'react';
import { useForm } from 'react-hook-form';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { formGridSpacing } from '../../../../styles/formStyles';
import { FORM_TAG } from '../../../../utils/constants/components';
import {
  CREATE_BORROWER_DATA_FIELD_FORM_ID,
  DESCRIPTION_ID,
  LABEL_ID,
} from '../../../../utils/constants/identifiers';
import { DESCRIPTION_LABEL, LABEL_LABEL } from '../../../../utils/constants/labels';
import { FormProps } from '../../../../utils/interfaces/form/FormProps';
import { InputField } from '../../../common/form/InputField';
import { LargeGridField } from '../../../common/form/grid/LargeGridField';

export const CreateBorrowerDataFieldForm = (props: FormProps) => {
  const { onSubmit } = props;

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  return (
    <Box
      component={FORM_TAG}
      id={CREATE_BORROWER_DATA_FIELD_FORM_ID}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid container rowSpacing={1} columnSpacing={formGridSpacing}>
        <LargeGridField>
          <InputField
            id={LABEL_ID}
            label={LABEL_LABEL}
            register={register}
            errors={errors}
            required
          />
        </LargeGridField>
        <LargeGridField>
          <InputField
            id={DESCRIPTION_ID}
            label={DESCRIPTION_LABEL}
            register={register}
            errors={errors}
          />
        </LargeGridField>
      </Grid>
    </Box>
  );
};
