import { useContext } from 'react';

import { PermissionMapped } from '../../interfaces/Permissions';
import { Context } from '../../store';
import { ContextType } from '../../store/types/ContextType';

export const usePermissions = () => {
  const { state } = useContext<ContextType>(Context);

  const userPermissions = state.user?.permissions;

  // TODO: Ask BE why they dont return any permissions sometimes.
  // Check if we have any permissions
  const hasPermissions = (permissions: Record<string, PermissionMapped>) => {
    return Object.keys(permissions).length !== 0;
  };

  const hasCreatePermission = (permissionType: string): boolean => {
    if (userPermissions && hasPermissions(userPermissions)) {
      return userPermissions[permissionType].create;
    }
    return false;
  };

  const hasReadPermission = (permissionType: string): boolean => {
    if (userPermissions && hasPermissions(userPermissions)) {
      return userPermissions[permissionType].read;
    }
    return false;
  };

  const hasUpdatePermission = (permissionType: string): boolean => {
    if (userPermissions && hasPermissions(userPermissions)) {
      return userPermissions[permissionType].update;
    }
    return false;
  };

  const hasDeletePermission = (permissionType: string): boolean => {
    if (userPermissions && hasPermissions(userPermissions)) {
      return userPermissions[permissionType].delete;
    }
    return false;
  };

  return {
    hasReadPermission,
    hasCreatePermission,
    hasUpdatePermission,
    hasDeletePermission,
  };
};
