import { UseQueryResult, useQuery } from 'react-query';

import { GetRequestProps } from '../../interfaces/GetRequestProps';

export const useGetRequest = <TResult>(
  request: GetRequestProps<TResult>,
  keys: {} = {},
): UseQueryResult<TResult> => {
  let key: Array<string | object | number> = [request.key, keys];
  if (request.params) {
    if (
      typeof request.params !== 'object' ||
      Object.keys(request.params).filter((x) => request.params![x]).length > 0
    ) {
      key = [...key, request.params];
    }
  }

  return useQuery<TResult>(key, request.func, request.options);
};
