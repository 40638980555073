import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { isAuthenticated } from '../../../utils/authentication/authentication';
import { ROUTE_PATH_DASHBOARD } from '../../../utils/constants/routes';

export const AuthenticationProtectedRoute = () => {
  if (isAuthenticated()) {
    return <Navigate to={ROUTE_PATH_DASHBOARD} replace />;
  }

  return <Outlet />;
};
