import React, { useContext, useState } from 'react';
import { FieldValues } from 'react-hook-form';

import {
  deleteBorrowerDataField,
  getBorrowerDataFields,
  updateBorrowerDataField,
} from '../../../../api/controllers/BorrowerDataController';
import {
  CLOSE_CONFIRMATION_DIALOG,
  OPEN_CONFIRMATION_DIALOG,
  OPEN_FORM_DIALOG,
} from '../../../../utils/constants/actions';
import { DELETE_FIELD_CONFIRMATION } from '../../../../utils/constants/confirmations';
import {
  DELETE_FIELD_DIALOG_ID,
  UPDATE_BORROWER_DATA_FIELD_FORM_ID,
} from '../../../../utils/constants/identifiers';
import { EMPTY_QUERY_ID } from '../../../../utils/constants/options';
import { BORROWER_DATA_FIELDS_PERMISSION } from '../../../../utils/constants/permissions';
import { DELETE_FIELD_TITLE, EDIT_FIELD_TITLE } from '../../../../utils/constants/titles';
import { FormDialogSubmitType } from '../../../../utils/enums/FormDialogSubmitType';
import { closeFormHandler } from '../../../../utils/helpers/closeFormHandler';
import { Context } from '../../../../utils/store';
import { ContextType } from '../../../../utils/store/types/ContextType';
import { UpdateBorrowerDataFieldForm } from '../forms/UpdateBorrowerDataFieldForm';
import { BorrowerDataFieldsTableView } from '../tables/BorrowerDataFieldsTableView';

export const BorrowerDataFieldsTab = () => {
  const { dispatch } = useContext<ContextType>(Context);

  const [fieldId, setFieldId] = useState<number>();

  const borrowerDataFields = getBorrowerDataFields();
  const deleteField = deleteBorrowerDataField();
  const updateField = updateBorrowerDataField(fieldId || EMPTY_QUERY_ID);

  const handleEditClick = (id: number): void => {
    const field = borrowerDataFields.find((x) => x.id === id);
    setFieldId(field?.id);

    if (field) {
      dispatch({
        type: OPEN_FORM_DIALOG,
        payload: {
          title: EDIT_FIELD_TITLE,
          content: <UpdateBorrowerDataFieldForm onSubmit={handleEditSubmit} field={field} />,
          formId: UPDATE_BORROWER_DATA_FIELD_FORM_ID,
          submitType: FormDialogSubmitType.Edit,
        },
      });
    }
  };

  const handleEditSubmit = (data: FieldValues): void => {
    updateField.mutateAsync(data).then((response) => closeFormHandler(dispatch, response.status));
  };

  const handleDeleteClick = (id: number): void => {
    dispatch({
      type: OPEN_CONFIRMATION_DIALOG,
      payload: {
        dialogId: DELETE_FIELD_DIALOG_ID,
        title: DELETE_FIELD_TITLE,
        content: DELETE_FIELD_CONFIRMATION,
        onAgree: () => handleDeleteConfirm(id),
      },
    });
  };

  const handleDeleteConfirm = (id: number): void => {
    deleteField.mutateAsync(id);

    dispatch({ type: CLOSE_CONFIRMATION_DIALOG });
  };

  return (
    <BorrowerDataFieldsTableView
      handleDelete={handleDeleteClick}
      handleEdit={handleEditClick}
      fieldsData={borrowerDataFields}
      permissionType={BORROWER_DATA_FIELDS_PERMISSION}
    />
  );
};
