import React, { useState } from 'react';
import { FieldValues } from 'react-hook-form';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import {
  createCountrySpv,
  getAllOriginatorCountries,
} from '../../../../../api/controllers/OriginatorSpvController';
import { OriginatorCountrySpvSettingBase } from '../../../../../api/types/originatorTypes';
import { stepperButtonsWrapperStyles } from '../../../../../styles/stepperStyles';
import { CANCEL_BUTTON } from '../../../../../utils/constants/buttons';
import { ButtonVariants } from '../../../../../utils/enums/ButtonVariants';
import { CountrySpvViewType } from '../../../../../utils/enums/CountrySpvViewType';
import { HttpStatusCodes } from '../../../../../utils/enums/HttpStatusCodes';
import { AddSpvWrapperProps } from '../../../../../utils/interfaces/originator/details/AddSpvWrapperProps';
import { CountrySpvGrid } from './CountrySpvGrid';
import { CreateCountrySpvForm } from './CreateCountrySpvForm';

export const AddSpvWrapper = (props: AddSpvWrapperProps) => {
  const { handleClose, view, spvs, countryId, originatorCountryId, originatorId } = props;

  const [currentView, setCurrentView] = useState<CountrySpvViewType>(
    view || CountrySpvViewType.Form,
  );

  const allOriginatorCountries = getAllOriginatorCountries(originatorId);

  const currentOriginatorCountryId = originatorCountryId
    ? originatorCountryId
    : allOriginatorCountries.find((x) => x.countryId === countryId)?.id || 0;

  const createCountrySpvMutation = createCountrySpv(originatorId, currentOriginatorCountryId);

  const onSubmit = (data: FieldValues) => {
    createCountrySpvMutation.mutateAsync(data as OriginatorCountrySpvSettingBase).then((x) => {
      if (x.status !== HttpStatusCodes.BadRequest) {
        setCurrentView(CountrySpvViewType.Table);
      }
    });
  };

  return (
    <>
      {currentView === CountrySpvViewType.Form ? (
        <CreateCountrySpvForm handleClose={handleClose} spvs={spvs} onSubmit={onSubmit} />
      ) : (
        <CountrySpvGrid
          setView={setCurrentView}
          originatorId={originatorId}
          originatorCountryId={currentOriginatorCountryId}
        />
      )}
      <Box sx={stepperButtonsWrapperStyles}>
        <Button variant={ButtonVariants.Outlined} onClick={handleClose}>
          {CANCEL_BUTTON}
        </Button>
      </Box>
    </>
  );
};
