import React from 'react';

import TextField from '@mui/material/TextField';

import { authFieldStyles } from '../../../styles/authFormStyles';
import { PASSWORD_FIELD_CONFIRM_ID } from '../../../utils/constants/identifiers';
import { PASSWORD_CONFIRM_LABEL } from '../../../utils/constants/labels';
import { REQUIRED_FIELD_MESSAGE } from '../../../utils/constants/messages';
import { FORM_FIELD_MARGIN } from '../../../utils/constants/styles';
import { handlePasswordMatch, handleRequiredFields } from '../../../utils/helpers/formFieldsHelper';
import { ConfirmPasswordFieldProps } from '../../../utils/interfaces/form/FormFieldProps';

export const ConfirmPasswordField = (props: ConfirmPasswordFieldProps) => {
  const { register, errors, watch, watchPasswordFieldId } = props;

  return (
    <TextField
      margin={FORM_FIELD_MARGIN}
      id={PASSWORD_FIELD_CONFIRM_ID}
      label={PASSWORD_CONFIRM_LABEL}
      type="password"
      InputLabelProps={{ required: true }}
      {...register(PASSWORD_FIELD_CONFIRM_ID, {
        required: REQUIRED_FIELD_MESSAGE,
        validate: (value: string) => handlePasswordMatch(value, watch(watchPasswordFieldId)),
      })}
      error={Boolean(errors[PASSWORD_FIELD_CONFIRM_ID])}
      helperText={handleRequiredFields(errors, PASSWORD_FIELD_CONFIRM_ID)}
      sx={authFieldStyles}
    />
  );
};
