import { useState } from 'react';

interface UseStepperReturn {
  activeStep: number;
  goToNextStep: () => void;
}

export const useStepper = <T>(steps: T[], handleClose: () => void): UseStepperReturn => {
  const [activeStep, setActiveStep] = useState(0);

  const goToNextStep = () => {
    if (activeStep + 1 === steps.length) {
      handleClose();
      return;
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  return {
    activeStep,
    goToNextStep,
  };
};
